import React, { useEffect, useState, useReducer } from "react";
import {
    Header,
    Tabs,
    Box,
    Button,
    Flashbar,
    SpaceBetween,
    Grid,
    AppLayout,
    Popover,
    ButtonDropdown,
} from '@amzn/awsui-components-react';
import "../../css/Exception.css"
import ExceptionSummary from "../common/ExceptionSummary";
import ExceptionTrends from "../common/ExceptionTrends";
import {getAcknowledgeDataForVAT, getUserAlias, isObligationUnderReview} from "../../utils/utils";
import ObligationDetails from "../common/ObligationDetails";
import {
    downloadAuditPackFileBys3PreSignedURL,
    getReportingAggregatedData,
    modifyWorkflowStatus
} from "../redux/exceptionAPIs";
import '../../css/style.css';
import Adjustments from "../common/adjustment/Adjustments";
import ExpandableSection from "@amzn/awsui-components-react/polaris/expandable-section";
import { postMessage } from "../../../phastos/components/Slack/slackIntegrationAPIs";
import { Correspondence } from "../common/Correspondence";
import ViewDashboards from "./ViewDashboards";
import ViewAttachments from "../common/attachment/ViewAttachments";
import ViewSummaryBreakdown from "./ViewSummaryBreakdown";
import VATErrorSummary from "./VATErrorSummary";
import VATSummary from "./VATSummary";
import VATChecklists from "./VATChecklists";
import { getAPIInputContext } from "../../../../common/utils/apiUtils";
import { API } from "aws-amplify";
import { FILING_GET_TRANSMISSION_RECEIPT, IS_PAGE_AUTHORIZED, PHASTOS_GET_REPORTING_AGGREGATED_DATA } from "../../../../ApiPaths";
import { downloadFile } from "../../../../common/utils/s3Utils";
import SourceSummaryView from "../../../sourceSummary/SourceSummaryView";
import { useNavigate } from "react-router-dom";
import { exportToExcel } from 'react-json-to-excel';
import { SOURCE_SUMMARY_DETAILS, sourceSummaryTableReducer, sourceSummaryTableReducerInit } from "../../../sourceSummary/redux/sourceSummaryReducer";
import { fetchSourceSummaryDetails } from "../../../sourceSummary/redux/sourceSummaryAPI";
import { OBLIGATION_STATUS, PHASTOS_OBLIGATION } from "../common/constants";
import { getExceptionTransactionDetails } from "../common/adjustment/constants/errorSummaryUtils";
import { RejectReturnModal } from "../common/RejectReturnModal";
import {APPROVER_ROLE, CLIENT_NAME, REVIEWER_ROLE} from "../../../../common/constants/constants";
import {
    DOWNLOAD_INITIAL_STATE,
    downloadReducer,
    downloadReducerInit
} from "../../../excalibur/components/downloadDocumentReducer";
import {INITIAL_STATE, VATReducer, VATReducerInit} from "./VATReducer";
import { DOWNLOAD_HEADERS_FOR_BAS_SUMMARY } from "../VAT/constants/boxLevelConstants";

function VATException(props) {
    const navigate = useNavigate();

    const { 
        itemData,
        slackChannel,
        correspondenceMessages,
        correspondenceLoading,
        handleToolsChange,
        handleNewMessage,
        exceptionSummaryData,
        updateWorkflowStatus,
        updateWorkflowStatusDispatcher,
    } = props;

    const exceptionData = itemData?.exceptionData;
    const stepsInitialState = getAcknowledgeDataForVAT(itemData?.acknowledgementMap);
    const [stepsState, setStepsState] = useState(stepsInitialState);
    const [approveButtonDisabled, setApproveButtonDisabled] = useState(false);
    const [obligationStatus, setObligationStatus] = useState(itemData?.status);
    const [downloadStatementDisabled, setDownloadStatementDisabled] = useState(false);
    const isUnderReview = isObligationUnderReview(obligationStatus);
    const [isTransactionReviewLoading, setIsTransactionReviewLoading] = useState(false);
    const [lastEvaluatedKey, setLastEvaluatedKey] = useState(null);
    const [lastUpdatedOn, setLastUpdatedOn] = useState('-');
    const [vatStateMap, vatDispatcher] = useReducer(VATReducer,
        INITIAL_STATE, VATReducerInit);

    const exceptionSummaryTableProp = [
        {
            'step': 'Summary',
            'status': stepsState.summaryStep.completed ? 'Completed' : 'Pending',
            'person': stepsState.summaryStep.user,
            'date': stepsState.summaryStep.updatedOn,
        },
        {
            'step': 'Checklists',
            'status': stepsState.checklistStep.completed ? 'Completed' : 'Pending',
            'person': stepsState.checklistStep.user,
            'date': stepsState.checklistStep.updatedOn,
        },
        {
            'step': 'Insights',
            'status': stepsState.insightStep.completed ? 'Completed' : 'Pending',
            'person': stepsState.insightStep.user,
            'date': stepsState.insightStep.updatedOn,
        },
        {
            'step': 'Transaction review',
            'status': stepsState.transactionReviewStep.completed ? 'Completed' : 'Pending',
            'person': stepsState.transactionReviewStep.user,
            'date': stepsState.transactionReviewStep.updatedOn,
        },
        {
            'step': 'Audit',
            'status': stepsState.auditStep.completed ? 'Completed' : 'Pending',
            'person': stepsState.auditStep.user,
            'date': stepsState.auditStep.updatedOn,
        }
    ];

    const setStepStateHandler = async (step) => {
        const userAlias = await getUserAlias();
        setStepsState({
            ...stepsState,
            [step]: {completed: true, user: userAlias, updatedOn: new Date().toLocaleDateString()},
        });
    };

    const [transactionReviewEditMode, setTransactionReviewEditMode] = useState(false);

    // User Role

    const [userRole, setUserRole] = useState(REVIEWER_ROLE);

    useEffect(() => {
        getAPIInputContext().then(input => {
            input.body.resourceName = REVIEWER_ROLE;

            API.post("TWIN_API", IS_PAGE_AUTHORIZED, input).then(resp => {
                if (resp.status === 200) {
                    if(resp.isAuthorized === true){
                        setUserRole(REVIEWER_ROLE);
                    }
                }
            });
        });
        getAPIInputContext().then(input => {
            input.body.resourceName = APPROVER_ROLE;

            API.post("TWIN_API", IS_PAGE_AUTHORIZED, input).then(resp => {
                if (resp.status === 200) {
                    if(resp.isAuthorized === true){
                        setUserRole(APPROVER_ROLE);
                    }
                }
            });
        });
    }, [])

    // Send for Approval

    const isSendForApproveButtonDisabled = () => {
        return !(
            isUnderReview
            && stepsState.summaryStep.completed && stepsState.checklistStep.completed
            && stepsState.insightStep.completed && stepsState.transactionReviewStep.completed 
            && stepsState.auditStep.completed
        );
    };

    const generateUpdatingStatusRequest = (statusValue) => {
        return {
            parentWorkflowId: itemData?.parentWorkflowId,
            status: statusValue,
            acknowledgementStructureMap: {},
        }
    }

    const sendForApproveButtonClickHandler = () => {
        const onSuccess = async () => {
            const userAlias = await getUserAlias();
            postMessage(slackChannel, `${userAlias}@ has initiated the approval flow.`);
            setApproveButtonDisabled(true);
            setObligationStatus(OBLIGATION_STATUS.UnderApproval)
        };
        modifyWorkflowStatus(updateWorkflowStatusDispatcher,
            generateUpdatingStatusRequest(OBLIGATION_STATUS.UnderApproval),
            onSuccess);
    };

    // Approve Return

    const isApproveButtonDisable = () => {
        return !(
            obligationStatus === OBLIGATION_STATUS.UnderApproval 
            && checklistState.approver.option1 && checklistState.approver.option2 
            && checklistState.approver.option3 && checklistState.approver.option4
        );
    };

    const approveButtonClickHandler = (event) => {
        event.stopPropagation();
        const onSuccess = async () => {
            const userAlias = await getUserAlias();
            postMessage(slackChannel, `${userAlias}@ has approved the return.`);
            setObligationStatus(OBLIGATION_STATUS.UnderFiling)
        };
        modifyWorkflowStatus(updateWorkflowStatusDispatcher,
            generateUpdatingStatusRequest(OBLIGATION_STATUS.UnderFiling),
            onSuccess);
    };

    // Reject Return

    const [rejectConfirmModal, setRejectConfirmModal] = useState(false);

    const rejectButtonClickHandler = (event) => {
        event.stopPropagation();
        setRejectConfirmModal(true);
    };

    const rejectConfirmHandler = (rejectReason) => {
        const onSuccess = async () => {
            const userAlias = await getUserAlias();
            postMessage(slackChannel, `${userAlias}@ has rejected the return for the following reason: \n"${rejectReason}"`);
            setObligationStatus(OBLIGATION_STATUS.UnderReview)
        };
        modifyWorkflowStatus(updateWorkflowStatusDispatcher,
            generateUpdatingStatusRequest(OBLIGATION_STATUS.UnderReview),
            onSuccess);
    };

    // Ingestion

    const onIngestionClickHandler = () => {
        setActiveTabId("tenth");
    };

    const [activeTabId, setActiveTabId] = useState('first');

    // Downloads

    const downloadStatement = () => {
        setDownloadStatementDisabled(true);
        getAPIInputContext().then(async input => {
            input.body.parentWorkflowId = itemData?.parentWorkflowId;

            await API.post("TWIN_API", PHASTOS_GET_REPORTING_AGGREGATED_DATA, input).then(resp => {
                if (resp.status === 200) {
                    const data = JSON.parse(resp.body.data);
                    return data;
                } 
            })
            .then(async (data) => {
                let dataMap = {
                    "G1": "0",
                    "G2": "0",
                    "G3": "0",
                    "G10": "0",
                    "G11": "0"
                }
                await data.reportingAggregatedDataInfoList.forEach(element => {
                    const key = element.groupingAttribute.toString();
                    // TODO: - Converting value to absolute value, add check for negative values
                    const value = Math.abs(Math.round(element.totalGrossAmount)).toString();
                    dataMap = {
                        ...dataMap, 
                        [key] : value
                    };
                })
                input.body.useCase = "FOXHOUND";
                input.body.subUseCase = "DATA_AGGREGATION";
                input.body.transactionId = input.body.parentWorkflowId + new Date();
                input.body.clientName = CLIENT_NAME;
                input.body.additionalDataMap = dataMap;
            })
            .then(async () => {
                try {
                    await API.post("TWIN_API", FILING_GET_TRANSMISSION_RECEIPT, input).then(async (resp) => {
                        downloadFile(resp.body.folderPath, resp.body.s3Bucket, props.domain, () => {
                            setDownloadStatementDisabled(false);
                        });
                    });
                } catch (e) {
                    // OPTIONS call throwing error on Firefox for this API for the beta workflow
                    console.log(e);
                    setDownloadStatementDisabled(false);
                }
            });
        });
    };

    const [downloadAuditFileState, downloadAuditFileDispatcher] = useReducer(downloadReducer,
        DOWNLOAD_INITIAL_STATE, downloadReducerInit);

    const downloadAuditFile = () => {
        const request = {'parentWorkflowId' : itemData?.parentWorkflowId};
        downloadAuditPackFileBys3PreSignedURL(downloadAuditFileDispatcher, request);
    };

    const [triggeredBASSummaryDownload, setTriggeredBASSummaryDownload] = useState(false);
    // eslint-disable-next-line no-unused-vars
    const [lastDownloadOn, setlastDownloadOn] = useState('-');

    const downloadBASSummaryFile = () => {
        setTriggeredBASSummaryDownload(true);
        const request = {
            setLoading: setSummaryLoading,
            setData: setSummaryData,
            setLastUpdatedOn: setLastUpdatedOn,
            setLastEvaluatedKey: setLastEvaluatedKey,
            parentWorkflowId: itemData?.parentWorkflowId,
            useCase: itemData?.phastosUseCaseName,
            lastEvaluatedKey: lastEvaluatedKey
        };
        getReportingAggregatedData(request);
    };

    const [summaryData, setSummaryData] = useState([]);
    const [summaryLoading, setSummaryLoading] = useState(false);

    const getRenamedDataForDownloadSummary = (inputData) => {
        return inputData.map(obj => ({
            [DOWNLOAD_HEADERS_FOR_BAS_SUMMARY.BOX_LABEL]: obj.groupingAttribute,
            [DOWNLOAD_HEADERS_FOR_BAS_SUMMARY.LABEL_DESCRIPTION]: obj.labelDescription,
            [DOWNLOAD_HEADERS_FOR_BAS_SUMMARY.TOTAL_GROSS_AMOUNT]: obj.totalGrossAmount,
            [DOWNLOAD_HEADERS_FOR_BAS_SUMMARY.ADJUSTMENT_VALUE]: obj.adjustmentValue,
            [DOWNLOAD_HEADERS_FOR_BAS_SUMMARY.TOTAL_VALUE_AFTER_ADJUSTMENT]: obj.totalValueAfterAdjustment,
        }));
    }

    useEffect(() => {
        if (summaryData === null || summaryData === undefined || summaryData.length === 0 || !triggeredBASSummaryDownload) { return };
        const formattedSummaryData = JSON.parse(JSON.stringify(getRenamedDataForDownloadSummary(summaryData), null , 4));
        exportToExcel(formattedSummaryData, `BAS Summary - ${itemData?.obligationName} - ${itemData?.country} - ${itemData?.reportingPeriod}`);
        setTriggeredBASSummaryDownload(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [summaryData]);

    // eslint-disable-next-line no-unused-vars
    const downloadAuditPacket = () => {
        downloadStatement();
        // downloadTransactionsFile();
        downloadBASSummaryFile();
    };

    // Checklist

    const [checklistState, setChecklistState] = useState({
        preparer: {
            option1: stepsState.checklistStep.completed,
            option2: stepsState.checklistStep.completed,
            option3: stepsState.checklistStep.completed,
            option4: stepsState.checklistStep.completed,
            option5: stepsState.checklistStep.completed,
            option6: stepsState.checklistStep.completed,
        },
        approver: {
            option1: obligationStatus === OBLIGATION_STATUS.UnderFiling || obligationStatus === OBLIGATION_STATUS.Filed,
            option2: obligationStatus === OBLIGATION_STATUS.UnderFiling || obligationStatus === OBLIGATION_STATUS.Filed,
            option3: obligationStatus === OBLIGATION_STATUS.UnderFiling || obligationStatus === OBLIGATION_STATUS.Filed,
            option4: obligationStatus === OBLIGATION_STATUS.UnderFiling || obligationStatus === OBLIGATION_STATUS.Filed,
        }
    });

    // Filing

    const filingBtnHandler = () => {
        navigate("../../filing", {replace: false});
    };

    // Load VATErrorSummary

    const [transactionSummaryData, setTransactionSummaryData] = useState([]);
    useEffect(() => {
        const request = {
            parentWorkflowId: itemData?.parentWorkflowId,
            setItems: setTransactionSummaryData,
            setIsLoading: setIsTransactionReviewLoading,
            useCase: itemData?.obligationName,
            phastosObligation: PHASTOS_OBLIGATION.VAT
        };
        getExceptionTransactionDetails(request);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // Load SourceSummaryView

    const [sourceSummaryData, sourceSummaryTableDispatcher] = useReducer(sourceSummaryTableReducer,
        SOURCE_SUMMARY_DETAILS, sourceSummaryTableReducerInit);
    
    const refreshSourceSummaryData = () => {
        sourceSummaryTableDispatcher({
            type: "API_BEGIN_FOR_SOURCE_SUMMARY", payload: {}
        });
        const request = {
            parentWorkflowId: itemData?.parentWorkflowId,
            tableDispatcher: sourceSummaryTableDispatcher,
            useCase: itemData?.obligationName,
            phastosObligation: PHASTOS_OBLIGATION.VAT
        }
        fetchSourceSummaryDetails(request);
    } 
    useEffect(() => {
        refreshSourceSummaryData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    const workflowIdentificationProps = {
        reportingPeriod : itemData?.reportingPeriod,
        obligationName : itemData?.obligationName,
        country : itemData?.country
    };

    return (
        <>
            <AppLayout
                navigationHide={true}
                toolsHide={slackChannel === undefined}
                tools={
                        <Correspondence 
                            correspondenceMessages={correspondenceMessages}
                            correspondenceLoading={correspondenceLoading}
                            handleNewMessage={handleNewMessage}
                        />
                }
                onToolsChange={handleToolsChange}
                content={
                    <div className="phastos-exception">
                        <Flashbar items={vatStateMap?.flashBarItem}/>
                        <Flashbar items={exceptionSummaryData?.flashBarItem}/>
                        <Flashbar items={updateWorkflowStatus?.flashBarItem}/>
                        <Flashbar items={downloadAuditFileState?.flashBarItem}/>
                        <SpaceBetween size='l'>
                            <div className="progress-card">
                                <ul id="progressbar">
                                    <li className="active clickable" id="ingestion" onClick={onIngestionClickHandler}><strong>Ingestion</strong></li>
                                    <li className={obligationStatus === OBLIGATION_STATUS.UnderApproval || obligationStatus === OBLIGATION_STATUS.UnderFiling || obligationStatus === OBLIGATION_STATUS.Filed ? "active" : obligationStatus === OBLIGATION_STATUS.UnderReview ? "semi-active" : ""} id="review"><strong>Review</strong></li>
                                    <li id="approve" className={obligationStatus === OBLIGATION_STATUS.UnderApproval ? "semi-active" : obligationStatus === OBLIGATION_STATUS.UnderFiling || obligationStatus === OBLIGATION_STATUS.Filed ? "active" : ""}><strong>Approve</strong></li>
                                    <li id="filing_in_progress" className={obligationStatus === OBLIGATION_STATUS.UnderFiling ? "semi-active" : obligationStatus === OBLIGATION_STATUS.Filed ? "active" : ""}><strong>Filing in progress</strong></li>
                                    <li id="filed" style={{ width: '0' }} className={obligationStatus === OBLIGATION_STATUS.Filed ? "active" : ""}><strong>Filed</strong></li>
                                </ul>
                            </div>
                            <ExpandableSection
                                variant="container"
                                header={<Header actions={<SpaceBetween direction="horizontal" size="xs">
                                    <div onClick={(e) => {e.stopPropagation()}}>
                                        <ButtonDropdown 
                                            items={[{id :"auditFile", text : "Audit File"}]}
                                            variant="normal"
                                            loading={downloadStatementDisabled || summaryLoading}
                                            disabled={downloadStatementDisabled || summaryLoading}
                                            onItemClick={(e) => {
                                                switch (e.detail.id) {
                                                    case "statement":
                                                        downloadStatement();
                                                        break;
                                                    case "auditFile":
                                                        downloadAuditFile();
                                                        break;
                                                    default:
                                                        throw new Error(`Invalid dropdownId: ${e.detail.id}`);
                                                }
                                            }}
                                        >
                                        Download
                                        </ButtonDropdown>
                                    </div>
                                    {userRole === REVIEWER_ROLE ? <SpaceBetween direction="horizontal" size="xs">
                                        <Button disabled={obligationStatus !== OBLIGATION_STATUS.UnderFiling} onClick={filingBtnHandler}>Start filing</Button>
                                            <Button disabled={isSendForApproveButtonDisabled() || approveButtonDisabled}
                                                    onClick={sendForApproveButtonClickHandler}>Send for approval</Button>
                                        {(isSendForApproveButtonDisabled() || approveButtonDisabled) &&
                                            <Popover
                                                position="top"
                                                size="small"
                                                triggerType="custom"
                                                content={
                                                    <div>
                                                        All review step not yet completed.
                                                    </div>
                                                }
                                            >
                                                <Button iconName="status-info" variant="icon" />
                                            </Popover> }

                                    </SpaceBetween> :
                                   <SpaceBetween direction="horizontal" size="xs">
                                        <Button disabled={obligationStatus !== OBLIGATION_STATUS.UnderApproval} onClick={rejectButtonClickHandler}>Reject</Button>
                                        <Button disabled={isApproveButtonDisable()} onClick={approveButtonClickHandler}>Approve</Button>
                                       {!(checklistState.approver.option1 && checklistState.approver.option2 && checklistState.approver.option3 && checklistState.approver.option4) &&
                                       <Popover
                                           position="top"
                                           size="small"
                                           triggerType="custom"
                                           content={
                                               <div>
                                                   Checklist not yet completed.
                                               </div>
                                           }
                                       >
                                           <Button iconName="status-info" variant="icon" />
                                       </Popover>}
                                    </SpaceBetween>
                                    }
                                </SpaceBetween>}>Obligation details</Header>}
                            >
                                <Grid gridDefinition={[{colspan: 6}, {colspan: 6}]}>
                                    <ObligationDetails data={itemData} obligationStatus={obligationStatus} phastosObligation={PHASTOS_OBLIGATION.VAT} />
                                    <ExceptionSummary exceptionSummaryTableProp={exceptionSummaryTableProp} />
                                </Grid>
                            </ExpandableSection>
                            <div>
                                <Tabs
                                    tabs={[
                                        {
                                            label: 'Summary',
                                            id: 'first',
                                            content: <VATSummary slackChannel={slackChannel} parentWorkflowId={itemData?.parentWorkflowId}
                                                                 useCase={itemData?.phastosUseCaseName}
                                                                 lastEvaluatedKey={lastEvaluatedKey} setLastEvaluatedKey={setLastEvaluatedKey}
                                                                 lastUpdatedOn={lastUpdatedOn} setLastUpdatedOn={setLastUpdatedOn}
                                            onViewBasSummary={setActiveTabId}
                                            obligationStatus={obligationStatus}
                                                                 isUnderReview={isUnderReview}
                                                                 stepsState={stepsState}
                                                                 setStepsStateHandler={setStepStateHandler}
                                                                 updateWorkflowStatusDispatcher={updateWorkflowStatusDispatcher}
                                                                 dispatcher={vatDispatcher}
                                                                 workflowIdentificationProps = {workflowIdentificationProps}
                                            />,
                                        },
                                        {
                                            label: 'Breakdown',
                                            id: 'second',
                                            content: <ViewSummaryBreakdown exceptionData={exceptionData}
                                                                           useCase={itemData?.obligationName}
                                                                           slackChannel={slackChannel} />,
                                        },
                                        {
                                            label: 'Checklists',
                                            id: 'third',
                                            content: <VATChecklists isUnderReview={isUnderReview}
                                                                    stepsState={stepsState}
                                                                    setStepsStateHandler={setStepStateHandler}
                                                                    updateWorkflowStatusDispatcher={updateWorkflowStatusDispatcher}
                                                                    parentWorkflowId={itemData?.parentWorkflowId}
                                                                    setActiveTabId={setActiveTabId}
                                                                    obligationStatus={obligationStatus}
                                                                    checklistState={checklistState}
                                                                    setChecklistState={setChecklistState}
                                                                    slackChannel={slackChannel}
                                                                    userRole={userRole}/>
                                        },
                                        {
                                            label: 'Insights',
                                            id: 'fourth',
                                            content: <ViewDashboards exceptionData={exceptionData} isUnderReview={isUnderReview}
                                                                     stepsState={stepsState}
                                                                     setStepsStateHandler={setStepStateHandler}
                                                                     useCase={itemData?.obligationName}
                                                                     updateWorkflowStatusDispatcher={updateWorkflowStatusDispatcher}
                                                                     parentWorkflowId={itemData?.parentWorkflowId}
                                                                     slackChannel={slackChannel}/>,
                                        },
                                        {
                                            label: 'Source summary',
                                            id: 'tenth',
                                            content: <SourceSummaryView 
                                                        workflowData={itemData} 
                                                        sourceSummaryData={sourceSummaryData}
                                                        sourceSummaryTableDispatcher={sourceSummaryTableDispatcher}
                                                        refreshSourceSummaryData={refreshSourceSummaryData}
                                                        phastosObligation={PHASTOS_OBLIGATION.VAT}/>,
                                        },
                                        {
                                            label: 'Transaction review',
                                            id: 'fifth',
                                            content:
                                                <Box>
                                                    <VATErrorSummary
                                                        exceptionData={exceptionData}
                                                        isUnderReview={isUnderReview}
                                                        stepsState={stepsState}
                                                        obligationStatus={obligationStatus}
                                                        setStepsStateHandler={setStepStateHandler}
                                                        updateWorkflowStatusDispatcher={updateWorkflowStatusDispatcher}
                                                        parentWorkflowId={itemData?.parentWorkflowId}
                                                        slackChannel={slackChannel}
                                                        transactionReviewEditMode={transactionReviewEditMode}
                                                        setTransactionReviewEditMode={setTransactionReviewEditMode}
                                                        transactionSummaryData={transactionSummaryData}
                                                        isTransactionReviewLoading={isTransactionReviewLoading}
                                                        setIsTransactionReviewLoading={setIsTransactionReviewLoading}
                                                        useCase={itemData?.obligationName}
                                                        phastosUseCaseName={itemData?.phastosUseCaseName}
                                                        reportingPeriod={itemData?.reportingPeriod}
                                                    />
                                                </Box>
                                        },
                                        {
                                            label: 'Audit',
                                            id: 'sixth',
                                            content: <Adjustments parentWorkflowId={itemData?.parentWorkflowId} isUnderReview={isUnderReview}
                                                                  stepsState={stepsState}
                                                                  setStepsStateHandler={setStepStateHandler}
                                                                  updateWorkflowStatusDispatcher={updateWorkflowStatusDispatcher}
                                                                  slackChannel={slackChannel}
                                                                  isVAT
                                                                  workflowIdentificationProps = {workflowIdentificationProps}/>,
                                        },
                                        {
                                            label: 'Attachments',
                                            id: 'seventh',
                                            content: <ViewAttachments
                                                parentWorkflowId={itemData?.parentWorkflowId}
                                                useCase={itemData?.type}
                                                slackChannel={slackChannel}
                                            />,
                                            disabled: false
                                        },
                                        {
                                            label: 'Trends',
                                            id: 'eight',
                                            content: <ExceptionTrends
                                                exceptionData={exceptionData}
                                                isUnderReview={isUnderReview}
                                                stepsState={stepsState}
                                                setStepsStateHandler={setStepStateHandler}
                                                updateWorkflowStatusDispatcher={updateWorkflowStatusDispatcher}
                                                parentWorkflowId={itemData?.parentWorkflowId}
                                                useCase={itemData?.obligationName}
                                                isVATReturn={true}
                                            />
                                        },
                                        {
                                            label: 'Filing',
                                            id: 'ninth',
                                            content: <></>,
                                            disabled: true,
                                        }
                                    ]}
                                    activeTabId={activeTabId}
                                    onChange={(event) => {setActiveTabId(event.detail.activeTabId)}}
                                />
                            </div>
                        </SpaceBetween>
                        <RejectReturnModal 
                            rejectConfirmModal={rejectConfirmModal}
                            rejectConfirmHandler={rejectConfirmHandler}
                            setRejectConfirmModal={setRejectConfirmModal}
                        />
                    </div>
                }
            />
        </>
    );
}

export default VATException;

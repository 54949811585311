import {getSubUseCaseSelectionLimits} from "./components/fetchAvailableUseCases";

export let caseChange = (e, setUseCase, setSuccess, setIsRequiredSelectionComplete) => {

    // if subUseCase selection required.
    let isSelectionComplete = true;
    if (getSubUseCaseSelectionLimits(e.detail.id) > 0) {
        isSelectionComplete = false
    }
    setIsRequiredSelectionComplete(isSelectionComplete);

    setUseCase({useCaseValue: e.detail.id, isSelected: true});

    setSuccess({isVisible:false})
}

export const changeSubUseCase = (selectedOptions, useCase, setSelectedSubUseCases, setIsRequiredSelectionComplete) => {
    // if length of selection goes beyond the threshold,
    // remove the earliest selected option from the selected list
    if (getSubUseCaseSelectionLimits(useCase.useCaseValue) >= selectedOptions.length) {
        setSelectedSubUseCases(selectedOptions)
    } else {
        setSelectedSubUseCases(selectedOptions.slice(1))
    }

    // enable options, if relevant use case and sub-use case is selected.
    if(selectedOptions.length) {
        setIsRequiredSelectionComplete(true)
    } else {
        setIsRequiredSelectionComplete(false)
    }
}

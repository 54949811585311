import { ValueWithLabel } from "../utils/Utils";
import { ButtonDropdown } from "@amzn/awsui-components-react/polaris";

export const jsonToHtmlTransformer = (json) => {
    return <>
        {json.map(object => {
            switch(object.type){
                case 'ValueWithLabel' : return getValueWithLabelElement(object.properties);
                case 'ButtonDropdown' : return getButtonDropdownElement(object.properties);
                default : return <></>
            }
        })}
    </>
}

const getValueWithLabelElement = (properties) => {
    return  <ValueWithLabel id={properties?.id} label={properties?.label}>
                {properties?.value}
            </ValueWithLabel>

}

const getButtonDropdownElement = (properties) => {
    return <ButtonDropdown id={properties?.id} classname={properties?.classname}
                items={properties?.items}
                variant={properties?.varient}
                onItemClick={properties?.onItemClick}
            >
                {properties?.value.text}
            </ButtonDropdown>
} 

import {
    addExceptionAndFilterTableAttributes,
    AmountCellRenderer,
    LinkCellRenderer,
    searchDefinitions
} from "../../common/adjustment/constants/transactionLevelUtils";

export const TAX_CODE_ENUM = {
    'TAR': [
        'TAR_I_DOMESTIC_0',
        'TAR_I_DOMESTIC_10',
        'TAR_I_EXCLUDE',
        'TAR_I_OSS_GIFT CARDS', 
        'TAR_I_FOREIGN',
        'TAR_I_OUTSIDE_AU_0',
        'TAR_I_REVIEW'
    ],
    'AR': [
        'AR_I_TRANS REG_DISREGARD AWS',
        'AR_I_TRANS REG_DISREGARD ONLINE',
        'AR_E_VENDOR RETURNS_0',
        'AR_E_VENDOR RETURNS_10',
        'AR_E_PPA_0',
        'AR_E_PPA_10',
        'AR_I_TRANS REG_0',
        'AR_I_TRANS REG_10',
        'AR_I_REVIEW'
    ],
    'AP': [
        'AP_E_AU_0',
        'AP_E_AU_10',
        'AP_E_AU_CAPITAL_0',
        'AP_E_AU_CAPITAL_10',
        'AP_E_OUTSIDE_SCOPE_ACCOUNTS',
        'AP_E_OUTSIDE_SCOPE_NONDEDUCTIBLE',
        'AP_E_REVIEW',
    ],
    'Intercompany': [
        'IC_I_OSS',
        'IC_E_IC_0',
        'IC_E_IC_10',
        'IC_I_ADJ_PREPAID',
        'IC_E_ADJ_QPD',
        'IC_E_ADJ_STRIPE',
        'IC_I_GLOBALSTORE',
        'IC_I_DISREGARD NOT IC',
        'IC_I_0',
        'IC_I_10',
        'IC_REVIEW',
    ],
    'APAccrual': [
        'APA_E_OUTSIDE_SCOPE_ACCOUNTS',
        'APA_E_OUTSIDE_SCOPE_NONDEDUCTIBLE',
        'APA_E_AU_0',
        'APA_E_AU_CAPAITAL_0',
        'APA_E_AU_CAPAITAL_10',
        'APA_E_AU_10',
        'APA_E_REVIEW',
    ]
};

const cellEditorSelector = (params) => {
    if (params.data === undefined) return undefined;

    if (params?.column.colId === 'derivedTaxCode' && params?.data?.sourceType) {
      return {
        component: 'agRichSelectCellEditor',
        popup: true,
        params: {
            values: TAX_CODE_ENUM[params.data['sourceType']],
            cellHeight: 24,
        },
      };
    }
    return undefined;
};

export const searchDefinitionForVat = () => {
    const unsupportedSearchFields = ['checkBox', 'draftChanges', 'orderDate', 'netSalesAmount', 'encryptedMerchantId',
        'netTaxAmount', 'totalAmountBaseCurrency', 'totalAmount', 'netGiftwrapAmount', 'netShippingAmount',
        'glPostedDate', 'quantity', 'taxRate', 'currencyConversionRate', 'derivedTaxRate'];
    const definitions = searchDefinitions(columnDefinitions, unsupportedSearchFields);
    addExceptionAndFilterTableAttributes(definitions);
    return definitions;
}

export const columnDefinitions = [
    { headerName: '', field: 'checkBox', checkboxSelection: true, headerCheckboxSelection: true, headerCheckboxSelectionFilteredOnly: true, hide: false, minWidth: 54, filter: false, width: 54 },
    { headerName: 'Transaction ID', field: 'hashKey', enableRowGroup: true, hide: true },
    { headerName: 'Order ID', field: 'orderId', hide: false, cellRenderer: LinkCellRenderer },
    { headerName: 'Order Date', field: 'orderDate', hide: false, sortable: false, valueGetter: params => {
        if (params.data === undefined || params.data.orderDate === null || params.data.orderDate === undefined) return '';
        return (new Date(params.data.orderDate)).toLocaleDateString();
    } },
    { headerName: 'Source Type', field: 'sourceType', hide: false, enableRowGroup: true },
    { headerName: 'Tax Code', field: 'derivedTaxCode', cellEditorSelector: cellEditorSelector, hide: false, editable: true, enableRowGroup: true },
    { headerName: 'GST Exclusive (AUD)', field: 'netSalesAmount', hide: false, cellRenderer: AmountCellRenderer },
    { headerName: 'Tax Rate', field: 'taxRate', hide: false, editable: true, cellRenderer: AmountCellRenderer },
    { headerName: 'GST (AUD)', field: 'netTaxAmount', hide: false, cellRenderer: AmountCellRenderer },
    { headerName: 'GST Inclusive (AUD)', field: 'totalAmountBaseCurrency', hide: false, cellRenderer: AmountCellRenderer },
    { headerName: 'Base Currency Code', field: 'baseCurrencyCode' },
    { headerName: 'Currency Conversion Rate', field: 'currencyConversionRate', hide: false },
    { headerName: 'Derived Tax Rate', field: 'derivedTaxRate', hide: false, cellRenderer: AmountCellRenderer },
    { headerName: 'Transaction Category', field: 'transactionCategory', hide: false, enableRowGroup: true },
    { headerName: 'Transaction Type', field: 'transactionType', hide: false, enableRowGroup: true },
    { headerName: 'GL Account Number', field: 'glAccountNumber', hide: false },
    { headerName: 'GL Account Number Description', field: 'glAccountNumberDescription', hide: false },
    { headerName: 'GL Product Group', field: 'glProductGroup', hide: false },
    { headerName: 'GL Product Group Description', field: 'glProductGroupDescription', hide: false },
    { headerName: 'Ship From Country', field: 'shipFromCountry', hide: false, enableRowGroup: true },
    { headerName: 'Ship To Country', field: 'shipToCountry', hide: false, enableRowGroup: true },
    {headerName: 'Unresolved exceptions', field: 'unresolvedExceptions', hide: false},
    {headerName: 'Corrected exceptions', field: 'correctedExceptions', hide: false},
    {headerName: 'Confirmed exceptions', field: 'confirmedExceptions', hide: false},
    {headerName: 'Applied filters', field: 'appliedFilters', hide: false},

    { headerName: 'GST Inclusive', field: 'totalAmount', cellRenderer: AmountCellRenderer },
    { headerName: 'Net Giftwrap Amount (AUD)', field: 'netGiftwrapAmount', cellRenderer: AmountCellRenderer },
    { headerName: 'Net Shipping Amount (AUD)', field: 'netShippingAmount', cellRenderer: AmountCellRenderer },
    { headerName: 'Total Discount Amount (AUD)', field: 'totalDiscountAmount', cellRenderer: AmountCellRenderer },
    { headerName: 'ASIN', field: 'asin' },
    { headerName: 'Country Code', field: 'countryCode' },
    { headerName: 'Customer Shipment Item ID', field: 'customerShipmentItemId' },
    { headerName: 'Encrypted Merchant ID', field: 'encryptedMerchantId' },
    { headerName: 'GL Channel Code', field: 'glChannelCode' },
    { headerName: 'GL Channel Code Description', field: 'glChannelCodeDescription' },
    { headerName: 'GL Company Code', field: 'glCompanyCode' },
    { headerName: 'GL Company Code Description', field: 'glCompanyCodeDescription' },
    { headerName: 'GL Cost Center', field: 'glCostCenter' },
    { headerName: 'GL Cost Center Description', field: 'glCostCenterDescription' },
    { headerName: 'GL Location Code', field: 'glLocationCode' },
    { headerName: 'GL Location Code Description', field: 'glLocationCodeDescription' },
    { headerName: 'GL Posted Date', field: 'glPostedDate' },
    { headerName: 'GL Project Code', field: 'glProjectCode' },
    { headerName: 'GL Project Code Description', field: 'glProjectCodeDescription' },
    { headerName: 'Line Description', field: 'lineDescription' },
    { headerName: 'Line Type Lookup Code', field: 'lineTypeLookupCode' },
    { headerName: 'Marketplace ID', field: 'marketplaceId' },
    { headerName: 'Merchant ID', field: 'merchantId' },
    { headerName: 'Order Item ID', field: 'orderItemId' },
    { headerName: 'Quantity', field: 'quantity' },
    { headerName: 'Reversal ID', field: 'reversalId' },
    { headerName: 'Ship Date', field: 'shipDate' },
    { headerName: 'Shipment ID', field: 'shipmentId' },
    { headerName: 'Exception Key', field: 'exceptionKey' },
    {
        headerName: 'Draft Changes (Y/N)',
        field: 'draftChanges',
        editable: false,
        valueGetter: params => {
            if (params.data === undefined) return 'N';
            return params.data.draftChanges === undefined ? 'N' : params.data.draftChanges;
        },
    }
];

export const defaultColumnDefinitions = {
    sortable: true,
    minWidth: 200,
    defaultMinWidth: 200,
    resizable: true,
    floatingFilter: true,
    type: 'editableColumn',
    enablePivot: true,
    editable: false,
    filter: true,
    hide: true,
};
import React, {useState} from 'react';
import {Container} from "@amzn/awsui-components-react/polaris";
import {Button, Header, SpaceBetween} from "@amzn/awsui-components-react";
import ChecklistToggle from "./ChecklistToggle-V1";
import {getUserAlias} from "../utils/utils-v1";
import {modifyWorkflowStatus} from "../../redux/exceptionAPIs";

export default function ExceptionChecklists(props) {
    const {isUnderReview, stepsState, setStepsStateHandler, updateWorkflowStatusDispatcher, parentWorkflowId} = props;
    const checkListInitialState = {
        option1: stepsState.checklistStep.completed,
        option2: stepsState.checklistStep.completed,
        option3: stepsState.checklistStep.completed,
    };
    const [checkListOptionsState, setCheckListOptionsState] = useState(checkListInitialState);

    const isCompleteButtonDisabled = () => {
        // TODO : make checkListOptionsState iterable so that in future any checklist added, it will auto pick
        return !(isUnderReview && stepsState.summaryStep.completed && !stepsState.checklistStep.completed
            && checkListOptionsState.option1 && checkListOptionsState.option2 && checkListOptionsState.option3);
    };

    const [completeButtonState, setCompleteButtonState] = useState(false);

    const completeButtonClickHandler = async () => {
        setCompleteButtonState(true);
        const userAlias = await getUserAlias();
        const request = {
            parentWorkflowId: parentWorkflowId,
            acknowledgementStructureMap: {
                "CHECKLIST": { status:"COMPLETE", user : userAlias }
            },
        };
        const onSuccess = () => {
            setStepsStateHandler("checklistStep");
        };
        modifyWorkflowStatus(updateWorkflowStatusDispatcher, request, onSuccess);
        setCompleteButtonState(false);
    };

    return (
        <SpaceBetween size='xl'>
            <Container
                header={<Header actions={
                    <Button variant='primary'
                            onClick={completeButtonClickHandler}
                            disabled={isCompleteButtonDisabled() || completeButtonState}
                    >Acknowledge</Button>
                }>Checklists</Header>}
            >
                <SpaceBetween size='l' direction='vertical'>
                    <ChecklistToggle
                        checklistOption={"option1"}
                        checkedValue={checkListOptionsState.option1}
                        setCheckListOptionsState={setCheckListOptionsState}
                        checklistText={"I’ve reviewed the transactional summary for this obligation."}
                    />
                    <ChecklistToggle
                        checklistOption={"option2"}
                        checkedValue={checkListOptionsState.option2}
                        setCheckListOptionsState={setCheckListOptionsState}
                        checklistText={"I’ve reviewed and considered the impact of exceptions highlighted for this obligation."}
                    />
                    <ChecklistToggle
                        checklistOption={"option3"}
                        checkedValue={checkListOptionsState.option3}
                        setCheckListOptionsState={setCheckListOptionsState}
                        checklistText={"I’ve reviewed the historic trends and completed internal control checks to review this obligation."}
                    />
                </SpaceBetween>
            </Container>
        </SpaceBetween>
    )
}
import * as React from "react";
import Table from "@amzn/awsui-components-react/polaris/table";
import Box from "@amzn/awsui-components-react/polaris/box";
import Header from "@amzn/awsui-components-react/polaris/header";
import { useEffect, useState } from "react";
import { parseFloatValue, parseIntValue } from "../../common/utils/commonUtils";
import TableDownloadButton, {DEFINITION_TYPE_POLARIS} from "../exception/components/common/TableDownloadButtonComponent";

export default function TaxCodeBreakdownTable(props) {
    const {
        sourceSummaryData,
        selectedItems,
        workflowData
    } = props;

    const [data, setData] = useState([]);

    useEffect(() => {
        const result = [];
        for (const selectedItem of selectedItems) {
            if (sourceSummaryData.taxCodeDataForSource[selectedItem.sourceType]) {
                for (const j of sourceSummaryData.taxCodeDataForSource[selectedItem.sourceType]) {
                    result.push(j);
                }
            }
        }
        setData(result);
    }, [selectedItems, sourceSummaryData]);

    return (
        <Table
            items={data}
            loading={sourceSummaryData.fetchingDataForSource}
            columnDefinitions={taxCodeBreakdownColumnDefinition}
            loadingText="Loading resources"
            sortingDisabled
            empty={
                <Box textAlign="center" color="inherit">
                    <b>No data</b>
                    <Box
                        padding={{ bottom: "s" }}
                        variant="p"
                        color="inherit"
                    >
                        Select a source to view tax code breakdown data
                    </Box>
                </Box>
            }
            header={<Header actions={
                <TableDownloadButton data={data}
                                     nameProps={{
                                         obligationName: workflowData?.obligationName,
                                         reportingPeriod: workflowData?.reportingPeriod,
                                         country: workflowData?.country
                                     }}
                                     tableId={'Summary by Tax Code for Source'}
                                     columnDefinition={taxCodeBreakdownColumnDefinition}
                                     tableType={DEFINITION_TYPE_POLARIS}
                                     additionalButtonDisableFlag={(sourceSummaryData.sourceSummary.length !== selectedItems.length)}
                                     isHeaderButton={true}
                />}> Summary by Tax Code for Source </Header>}
        />
    );
}


const taxCodeBreakdownColumnDefinition = [
    {
        id: "sourceType",
        header: "Source Type",
        cell: item => item?.sourceType || "-",
        sortingField: "sourceType"
    },
    {
        id: "taxCode",
        header: "Tax Code",
        cell: item => item?.derivedTaxCode || "-",
        sortingField: "taxCode"
    },
    {
        id: "transactionCount",
        header: "Transaction count",
        cell: item => parseIntValue(item.transactionCount),
        sortingField: "transactionCount"
    },
    {
        id: "exceptionCount",
        header: "Exception count",
        cell: item => parseIntValue(item.exceptionCount),
        sortingField: "exceptionCount"
    },
    {
        id: "netAmount",
        header: "Net Amount (AUD)",
        cell: item => parseFloatValue(item.netAmount),
        sortingField: "netAmount"
    },
    {
        id: "netTax",
        header: "Net Tax (AUD)",
        cell: item => parseFloatValue(item.netTax),
        sortingField: "netTax"
    },
    {
        id: "netGrossAmount",
        header: "Gross Amount (AUD)",
        cell: item => parseFloatValue(item.netGrossAmount),
        sortingField: "netGrossAmount"
    }
];
const SLACK_API_ENDPOINT = 'https://slack.com/api';

async function slackAPI(endpoint, urlSearchParams) {
    urlSearchParams.append('token', 'xoxb-5176159614675-5176981214338-AQC8qY8WXyvrB6gDFKdBZLal');
    const response = await fetch(endpoint, {
      method: 'POST',
      body: urlSearchParams,
      headers: {
        // Slack API does not support application/json due to misconfigured OPTIONS request handling
        'content-type': 'application/x-www-form-urlencoded',
      },
    });
    return response;
}

export async function createChannel(channelName, createChannelSlackStatusDispatcher, listChannelsSlackDataDispatcher) {
    slackAPI(SLACK_API_ENDPOINT + '/conversations.create', new URLSearchParams({
        'name': channelName,
        'is_private': true,
    })).then(response => response.json()).then((data) => {
        if (data?.ok === true) {
            listChannels(listChannelsSlackDataDispatcher);
            createChannelSlackStatusDispatcher({
                type: "SUCCESS", payload: {
                    data: data,
                    dispatcher: createChannelSlackStatusDispatcher,
                    flashbarMessage: `Slack channel ${channelName} successfully created.`,
                }
            });
        } else {
            let errorMessage;
            switch(data?.error) {
                case 'name_taken':
                    errorMessage = `Slack channel ${channelName} already exists.`;
                    break;
                default:
                    errorMessage = 'Something went wrong, please try again later.';
            }
            createChannelSlackStatusDispatcher({
                type: "FAILURE", payload: {
                    error: data,
                    flashbarMessage: errorMessage,
                    dispatcher: createChannelSlackStatusDispatcher,
                }
            });
        }
    })
}

async function lookupByEmail(emailAddress) {
    return await slackAPI(SLACK_API_ENDPOINT + '/users.lookupByEmail', new URLSearchParams({
        'email': emailAddress
    })).then(response => response.json()).then((data) => {
        if (data?.ok === true) {
            return data?.user?.id;
        } else {
            return null;
        }
    });
}

export async function addUser(channel, userEmailAddress, addUserSlackStatusDispatcher) {
    const userId = await lookupByEmail(userEmailAddress);
    if (userId === null) {
        addUserSlackStatusDispatcher({
            type: "FAILURE", payload: {
                error: {},
                dispatcher: addUserSlackStatusDispatcher,
                flashbarMessage: `Error occurred while looking user up by e-mail address.`,
            }
        });
        return;
    }

    slackAPI(SLACK_API_ENDPOINT + '/conversations.invite', new URLSearchParams({
        'channel': channel,
        'users': userId,
    })).then(response => response.json()).then((data) => {
        if (data?.ok === true) {
            addUserSlackStatusDispatcher({
                type: "SUCCESS", payload: {
                    data: data,
                    dispatcher: addUserSlackStatusDispatcher,
                    flashbarMessage: `Slack user ${userEmailAddress} successfully added.`,
                }
            });
        } else {
            let errorMessage;
            switch(data?.error) {
                case 'already_in_channel':
                    errorMessage = `Slack user ${userEmailAddress} already added to the channel.`;
                    break;
                default:
                    errorMessage = 'Something went wrong, please try again later.';
            }
            addUserSlackStatusDispatcher({
                type: "FAILURE", payload: {
                    error: data,
                    flashbarMessage: errorMessage,
                    dispatcher: addUserSlackStatusDispatcher,
                }
            });
        }
    });
}

export async function listChannels(listChannelsSlackDataDispatcher) {
    slackAPI(SLACK_API_ENDPOINT + '/users.conversations', new URLSearchParams({
        'limit': 100,
        'types': 'public_channel,private_channel',
    })).then(response => response.json()).then((data) => {
        if (data?.ok === true) {
            listChannelsSlackDataDispatcher({
                type: "SUCCESS", payload: {
                    data: data,
                    dispatcher: listChannelsSlackDataDispatcher,
                }
            });
        } else {
            let errorMessage;
            switch(data?.error) {
                default:
                    errorMessage = 'Error occurred while fetching list of Slack channels.';
            }
            listChannelsSlackDataDispatcher({
                type: "FAILURE", payload: {
                    error: data,
                    flashbarMessage: errorMessage,
                    dispatcher: listChannelsSlackDataDispatcher,
                }
            });
        }
    });
}

export async function getMessages(channel, getMessagesSlackDataDispatcher) {
    slackAPI(SLACK_API_ENDPOINT + '/conversations.history', new URLSearchParams({
        'channel': channel,
    })).then(response => response.json()).then((data) => {
        if (data?.ok === true) {
            getMessagesSlackDataDispatcher({
                type: "SUCCESS", payload: {
                    data: data,
                    dispatcher: getMessagesSlackDataDispatcher,
                }
            });
        } else {
            let errorMessage;
            switch(data?.error) {
                default:
                    errorMessage = 'Error occurred while fetching Slack messages.';
            }
            getMessagesSlackDataDispatcher({
                type: "FAILURE", payload: {
                    error: data,
                    flashbarMessage: errorMessage,
                    dispatcher: getMessagesSlackDataDispatcher,
                }
            });
        }
    });
}

export async function postMessage(channel, message) {
    slackAPI(SLACK_API_ENDPOINT + '/chat.postMessage', new URLSearchParams({
        'channel': channel,
        'text': message,
    })).then(response => response.json()).then((data) => {
        if (data?.ok === true) {
            // Process success
        } else {
            let errorMessage;
            switch(data?.error) {
                default:
                    errorMessage = 'Error occurred while fetching Slack messages.';
                    console.log(errorMessage);
            }
            // Process failure
        }
    });
}

async function getUserProfile(userId) {
    return await slackAPI(SLACK_API_ENDPOINT + '/users.profile.get', new URLSearchParams({
        'user': userId
    })).then(response => response.json()).then((data) => {
        if (data?.ok === true) {
            return data;
        } else {
            return null;
        }
    });
}

export async function getUserProfiles(userIds) {
    let userProfiles = {};
    for (let i=0; i<userIds.length; i++) {
        const userProfile = await getUserProfile(userIds[i]);
        userProfiles = {
            ...userProfiles,
            [userIds[i]]: userProfile,
        }
    }
    return userProfiles;
}
export const GET_UPLOADED_DOCUMENT_DETAILS_INITIAL_STATE = {
    fetching: false,
    documents: [],
};

export const getUploadedDocumentDetailsReducerInit = initialState =>{
    return initialState;
};

export const getUploadedDocumentDetailsReducer = (state, action) => {
    switch (action.type){
        case "BEGIN":
            return {
                ...state,
                fetching: true,
            }
        case "SUCCESS":
            return {
                ...state,
                documents: action.payload?.documents,
                fetching: false,
            }
        case "FAILURE":
            return  GET_UPLOADED_DOCUMENT_DETAILS_INITIAL_STATE;
        default:
            return state
    }
};
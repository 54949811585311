import React, { useEffect } from "react";
import {
    PropertyFilter,
    Pagination,
    Box,
    Header,
    Table,
    Button,
    SpaceBetween,
    Spinner
} from "@amzn/awsui-components-react/polaris";
import { getExcaliburAuditData } from "../../excalibur/components/excaliburAPIs";
import { formatDateWithTime } from "../../../common/utils/apiUtils";
import { statusColorCodes } from '../../../common/utils/commonUtils';
import {PROPERTY_FILTERING_I18N_CONSTANTS} from '../../phastos/table-config';
import {downloadFolder, uploadFiles} from "../../../common/utils/s3Utils";
import {ARTIFACTS_FORMAT} from "../../../common/constants/constants";
import AppConfig from "../../../AppConfig";
import {getFilingReceipt} from "../utils/filingAPIs";

const Actiontable = (params) => {

    const { table, tableDispatcher } = params;
    const [loading, setLoading] = React.useState(false);
    const [query, setQuery] = React.useState({
        tokens: [],
        operation: "and"
    });

    const downloadArtifacts = async (inputFileKey, s3Bucket) => {
        setLoading(true);
        try {
            await downloadFolder(inputFileKey, s3Bucket, params.domain)
        } catch (error) {
            tableDispatcher({
                type: "SET_FLASH_BAR", payload: {
                    msg: 'Error occured while downloading the file',
                    type: 'error',
                    dispatcher: tableDispatcher
                }
            })
        }
        setLoading(false);
    }

    const downloadSubmissionReceipt = (audit) => {
        if(audit.generatedFileKey){
            downloadArtifacts(audit.generatedFileKey, AppConfig[params.domain].FILING_RECEIPT_BUCKET);
        } else {
            getFilingReceipt(tableDispatcher, audit.useCase, audit.subUseCase, audit.transactionId, params.domain, setLoading);
        }
    }

    const uploadArtifacts = async (audit, files) => {
        setLoading(true);
        try {
            await uploadFiles(files, AppConfig[params.domain].ARTIFACTS_BUCKET, audit.inputFileKey, params.domain)
        } catch (error) {
            tableDispatcher({
                type: "SET_FLASH_BAR", payload: {
                    msg: 'Error occured while uploading the file',
                    type: 'error',
                    dispatcher: tableDispatcher
                }
            })
        }
        setLoading(false);
    }

    useEffect(() => {
        if (table.initialize)
            getExcaliburAuditData(tableDispatcher, table.lastEvaluatedKey, "RemoteFiling")
    })

    return (
        <>
            {loading ? <Spinner className={"appLoader"} size="large" variant={"disabled"}>Loading</Spinner> : null}
        <Table
            loading={table.loading}
            loadingText={table.loadingText}
            onSortingChange={((event)=>{
                tableDispatcher({type:"AUDIT_SORTING_CHANGED",
                    payload:{isDescending:event.detail.isDescending,
                        sortingField:event.detail.sortingColumn.sortingField}});
            })}
            sortingColumn={table.sortingColumn}
            sortingDescending={table.sortingDescending}
            columnDefinitions={[
                {
                    id: "useCase",
                    header: "Country",
                    cell: e => e.useCase,
                    sortingField: "useCase"
                },
                {
                    id: "subUseCase",
                    header: "Filing Type",
                    cell: e => e.subUseCase,
                    sortingField: "subUseCase"

                },
                {
                    id: "vatRegistrationNo",
                    header: "VAT No.",
                    cell: e => e.additionalDataMap?.VRN
                },
                {
                    id: "status",
                    header: "Status",
                    cell: e => {
                        return (<div style={statusColorCodes(e.status)}>{e.status}</div>)
                    },
                },
                {
                    id: "startTimestamp",
                    header: "Request Created on",
                    cell: e => formatDateWithTime(e.startTimestamp),
                    sortingField: "startTimestamp"
                },
                {
                    id: "createdBy",
                    header: "Submitted By",
                    cell: e => e.createdBy,
                },
                {
                    id: "inputFileKey",
                    header: "Input File",
                    cell: e => {
                        return (
                                <Button iconName="download" variant={"link"}
                                    onClick={() => downloadArtifacts(e.inputFileKey, e.inputFileBucket)}>File</Button>
                        )
                    },
                    minWidth: 150
                },
                {
                    id: "submissionDetails",
                    header: "Submission Details",
                    cell: e => JSON.stringify(e.additionalDataMap),
                },
                {
                    id: "getReceipt",
                    header: "Submission Receipt",
                    cell: e => {
                        return (
                            <Button iconName="download" variant={"link"} onClick={() => downloadSubmissionReceipt(e)}>Receipt</Button>
                        )
                    },
                    minWidth: 150
                },
                {
                    id: "errorDetails",
                    header: "Error Details",
                    cell: e => e.errorDetails,
                },
                {
                    id: "txnArtifacts",
                    header: "Artifacts",
                    cell: e => {
                        return (
                            <SpaceBetween direction="horizontal" size="xs">
                                <Button iconName="download"  onClick={() =>
                                    downloadArtifacts(e.inputFileKey, AppConfig[params.domain].ARTIFACTS_BUCKET)}>View</Button>
                                <Button iconName="upload" variant="primary">
                                    <input className="inputButton hidden" type={"file"} accept={ARTIFACTS_FORMAT} name="file" onChange={(event) => uploadArtifacts(e, event.target.files)} multiple/>
                                    Upload
                                </Button>
                            </SpaceBetween>
                        )
                    },
                    minWidth: 300
                }
            ]}
            visibleColumns={[
                "useCase",
                "subUseCase",
                "vatRegistrationNo",
                "status",
                "startTimestamp",
                "createdBy",
                "inputFileKey",
                "getReceipt",
                "errorDetails",
                "txnArtifacts"
            ]}
            empty={
                <Box textAlign="center" color="inherit">
                    <b>No files</b>
                    <Box
                        padding={{ bottom: "s" }}
                        variant="p"
                        color="inherit">
                        No files to display.
                    </Box>

                </Box>
            }
            filter={
                <div>
                    <Header>
                        Submitted Requests
                    </Header>
                    <PropertyFilter
                        onChange={({ detail }) => setQuery(detail)}
                        query={query}
                        i18nStrings={PROPERTY_FILTERING_I18N_CONSTANTS}
                        countText="5 matches"
                        expandToViewport
                        filteringOptions={[
                            {
                                propertyKey: "useCase",
                                value: "UK"
                            },
                            {
                                propertyKey: "subUseCase",
                                value: "VAT_FILING"
                            },
                            {
                                propertyKey: "status",
                                value: "SUCCESS"
                            },
                            {
                                propertyKey: "status",
                                value: "FAILURE"
                            },
                            {
                                propertyKey: "status",
                                value: "COMPLETED"
                            },
                        ]}
                        filteringProperties={[
                            {
                                key: "useCase",
                                operators: ["=", "!=", ":", "!:"],
                                propertyLabel: "Country",
                                groupValuesLabel: ""
                            },
                            {
                                key: "subUseCase",
                                operators: ["=", "!=", ":", "!:"],
                                propertyLabel: "Filing Type",
                                groupValuesLabel: "Sub Use Case"
                            },
                            {
                                key: "status",
                                operators: ["=", "!=", ":", "!:"],
                                propertyLabel: "Status",
                                groupValuesLabel: "Status"
                            }
                        ]} />
                </div>
            }
            items={table.auditData.get(table.currentPageIndex)}
            pagination={
                <Pagination
                    currentPageIndex={table.currentPageIndex}
                    pagesCount={table.pageCount}
                    onChange={event => {
                        tableDispatcher({type:"PAGE_INDEX_CHANGED",
                            payload:{pageIndex:event.detail.currentPageIndex, dispatcher:tableDispatcher}})
                    }
                    }
                    ariaLabels={{
                        nextPageLabel: "Next page",
                        previousPageLabel: "Previous page",
                        pageLabel: pageNumber =>
                            `Page ${pageNumber} of all pages`
                    }}
                />
            }
            preferences={
                <Button iconName="refresh" variant="icon"
                        onClick={()=>{
                            tableDispatcher({type:"REFRESH_AUDIT",payload:{dispatcher:tableDispatcher}})
                        }}
                />
            }
        />
        </>
    );
}

export default Actiontable;
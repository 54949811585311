import React, {useEffect, useMemo, useReducer} from "react";
import {getLoadingSpinner} from "../../../../common/utils/commonUtils";
import {
    GET_QUICKSIGHT_EMBED_URL_INITIAL_STATE, getQuicksightEmbedUrlInitialState,
    getQuicksightEmbedUrlReducer
} from "../redux/getQuicksightEmbedUrlReducer";
import {getQuickSightLinkMap} from "../../utils/utils";
import {triggerGetQuickSightEmbedUrl} from "./quicksightUtils";


export default function QuickSightFrame(props) {

    const {
        exceptionData,
        useCase,
        linkKey
    } = props;

    // Quicksight Dashboard Embed Links
    const [quicksightEmbedding, quicksightEmbeddingDispatcher] = useReducer(getQuicksightEmbedUrlReducer,
        GET_QUICKSIGHT_EMBED_URL_INITIAL_STATE, getQuicksightEmbedUrlInitialState);
    const memoizedQuickSightLinkMap = useMemo(() => getQuickSightLinkMap(exceptionData), [exceptionData]);
    const memoizedQuickSightFrameProps = useMemo(() => memoizedQuickSightLinkMap[linkKey],
        [linkKey, memoizedQuickSightLinkMap]);


    useEffect(() => {
        triggerGetQuickSightEmbedUrl(
            memoizedQuickSightFrameProps,
            useCase,
            quicksightEmbeddingDispatcher
        );
    }, [memoizedQuickSightFrameProps, useCase]);

    return (
        <>
            {
                quicksightEmbedding.fetching ?
                    getLoadingSpinner('ComponentSpinner')
                    : <iframe title='QuickSight' className="dashboard"
                              src={quicksightEmbedding.embedUrl}/>
            }
        </>);

}


import React from "react";
import { useCollection } from '@amzn/awsui-collection-hooks';
import Table from "@amzn/awsui-components-react/polaris/table";
import Header from "@amzn/awsui-components-react/polaris/header";
import {Pagination, PropertyFilter, CollectionPreferences} from "@amzn/awsui-components-react";
import Button from "@amzn/awsui-components-react/polaris/button";
import {getColumnDefinitions, filteringProperties} from "./constants/Constants";
import {EmptyState, paginationLabels, PROPERTY_FILTERING_I18N_CONSTANTS} from "../../app/phastos/table-config.js";
import {downloadDocumentBys3PreSignedURL} from "../../app/excalibur/components/excaliburAPIs"
import {getMatchesCountText} from "../../app/exception/table-config.js";

const Actiontable = (params) => {
  const {searchDocumentTable, selectedItems, setSelectedItems, downloadDocumentDispatcher} = params;
  const preferences={
    pageSize: 10,
    visibleContent: [
        "documentName",
        "countryCode",
        "useCase",
        "subUseCase",
        "entity",
        "documentType",
        "creationYear",
        "requestor"
    ]}

    const onSelectionChange = (item) => {
        setSelectedItems(item.detail.selectedItems);
    } 

    const { items, filteredItemsCount, collectionProps, propertyFilterProps, paginationProps } = useCollection(
      searchDocumentTable.listOfDocuments,
        {
          filtering: {
            empty: (
              <EmptyState
                title="No instances"
                subtitle="No instances to display."
              />
            ),
            noMatch: (
              <EmptyState
                title="No matches"
                subtitle="We can’t find a match."
              />
            ),
          },
          propertyFiltering: {
            filteringProperties,
            empty: <EmptyState title="No matches" />,
            noMatch: (
              <EmptyState
                title="No matches"
              />
            ),
          },
          pagination: { pageSize: preferences.pageSize },
          selection: {}
        }
      );

    const isDisabled = selectedItems.length !== 1;

    const handleOnClick = () => {
      if(selectedItems?.length === 1) {
        const { documentID, documentAdditionalDataMap = {} } = selectedItems[0] ?? {};
        const { useCase, subUseCase } = documentAdditionalDataMap ?? {};
        downloadDocumentBys3PreSignedURL(documentID, useCase, subUseCase, downloadDocumentDispatcher);
      }
    }
    
    return (
        <Table
          {...collectionProps}
          onSelectionChange={onSelectionChange}
          selectedItems={selectedItems}
          selectionType="multi"
          header={
              <div>
                  <Header
                      actions={<Button className="search-button" variant="primary" disabled={isDisabled}
                      onClick={handleOnClick}>
                          Download
                          </Button>}
                  >
                      Search Result
                  </Header>
              </div>
          }
          columnDefinitions={getColumnDefinitions()}
          loading={searchDocumentTable.fetching}
          visibleColumns={preferences.visibleContent}
          items={items}
          pagination={<Pagination {...paginationProps} ariaLabels={paginationLabels}/>}
          filter={
              <PropertyFilter
                  i18nStrings={PROPERTY_FILTERING_I18N_CONSTANTS}
                  filteringProperties={propertyFilterProps.filteringProperties}
                  filteringOptions={propertyFilterProps.filteringOptions}
                  onChange={propertyFilterProps.onChange}
                  query={propertyFilterProps.query}
                  countText={getMatchesCountText(filteredItemsCount)}
              />
          }
          preferences={
            <CollectionPreferences
                title="Preferences"
                confirmLabel="Confirm"
                cancelLabel="Cancel"
                preferences={preferences}
                pageSizePreference={{
                    title: "Page size",
                    options: [
                        { value: 10, label: "10 resources" },
                        { value: 20, label: "20 resources" }
                    ]
                }}
                wrapLinesPreference={{
                    label: "Wrap lines",
                    description:
                        "Select to see all the text and wrap the lines"
                }}
                stripedRowsPreference={{
                    label: "Striped rows",
                    description:
                        "Select to add alternating shaded rows"
                }}
                contentDensityPreference={{
                    label: "Compact mode",
                    description:
                        "Select to display content in a denser, more compact mode"
                }}
                visibleContentPreference={{
                    title: "Select visible content",
                    options: [
                        {
                            label: "Available Attributes",
                            options: [
                                {
                                    id: "documentName",
                                    label: "Document name",
                                    editable: false
                                },
                                { id: "countryCode", label: "Country Code" },
                                { id: "useCase", label: "Use case" },
                                { id: "subUseCase", label: "SubUseCase"},
                                { id: "entity", label: "Entity" },
                                { id: "documentType", label: "Document Type" },
                                { id: "creationYear", label: "Creation Year"},
                                { id: "requestor", label: "Requestor"}
                            ]
                        }
                    ]
                }}
              />
          }
        />
    );
}

export default Actiontable;
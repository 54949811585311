import React from "react";
import {Header, Table} from "@amzn/awsui-components-react";
import {exceptionSummaryColumnDefinition} from "../DSTableConfig-V1";

export default function ExceptionSummary(props) {

    const {stepsState} = props;

    return (
        <Table
            header={
                <div>
                    <Header>Execution summary</Header>
                </div>
            }
            columnDefinitions={exceptionSummaryColumnDefinition}
            // TODO: remove this dummy items when integration of new backend API is done.
            items={[
                {
                    'step': 'Summary',
                    'status': stepsState.summaryStep.completed ? 'Completed' : 'Pending',
                    'person': stepsState.summaryStep.user,
                    'date': stepsState.summaryStep.updatedOn,
                },
                {
                    'step': 'Exception review',
                    'status': stepsState.exceptionReviewStep.completed ? 'Completed' : 'Pending',
                    'person': stepsState.exceptionReviewStep.user,
                    'date': stepsState.exceptionReviewStep.updatedOn,
                },
                {
                    'step': 'Checklists',
                    'status': stepsState.checklistStep.completed ? 'Completed' : 'Pending',
                    'person': stepsState.checklistStep.user,
                    'date': stepsState.checklistStep.updatedOn,
                }
            ]}
        />
    );
}
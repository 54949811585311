import {getCurrencyAmountForSummary} from "../../common/adjustment/constants/errorSummaryUtils";
import React from "react";
import {CollectionPreferences} from "@amzn/awsui-components-react";
import {isNull} from "lodash";

const COLUMN_IDENTIFIER = {
    HashKey: "hashKey",
    CommodityCode: "commodityCode",
    CountryOfDispatch: "countryOfDispatch",
    CountryOfOrigin:  "countryOfOrigin",
    NatureOfTransaction: "natureOfTransaction",
    Weight: "weight",
    Units: "units",
    InvoiceValue: "InvoiceValue",
    StatisticalValue: "StatisticalValue",
    ShipTo:  "shipTo",
    DeliveryTerms: "deliveryTerms",
    GoodsDescription: "goodsDescription",
    ModeOfTransport: "modeOfTransport",
    PortOrAirport: "portOrAirport",
    RecipientVat: "recipientVAT",
    Region:  "region",
    ShipFrom:   "shipFrom",
    SupplierUnitType:  "supplierUnitType",
    SourceType:   "sourceType"
};

const USECASE_MAP = {
    PL_INTRASTAT_ARRIVALS: "PL_INTRASTAT_ARRIVALS",
    PL_INTRASTAT_DISPATCH: "PL_INTRASTAT_DISPATCH",
    DE_INTRASTAT_ARRIVALS: "DE_INTRASTAT_ARRIVALS",
    DE_INTRASTAT_DISPATCH: "DE_INTRASTAT_DISPATCH"
};

const HASH_KEY = {
    id: COLUMN_IDENTIFIER.HashKey,
    header: 'Hash key',
    cell: item => item.hashKey,
    sortingField: COLUMN_IDENTIFIER.HashKey
};

const COMMODITY_CODE = {
    id: COLUMN_IDENTIFIER.CommodityCode,
    header: 'Commodity Code',
    cell: item => item.commodityCode,
    sortingField: COLUMN_IDENTIFIER.CommodityCode,
    width: 200,
    minWidth: 100
};

const SHIP_FROM = (headerText) => {
    return {
        id: COLUMN_IDENTIFIER.ShipFrom,
        header: headerText,
        cell: item => item.shipFrom,
        sortingField: COLUMN_IDENTIFIER.ShipFrom,
        width: 200,
        minWidth: 100
    }
}

const SHIP_TO = {
    id: COLUMN_IDENTIFIER.ShipTo,
    header: 'Destination Country',
    cell: item => item.shipTo,
    sortingField: COLUMN_IDENTIFIER.ShipTo,
    width: 300,
    minWidth: 100
};

const NATURE_OF_TRANSACTION = {
    id: COLUMN_IDENTIFIER.NatureOfTransaction,
    header: 'Nature of Transaction',
    cell: item => item.natureOfTransaction,
    sortingField: COLUMN_IDENTIFIER.NatureOfTransaction,
    width: 200,
    minWidth: 100
};

const COUNTRY_OF_ORIGIN = {
    id: COLUMN_IDENTIFIER.CountryOfOrigin,
    header: 'Country of Origin',
    cell: item => item.countryOfOrigin,
    sortingField: COLUMN_IDENTIFIER.CountryOfOrigin,
    width: 200,
    minWidth: 100
};

const WEIGHT = (headerText) => {
    return {
        id: COLUMN_IDENTIFIER.Weight,
        header: headerText,
        cell: item => getCurrencyAmountForSummary(item.weight),
        sortingField: COLUMN_IDENTIFIER.Weight,
        width: 200,
        minWidth: 100
    };
}

const UNITS = (headerText) => {
    return {
        id: COLUMN_IDENTIFIER.Units,
        header: headerText,
        cell: item => getCurrencyAmountForSummary(item.units),
        sortingField: COLUMN_IDENTIFIER.Units,
        width: 200,
        minWidth: 100
    }
}

const INVOICE_VALUE = (headerText) => {
    return {
        id: COLUMN_IDENTIFIER.InvoiceValue,
        header: headerText,
        cell: item => getCurrencyAmountForSummary(item.invoiceValue),
        sortingField: COLUMN_IDENTIFIER.InvoiceValue,
        width: 250,
        minWidth: 100
    };
};

const STATISTICAL_VALUE = (headerText) => {
    return {
        id: COLUMN_IDENTIFIER.StatisticalValue,
        header: headerText,
        cell: item => getCurrencyAmountForSummary(item.statisticalValue),
        sortingField: COLUMN_IDENTIFIER.StatisticalValue,
        width: 250,
        minWidth: 100
    };
};

const DELIVERY_TERMS = {
    id: COLUMN_IDENTIFIER.DeliveryTerms,
    header: 'Delivery terms',
    cell: item => item.deliveryTerms,
    sortingField: COLUMN_IDENTIFIER.DeliveryTerms,
    width: 200,
    minWidth: 100
};

const GOODS_DESCRIPTION = {
    id: COLUMN_IDENTIFIER.GoodsDescription,
    header: 'Description',
    cell: item => item.goodsDescription,
    sortingField: COLUMN_IDENTIFIER.GoodsDescription,
    width: 500,
    minWidth: 350
};

const MODE_OF_TRANSPORT = {
    id: COLUMN_IDENTIFIER.ModeOfTransport,
    header: 'Mode of transport',
    cell: item => item.modeOfTransport,
    sortingField: COLUMN_IDENTIFIER.ModeOfTransport,
    width: 200,
    minWidth: 100
};

const PORT_OR_AIRPORT = {
    id: COLUMN_IDENTIFIER.PortOrAirport,
    header: 'Port/Airport',
    cell: item => item.portOrAirport,
    sortingField: COLUMN_IDENTIFIER.PortOrAirport,
    width: 200,
    minWidth: 100
};

const RECIPIENT_VAT = (headerText) => {
    return {
        id: COLUMN_IDENTIFIER.RecipientVat,
        header: headerText,
        cell: item => item.recipientVAT,
        sortingField: COLUMN_IDENTIFIER.RecipientVat,
        width: 250,
        minWidth: 100
    };
};

const REGION = {
    id: COLUMN_IDENTIFIER.Region,
    header: 'Region',
    cell: item => item.region,
    sortingField: COLUMN_IDENTIFIER.Region,
    width: 150,
    minWidth: 100
};

const SUPPLIER_UNIT_TYPE = {
    id: COLUMN_IDENTIFIER.SupplierUnitType,
    header: 'Supplementary Unit Type',
    cell: item => item.supplierUnitType,
    sortingField: COLUMN_IDENTIFIER.SupplierUnitType,
    width: 250,
    minWidth: 100
};

const SOURCE_TYPE = {
    id: COLUMN_IDENTIFIER.SourceType,
    header: 'Use case',
    cell: item => {
        if (!isNull(item.sourceType)) {
            return item.sourceType;
        } else {
            return <div style={{float: "right"}}>{"-"}</div>;
        }
    },
    sortingField: COLUMN_IDENTIFIER.SourceType,
    width: 150,
    minWidth: 100
};

//TODO : Fetch column definitions from TwinService instead of creating a map here
export const getColumnDefinitions = (useCase) => {
    switch (useCase) {
        case USECASE_MAP.DE_INTRASTAT_ARRIVALS:
            return [HASH_KEY, COMMODITY_CODE, GOODS_DESCRIPTION, SHIP_FROM("Ship from Country"),
                COUNTRY_OF_ORIGIN, NATURE_OF_TRANSACTION, REGION, MODE_OF_TRANSPORT, WEIGHT("Weight in KG"),
                UNITS("Units"), INVOICE_VALUE("Invoice value"), STATISTICAL_VALUE("Statistical Value")];
        case USECASE_MAP.DE_INTRASTAT_DISPATCH:
            return [HASH_KEY, COMMODITY_CODE, GOODS_DESCRIPTION, SHIP_TO, COUNTRY_OF_ORIGIN, NATURE_OF_TRANSACTION,
                REGION, MODE_OF_TRANSPORT, WEIGHT("Weight in KG"), UNITS("Units"),
                INVOICE_VALUE("Invoice value"), STATISTICAL_VALUE("Statistical Value"),
                RECIPIENT_VAT('VAT Nr of Counterparty')];
        case USECASE_MAP.PL_INTRASTAT_ARRIVALS:
            return [HASH_KEY, COMMODITY_CODE, NATURE_OF_TRANSACTION, MODE_OF_TRANSPORT, DELIVERY_TERMS, COUNTRY_OF_ORIGIN,
                SHIP_FROM("Country of Dispatch"), UNITS("Quantity"),
                SUPPLIER_UNIT_TYPE, STATISTICAL_VALUE("Statistical amount in PLN"),
                INVOICE_VALUE("Invoice amount in PLN"), WEIGHT("Net Weight in KG")];
        case USECASE_MAP.PL_INTRASTAT_DISPATCH:
            return [HASH_KEY, COMMODITY_CODE, NATURE_OF_TRANSACTION, MODE_OF_TRANSPORT, DELIVERY_TERMS, COUNTRY_OF_ORIGIN,
                SHIP_TO, UNITS("Quantity"), SUPPLIER_UNIT_TYPE, STATISTICAL_VALUE("Statistical amount in PLN"),
                INVOICE_VALUE("Invoice amount in PLN"), WEIGHT("Net Weight in KG"),
                RECIPIENT_VAT("VAT ID of the counterparty")];
        default:
            return [HASH_KEY, COMMODITY_CODE, SHIP_FROM("Ship from Country"),
                SHIP_TO, NATURE_OF_TRANSACTION, COUNTRY_OF_ORIGIN, WEIGHT("Weight in KG"), UNITS("Units"),
                INVOICE_VALUE("Invoice Value"), STATISTICAL_VALUE("Statistical Value"),
                DELIVERY_TERMS, GOODS_DESCRIPTION, MODE_OF_TRANSPORT, PORT_OR_AIRPORT, RECIPIENT_VAT("VAT ID of the counterparty"),
                REGION, SUPPLIER_UNIT_TYPE, SOURCE_TYPE];
    }
}

export const getVisibleContent = () => {
    return [
        COLUMN_IDENTIFIER.CommodityCode,
        COLUMN_IDENTIFIER.CountryOfDispatch,
        COLUMN_IDENTIFIER.CountryOfOrigin,
        COLUMN_IDENTIFIER.NatureOfTransaction,
        COLUMN_IDENTIFIER.Weight,
        COLUMN_IDENTIFIER.Units,
        COLUMN_IDENTIFIER.InvoiceValue,
        COLUMN_IDENTIFIER.StatisticalValue,
        COLUMN_IDENTIFIER.ShipTo,
        COLUMN_IDENTIFIER.DeliveryTerms,
        COLUMN_IDENTIFIER.GoodsDescription,
        COLUMN_IDENTIFIER.ModeOfTransport,
        COLUMN_IDENTIFIER.PortOrAirport,
        COLUMN_IDENTIFIER.RecipientVat,
        COLUMN_IDENTIFIER.Region,
        COLUMN_IDENTIFIER.ShipFrom,
        COLUMN_IDENTIFIER.SupplierUnitType,
        COLUMN_IDENTIFIER.SourceType
    ];
}

export const getFilteringProperties = () => {
    return [
        {
            propertyLabel: 'Commodity code',
            key: COLUMN_IDENTIFIER.CommodityCode,
            operators: ['='],
        },
        {
            propertyLabel: 'Country of dispatch',
            key: COLUMN_IDENTIFIER.CountryOfDispatch,
            operators: ['='],
        },
        {
            propertyLabel: 'Country of origin (CoO)',
            key: COLUMN_IDENTIFIER.CountryOfOrigin,
            operators: ['='],
        },
        {
            propertyLabel: 'Nature of transaction (NOTC)',
            key: COLUMN_IDENTIFIER.NatureOfTransaction,
            operators: ['='],
        },
        {
            propertyLabel: 'Weight',
            key: COLUMN_IDENTIFIER.Weight,
            operators: ['='],
        },
        {
            propertyLabel: 'Units',
            key: COLUMN_IDENTIFIER.Units,
            operators: ['='],
        },
        {
            propertyLabel: 'Invoice value (local currency)',
            key: COLUMN_IDENTIFIER.InvoiceValue,
            operators: ['='],
        },
        {
            propertyLabel: 'Statistical value (local currency)',
            key: COLUMN_IDENTIFIER.StatisticalValue,
            operators: ['='],
        },
        {
            propertyLabel: 'Ship to',
            key: COLUMN_IDENTIFIER.ShipTo,
            operators: ['='],
        },
        {
            propertyLabel: 'Delivery terms',
            key: COLUMN_IDENTIFIER.DeliveryTerms,
            operators: ['='],
        },
        {
            propertyLabel: 'Goods description',
            key: COLUMN_IDENTIFIER.GoodsDescription,
            operators: ['='],
        },
        {
            propertyLabel: 'Mode of transport',
            key: COLUMN_IDENTIFIER.ModeOfTransport,
            operators: ['='],
        },
        {
            propertyLabel: 'Port-Airport',
            key: COLUMN_IDENTIFIER.PortOrAirport,
            operators: ['='],
        },
        {
            propertyLabel: 'Recipient\'s VAT ID',
            key: COLUMN_IDENTIFIER.RecipientVat,
            operators: ['='],
        },
        {
            propertyLabel: 'Region',
            key: COLUMN_IDENTIFIER.Region,
            operators: ['='],
        },
        {
            propertyLabel: 'Ship from',
            key: COLUMN_IDENTIFIER.ShipFrom,
            operators: ['='],
        },
        {
            propertyLabel: 'Supplementary unit type',
            key: COLUMN_IDENTIFIER.SupplierUnitType,
            operators: ['='],
        },
        {
            propertyLabel: 'Use case',
            key: COLUMN_IDENTIFIER.SourceType,
            operators: ['='],
        },
    ]
}

export const getPreferenceOptions = () => {
    return [
        { id: COLUMN_IDENTIFIER.CommodityCode, label: 'Commodity code' },
        { id: COLUMN_IDENTIFIER.CountryOfDispatch, label: 'Country of dispatch' },
        { id: COLUMN_IDENTIFIER.CountryOfOrigin, label: 'Country of origin (CoO)' },
        { id: COLUMN_IDENTIFIER.NatureOfTransaction, label: 'Nature of transaction (NOTC)' },
        { id: COLUMN_IDENTIFIER.Weight, label: 'Weight' },
        { id: COLUMN_IDENTIFIER.Units, label: 'Units' },
        { id: COLUMN_IDENTIFIER.InvoiceValue, label: 'Invoice value (local currency)' },
        { id: COLUMN_IDENTIFIER.StatisticalValue, label: 'Statistical value (local currency)' },
        { id: COLUMN_IDENTIFIER.ShipTo, label: 'Ship to'},
        { id: COLUMN_IDENTIFIER.DeliveryTerms, label: 'Delivery terms'},
        { id: COLUMN_IDENTIFIER.GoodsDescription, label: 'Goods description'},
        { id: COLUMN_IDENTIFIER.ModeOfTransport, label: 'Mode of transport'},
        { id: COLUMN_IDENTIFIER.PortOrAirport, label: 'Port-Airport'},
        { id: COLUMN_IDENTIFIER.RecipientVat, label: 'Recipient\'s VAT ID'},
        { id: COLUMN_IDENTIFIER.Region, label: 'Region'},
        { id: COLUMN_IDENTIFIER.ShipFrom, label: 'Ship from'},
        { id: COLUMN_IDENTIFIER.SupplierUnitType, label: 'Supplementary unit type'}
    ]
}

const VISIBLE_CONTENT_OPTIONS = [
    {
        label: 'Main transaction properties',
        options: [
            { id: 'orderId', label: 'Order Id' },
            { id: 'reversalId', label: 'Reversal Id' },
            { id: 'shipmentId', label: 'Shipment Id' },
            { id: 'marketplaceId', label: 'MarketPlace Id' },
            { id: 'merchantId', label: 'Merchant Id' },
            { id: 'shipDate', label: 'ShipDate' },
            { id: 'asin', label: 'ASIN' },
            { id: 'shipFromCountry', label: 'ShipFromCountry' },
            { id: 'shipToCountry', label: 'ShipToCountry' },
        ],
    },
];

export const Preferences = ({
                                preferences,
                                setPreferences,
                                disabled,
                                visibleContentOptions = VISIBLE_CONTENT_OPTIONS,
                            }) => (
    <CollectionPreferences
        title="Preferences"
        confirmLabel="Confirm"
        cancelLabel="Cancel"
        disabled={disabled}
        preferences={preferences}
        onConfirm={({ detail }) => setPreferences(detail)}
        wrapLinesPreference={{
            label: 'Wrap lines',
            description: 'Check to see all the text and wrap the lines',
        }}
        visibleContentPreference={{
            title: 'Select visible columns',
            options: visibleContentOptions,
        }}
    />
);
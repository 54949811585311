// eslint-disable-next-line
import styles from "@chatscope/chat-ui-kit-styles/dist/default/styles.min.css";
import {
  MainContainer,
  ChatContainer,
  MessageList,
  Message,
  MessageInput,
} from "@chatscope/chat-ui-kit-react";
import { TextContent } from "@amzn/awsui-components-react";

export const Correspondence = (props) => {
    const { correspondenceMessages, handleNewMessage, correspondenceLoading } = props;

    const messages = [];
    correspondenceMessages.forEach(correspondenceMessage => {
        if (correspondenceMessage.text !== undefined) {
            const matches = [...(correspondenceMessage.text.matchAll('([a-zA-z0-9]*@)((?:.*\\s*)*)'))];
            const text = matches[0].length < 2 ? correspondenceMessage.text : `<b>${matches[0][1]}</b>${matches[0][2]}`;
            messages.push(
                <Message
                    model={{
                        message: correspondenceMessage.text,
                        direction: "incoming",
                        position: 'single',
                    }}
                >
                    <Message.HtmlContent html={text}/>
                    <Message.Footer sentTime={correspondenceMessage.time}/>
                </Message>
            );
        }
    });

    const onSend = (innerHtml, textContent, innerText, nodes) => {
        handleNewMessage(textContent);
    };

    const navBarHeight = 56;

    return (
        <div>
            <div style={{ height: "8vh", textAlign: "center" }}>
                <div style={{ position: "relative", top: "25%", display: "inline-block" }}>
                    <TextContent><h2>Correspondence</h2></TextContent>
                </div>
            </div>

            <div style={{ position: "relative", height: `calc(92vh - ${navBarHeight}px)` }}>
                <MainContainer>
                    <ChatContainer>
                    <MessageList loading={correspondenceLoading}>
                        {messages}
                    </MessageList>
                    <MessageInput placeholder="Type message here" attachButton={false} onSend={onSend} />
                    </ChatContainer>
                </MainContainer>
            </div>
        </div>
    )
}
import { isNull, isUndefined, set } from "lodash";
import { getErrorPerc, getSourceListForObligation } from "../utils/utils";
import { DATA_SOURCE_TYPE } from "../constants/constants";

export const SOURCE_SUMMARY_DETAILS = {
    sourceSummary: [],
    transactionDataForSource: {},
    taxCodeDataForSource: {},
    fetchingSourceSummary: false,
    fetchingDataForSource: false,
    lastUpdatedMap: {}
};

const DEFAULT_TRANSACTION_SUMMARY = {
    transactionCount: null,
    exceptionCount: null,
    netAmount: null,
    netTax: null,
    netGrossAmount: null,
    invoiceValue: null,
    progress: null
}

const getReportSource = (sourceType) => {
    switch (sourceType) {
        case DATA_SOURCE_TYPE.UPS:
        case DATA_SOURCE_TYPE.MOOG:
        case DATA_SOURCE_TYPE.KERRY: return "File-based"
        case DATA_SOURCE_TYPE.VENDOR_ARRIVALS:
        case DATA_SOURCE_TYPE.INTRA_FC:
        case DATA_SOURCE_TYPE.PURCHASE:
        case DATA_SOURCE_TYPE.RETURN:
        case DATA_SOURCE_TYPE.DONATIONS:
        case DATA_SOURCE_TYPE.COS:
        case DATA_SOURCE_TYPE.COS_TAR: return "System"
        default: return ""
    }
}

export const sourceSummaryTableReducerInit = initialState =>{
    return initialState;
};

const getProgress = (errorCount, totalCount) => {
    if (isNull(errorCount) || isUndefined(errorCount) || isNull(totalCount) || isUndefined(totalCount)) {
        return '-';
    };

    const errorPer = getErrorPerc(errorCount, totalCount);
    let progress = parseFloat(100 - errorPer);
    return progress;
};

const getSourceSummaryData = (data, phastosObligation) => {
    // TODO: - Check if data copy is required or data is mutable
    const result = [];
    let sourcesCovered = [];
    if (!isNull(data) && !isUndefined(data)) {
        for (const sourceItem of data) {
            const progress = getProgress(sourceItem?.exceptionCount, sourceItem?.transactionCount);
            set(sourceItem, "progress", progress);
            result.push(sourceItem);
            sourcesCovered.push(sourceItem?.sourceType);
        }
    }
    const sourceTypeList = getSourceListForObligation(phastosObligation);
    for (const sourceType of sourceTypeList) {
        if (sourcesCovered.indexOf(sourceType) === -1) {
            result.push({sourceType: sourceType, ...DEFAULT_TRANSACTION_SUMMARY});
        }
    }
    const sourceSummaryArray = [];
    for (const sourceItem of result) {
        set(sourceItem, "reportSource", getReportSource(sourceItem.sourceType));
        sourceSummaryArray.push(sourceItem);
    }
    return sourceSummaryArray;
};

export const sourceSummaryTableReducer = (state, action) => {
    switch (action.type){
        case "API_BEGIN_FOR_SOURCE_SUMMARY":
            return {
                ...state,
                sourceSummary: [],
                lastUpdatedMap: { sourceSummary: '-' },
                transactionDataForSource: [],
                taxCodeDataForSource: [],
                fetchingSourceSummary: true,
                fetchingDataForSource: false,
            }
        case "API_BEGIN_FOR_SOURCE_DATA":
            return {
                ...state,
                transactionDataForSource: [],
                taxCodeDataForSource: [],
                fetchingSourceSummary: false,
                fetchingDataForSource: true,
            }
        case "API_RESPONSE_FOR_SOURCE_SUMMARY":
            return {
                ...state,
                sourceSummary: getSourceSummaryData(action.payload?.data.transactionSummaryList, action.payload?.data.phastosObligation),
                lastUpdatedMap: { sourceSummary: action.payload?.data.lastUpdatedOn },
                transactionDataForSource: [],
                taxCodeDataForSource: [],
                fetchingSourceSummary: false,
                fetchingDataForSource: false,
            }
        case "API_RESPONSE_FOR_TAX_CODE":
            return {
                ...state,
                taxCodeDataForSource: {
                    ...state.taxCodeDataForSource,
                    [action.payload?.sourceType]: action.payload?.data
                },
                fetchingSourceSummary: false,
                fetchingDataForSource: false,
            }
        case "API_RESPONSE_FOR_TXN":
            return {
                ...state,
                transactionDataForSource: {
                    ...state.transactionDataForSource,
                    [action.payload?.sourceType]: action.payload?.data
                },
                fetchingSourceSummary: false,
                fetchingDataForSource: false,
            }
        case "RESET_DATA_FOR_SOURCE_DATA":
            return {
                ...state,
                transactionDataForSource: {},
                taxCodeDataForSource: {}
            }
        case "FAILURE_API_RESPONSE":
            return SOURCE_SUMMARY_DETAILS;
        default:
            return state;
    }
};
import {isNull, isUndefined} from "lodash";
import {Icon} from "@amzn/awsui-components-react";
import React from "react";
import { MOG_DATA_SOURCES_LIST, VAT_DATA_SOURCES_LIST } from "../constants/constants";
import { PHASTOS_OBLIGATION } from "../../exception/components/common/constants";
import { MOG_TRANSACTION_STATUS_MAPPING } from "../../exception/components/MOG-V2/constants/errorSummaryConstants";
import { warningSvg } from "../../../common/icons/warning";

export const getErrorPerc = (errorCount, totalCount) => {
    return ((parseInt(errorCount)/parseInt(totalCount)) * 100);
};

const getFormattedStatusForVAT = (errorPer) => {
    if (errorPer > 0) {
        return (<div><Icon variant='warning' name="status-warning"/> Review required</div>);
    } else {
        return (<div><Icon variant='success' name="status-positive"/> Review required</div>);
    }
};

const getFormattedStatusForMOG = (errorPer) => {
    if (errorPer > 2) {
        return (<div><Icon variant='error' name="status-warning"/> Review required</div>);
    } else if (errorPer <= 2 && errorPer > 0) {
        return (<div><Icon svg={warningSvg}/> Review required</div>);
    } else {
        return (<div><Icon variant='success' name="status-positive"/> Review complete</div>);
    }
};

export const getFormattedStatus = (errorCount, totalCount, phastosObligation) => {
    if ((isNull(errorCount) || isUndefined(errorCount) || isNaN(errorCount)) && (isNull(totalCount) || isUndefined(totalCount) || isNaN(totalCount))) {
        return (<div><Icon variant='normal' name="status-pending"/> Ingestion pending / NA</div>);
    }
    const errorPer = getErrorPerc(errorCount, totalCount);
    switch (phastosObligation) {
        case PHASTOS_OBLIGATION.MOG:
            return getFormattedStatusForMOG(errorPer);
        case PHASTOS_OBLIGATION.VAT:
        default:
            return getFormattedStatusForVAT(errorPer);
    }
};

export const getFormattedCount = (transactionCount) => {
    if (isNull(transactionCount) || isUndefined(transactionCount) || isNaN(transactionCount)){
        return (<div> -</div>);
    }
    return (<div>{parseInt(transactionCount).toLocaleString("en-US")}</div>);
};

export const getFormattedProgress = (progress) => {
    if (isNull(progress) || isUndefined(progress)){
        return (<div> 0.00%</div>);
    }
    return (<div>{(Math.floor(progress*100)/100).toFixed(2)}%</div>);
};

export const getSourceListForObligation = (phastosObligation) => {
    switch (phastosObligation) {
        case PHASTOS_OBLIGATION.MOG:
            return MOG_DATA_SOURCES_LIST;
        case PHASTOS_OBLIGATION.VAT:
        default:
            return VAT_DATA_SOURCES_LIST;
    }
};

export const requireDerivedTaxCodeGrouping = (phastosObligation) => {
    switch (phastosObligation) {
        case PHASTOS_OBLIGATION.MOG:
            return false;
        case PHASTOS_OBLIGATION.VAT:
        default:
            return true;
    }
};

export const getTransactionStatusMapping = (phastosObligation) => {
    switch (phastosObligation) {
        case PHASTOS_OBLIGATION.MOG:
            return MOG_TRANSACTION_STATUS_MAPPING;
        case PHASTOS_OBLIGATION.VAT:
        default:
            return null;
    }
};
import React, { useEffect, useReducer, useState } from 'react';
import { Navigate, Route, Routes, useNavigate, useLocation } from 'react-router-dom';
import NotFound from './app/not_found/NotFound';
import Excalibur from './app/excalibur/Excalibur';
import Phastos from './app/phastos/Phastos';
import Faq from './app/faq/Faq';
import Home from './app/home/Home';
import WorkflowDetails from "./app/exception/WorkflowDetails";
import Filing from './app/filing/Filing';
import DocumentManager from "./app/documentmanager/DocumentManager";
import SourceSummary from "./app/sourceSummary/SourceSummaryView";
import {
    ROUTE_PATH_HOME_PAGE,
    ROUTE_PATH_PHASTOS_PAGE,
    ROUTE_PATH_PHASTOS_DETAILS_PAGE,
    ROUTE_PATH_FILING_PAGE,
    ROUTE_PATH_FILING_HMRC_PAGE,
    ROUTE_PATH_FAQ_PAGE,
    ROUTE_PATH_NOT_FOUND_PAGE,
    ROUTE_DEFAULT,
    ROUTE_EXCALIBUR,
    ROUTE_DOCUMENT_MANAGER,
    ROUTE_SOURCE_SUMMARY,
} from './common/constants/route_paths';
import {getAuthorizationData} from "./common/api/authAPIs";
import {BreadcrumbGroup} from "@amzn/awsui-components-react";
import "./App.css";
import {getBreadCrumbItems} from "./common/constants/breadCrumbConstants";
import { AUTHORIZATION_INITIAL_STATE, authorizationReducer, authorizationReducerInit } from './common/api/authorizationReducer';
import { INTERNAL_ERROR_FLASH_BAR_PROPS, NOT_AUTHORIZED_FLASH_BAR_PROPS } from './common/constants/constants';
import {getFlashBar, getLoadingSpinner} from './common/utils/commonUtils';
import { get, isUndefined } from "lodash";

const AppRoutes = ({domain, user, activeTab, setActiveTab}) => {
  return (
    <Routes>
        <Route exact path={ROUTE_PATH_HOME_PAGE}
               element={<Page pageName={"Home"} content={<Home domain={domain}/>}/>}/>
        <Route exact path={ROUTE_PATH_PHASTOS_PAGE}
               element={<Page pageName={"Phastos"} content={<Phastos alias={user} domain={domain} activeTab={activeTab} setActiveTab={setActiveTab}  />}/>}/>
        <Route exact path={ROUTE_PATH_PHASTOS_DETAILS_PAGE}
               element={<Page pageName={"Exception"} content={<WorkflowDetails domain={domain} user={user}/>}/>}/>
        <Route path={ROUTE_PATH_FILING_PAGE}
               element={<Page pageName={"Filing"} content={<Filing domain={domain}/>}/>}/>
        <Route path={ROUTE_PATH_FILING_HMRC_PAGE}
               element={<Page pageName={"Filing"} content={<Filing domain={domain}/>}/>}/>
        <Route path={ROUTE_PATH_NOT_FOUND_PAGE} element={<NotFound/>}/>
        <Route path={ROUTE_EXCALIBUR}
               element={<Page pageName={"Excalibur"} content={<Excalibur domain={domain}/>}/>}/>
        <Route exact path={ROUTE_PATH_FAQ_PAGE}
               element={<Page pageName={"Faq"} content={<Faq domain={domain}/>}/>}/>
        <Route exact path={ROUTE_SOURCE_SUMMARY}
               element={<Page pageName={"SourceSummary"} content={<SourceSummary domain={domain}/>}/>}/>
        <Route path={ROUTE_DEFAULT} element={<Navigate to={ROUTE_PATH_NOT_FOUND_PAGE}/>}/>
        {/*<Route path={ROUTE_CONFIGURATIONS} element={<ConfigurationManager />} />*/}
        <Route path={ROUTE_DOCUMENT_MANAGER}
               element={<Page pageName={"DocumentManagement"} content={<DocumentManager domain={domain}/>}/>}/>
    </Routes>
  );
};

function Page({pageName, content}) {
       const [returnContent, setContent] = useState();
       const [breadcrumbItems, setBreadcrumbItems] = useState([]);

       const params = useLocation();
       const navigate = useNavigate();

       const [authorizationData, authorizationDataDispatcher] = useReducer(authorizationReducer,
              AUTHORIZATION_INITIAL_STATE, authorizationReducerInit);
 
       // Scroll to top
       useEffect(() => {
              window.scrollTo(0, 0);
       }, [returnContent]);

       useEffect(() => {
              setContent((getLoadingSpinner()));
              setBreadcrumbItems([]);
              if (isUndefined(get(authorizationData, `data.${pageName}`, undefined))) {
                     getAuthorizationData(pageName, authorizationDataDispatcher);
              } else {
                     const pageAuthorizationData = authorizationData?.data?.[pageName];
                     if (pageAuthorizationData?.isInternalError === true) {
                            //TODO : add monitoring and alarms in case of internal error
                            setContent(getFlashBar(INTERNAL_ERROR_FLASH_BAR_PROPS));
                     } else {
                            if (pageAuthorizationData?.isAuthorized === true){
                                   setContent(content);
                                   setBreadcrumbItems(getBreadCrumbItems(pageName, params));
                            } else {
                                   setContent(getFlashBar(NOT_AUTHORIZED_FLASH_BAR_PROPS));
                            }
                     }
              }
              // eslint-disable-next-line react-hooks/exhaustive-deps
       }, [pageName, content, authorizationData]);
       
       return (
       <div className="page">
              { breadcrumbItems.length > 0 &&
                     <div className="BreadCrumbGroupDiv">
                            <BreadcrumbGroup items={breadcrumbItems} onClick={e => {
                                   e.preventDefault();
                                   navigate(e.detail.href);
                            }}/>
                     </div>
              }
              {returnContent}
       </div>
    );
}
export default AppRoutes;

import React, {useEffect, useState, useReducer} from 'react';
import { useCollection } from '@amzn/awsui-collection-hooks';
import {
    Header,
    Pagination,
    Tabs,
    Table,
    SpaceBetween,
    Button,
    Flashbar,
} from '@amzn/awsui-components-react';
import DataSharingTab from "./components/DataSharing/DataSharingTab";
import MOGTab from "./components/MOG/MOGTab";
import {
    scheduledColumnDefinitions,
    adhocColumnDefinitions,
    paginationLabels,
    Preferences,
    filteringProperties,
    defaultSortingAppliedOn,
    vatFilteringProperties,
} from './table-config';
import { useNavigate } from "react-router-dom";
import { JOB_DETAILS_STATE, tableReducer, jobDetailsTableReducerInit } from "./components/jobDetailsReducer";
import { fetchJobDetailsForWorkflow } from "./components/phastosAPIs";
import VATReturnTab from "./components/VAT/VATReturnTab";
import {
    getCollectionConfig
} from './Constants/Constants';
import {getLoadingSpinner} from "../../common/utils/commonUtils";
import {defaultSortingForMOGAppliedOn} from "./components/MOG/table-config";
import DigitalServicesTaxTab from "./components/DigitalServicesTax/DigitalServicesTaxTab";
import AdhocDataSharingTab from "./components/AdhocDataSharing/AdhocDataSharingTab";
import {
    DOWNLOAD_INITIAL_STATE,
    downloadReducer,
    downloadReducerInit
} from "../excalibur/components/downloadDocumentReducer";
export default function Actiontable(props) {

  const dataSharingData = props?.dataSharingData;
  const vatReturnData = props?.vatReturnData;
  const adhocDataSharingData = props?.adhocDSData;
  const dstData = props?.digitalServicesTaxData;
  const mogData = props?.MOGData;

  const {
    dispatcher: workflowTableDispatcher,
    user,
    viewSummaryMetricsProps,
    activeTabId,
    setActiveTabId,
    statusFilterValue,
    setStatusFilterValue,
    isDSFiledChecked,
    setIsDSFiledChecked,
    isAdhocDSFiledChecked,
    setIsAdhocDSFiledChecked,
    isVATFiledChecked,
    setIsVATFiledChecked,
    isMOGFiledChecked,
    setIsMOGFiledChecked,
    listChannelsSlackData,
    listChannelsSlackDataDispatcher,
    updateWorkflowStatusDispatcher,
    addUserSlackStatusDispatcher,
    createChannelSlackStatusDispatcher,
      ...params
  } = props;

  const navigate = useNavigate();

  const [dsTablePreferences, setDSTablePreferences] = useState({ pageSize: 10, visibleContent: ['obligation', 'entity', 'period', 'country', 'type', 'status', 'intermediateDeadline', 'filingDeadline', 'errorTransactions', 'grossMerchantSaleAmount', 'totalUniqueMerchants', 'action'] });
  const [vatTablePreferences, setVATTablePreferences] = useState({ pageSize: 10, visibleContent: ['obligation', 'entity', 'period', 'country', 'scheduledExecutionTime', 'status', 'reviewDeadline', 'approvalDeadline', 'filingDeadline'] });
  const [mogTablePreferences, setMOGTablePreferences] = useState({ pageSize: 10, visibleContent: ['obligation', 'entity', 'entityCode', 'period', 'country', 'scheduledExecutionTime', 'status', 'reviewDeadline', 'approvalDeadline', 'filingDeadline'] });
  const [dstTablePreferences, setDSTTablePreferences] = useState({ pageSize: 10, visibleContent: ['obligation', 'entity', 'period', 'country', 'scheduledExecutionTime', 'status', 'reviewDeadline', 'approvalDeadline', 'filingDeadline'] });
  const [adhocDSTablePreferences, setAdhocDSTablePreferences] = useState({ pageSize: 10, visibleContent: ['obligation', 'entity', 'period', 'country', 'phastosUseCaseName', 'status', 'createdAt', 'grossMerchantSaleAmount', 'totalUniqueMerchants', 'action'] });
  const [dsSelectedItems, setDSSelectedItems] = useState([]);
  const [adhocDSSelectedItems, setAdhocDSSelectedItems] = useState([]);
  const [vatSelectedItems, setVATSelectedItems] = useState([]);
  const [mogSelectedItems, setMOGSelectedItems] = useState([]);

  const [scheduledTablePreferences, setScheduledTablePreferences] = useState({ pageSize: 10, visibleContent: ['obligation', 'entity', 'period', 'country', 'type', 'scheduledExecutionTime', 'status', 'grossMerchantSaleAmount', 'totalUniqueMerchants','action'] });
  const [adhocTablePreferences, setAdhocTablePreferences] = useState({ pageSize: 10, visibleContent: ['obligation', 'entity', 'period', 'country', 'type', 'status', 'createdAt', 'grossMerchantSaleAmount', 'totalUniqueMerchants'] });
  const [selectedScheduleJobs, setSelectedScheduleJobs] = useState([]);
  const [selectedOnDemandJobs, setSelectedOnDemandJobs] = useState([]);
  const [downloadDocument, downloadDocumentDispatcher] = useReducer(downloadReducer,
      DOWNLOAD_INITIAL_STATE, downloadReducerInit);

    const [jobDetailsTable, jobDetailsTableDispatcher] = useReducer(tableReducer,
        JOB_DETAILS_STATE, jobDetailsTableReducerInit);

  const filterFiledObligation = (obligations, isFilter) => {
    const result = [];
    if (!isFilter) {
        for (let i = 0; i < obligations.length; i++) {
            if (obligations[i].status !== 'FILED') {
                result.push(obligations[i]);
            }
        }
        return result;
    }
    return obligations;
  };

    const {
        items: dsDataItems,
        filteredItemsCount: dsFilteredItemsCount,
        collectionProps: dsCollectionProps,
        propertyFilterProps: dsPropertyFilterProps,
        paginationProps: dsPaginationProps,
        actions: dsActions
    } = useCollection(
        filterFiledObligation(dataSharingData, isDSFiledChecked),
        getCollectionConfig(filteringProperties, dsTablePreferences, defaultSortingAppliedOn),
    );

    const {
        items: adhocDSDataItems,
        filteredItemsCount: adhocDSFilteredItemsCount,
        collectionProps: adhocDSCollectionProps,
        propertyFilterProps: adhocDSPropertyFilterProps,
        paginationProps: adhocDSPaginationProps,
        actions: adhocDSActions
    } = useCollection(
        filterFiledObligation(adhocDataSharingData, isAdhocDSFiledChecked),
        getCollectionConfig(filteringProperties, adhocDSTablePreferences, defaultSortingAppliedOn),
    );

  const {
    items: vatDataItems,
    filteredItemsCount: vatFilteredItemsCount,
    collectionProps: vatCollectionProps,
    propertyFilterProps: vatPropertyFilterProps,
    paginationProps: vatPaginationProps,
    actions: vatActions,
  } = useCollection(
      filterFiledObligation(vatReturnData, isVATFiledChecked),
      getCollectionConfig(vatFilteringProperties, vatTablePreferences, defaultSortingAppliedOn),
  );
  const {
        items: dstDataItems,
        filteredItemsCount: dstFilteredItemsCount,
        collectionProps: dstCollectionProps,
        propertyFilterProps: dstPropertyFilterProps,
        paginationProps: dstPaginationProps,
        actions: dstActions
    } = useCollection(
        dstData,
        getCollectionConfig(filteringProperties, dstTablePreferences, defaultSortingAppliedOn),
    );

  const {
    items: mogDataItems,
    filteredItemsCount: mogFilteredItemsCount,
    collectionProps: mogCollectionProps,
    propertyFilterProps: mogPropertyFilterProps,
    paginationProps: mogPaginationProps,
    actions: mogActions,
  } = useCollection(
    filterFiledObligation(mogData, isMOGFiledChecked),
    getCollectionConfig(vatFilteringProperties, mogTablePreferences, defaultSortingForMOGAppliedOn),
  );

  useEffect(() => {
    if (dsSelectedItems && dsSelectedItems.length === 1) {
        setSelectedScheduleJobs([]);
        setSelectedOnDemandJobs([]);
        jobDetailsTableDispatcher({
            type: "API_BEGIN", payload: {}
        })
        const lastIndex = dsSelectedItems[0].parentWorkflowId.lastIndexOf("#");
        const parentWorkflowIdPrefix = dsSelectedItems[0].parentWorkflowId.substr(0, lastIndex);
        fetchJobDetailsForWorkflow(jobDetailsTableDispatcher, parentWorkflowIdPrefix);
    }

    if (vatSelectedItems && vatSelectedItems.length === 1) {
        jobDetailsTableDispatcher({
            type: "API_BEGIN", payload: {}
        })
        const lastIndex = vatSelectedItems[0].parentWorkflowId.lastIndexOf("#");
        const parentWorkflowIdPrefix = vatSelectedItems[0].parentWorkflowId.substr(0, lastIndex);
        fetchJobDetailsForWorkflow(jobDetailsTableDispatcher, parentWorkflowIdPrefix);
    }

    if (mogSelectedItems && mogSelectedItems.length === 1) {
        jobDetailsTableDispatcher({
            type: "API_BEGIN", payload: {}
        })
        fetchJobDetailsForWorkflow(jobDetailsTableDispatcher, mogSelectedItems[0].parentWorkflowId);
    }
  }, [dsSelectedItems, vatSelectedItems, mogSelectedItems],)

  const { items: scheduledData, paginationProps: scheduledPaginationProps } = useCollection(
      jobDetailsTable ? jobDetailsTable.scheduledJobs : [],
      {
        pagination: {pageSize: scheduledTablePreferences.pageSize}
      }
  );
  const { items: adhocData, paginationProps: adhocPaginationProps } = useCollection(
      jobDetailsTable ? jobDetailsTable.adhocJobs : [],
      {
        pagination: {pageSize: scheduledTablePreferences.pageSize}
      }
  );

  const onSelectionChange = (item) => {
    setDSSelectedItems(item.detail.selectedItems)
  }

  const getObligationTableActions = (item) => {
    return (
        <SpaceBetween size='l' direction='horizontal'>
            <Button disabled={true}>Export</Button>
        </SpaceBetween>
    );
};

  if (viewSummaryMetricsProps.tableData.loading) {
      return (getLoadingSpinner())
  }

  const onTabChange = (event) => {
      setActiveTabId(event.detail.activeTabId);
      setVATSelectedItems([]);
      setDSSelectedItems([]);
      setStatusFilterValue({dataSharingTab: null, vatTab: null, mogTab: null, digitalServicesTaxTab: null, adhocDataSharingTab: null});  };

  const getExportEnabledFlag = () => {
      return dsSelectedItems[0]?.featureFlags?.is_export_enabled !== undefined;
  }

  return (
    <div>
      <Tabs tabs={[
          {
              label: `VAT/GST Filing (${vatReturnData.length})`,
              id: "VATReturns",
              content: <VATReturnTab
                  viewSummaryMetricsProps={viewSummaryMetricsProps}
                  collectionProps={vatCollectionProps}
                  selectedItems={vatSelectedItems}
                  allItems={vatReturnData}
                  preferences={vatTablePreferences}
                  setPreferences={setVATTablePreferences}
                  items={vatDataItems}
                  paginationProps={vatPaginationProps}
                  propertyFilterProps={vatPropertyFilterProps}
                  filteredItemsCount={vatFilteredItemsCount}
                  statusFilterValue={statusFilterValue}
                  actions={vatActions}
                  setSelectedItems={setVATSelectedItems}
                  isVATFiledChecked={isVATFiledChecked}
                  setIsVATFiledChecked={setIsVATFiledChecked}
                  createChannelSlackStatusDispatcher={createChannelSlackStatusDispatcher}
                  addUserSlackStatusDispatcher={addUserSlackStatusDispatcher}
                  listChannelsSlackData={listChannelsSlackData}
                  listChannelsSlackDataDispatcher={listChannelsSlackDataDispatcher}
              />
          },
          {
              label: `Data Sharing (${dataSharingData.length})`,
              id: "DataSharingTab",
              content: <DataSharingTab
                  viewSummaryMetricsProps={viewSummaryMetricsProps}
                  collectionProps={dsCollectionProps}
                  onSelectionChange={onSelectionChange}
                  selectedItems={dsSelectedItems}
                  allItems={dataSharingData}
                  preferences={dsTablePreferences}
                  setPreferences={setDSTablePreferences}
                  items={dsDataItems}
                  paginationProps={dsPaginationProps}
                  propertyFilterProps={dsPropertyFilterProps}
                  filteredItemsCount={dsFilteredItemsCount}
                  statusFilterValue={statusFilterValue}
                  actions={dsActions}
                  updateWorkflowStatusDispatcher={updateWorkflowStatusDispatcher}
                  workflowTableDispatcher={workflowTableDispatcher}
                  setSelectedItems={setDSSelectedItems}
                  isDSFiledChecked={isDSFiledChecked}
                  setIsDSFiledChecked={setIsDSFiledChecked}
                  createChannelSlackStatusDispatcher={createChannelSlackStatusDispatcher}
                  addUserSlackStatusDispatcher={addUserSlackStatusDispatcher}
                  listChannelsSlackData={listChannelsSlackData}
                  listChannelsSlackDataDispatcher={listChannelsSlackDataDispatcher}
              />
          },
          {
              label: `Intrastat (${mogData.length})`,
              id: "MovementsOfGood",
              content: <MOGTab
                viewSummaryMetricsProps={viewSummaryMetricsProps}
                collectionProps={mogCollectionProps}
                selectedItems={mogSelectedItems}
                allItems={mogData}
                preferences={mogTablePreferences}
                setPreferences={setMOGTablePreferences}
                items={mogDataItems}
                paginationProps={mogPaginationProps}
                propertyFilterProps={mogPropertyFilterProps}
                filteredItemsCount={mogFilteredItemsCount}
                statusFilterValue={statusFilterValue}
                actions={mogActions}
                setSelectedItems={setMOGSelectedItems}
                createChannelSlackStatusDispatcher={createChannelSlackStatusDispatcher}
                addUserSlackStatusDispatcher={addUserSlackStatusDispatcher}
                listChannelsSlackData={listChannelsSlackData}
                listChannelsSlackDataDispatcher={listChannelsSlackDataDispatcher}
                isMOGFiledChecked={isMOGFiledChecked}
                setIsMOGFiledChecked={setIsMOGFiledChecked}
              />,
              disabled: false,
          },
          {
              label: `Digital Services Tax (${dstData.length})`,
              id: "DigitalServicesTax",
              content: <DigitalServicesTaxTab
                  viewSummaryMetricsProps={viewSummaryMetricsProps}
                  collectionProps={dstCollectionProps}
                  allItems={dstData}
                  preferences={dstTablePreferences}
                  setPreferences={setDSTTablePreferences}
                  items={dstDataItems}
                  paginationProps={dstPaginationProps}
                  propertyFilterProps={dstPropertyFilterProps}
                  filteredItemsCount={dstFilteredItemsCount}
                  statusFilterValue={statusFilterValue}
                  actions={dstActions}
                  updateWorkflowStatusDispatcher={updateWorkflowStatusDispatcher}
                  workflowTableDispatcher={workflowTableDispatcher}
                  createChannelSlackStatusDispatcher={createChannelSlackStatusDispatcher}
                  addUserSlackStatusDispatcher={addUserSlackStatusDispatcher}
                  listChannelsSlackData={listChannelsSlackData}
                  listChannelsSlackDataDispatcher={listChannelsSlackDataDispatcher}
              />
          },
          {
              label: `Adhoc Data Sharing (${adhocDataSharingData.length})`,
              id: "AdhocDataSharing",
              content: <AdhocDataSharingTab
                  user={user}
                  alias={params.alias}
                  viewSummaryMetricsProps={viewSummaryMetricsProps}
                  collectionProps={adhocDSCollectionProps}
                  selectedItems={adhocDSSelectedItems}
                  allItems={adhocDataSharingData}
                  preferences={adhocDSTablePreferences}
                  setPreferences={setAdhocDSTablePreferences}
                  items={adhocDSDataItems}
                  paginationProps={adhocDSPaginationProps}
                  propertyFilterProps={adhocDSPropertyFilterProps}
                  filteredItemsCount={adhocDSFilteredItemsCount}
                  statusFilterValue={statusFilterValue}
                  actions={adhocDSActions}
                  updateWorkflowStatusDispatcher={updateWorkflowStatusDispatcher}
                  workflowTableDispatcher={workflowTableDispatcher}
                  setAdhocDSSelectedItems={setAdhocDSSelectedItems}
                  isAdhocDSFiledChecked={isAdhocDSFiledChecked}
                  setIsAdhocDSFiledChecked={setIsAdhocDSFiledChecked}
                  createChannelSlackStatusDispatcher={createChannelSlackStatusDispatcher}
                  addUserSlackStatusDispatcher={addUserSlackStatusDispatcher}
                  listChannelsSlackData={listChannelsSlackData}
                  listChannelsSlackDataDispatcher={listChannelsSlackDataDispatcher}
              />
          },
          // {
          //   label: "Withholding",
          //   id: "Withholding",
          //   disabled: true,
          // }
      ]} onChange={event => { onTabChange(event) }} activeTabId={activeTabId} />
      <br />
      {(dsSelectedItems && dsSelectedItems.length === 1) || (vatSelectedItems && vatSelectedItems.length === 1) ?
      <SpaceBetween size='xl'>
        <Flashbar items={downloadDocument?.flashBarItem}/>
        <Tabs
          tabs={[
            {
              label: "Scheduled",
              id: "first",
              content: <Table
                header={
                  <Header actions={getObligationTableActions('Scheduled')}>
                    Obligations Breakdown
                  </Header>
                }
                {...dsCollectionProps}
                loading={jobDetailsTable.fetching}
                selectionType="multi"
                onSelectionChange={({ detail }) => setSelectedScheduleJobs(detail.selectedItems)}
                selectedItems={selectedScheduleJobs}
                columnDefinitions={scheduledColumnDefinitions(downloadDocumentDispatcher, getExportEnabledFlag(), dsSelectedItems[0]?.phastosUseCaseName)}
                visibleColumns={scheduledTablePreferences.visibleContent}
                items={scheduledData}
                pagination={<Pagination {...scheduledPaginationProps} ariaLabels={paginationLabels} />}
                preferences={<Preferences preferences={scheduledTablePreferences} setPreferences={setScheduledTablePreferences} />}
              />
            },
            {
              label: "On-demand",
              id: "second",
              content: <Table
                header={
                  <Header actions={getObligationTableActions('On-Demand')}>
                    Obligations Breakdown
                  </Header>
                }
                {...dsCollectionProps}
                columnDefinitions={adhocColumnDefinitions(navigate)}
                loading={jobDetailsTable.fetching}
                selectionType="multi"
                onSelectionChange={({ detail }) => setSelectedOnDemandJobs(detail.selectedItems)}
                selectedItems={selectedOnDemandJobs}
                visibleColumns={adhocTablePreferences.visibleContent}
                items={adhocData}
                pagination={<Pagination {...adhocPaginationProps} ariaLabels={paginationLabels} />}
                preferences={<Preferences preferences={adhocTablePreferences} setPreferences={setAdhocTablePreferences} />}
              />
            }
          ]}
        />
      </SpaceBetween> : null}
      <br />
    </div>
  );
}
import React from "react";
import {ColumnLayout, Header} from "@amzn/awsui-components-react";
import {Box, Container} from "@amzn/awsui-components-react/polaris";
import {formatStatus} from "../../../../../common/utils/commonUtils";

export default function ObligationDetails(props) {
    const {data, obligationStatus} = props;

    return (
        <Container header={<Header>Obligation details</Header>}>
            <ColumnLayout columns={2} variant='text-grid'>
                <Box>Obligation</Box>
                <Box>{data?.obligationName}</Box>
                <Box>Country</Box>
                <Box>{data?.country}</Box>
                <Box>Entity</Box>
                <Box>{data?.entity}</Box>
                <Box>Period</Box>
                <Box>{data?.reportingPeriod}</Box>
                <Box>Status</Box>
                <Box>{formatStatus(obligationStatus)}</Box>
            </ColumnLayout>
        </Container>
    )
}
import {UPLOAD_PREFIX} from "../../../common/constants/constants";
import {getParentAndSubUseCase} from "./fetchAvailableUseCases";
import {generateExcaliburReport} from "./excaliburAPIs";
import AppConfig from "../../../AppConfig";
import {getS3Bucket} from "../../../common/utils/s3Utils";

export const configUpload = async (event, useCases, domain, setSuccess, setUploading) => {

    setSuccess({isVisible:false});
    setUploading(true);
    const config = event.target.files
    //the upload folder depends on the use case so the key for the params:
    //for a use case FR, Key: FR/FR_fileName
    console.log("========configUpload=====");
    for(const useCase of useCases) {
        const useCaseArray = getParentAndSubUseCase(useCase);
        const key = useCaseArray.join("/");

        const params = {
            Bucket: AppConfig[domain].EXCALIBUR_BUCKET,
            Key: [UPLOAD_PREFIX, key, config[0].name].join("/"),
            Body: config[0]
        };

        // if a use case contains sub use cases, the folder name changes to the
        // use case name which is extracted using split function and the file name
        // needs to be pre-appended with the sub use case as follows
        // so for a use case "LUX_IOSS", Key: "LUX/IOSS_fileName"
        const s3bucket = getS3Bucket(domain);
        try {
            const data = await s3bucket.upload(params).promise();
            console.log(`File uploaded successfully at ${data.Location}`);
            generateExcaliburReport(params.Key, data.VersionId, useCaseArray, config[0].name, setSuccess);
        } catch (err) {
            console.log("File could not be uploaded as" + err);
            throw err;
        }
    }
    setUploading(false);
}

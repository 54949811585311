import {ERROR_TYPE_EXCEPTION, ERROR_TYPE_EXCLUDED, TRANSACTION_TYPE} from "./adjustment/constants/errorSummaryUtils";
import {
    ATTRIBUTES_FOR_EXCEPTION_TABLE,
    ATTRIBUTES_FOR_FILTER_TABLE,
    getConditionEnumFromOperator,
    getOperatorFromConditionEnum
} from "./adjustment/constants/transactionLevelUtils";

/**
 * This util function will update transaction filter data state to the parent component.
 * @param filterData
 * @param query
 * @param setTransactionFilterData
 */
export const updateFilterData = (filterData, query, setTransactionFilterData) => {
    const filterConditions = tokensToFilterData(query.tokens);
    setTransactionFilterData({
        ...filterData,
        filterConditions: filterConditions
    });
}

/**
 * This function will convert search bar query tokens into filterConditions.
 * Used while updating request data for exception module's apis.
 * @param tokens
 * @returns {*[]}
 */
export const tokensToFilterData = (tokens) => {
    const conditions = [];
    tokens.forEach((item) => {
        conditions.push({
            attributeName: item.propertyKey,
            attributeValue: item.value,
            condition: getConditionEnumFromOperator(item.operator)
        });
    });
    return conditions;
}

/**
 * This function will convert filter conditions to search bar query tokens.
 * Used to populate query tokens on pre-set condition set clicks.
 * @param conditions
 * @returns {*[]}
 */
export const filterConditionsToTokens = (conditions) => {
    const tokens = [];
    conditions.forEach((item) => {
        if (item.attributeValue !== undefined) {
            tokens.push({
                propertyKey: item.attributeName,
                operator: getOperatorFromConditionEnum(item.condition),
                value: item.attributeValue
            });
        }
    });
    return tokens;
}

/**
 * First check - if applied new filters include both exception and filter table fields
 * Second check - if applied new filters include exception table fields but invalid type field
 * Third check - if applied new filters include filter table fields but invalid type field
 * @param conditions
 * @param setFlashBarItemContent
 * @param setFlashBarVisibility
 * @returns {boolean}
 */
export const validateConditions = (conditions, setFlashBarItemContent, setFlashBarVisibility) => {
    if (conditions.some(item => ATTRIBUTES_FOR_EXCEPTION_TABLE.includes(item.attributeName))
        && conditions.some(item => ATTRIBUTES_FOR_FILTER_TABLE.includes(item.attributeName))) {
        setFlashBarItemContent({
            header: 'Invalid set of filter fields applied.',
            content: 'Filters on both Excluded and Exception group fields can not be applied.',
        });
        setFlashBarVisibility(true);
        return false;
    }
    if (!isTransactionTypeValid(conditions, ATTRIBUTES_FOR_EXCEPTION_TABLE, ERROR_TYPE_EXCEPTION)) {
        setFlashBarItemContent({
            header: 'Invalid filter conditions applied.',
            content: 'With filters on Exception group fields, the field - \'Type\' can have only value - \'Exception\'',
        });
        setFlashBarVisibility(true);
        return false;
    }
    if (!isTransactionTypeValid(conditions, ATTRIBUTES_FOR_FILTER_TABLE, ERROR_TYPE_EXCLUDED)) {
        setFlashBarItemContent({
            header: 'Invalid filter conditions applied.',
            content: 'With filters on Excluded group fields, the field - \'Type\' can have only value - \'Excluded\'',
        });
        setFlashBarVisibility(true);
        return false;
    }
    // in-case user does not dismiss the flash bar but changes filters to valid conditions
    setFlashBarVisibility(false);
    return true;
}

/**
 * Used for validating conditions, if transaction type is included or not in query.
 * if included and does not match with additional attributes type then validation declares as invalid.
 * @param conditions
 * @param attributes
 * @param transactionType
 * @returns {boolean|*}
 */
const isTransactionTypeValid = (conditions, attributes, transactionType) => {
    if (!conditions.some(item => (item.attributeName === TRANSACTION_TYPE))) {
        return true;
    }
    const typeIncludes = conditions.some(item => attributes.includes(item.attributeName));
    const transactionTypeValMismatch = conditions.some(item =>
        ((item.attributeName === TRANSACTION_TYPE) && (item.attributeValue !== transactionType)));
    return !(typeIncludes && transactionTypeValMismatch);
}

/**
 * Returns filter condition for transaction type.
 * @param type
 * @returns {{condition: string, attributeValue, attributeName: string}}
 */
const getTransactionTypeItem = (type) => {
    return {
        attributeName: TRANSACTION_TYPE,
        condition: getConditionEnumFromOperator("="),
        attributeValue: type
    }
}

/**
 * Will add transaction type filter to conditions if not present.
 * @param conditions
 * @param attributes
 * @param transactionType
 */
export const handleTransactionType = (conditions, attributes, transactionType) => {
    if (conditions.some(item => attributes.includes(item.attributeName)) &&
        !conditions.some(item => (item.attributeName === TRANSACTION_TYPE))) {
        conditions.push(getTransactionTypeItem(transactionType));
    }
}
export const PUT_FILE_IN_S3_INITIAL_STATE = {
    fetching: false,
    flashBarItem: []
};

export const putFileInS3Init = initialState =>{
    return initialState;
};

const getFlashBar = (type, message, id, action) => {
    return [{
        type: type,
        dismissible: true,
        dismissLabel: "Dismiss message",
        onDismiss: () => {
            action.payload?.dispatcher({type:"DISMISS_FLASHBAR", payload:{}})
        },
        content: (
            <>
                {message}
            </>
        ),
        id: id,
    }]
};

export const putFileInS3Reducer = (state, action) => {
    switch (action.type){
        case "BEGIN":
            return {
                ...state,
                fetching: true
            }
        case "SUCCESS":
            const message = action.payload?.msg;
            return {
                ...state,
                flashBarItem: getFlashBar("success", message, "message_1", action),
            }
        case "FAILURE":
            const errMessage = action.payload?.errMsg
            return {
                ...state,
                flashBarItem: getFlashBar("error", errMessage, "message_1", action),
            }
        case "DISMISS_FLASHBAR":
            return {
                ...state,
                flashBarItem: []
            }
        default:
            return state
    }
};
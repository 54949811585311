import {
    Box,
    Button,
    Checkbox, CollectionPreferences,
    Container,
    Header,
    Pagination,
    Popover,
    SpaceBetween,
    StatusIndicator
} from "@amzn/awsui-components-react";

import 'ag-grid-enterprise';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { useEffect, useMemo, useRef, useState } from "react";
import { getAPIInputContext } from "../../../../common/utils/apiUtils";
import { API } from "aws-amplify";
import { PHASTOS_ADJUST_EXCEPTION_TRANSACTIONS } from "../../../../ApiPaths";
import { getUserAlias } from "../../utils/utils"; 
import { postMessage } from "../../../phastos/components/Slack/slackIntegrationAPIs";
import React from "react";
import {
    cellValueChanged,
    getAdjustmentsList,
    getEditableCellStyle,
    getTransactionDetails,
    updateActionStatus,
    updateDraftFilter
} from "../common/adjustment/constants/transactionLevelUtils";
import { getColumnDefinitions, defaultColumnDefinitions } from "./constants/transactionAdjustmentConstants";
import {TransactionAdjustmentModal} from "../common/adjustment/TransactionAdjustmentModal";

export function TransactionsAdjustment(props) {
    const { 
        currentFilter,
        filterData,
        parentWorkflowId,
        slackChannel,
        useCase,
        reportingPeriod,
        useCaseType,
        phastosUseCaseName,
    } = props;

    const gridRef = useRef();
    const INITIAL_PAGE_NUMBER = 1;
    const INITIAL_TOTAL_RECORDS = 0;
    const [baseTransactionsData, setBaseTransactionsData] = useState([]);
    const [transactionsData, setTransactionsData] = useState([]);
    const [currentPageNumber, setCurrentPageNumber] = useState(INITIAL_PAGE_NUMBER);
    const [totalPageNumber, setTotalPageNumber] = useState(INITIAL_PAGE_NUMBER);
    const [totalRecords, setTotalRecords] = useState(INITIAL_TOTAL_RECORDS);

    const setPaginationToInitial = () => {
        setCurrentPageNumber(INITIAL_PAGE_NUMBER);
        setTotalPageNumber(INITIAL_PAGE_NUMBER);
        setTotalRecords(INITIAL_TOTAL_RECORDS);
    }

    const [isChecked, setIsChecked] = useState(false);

    const draftChanges = (isChecked) => {
        updateDraftFilter(gridRef, isChecked);
    };

    useEffect(() => {
        draftChanges(isChecked);
    }, [isChecked]);

    // eslint-disable-next-line
    const [columnDefs, setColumnDefs] = useState(getColumnDefinitions(useCaseType));

    const defaultColDef = useMemo(()=> (defaultColumnDefinitions), []);

    const columnTypes = useMemo(() => {
        return {
          editableColumn: {
            cellStyle: (params) => {
                return getEditableCellStyle(params, baseTransactionsData);
            },
          },
        };
    }, [baseTransactionsData]);

    const [isLoadingTransactions, setIsLoadingTransactions] = useState(true);

    const refreshTransactions = (pageNumber, pageSize) => {
        // Reset state
        setIsLoadingTransactions(true);
        setAcceptTransactionsDisabled(true);
        setApplyAdjustmentsDisabled(true);
        setIsChecked(false);

        const request = {
            parentWorkflowId: parentWorkflowId,
            filterData: filterData,
            setIsLoadingTransactions: setIsLoadingTransactions,
            setBaseTransactionsData: setBaseTransactionsData,
            setTransactionsData: setTransactionsData,
            currentPageNumber: pageNumber,
            setCurrentPageNumber: setCurrentPageNumber,
            setTotalPageNumber: setTotalPageNumber,
            setTotalRecords: setTotalRecords,
            useCase: useCase,
            reportingPeriod: reportingPeriod,
            pageSize: pageSize
        }
        getTransactionDetails(request);
    };

    useEffect(() => {
        setPaginationToInitial();
        refreshTransactions(INITIAL_PAGE_NUMBER, preferences.pageSize);
        // eslint-disable-next-line
    }, [filterData]);

    const handleCellValueChange = (event) => {
        cellValueChanged(event, transactionsData, baseTransactionsData, setTransactionsData);
        updateActionStatus(event.api.getSelectedRows(), baseTransactionsData, setApplyAdjustmentsDisabled, setAcceptTransactionsDisabled);
    };

    const sendSlackStatusMessage = async (message) => {
        const userAlias = await getUserAlias();
        postMessage(slackChannel, `${userAlias}@ ${message}`);
    };

    const [showAdjustModal, setShowAdjustModal] = useState(false);
    const [showAcceptModal, setShowAcceptModal] = useState(false);
    const [updateReason, setUpdateReason] = useState(null);

    const adjustTransactionsHandler = () => {
        setShowAdjustModal(true);
    };

    const acceptTransactionsHandler = () => {
        setShowAcceptModal(true);
    };

    const resetAdjustModal = () => {
        setShowAdjustModal(false);
        setUpdateReason(null);
    };

    const resetAcceptModal = () => {
        setShowAcceptModal(false);
        setUpdateReason(null);
    };

    const adjustExceptionTransactions = (actionType, updateReason) => {
        getAPIInputContext().then(input => {
            const eligibleAttributes = ['commodityCode'];
            const transactionsAdjustmentInfoList = getAdjustmentsList(gridRef, baseTransactionsData, eligibleAttributes);

            input.body.parentWorkflowId = parentWorkflowId;
            input.body.actionType = actionType;
            input.body.transactionsAdjustmentInfoList = transactionsAdjustmentInfoList;
            input.body.useCase = useCase;
            input.body.reportingPeriod = reportingPeriod;
            input.body.phastosUseCaseName = phastosUseCaseName;
            input.body.updateReason = updateReason;
    
            API.post("TWIN_API", PHASTOS_ADJUST_EXCEPTION_TRANSACTIONS, input).then(resp => {
                console.log(PHASTOS_ADJUST_EXCEPTION_TRANSACTIONS + " API was called, response : " + resp);
                if (resp.status === 200) {
                    const actionTypeText = (actionType === 'Confirmed' ? 'confirmed' : 'applied adjustments to');
                    const transactionText = transactionsAdjustmentInfoList.length > 1 ? 'transactions' : 'transaction';
                    sendSlackStatusMessage(`has ${actionTypeText} ${transactionsAdjustmentInfoList.length} ${transactionText}.`);
                    if (actionType === 'Corrected') {
                        sendSlackStatusMessage(`has corrected the transaction for the following reason: \n"${updateReason}"`);
                    }
                    setIsLoadingTransactions(true);
                    setTimeout(() => {
                        setPaginationToInitial();
                        refreshTransactions(INITIAL_PAGE_NUMBER, preferences.pageSize);
                    }, 3000);
                } else {
                    console.log(PHASTOS_ADJUST_EXCEPTION_TRANSACTIONS + " API Error Response : " + JSON.stringify(resp));
                    // Error handling
                }
                setApplyAdjustmentsLoading(false);
                setAcceptTransactionsLoading(false);
            });
        });
    }

    const [applyAdjustmentsDisabled, setApplyAdjustmentsDisabled] = useState(true);
    const [applyAdjustmentsLoading, setApplyAdjustmentsLoading] = useState(false);
    const adjustModalHandler = () => {
        setApplyAdjustmentsLoading(true);
        setShowAdjustModal(false);
        adjustExceptionTransactions("Corrected", updateReason);
        setUpdateReason(null);
    }

    const acceptModalHandler = () => {
        setApplyAdjustmentsLoading(true);
        setShowAcceptModal(false);
        adjustExceptionTransactions("Confirmed", updateReason);
        setUpdateReason(null);
    }

    const [acceptTransactionsDisabled, setAcceptTransactionsDisabled] = useState(true);
    const [acceptTransactionsLoading, setAcceptTransactionsLoading] = useState(false);

    const selectionChanged = (event) => {
        updateActionStatus(event.api.getSelectedRows(), baseTransactionsData, setApplyAdjustmentsDisabled, setAcceptTransactionsDisabled);
    };

    const onPaginationChange = (event) => {
        const pageNumber = event.detail.currentPageIndex;
        setCurrentPageNumber(pageNumber);
        refreshTransactions(pageNumber, preferences.pageSize);
    }

    const PAGE_SIZE_OPTIONS = [
        { value: 100, label: '100 Transactions' },
        { value: 300, label: '300 Transactions' },
        { value: 500, label: '500 Transactions' },
    ];

    const [preferences, setPreferences] = useState({pageSize: 100});

    const onConfirmAGGridPreferencesHandler = (detail) => {
        const pageSize = detail.pageSize;
        if(pageSize !== preferences.pageSize) {
            setPreferences(detail);
            setPaginationToInitial();
            refreshTransactions(INITIAL_PAGE_NUMBER, pageSize);
        }
    }

    return (
        <>
            <Container header={
                <Header counter={`(${totalRecords})`} actions={
                    <SpaceBetween size="l" direction="vertical" className="transaction-header-actions">
                        <SpaceBetween size='xs' direction='horizontal'>
                            <Checkbox
                                onChange={({ detail }) =>
                                    setIsChecked(detail.checked)
                                }
                                checked={isChecked}
                            >
                                Draft changes
                            </Checkbox>
                            <Popover
                                position="top"
                                size="small"
                                triggerType="custom"
                                content={
                                    <div>
                                        Select transactions to adjust or accept to enable the respective action.
                                    </div>
                                }
                            >
                                <Button iconName="status-info" variant="icon" />
                            </Popover>
                            <Button variant='normal'
                                    onClick={adjustTransactionsHandler}
                                    disabled={applyAdjustmentsDisabled}
                                    loading={applyAdjustmentsLoading}
                            >Adjust</Button>
                            <Button variant='normal'
                                    onClick={acceptTransactionsHandler}
                                    disabled={acceptTransactionsDisabled}
                                    loading={acceptTransactionsLoading}
                            >Accept</Button>
                            {/* <Button variant='normal'
                            onClick={hideTransactionsHandler}
                    >Hide</Button> */}
                        </SpaceBetween>
                        <SpaceBetween size='xs' direction='horizontal'>
                            <Pagination currentPageIndex={currentPageNumber} pagesCount={totalPageNumber}
                                        onChange={onPaginationChange}/>
                            <CollectionPreferences
                                title="Preferences"
                                confirmLabel="Confirm"
                                cancelLabel="Cancel"
                                preferences={preferences}
                                onConfirm={({detail}) => onConfirmAGGridPreferencesHandler(detail)}
                                pageSizePreference={{
                                    title: 'Page size',
                                    options: PAGE_SIZE_OPTIONS,
                                }}
                            />
                        </SpaceBetween>
                    </SpaceBetween>
                }>Transactions Details - {currentFilter}</Header>
            }>
                { !isLoadingTransactions &&
                    (<div className="ag-theme-alpine" style={{ width: "100%", height: "600px"}}>
                        <AgGridReact ref={gridRef}
                                    rowData={transactionsData}
                                    columnDefs={columnDefs}
                                    defaultColDef={defaultColDef}
                                    animateRows={true}
                                    rowSelection='multiple'
                                    singleClickEdit={true}
                                    suppressExcelExport={true}
                                    enableCharts={true}
                                    sideBar={true}
                                    rowGroupPanelShow='always'
                                    columnTypes={columnTypes}
                                    suppressRowClickSelection={true}
                                    onCellEditingStopped={handleCellValueChange}
                                    onSelectionChanged={selectionChanged}
                                    groupSelectsChildren={true}
                                    groupSelectsFiltered={true}
                        />
                    </div>)
                }
                { isLoadingTransactions &&
                    (<Box textAlign="center">
                        <StatusIndicator type="loading"/>
                    </Box>)
                }
            </Container>
            <TransactionAdjustmentModal showTransactionAdjustmentModal={showAdjustModal}
                                        resetTransactionAdjustmentModal={resetAdjustModal}
                                        headerText={'Adjust transaction'}
                                        confirmModalHandler={adjustModalHandler}
                                        updateReason={updateReason}
                                        setUpdateReason={setUpdateReason}
                                        placeholderText={'Enter reason to adjust transaction'}
            />
            <TransactionAdjustmentModal showTransactionAdjustmentModal={showAcceptModal}
                                        resetTransactionAdjustmentModal={resetAcceptModal}
                                        headerText={'Accept transaction'}
                                        confirmModalHandler={acceptModalHandler}
                                        updateReason={updateReason}
                                        setUpdateReason={setUpdateReason}
                                        placeholderText={'Enter reason to accept transaction'}
            />
        </>
    )
}
import React from "react";
import { ColumnLayout, Header } from "@amzn/awsui-components-react";
import { Box, Container } from "@amzn/awsui-components-react/polaris";
import { formatStatus } from "../../../phastos/components/MOG/table-config";
import {getObligationName} from "../../utils/utils";

export default function ObligationDetails(props) {
    const {data, obligationStatus, phastosObligation} = props;

    return (
        <Container header={<Header>Details</Header>}>
            <ColumnLayout columns={2}>
                <Box>Obligation</Box>
                <Box>{getObligationName(data, phastosObligation)}</Box>
                <Box>Country</Box>
                <Box>{data?.country}</Box>
                <Box>Entity</Box>
                <Box>{data?.entity}</Box>
                <Box>Period</Box>
                <Box>{data?.reportingPeriod}</Box>
                <Box>Status</Box>
                <Box>{formatStatus(obligationStatus)}</Box>
            </ColumnLayout>
        </Container>
    )
}
import React, {useState} from "react";
import {Box, Button, Modal, SpaceBetween, Input, Select} from "@amzn/awsui-components-react";
import './css/style.css';
import {getAPIInputContext} from "../../../../../common/utils/apiUtils";
import { get } from "lodash";
import {
    ADJUSTABLE_BOX_LIST,
    SELECT_BOX,
    SELECT_OPERATION,
    INPUT_ATTRIBUTE
} from "../../VAT/constants/boxLevelConstants";
import {boxLevelAdjustment} from "../../../../phastos/components/phastosAPIs";
import {INPUT_TYPE} from "./constants/boxLevelConstants";

export default function AdjustmentsModal(props) {
    const {
        showAdjustmentModal,
        onDismiss,
        onSuccess,
        parentWorkflowId,
        dispatcher
    } = props;

    const DEFAULT_ROW_STATE = {
        [INPUT_TYPE.BOX]: {[INPUT_ATTRIBUTE.VALUE]: SELECT_BOX, [INPUT_ATTRIBUTE.IS_INVALID]: false},
        [INPUT_TYPE.OPERATION]: {[INPUT_ATTRIBUTE.VALUE]: SELECT_OPERATION, [INPUT_ATTRIBUTE.IS_INVALID]: false},
        [INPUT_TYPE.INPUT_VALUE]: {[INPUT_ATTRIBUTE.VALUE]: '', [INPUT_ATTRIBUTE.IS_INVALID]: false},
        [INPUT_TYPE.DESCRIPTION]: {[INPUT_ATTRIBUTE.VALUE]: '', [INPUT_ATTRIBUTE.IS_INVALID]: false}
    };

    const [rowData, setRowData] = useState([DEFAULT_ROW_STATE]);

    const addRow = () => {
        setRowData([...rowData, DEFAULT_ROW_STATE]);
    };

    const removeRow = (rowIndex) => {
        const updatedData = [...rowData];
        updatedData.splice(rowIndex, 1);
        setRowData(updatedData);
    };

    const handleChange = (entry, rowIndex, type) => {
        const updatedData = [...rowData];
        updatedData[rowIndex][type] = {
            [INPUT_ATTRIBUTE.VALUE]: entry,
            [INPUT_ATTRIBUTE.IS_INVALID]: false,
        }
        setRowData(updatedData);
    };

    const isRowDataInvalid = () => {
        let isInvalid = false;
        rowData.forEach((data, rowIndex) => {
                if (get(data, [INPUT_TYPE.BOX, INPUT_ATTRIBUTE.VALUE]) === SELECT_BOX) {
                    setInvalid(rowIndex, INPUT_TYPE.BOX);
                    isInvalid = true;
                }
                if (get(data, [INPUT_TYPE.OPERATION, INPUT_ATTRIBUTE.VALUE]) === SELECT_OPERATION) {
                    setInvalid(rowIndex, INPUT_TYPE.OPERATION);
                    isInvalid = true;
                }
                if (get(data, [INPUT_TYPE.INPUT_VALUE, INPUT_ATTRIBUTE.VALUE]) === "") {
                    setInvalid(rowIndex, INPUT_TYPE.INPUT_VALUE);
                    isInvalid = true;
                }
                if (get(data, [INPUT_TYPE.DESCRIPTION, INPUT_ATTRIBUTE.VALUE]) === "") {
                    setInvalid(rowIndex, INPUT_TYPE.DESCRIPTION);
                    isInvalid = true;
                }
            }
        )

        return isInvalid;
    }

    const setInvalid = (rowIndex, inputType) => {
        const updatedData = [...rowData];
        updatedData[rowIndex][inputType][INPUT_ATTRIBUTE.IS_INVALID] = true;
        setRowData(updatedData);
    }

    const onDismissHandler = () => {
        setRowData([DEFAULT_ROW_STATE]);
        onDismiss();
    }

    // TODO: Call onSuccess based on API response
    const onSubmitHandler = () => {
        if (!isRowDataInvalid()) {
            const adjustmentInfoList = [];

            rowData.forEach((data) => {
                adjustmentInfoList.push({
                    boxLabel: get(data, [INPUT_TYPE.BOX, INPUT_ATTRIBUTE.VALUE]),
                    value: get(data, [INPUT_TYPE.INPUT_VALUE, INPUT_ATTRIBUTE.VALUE]),
                    operation: get(data, [INPUT_TYPE.OPERATION, INPUT_ATTRIBUTE.VALUE]),
                    description: get(data, [INPUT_TYPE.DESCRIPTION, INPUT_ATTRIBUTE.VALUE])
                });
            })

            getAPIInputContext().then(input => {
                input.body.parentWorkflowId = parentWorkflowId;
                input.body.boxLevelAdjustmentsInfoList = adjustmentInfoList;
                boxLevelAdjustment(input,dispatcher)
                onSuccess()
            });
            onDismissHandler();
        }
    }

    return (
        <Modal
            visible={showAdjustmentModal}
            onDismiss={onDismissHandler}
            header={'Adjustments'}
            closeAriaLabel="Close dialog"
            size="large"
            footer={
                <Box float="right">
                    <SpaceBetween direction="horizontal" size="xs">
                        <Button variant="link" onClick={onDismissHandler}>Cancel</Button>
                        <Button variant="primary" onClick={onSubmitHandler}>Submit</Button>
                    </SpaceBetween>
                </Box>
            }
        >
            <SpaceBetween size="xs" direction="vertical">
                <div>
                    {
                        rowData.map((data, index) => (
                            <div key={index} className="modalComponent">
                                <Select className="boxLabel" selectedOption={data.box}
                                        options={ADJUSTABLE_BOX_LIST.map(option => {
                                            return {value: option}
                                        })}
                                        onChange={({detail}) => handleChange(detail.selectedOption.value, index, INPUT_TYPE.BOX)}
                                        placeholder="Select box"
                                        invalid={get(data,[INPUT_TYPE.BOX, INPUT_ATTRIBUTE.IS_INVALID])}
                                />
                                <Select className="operation" selectedOption={data.operation}
                                        options={[{value: "ADD"}, {value: "SUBTRACT"}]}
                                        onChange={({detail}) => handleChange(detail.selectedOption.value, index, INPUT_TYPE.OPERATION)}
                                        placeholder="Select operation"
                                        invalid={get(data,[INPUT_TYPE.OPERATION, INPUT_ATTRIBUTE.IS_INVALID])}
                                />
                                <Input value={data.inputValue.value} className="boxValue" type="number" name="value"
                                       onChange={({detail}) => handleChange(detail.value, index, INPUT_TYPE.INPUT_VALUE)}
                                       placeholder="Enter value"
                                       invalid={get(data,[INPUT_TYPE.INPUT_VALUE, INPUT_ATTRIBUTE.IS_INVALID])}
                                />
                                <Input value={data.description.value} className="adjustDescription" type="text" name="description"
                                       onChange={({detail}) => handleChange(detail.value, index, INPUT_TYPE.DESCRIPTION)}
                                       placeholder="Enter description"
                                       invalid={get(data,[INPUT_TYPE.DESCRIPTION, INPUT_ATTRIBUTE.IS_INVALID])}
                                />
                                {
                                    index === 0 ?
                                        <Button iconName="add-plus" variant="icon" class="add-button"
                                                onClick={addRow}/> :
                                        <Button iconName="close" variant="icon" class="remove-button"
                                                onClick={() => removeRow(index)}/>
                                }
                            </div>
                        ))
                    }
                </div>
            </SpaceBetween>
        </Modal>
    )
}
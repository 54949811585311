import React, {useEffect, useState} from "react";
import {Button, Box, Modal, SpaceBetween, FormField, ButtonDropdown} from '@amzn/awsui-components-react';
import {createAdhocReportRequest, fetchBraavosReportIds} from "./phastosAPIs";
import Link from "@amzn/awsui-components-react/polaris/link";
import AppConfig from "../../../AppConfig";
import {BRAAVOS_REPORT_SUCCESS_STATUS} from "../../../common/constants/constants";

export default function AdhocReportModal(props) {

    const initialStateText = "Select Value";
    const {showAdhocReportRefreshModal, onDismiss, setShowAdhocReportRefreshModal, table, tableDispatcher} = props;
    const [isError, setIsError] = useState(false);
    const [errorMsg, setErrorMsg] = useState("");
    const [useCase, setUseCase] = useState({id: 0, text: initialStateText});
    const [braavosReportMap, setBraavosReportMap] = useState({});
    const [braavosReportDropDownItems, setBraavosReportDropDownItems] = useState([]);
    const [braavosReportIdItem, setBraavosReportIdItem] = useState({id: 0, reportName: initialStateText});

    const adhocUseCaseArr = [
        {id: 1, text: "ADHOC_DATA_SHARING"},
        {id: 2, text: "DE_ADHOC_PL_DATA_SHARING"},
        {id: 3, text: "DE_ADHOC_LS_DATA_SHARING"},
        {id: 4, text: "DE_ADHOC_REGIONS_DATA_SHARING"}
    ];
    useEffect(() => {
        const braavosGetReportRequest = {
            'ownerGroupId': AppConfig[props.domain].BRAAVOS_OWNER_GROUP_ID
        };
        fetchBraavosReportIds(braavosGetReportRequest, tableDispatcher);
        // eslint-disable-next-line
    }, []);


    const submitAdhocReportRequest = () => {

        if (braavosReportIdItem.reportName === initialStateText || useCase === initialStateText){
            setIsError(true);
            setErrorMsg("Please select Braavos Report IDs and select a Use Case");
            return;
        }
        if(braavosReportIdItem.validationStatus !== BRAAVOS_REPORT_SUCCESS_STATUS){
            setIsError(true);
            setErrorMsg(`Report validation is ${braavosReportIdItem.validationStatus}. Please rectify it first.`);

            return;
        }
        const adhocReportRequest = {
            'braavosReportIds': [braavosReportIdItem.reportId.toString()],
            'phastosUseCaseName': useCase.text
        };

        createAdhocReportRequest(tableDispatcher, adhocReportRequest);
        setShowAdhocReportRefreshModal(false);
    }

    useEffect(() => {

        if(table?.braavosAPICallStatus === false){
            setIsError(true);
            setErrorMsg("Exception occurred while fetching report details from Braavos, please try again later");
            return;
        }else{
            setIsError(false);
        }

        const map = {};
        const braavosReportItems = [];
        table?.braavosReportIdList?.forEach(v => {
            if (v.validationStatus === BRAAVOS_REPORT_SUCCESS_STATUS) {
                braavosReportItems.push({id: parseInt(v.reportId), text: v.reportName});
            } else {
                braavosReportItems.push({
                    id: parseInt(v.reportId),
                    text: v.reportName,
                    disabled: true,
                    disabledReason: `Report status is ${v.validationStatus}`
                });
            }
            map[v.reportId] = v;
        })
        setBraavosReportMap(map)
        setBraavosReportDropDownItems(braavosReportItems);
    }, [table]);

    return (
        <Modal
            visible={showAdhocReportRefreshModal}
            onDismiss={onDismiss}
            header={"Adhoc Report Execution"}
            closeAriaLabel="Close dialog"
            footer={
                <Box>
                    <Box float="left">
                        <Link external href={AppConfig[props.domain].BRAAVOS_UI_LINK}>Report Creation</Link>
                    </Box>
                    <Box float="right">
                        <SpaceBetween direction="horizontal" size="xs">
                            <Button variant="link" onClick={() => {
                                onDismiss();
                                tableDispatcher({
                                    type: "RESET_BRAAVOS_GET_REPORT_API_STATUS",
                                    payload: {}
                                });
                            }}>
                                Cancel
                            </Button>
                            <Button variant="primary" onClick={submitAdhocReportRequest}>
                                Initiate
                            </Button>
                        </SpaceBetween>
                    </Box>
                </Box>
            }
        >
            <div>
                {isError && (
                    <Box variant="p" color="text-status-error">
                        {errorMsg}
                    </Box>
                )}
                <FormField
                    label="Report Name*"
                    constraintText=""
                >
                    <ButtonDropdown classname="select"
                                    items={braavosReportDropDownItems}
                                    variant="normal"
                                    onItemClick={(e) => {
                                        const reportDetail = braavosReportMap[e.detail.id];
                                        setBraavosReportIdItem(reportDetail);
                                    }}
                    >
                        {braavosReportIdItem?.reportName}
                    </ButtonDropdown>
                </FormField>
                <FormField
                    label="Use Case*"
                    constraintText=""
                >
                    <ButtonDropdown classname="select"
                                    items={adhocUseCaseArr}
                                    variant="normal"
                                    onItemClick={(e) => {
                                        let selectedUseCase = adhocUseCaseArr[e.detail.id - 1];
                                        setUseCase(selectedUseCase);
                                    }}
                    >
                        {useCase?.text}
                    </ButtonDropdown>
                </FormField>
            </div>
        </Modal>
    );
}

import React from "react";
import Amplify from "@aws-amplify/core";
import Auth from '@aws-amplify/auth';
import AppConfig from "./AppConfig";
import "regenerator-runtime/runtime";
import './App.css';
import AppRoutes from './Routes'
import { BrowserRouter } from "react-router-dom";
import Navbar from "./common/navbar/Navbar";
import {getLoadingSpinner} from "./common/utils/commonUtils";
import { PHASTOS_TAB } from "./app/phastos/Constants/Constants";
import {LicenseManager} from "ag-grid-enterprise";
const AWS = require('aws-sdk');
function isBlank(string) {
  return /\S/.test(string);
}

function isEmptyString(string) {
  if (!string || !isBlank(string)) {
    return true;
  }
  return false;
}

async function getDomain() {
  if(process.env.NODE_ENV === 'development'){
    return "localhost";
  }
  const settingsResponse = await fetch("/settings.json");
  const settingsJSON = await settingsResponse.json();
  return settingsJSON.deploymentStage;
}

//TODO: add unit tests
export class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user: "",
      idToken: null,
      authenticationStage: "un-authenticated",
      authenticationError: "",
      domain: "",
      activeTab: PHASTOS_TAB.VAT_RETURNS,
    };
  }

  async componentDidMount() {
    let domain = await getDomain();
    let authConfig = {
      mandatorySignIn: true,
      region: AppConfig[domain].REGION,
      userPoolId: AppConfig[domain].USER_POOL_ID,
      userPoolWebClientId: AppConfig[domain].APP_CLIENT_ID,
      identityPoolId: AppConfig[domain].IDENTITY_POOL.IDENTITY_POOL_ID
    };

    if (!window.location.pathname.startsWith("/filinghmrc")) {
      authConfig = {
        ...authConfig,
        oauth: AppConfig[domain].OAUTH
      }
    }

    Amplify.configure({
      Auth: authConfig,
      API: AppConfig[domain].API_GATEWAY_ENDPOINTS
    });

    try {
      await Auth.currentSession();

    } catch (error) {
      if (error === "No current user") {
        this.setState({ authenticationStage: "signIn" });
        Auth.federatedSignIn();
      }
      else {
        this.setState({ authenticationError: error });
      }
    }
    try {
      let userInfo = await Auth.currentAuthenticatedUser();
      let clientLogin = userInfo.signInUserSession.idToken.payload.identities[0].userId;
      let idToken = userInfo.signInUserSession.idToken.jwtToken;
      // Enable AWS resource access only if user is authenticated
      if (userInfo) {
        AWS.config.update({
          region: AppConfig[domain].IDENTITY_POOL.IDENTITY_POOL_REGION,
          credentials: await Auth.currentCredentials()
        });
      }
      LicenseManager.setLicenseKey(AppConfig[domain].AG_GRID_LICENSE);
      this.setState({ user: clientLogin, idToken: idToken,
        authenticationStage: "authenticated", errorMessage: "", domain:domain });
    } catch (error) {
      this.setState({ authenticationError: error });

    }
  }

  setActiveTab = (activeTab) => {
    this.setState({
      ...this.state,
      activeTab: activeTab
    });
  };

  render() {
    if (this.state.authenticationStage === "un-authenticated" && !isEmptyString(this.state.authenticationError)) {
      return (
          <div className="App">
            <header className="App-header">
              <h3>Some error occured while trying to log in. Please try again.</h3>
            </header>
          </div>
      );
    }
    else if (this.state.authenticationStage === "authenticated") {
      return (
          <BrowserRouter>
            <Navbar user={this.state.user} />
            <AppRoutes domain={this.state.domain} user={this.state.user} activeTab={this.state.activeTab} setActiveTab={this.setActiveTab}/>
          </BrowserRouter>
      );
    }
    else {
      return (getLoadingSpinner());
    }
  }
}

export default App;
import React, {useState} from "react";
import {
    Box,
    Button,
    ColumnLayout,
    FormField,
    Modal,
    SpaceBetween
} from "@amzn/awsui-components-react";
import Input from "@amzn/awsui-components-react/polaris/input";
import {ValueWithLabel} from "../../../filing/utils/Utils";
import {get} from "lodash";
import {INPUT_ATTRIBUTE} from "../VAT/constants/boxLevelConstants";
import {getAPIInputContext} from "../../../../common/utils/apiUtils";
import {
    checkValidNumberInputAndDisplayErrorMessage,
} from "../../utils/utils";
import {DST_COLUMN_IDENTIFIER, INVALID_INPUT} from "./Constants";
import {boxLevelAdjustment} from "../../../phastos/components/phastosAPIs";
import {INPUT_TYPE} from "../common/adjustment/constants/boxLevelConstants";

export default function DSTAdjustmentModal(props) {
    const {
        parentWorkflowId,
        useCase,
        showAdjustmentModal,
        adjustmentModalDismissHandler,
        dstItem,
        onAdjustmentCloseHandler,
        dstDispatcher,
    } = props;


    const DEFAULT_DATA = {
        [INPUT_TYPE.BOX]: {[INPUT_ATTRIBUTE.VALUE]: dstItem?.hashKey, [INPUT_ATTRIBUTE.IS_INVALID]: false},
        [INPUT_TYPE.OPERATION]: {[INPUT_ATTRIBUTE.VALUE]: "UPDATE", [INPUT_ATTRIBUTE.IS_INVALID]: false},
        [INPUT_TYPE.INPUT_VALUE]: {[INPUT_ATTRIBUTE.VALUE]: '0.0', [INPUT_ATTRIBUTE.IS_INVALID]: false},
        [INPUT_TYPE.DESCRIPTION]: {
            [INPUT_ATTRIBUTE.VALUE]: '',
            [INPUT_ATTRIBUTE.IS_INVALID]: false
        },
        [INPUT_TYPE.ADDITIONAL_DATA_MAP]: {[INPUT_ATTRIBUTE.VALUE]: "", [INPUT_ATTRIBUTE.IS_INVALID]: false}
    };

    const data = DEFAULT_DATA;
    const [totalTransaction, setTotalTransaction] = useState(dstItem?.totalTransaction)
    const [totalDSTScopeTransaction, setTotalDSTScopeTransaction] = useState(dstItem?.totalDSTScopeTransaction)
    const [totalRevenue, setTotalRevenue] = useState(dstItem?.totalRevenue)
    const [totalRevenueAmountDSTReportingCurrency, setTotalRevenueAmountDSTReportingCurrency] =
    useState(dstItem?.totalRevenueAmountDstReportingCurrency)
    const [invalidTotalTransactionErrorMessage, setInvalidTotalTransactionErrorMessage] = useState("");
    const [invalidTotalDSTScopeTransactionErrorMessage, setInvalidValidTotalDSTScopeTransactionErrorMessage] = useState("")
    const [invalidTotalRevenueErrorMessage, setInvalidTotalRevenueErrorMessage] = useState("")
    const [invalidTotalRevenueDSTReportingCurrencyErrorMessage,
        setInvalidTotalRevenueDSTReportingCurrencyErrorMessage] = useState("")
    const [comment, setComment] = useState("");
    const  [isValidComment,  setIsValidComment] = useState(false);
    function isValidInput() {
        return (
            checkValidNumberInputAndDisplayErrorMessage(totalTransaction,
                setInvalidTotalTransactionErrorMessage, INVALID_INPUT) &&
            checkValidNumberInputAndDisplayErrorMessage(totalDSTScopeTransaction,
                setInvalidValidTotalDSTScopeTransactionErrorMessage, INVALID_INPUT) &&
            checkValidNumberInputAndDisplayErrorMessage(totalRevenue,
                setInvalidTotalRevenueErrorMessage, INVALID_INPUT) &&
        checkValidNumberInputAndDisplayErrorMessage(totalRevenueAmountDSTReportingCurrency,
            setInvalidTotalRevenueDSTReportingCurrencyErrorMessage, INVALID_INPUT)
            &&
                isValidComment
        );
    }

    const updateData = (entry, type) => {
        data[type] = {
            [INPUT_ATTRIBUTE.VALUE]: entry,
            [INPUT_ATTRIBUTE.IS_INVALID]: false,
        }
    };

    function submit() {
        if (isValidInput()) {
            const additionalDataMap = {
                [DST_COLUMN_IDENTIFIER.LegalEntity]: dstItem.legalEntity,
                [DST_COLUMN_IDENTIFIER.MarketplaceId]:dstItem.marketplaceId,
                [DST_COLUMN_IDENTIFIER.TotalTransaction]: totalTransaction,
                [DST_COLUMN_IDENTIFIER.TotalDSTScopeTransaction]: totalDSTScopeTransaction,
                [DST_COLUMN_IDENTIFIER.TotalRevenue]: totalRevenue,
                [DST_COLUMN_IDENTIFIER.TotalRevenueDstReportingCurrency]: totalRevenueAmountDSTReportingCurrency
            }
            updateData(additionalDataMap, INPUT_TYPE.ADDITIONAL_DATA_MAP);
            updateData(comment,INPUT_TYPE.DESCRIPTION)
            onSubmitHandler();
        }
    }


    const onSubmitHandler = () => {
        const adjustmentInfoList = [];

        adjustmentInfoList.push({
            boxLabel: get(data, [INPUT_TYPE.BOX, INPUT_ATTRIBUTE.VALUE]),
            value: get(data, [INPUT_TYPE.INPUT_VALUE, INPUT_ATTRIBUTE.VALUE]),
            operation: get(data, [INPUT_TYPE.OPERATION, INPUT_ATTRIBUTE.VALUE]),
            description: get(data, [INPUT_TYPE.DESCRIPTION, INPUT_ATTRIBUTE.VALUE]),
            additionalDataMap: get(data, [INPUT_TYPE.ADDITIONAL_DATA_MAP, INPUT_ATTRIBUTE.VALUE])
        });

        getAPIInputContext().then(input => {
            input.body.parentWorkflowId = parentWorkflowId;
            input.body.boxLevelAdjustmentsInfoList = adjustmentInfoList;
            input.body.useCase = useCase;
            boxLevelAdjustment(input, dstDispatcher);
        });
        adjustmentModalDismissHandler();
        onAdjustmentCloseHandler();
    }

    return (
        <Modal
            visible={showAdjustmentModal}
            onDismiss={adjustmentModalDismissHandler}
            header={'Adjustments'}
            closeAriaLabel="Close dialog"
            size="large"
            footer={
                <Box float="right">
                    <SpaceBetween direction="horizontal" size="xs">
                        <Button variant="link" onClick={adjustmentModalDismissHandler}>Cancel</Button>
                        <Button variant="primary" onClick={submit}>Submit</Button>
                    </SpaceBetween>
                </Box>
            }
        >
            <ColumnLayout columns={2}>
                <ValueWithLabel label="Category">{dstItem.businessCategory}</ValueWithLabel>
                <ValueWithLabel label="LegaEntity">{dstItem.legalEntity}</ValueWithLabel>
                <ValueWithLabel label="MarketPlace">{dstItem.marketplaceId}</ValueWithLabel>
                <ValueWithLabel label="Sub Business">{dstItem.subBusiness}</ValueWithLabel>
                <ValueWithLabel label="LocalCurrency">{dstItem.localCurrency}</ValueWithLabel>
                <ValueWithLabel label="DST Reporting Currency">{dstItem.dstReportingCurrency}</ValueWithLabel>
            </ColumnLayout>
            <ColumnLayout columns={2}>
                <FormField
                    label="Total Transactions"
                    errorText={invalidTotalTransactionErrorMessage}
                >
                    <Input
                        value={totalTransaction}
                        onChange={event =>
                            setTotalTransaction(event.detail.value)
                        }
                    />
                </FormField>
                <FormField
                    label="Total DST Scope Transaction"
                    errorText={invalidTotalDSTScopeTransactionErrorMessage}
                >
                    <Input
                        value={totalDSTScopeTransaction}
                        onChange={event =>
                            setTotalDSTScopeTransaction(event.detail.value)
                        }
                    />
                </FormField>
                <FormField
                    label="Total Revenue(Local Currency)"
                    errorText={invalidTotalRevenueErrorMessage}
                >
                    <Input
                        value={totalRevenue}
                        onChange={event =>
                            setTotalRevenue(event.detail.value)
                        }
                    />
                </FormField>
                <FormField
                    label="Total Revenue(DST Reporting Currency)"
                    errorText={invalidTotalRevenueDSTReportingCurrencyErrorMessage}
                >
                    <Input
                        value={totalRevenueAmountDSTReportingCurrency}
                        onChange={event =>
                            setTotalRevenueAmountDSTReportingCurrency(event.detail.value)
                        }
                    />
                </FormField>
                <FormField
                    label="Comment"
                >
                    <Input
                        value={comment}
                        onChange={event => {
                                setComment(event.detail.value)
                                if (event.detail.value.length<=0) {
                                    setIsValidComment(false);
                                }
                                else
                                    setIsValidComment(true);
                            }
                        }
                        invalid={!isValidComment}
                    />
                </FormField>
            </ColumnLayout>
        </Modal>
    );
}
import React, {useState} from "react";
import Table from "@amzn/awsui-components-react/polaris/table";
import Header from "@amzn/awsui-components-react/polaris/header";
import Box from "@amzn/awsui-components-react/polaris/box";
import { SpaceBetween, Pagination } from "@amzn/awsui-components-react/polaris";
import {getExcaliburAuditData} from "./components/excaliburAPIs";
import {useReducer} from "react";
import { useNavigate } from "react-router-dom";
import {INITIAL_STATE, tableReducer, tableReducerInit} from "./components/excaliburTableReducer";
import {
    getFileNameButtonFromS3Key,
    getFileNameButtonFromDMS,
    statusColorCodes
} from "../../common/utils/commonUtils";
import Button from "@amzn/awsui-components-react/polaris/button";
import { ROUTE_PATH_FILING_PAGE } from "../../common/constants/route_paths";
import Modal from "@amzn/awsui-components-react/polaris/modal";
import {formatDateWithTime} from "../../common/utils/apiUtils";

const Actiontable = (params) => {
    const {downloadDocumentDispatcher} = params;

    //Table State Management Reducer
    const [table, tableDispatcher] = useReducer(tableReducer,
        INITIAL_STATE, tableReducerInit);
    const navigate = useNavigate();     

    //This is to initialize the first call
    if(table.initialize){
        getExcaliburAuditData(tableDispatcher, table.lastEvaluatedKey, 'Excalibur');
    }

    const [selectedItems, setSelectedItems] = useState([]);
    const [visible, setVisible] = useState(false);

    const onSelectionChange = (item) => {
        setSelectedItems(item.detail.selectedItems)
    }

    const handleEFileClick = () => {
        setVisible(true);
    }
    const handleDismissModal = () => {
        setVisible(false);
    }
    const navigateToFiling = () => {
        navigate(ROUTE_PATH_FILING_PAGE, {
            state: {
                data: selectedItems[0]
            }
        });
    }

    return (
        <>
        <Modal
            onDismiss={handleDismissModal}
            visible={visible}
            footer={
                <Box float="right">
                    <SpaceBetween direction="horizontal" size="xs">
                        <Button variant="link">Cancel</Button>
                        <Button variant="primary" onClick={navigateToFiling}>Ok</Button>
                    </SpaceBetween>
                </Box>
            }
            header="Modal title"
        >
            Proceed To Filing?
        </Modal>
        <Table
            header={<Header
                variant="h2"
                actions={
                  <SpaceBetween direction="horizontal" size="xs">
                    <Button disabled={selectedItems.length === 0 || selectedItems[0].status !== "SUCCESS"} onClick={handleEFileClick}>
                        Start E-File
                    </Button>
                  </SpaceBetween>
                }
              >Submitted Requests
              </Header>}
            loading={table.loading}
            selectionType="single"
            onSelectionChange={onSelectionChange}
            selectedItems={selectedItems}
            loadingText={table.loadingText}
            onSortingChange={((event)=>{
                    tableDispatcher({type:"SORTING_CHANGED",
                        payload:{isDescending:event.detail.isDescending,
                            sortingField:event.detail.sortingColumn.sortingField}});
            })}
            sortingColumn={table.sortingColumn}
            sortingDescending={table.sortingDescending}
            columnDefinitions={[
                {
                    id: "useCase",
                    header: "Country",
                    cell: e => e.useCase,
                    sortingField: "useCase"
                },
                {
                    id: "subUseCase",
                    header: "Filing Type",
                    cell: e => e.subUseCase,
                    sortingField: "subUseCase"

                },
                {
                    id: "status",
                    header: "Status",
                    cell: e => {
                        return (<div style={statusColorCodes(e.status)}>{e.status}</div>)
                    },
                    sortingField: "status"

                },
                {
                    id: "startTimestamp",
                    header: "Request Created on",
                    cell: e => {return formatDateWithTime(e.startTimestamp)},
                    sortingField: "startTimestamp"
                },
                {
                    id: "createdBy",
                    header: "Submitted By",
                    cell: e => e.createdBy,
                    sortingField: "createdBy"
                },
                {
                    id: "inputFileKey",
                    header: "Input File",
                    cell: e => {
                        return getFileNameButtonFromS3Key(e.inputFileKey, 'EXCALIBUR_BUCKET',params.domain)
                    },
                    sortingField: "inputFileKey"
                },
                {
                    id: "generatedFileKey",
                    header: "Output File",
                    cell: e => {
                        return getFileNameButtonFromDMS(e.documentId, e.useCase, e.subUseCase, e.inputFileKey, downloadDocumentDispatcher);
                    },
                },
                {
                    id: "errorDetails",
                    header: "Error Details",
                    cell: e => e.errorDetails,
                }
            ]}
            visibleColumns={[
                "useCase",
                "subUseCase",
                "status",
                "startTimestamp",
                "createdBy",
                "inputFileKey",
                "generatedFileKey",
                "errorDetails"
            ]}
            empty={
                <Box textAlign="center" color="inherit">
                    <b>No files</b>
                    <Box
                        padding={{ bottom: "s" }}
                        variant="p"
                        color="inherit">
                        No files to display.
                    </Box>

                </Box>
            }
            items={table.cachedData.get(table.currentPageIndex)}
            pagination={
                <Pagination
                    currentPageIndex={table.currentPageIndex}
                    pagesCount={table.pageCount}
                    onChange={event => {
                        tableDispatcher({type:"PAGE_INDEX_CHANGED",
                            payload:{pageIndex:event.detail.currentPageIndex, dispatcher:tableDispatcher}})
                        console.log(table.cachedData);
                    }
                    }
                    ariaLabels={{
                        nextPageLabel: "Next page",
                        previousPageLabel: "Previous page",
                        pageLabel: pageNumber =>
                            `Page ${pageNumber} of all pages`
                    }}
                />
            }
            preferences={
                <Button iconName="refresh" variant="icon"
                    onClick={()=>{
                        tableDispatcher({type:"REFRESH",payload:{dispatcher:tableDispatcher}})
                    }}
                />
            }
        />
        </>
    );
}

export default Actiontable;
export const INITIAL_STATE = {
    initialize:true,
    flashBarItem: [],
    loading: true
};

export const VATReducerInit = initialState =>{
    return initialState;
}

const getFlashBarItem = (type, message, action) => {
    return {
        type: type,
        dismissible: true,
        dismissLabel: "Dismiss message",
        onDismiss: () => {
            action.payload?.dispatcher({type:"DISMISS_FLASHBAR", payload:{}})
        },
        content: (
            <>
                {message}
            </>
        ),
        id: "message_1"
    }
};

export const VATReducer = (state, action) => {
    switch (action.type){
        case "API_REQUEST_SUCCESS":
            const message = action.payload?.msg
            return {
                ...state,
                ...action.payload,
                flashBarItem: [getFlashBarItem("success", message, action)]
            }
        case "API_REQUEST_FAILURE":
            const errMessage = action.payload?.errMsg
            return {
                ...state,
                ...action.payload,
                flashBarItem: [getFlashBarItem("error", errMessage, action)]
            }
        case "DISMISS_FLASHBAR":
            return {
                ...state,
                flashBarItem: []
            }
        default:
            return state
    }
}
import React from "react";
import {
    Button,
    Grid, 
    Container,
    Header,
    SpaceBetween,
    ColumnLayout,
    Box,
} from '@amzn/awsui-components-react';
import "./css/Home.css"
import { phastosDesc, excaliburDesc, filingDesc, configurationDesc } from "./Constants";
import { useNavigate } from "react-router-dom";
import { ROUTE_EXCALIBUR, ROUTE_PATH_FAQ_PAGE, ROUTE_PATH_FILING_PAGE, ROUTE_PATH_PHASTOS_PAGE } from "../../common/constants/route_paths";

function Home() {
    const navigate = useNavigate();
    const contactUsClick = () => {
        window.open('mailto:taskless-wolverines@amazon.com');
    }

    return (
        <>
            <Box margin={{bottom: 'xl'}}>
                <div style={{backgroundColor: "#232F3E"}}>
                    <Box padding={{top: 'xxxl'}}>
                        <Box padding={{top: 'xxl'}}>
                            <span className="Home-subheader">Amazon Tax Compliance Solution</span>
                        </Box>
                    </Box>
                    <div className="Home-header">
                        <Box
                            fontWeight="bold"
                            fontSize="display-l"
                            color="inherit"
                        >
                            Taskless Phastos
                        </Box>
                        <Box variant="p">
            <span style={{color: '#ffffff'}}>
              Phastos enables tax professionals to manage indirect tax reporting compliance with minimum manual intervention. Phastos uses integrations with upstream systems for generating tax returns and connectors with tax authorities for filling, hence tax professionals focus on quality of tax returns filed in auditable manner.
            </span>
                        </Box>
                    </div>
                </div>
            </Box>
            <div className="home">
                <br/>
                <Grid gridDefinition={[{ colspan: 9 }, { colspan: 3 }]}>
                    <SpaceBetween size='xl' direction='vertical'>
                        <Container
                            header={
                                <Header
                                    variant="h2"
                                >
                                    Getting Started
                                </Header>
                            }
                        >

                            <ColumnLayout
                                columns={2} disableGutters={false}
                            >
                                <div>
                                    <h3>Prepare Returns</h3>
                                    <p>{phastosDesc}</p>
                                </div>
                                <div>
                                    <h3>Generate Returns</h3>
                                    <p>{excaliburDesc}</p>
                                </div>
                                <Button variant="primary" onClick={() => {
                                    navigate(ROUTE_PATH_PHASTOS_PAGE);
                                }}>
                                    Manage tax obligations
                                </Button>
                                <Button variant="normal" onClick={() => {
                                    navigate(ROUTE_EXCALIBUR);
                                }}>
                                    Request curated reports for tax authorities
                                </Button>
                                <div>
                                    <h3 className='column-layout-key'>File Returns</h3>
                                    <p>{filingDesc}</p>
                                </div>
                                <div>
                                    <h3 className='column-layout-key'>Configure Returns</h3>
                                    <p>{configurationDesc}</p>
                                </div>
                                <Button variant="normal" onClick={() => {
                                    navigate(ROUTE_PATH_FILING_PAGE);
                                }}>
                                    Start filing
                                </Button>
                                <Button variant="normal">
                                    Configure obligations
                                </Button>
                            </ColumnLayout>
                        </Container>
                        <Container header={<Header>Key Capabilities</Header>}>
                            <ColumnLayout columns={2} variant='text-grid'>
                                <div>
                                    <h3 className='column-layout-key'>Daily Data Ingestion</h3>
                                    <p className='column-layout-value'>Automatic data load supporting daily ingestion.</p>
                                </div>
                                <div>
                                    <h3 className='column-layout-key'>Anomaly Detection</h3>
                                    <p className='column-layout-value'>Machine Learning algorithm and statistical/deterministic rules are used to detect anomalies.</p>
                                </div>
                                <div>
                                    <h3 className='column-layout-key'>Source Data Integration</h3>
                                    <p className='column-layout-value'>Integration with different data sources like AP, AR, Intercompany, OFA, etc.</p>
                                </div>
                                <div>
                                    <h3 className='column-layout-key'>Tax Obligation Management</h3>
                                    <p className='column-layout-value'>Support for report preparation, review, adjustments and approval workflow.</p>
                                </div>
                                <div>
                                    <h3 className='column-layout-key'>Document Upload and Management</h3>
                                    <p className='column-layout-value'>Upload documents and screenshots to tax obligations without worrying about file security and availability.</p>
                                </div>
                                <div>
                                    <h3 className='column-layout-key'>Data Reconciliation and Trend Analysiss</h3>
                                    <p className='column-layout-value'>Ensure data accuracy and completeness.</p>
                                </div>
                                <div>
                                    <h3 className='column-layout-key'>Report Generation and Download</h3>
                                    <p className='column-layout-value'>Produce tax authority facing and customer facing reports which are tailor made for each compliance.</p>
                                </div>
                                <div>
                                    <h3 className='column-layout-key'>Automated Tax E-filing</h3>
                                    <p className='column-layout-value'>File returns with a click of a button with support for auto-saving tax authority responses as PDF.</p>
                                </div>
                                <div>
                                    <h3 className='column-layout-key'>Reconciliations</h3>
                                    <p className='column-layout-value'>Phastos provides the capability to perform multiple 2-way data reconciliation to preventatively assure accuracy and, when necessary, prove it to authorities.</p>
                                </div>
                                <div>
                                    <h3 className='column-layout-key'>Flexible Workflow Management</h3>
                                    <p className='column-layout-value'>Configure new tax obligations, create new data validations, manage workflow assignment using self-service.</p>
                                </div>
                            </ColumnLayout>
                        </Container>
                    </SpaceBetween>
                    <div>
                        <Container
                            header={
                                <Header
                                    variant="h2"
                                    actions={
                                        <SpaceBetween
                                            direction="horizontal"
                                            size="xs"
                                        >
                                        </SpaceBetween>
                                    }
                                >
                                    References
                                </Header>
                            }>
                            <ColumnLayout borders="horizontal" columns={1}>
                                <a href="https://w.amazon.com/bin/view/Taskless/Quantum/Charters/IndirectTaxReporting/Phastos" rel=" noopener noreferrer" target="_blank">Help Guide</a>
                                <a href={ROUTE_PATH_FAQ_PAGE}>FAQ's</a>
                            </ColumnLayout>
                        </Container>
                        <br/>
                        <Container
                            header={
                                <Header
                                    variant="h2"
                                    actions={
                                        <SpaceBetween
                                            direction="horizontal"
                                            size="xs"
                                        >
                                        </SpaceBetween>
                                    }
                                >
                                    Support
                                </Header>
                            }>
                            <ColumnLayout borders="horizontal" columns={1}>
                                <Button href="https://issues.amazon.com/issues/create?assignedFolder=6f2a9b24-8b63-4747-bb62-4650bb58fc78" target="_blank" className="supportBtn" iconName="bug" variant="link">
                                    Report Bug
                                </Button>
                                <Button iconName="contact" variant="link" onClick={contactUsClick} className="supportBtn">
                                    Contact Us
                                </Button>
                                <Button href="https://issues.amazon.com/issues/create?assignedFolder=6f2a9b24-8b63-4747-bb62-4650bb58fc78" target="_blank" className="supportBtn" iconName="heart" variant="link">
                                    Request New Feature
                                </Button>
                                <Button href="https://email-list.corp.amazon.com/email-list/expand-list/taskless-wolverines" target="_blank" iconName="envelope" className="supportBtn" variant="link">
                                    Get Updates
                                </Button>
                            </ColumnLayout>

                        </Container>
                    </div>
                </Grid>

                <br/>
            </div>
        </>
    );
}

export default Home;
import React, { useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import {
    getMatchesCountText,
    paginationLabels,
    Preferences,
    PROPERTY_FILTERING_I18N_CONSTANTS
} from "../../table-config";
import {
    Checkbox,
    Header,
    Pagination,
    PropertyFilter,
    SpaceBetween,
    Table
} from "@amzn/awsui-components-react";
import { isNull } from "lodash";
import { getColumnDefinitions } from './table-config';
import ViewSummaryMetricsForMOG from "./ViewSummaryMetrics";

export default function MOGTab(props) {
    const navigate = useNavigate();
    const {
        viewSummaryMetricsProps,
        collectionProps,
        selectedItems,
        allItems,
        preferences,
        items,
        paginationProps,
        propertyFilterProps,
        filteredItemsCount,
        setPreferences,
        setSelectedItems,
        statusFilterValue,
        actions,
        createChannelSlackStatusDispatcher,
        addUserSlackStatusDispatcher,
        listChannelsSlackData,
        listChannelsSlackDataDispatcher,
        isMOGFiledChecked,
        setIsMOGFiledChecked
    } = props;

    const columnDefinitions = getColumnDefinitions(
        navigate, createChannelSlackStatusDispatcher, addUserSlackStatusDispatcher, listChannelsSlackData, listChannelsSlackDataDispatcher
    );

    const onSelectionChange = (item) => {
        setSelectedItems(item.detail.selectedItems)
    };

    const {setPropertyFiltering} = actions;

    useEffect(() => {
        if (!isNull(statusFilterValue.mogTab)) {
            setPropertyFiltering({
                ...propertyFilterProps.query,
                tokens: [{
                    propertyKey: 'status',
                    operator: '=',
                    value: statusFilterValue.mogTab,
                }],
            })
        } else {
            setPropertyFiltering({
                ...propertyFilterProps.query,
                tokens: [],
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [statusFilterValue]);

    const getMOGReturnPaginationActions = () => {
        return (
            <SpaceBetween size='l' direction='horizontal'>
                <Checkbox checked={isMOGFiledChecked} onChange={event => { setIsMOGFiledChecked(event.detail.checked) }}>Include completed</Checkbox>
                <Pagination {...paginationProps} ariaLabels={paginationLabels}/>
            </SpaceBetween>
        );
    };

    return (
        <SpaceBetween size="xl">
            <ViewSummaryMetricsForMOG
                summaryDataTitle={viewSummaryMetricsProps.summaryDataTitle}
                changeStatusFilterValue={viewSummaryMetricsProps.changeStatusFilterValue}
                tableData={viewSummaryMetricsProps.tableData} />
            <Table
                {...collectionProps}
                selectionType="multi"
                onSelectionChange={onSelectionChange}
                header={
                    <div>
                        <Header
                            counter={selectedItems.length ? `(${selectedItems.length}/${allItems.length})` : `(${allItems.length})`}
                        >
                            Obligations
                        </Header>
                    </div>
                }
                columnDefinitions={columnDefinitions}
                visibleColumns={preferences.visibleContent}
                selectedItems={selectedItems}
                items={items}
                pagination={getMOGReturnPaginationActions()}
                filter={
                    <PropertyFilter
                        i18nStrings={PROPERTY_FILTERING_I18N_CONSTANTS}
                        filteringProperties={propertyFilterProps.filteringProperties}
                        filteringOptions={propertyFilterProps.filteringOptions}
                        onChange={propertyFilterProps.onChange}
                        query={propertyFilterProps.query}
                        countText={getMatchesCountText(filteredItemsCount)}
                    />
                }
                preferences={<Preferences preferences={preferences} setPreferences={setPreferences}/>}
            />
        </SpaceBetween>
    );
}
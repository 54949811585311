export const UPDATE_STATUS_INITIAL_STATE = {
    initialize:true,
    flashBarItem: [],
    status: null,
};

export const updateStatusReducerInit = initialState =>{
    return initialState;
};

const getFlashBar = (type, msg, id, action) => {
    return [{
        type: type,
        dismissible: true,
        dismissLabel: "Dismiss message",
        onDismiss: () => {
            action.payload?.dispatcher({type:"DISMISS_FLASHBAR", payload:{}})
        },
        content: (
            <>
                {msg}
            </>
        ),
        id: id,
    }]
};

export const updateStatusReducer = (state, action) => {
    switch (action.type){
        case "SUCCESS":
            const msg = action.payload?.msg;
            const requestId = action.payload?.requestId;
            return {
                ...state,
                ...action.payload,
                flashBarItem: getFlashBar("success", msg, requestId, action),
            }
        case "FAILURE":
            const errMsg = action.payload?.errMsg
            return {
                ...state,
                ...action.payload,
                flashBarItem: getFlashBar("error", errMsg, "message_1", action),
            }
        case "DISMISS_FLASHBAR":
            return {
                ...state,
                flashBarItem: []
            }
        default:
            return state
    }
};
import {isEmpty, set} from 'lodash';

export const getColumnDefinitions = () => {
  return [
      {
          id: "documentName",
          header: "Document Name",
          cell: e => e.documentName,
          sortingField: "documentName"
      },
      {
          id: "documentID",
          header: "Document ID",
          cell: e => e.documentID,
          sortingField: "documentID"
      },
      {
          id: "useCase",
          header: "UseCase",
          cell: e => e.documentAdditionalDataMap.useCase,
          sortingField: "useCase"
      },
      {
          id: "subUseCase",
          header: "Sub UseCase",
          cell: e => e.documentAdditionalDataMap.subUseCase
      },
      {
          id: "entity",
          header: "Entity",
          cell: e => e.documentAdditionalDataMap.entityName,
          sortingField: "entity"
      },
      {
          id: "documentType",
          header: "Document Type",
          cell: e => e.documentAdditionalDataMap.documentType,
          sortingField: "documentType"
      },
      {
          id: "requester",
          header: "Requestor",
          cell: e => e.documentAdditionalDataMap.requester,
          sortingField: "requester"
      },
      {
          id: "countryCode",
          header: "Country Code",
          cell: e => e.documentAdditionalDataMap.countryCode,
          sortingField: "countryCode"
      },
      {
          id: "createdBy",
          header: "Created By",
          cell: e => e.createdBy,
          sortingField: "createdBy"
      }
  ];
};

export const filteringProperties = [
  {
    propertyLabel: 'Document Name',
    key: 'documentName',
    groupValuesLabel: 'Document Name',
    operators: [':', '!:', '=', '!='],
  },
  {
    propertyLabel: 'Country Code',
    key: 'countryCode',
    groupValuesLabel: 'Country Code',
    operators: [':', '!:', '=', '!='],
  },
  {
    propertyLabel: 'Use case',
    key: 'useCase',
    groupValuesLabel: 'Use case',
    operators: [':', '!:', '=', '!='],
  },
  {
    propertyLabel: 'SubUseCase',
    key: 'subUseCase',
    groupValuesLabel: 'SubUseCase',
    operators: [':', '!:', '=', '!='],
  },
  {
    propertyLabel: 'Entity',
    key: 'entity',
    groupValuesLabel: 'Entity',
    operators: [':', '!:', '=', '!='],
  }
];

export const extractSearchStateMap = searchStateMap => {
  const resultMap = {};
  Object.keys(searchStateMap).forEach(key => {
    if (!isEmpty(searchStateMap[key])) {
      set(resultMap, key, searchStateMap[key]);
    }
  });
  return resultMap;
};

export const getURLlink = (presignedUrl, fileName) => {
  const link = document.createElement("a");
  link.href = presignedUrl;
  link.setAttribute("download", fileName);
  link.setAttribute('target', '_blank');
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
  return link;
}
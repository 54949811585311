// Validating the attributes for undefined since they are non null fields required for filing
export const ISVATFilingValidation = (data) => {
    var result = data.map(row => {
            return  row['Code'] === undefined || 
                    row['Reduced Net Rate 11%'] === undefined || 
                    row['Reduced VAT rate 11%'] === undefined || 
                    row['Standard NET rate 24%'] === undefined || 
                    row['Standard VAT rate 24%'] === undefined   
        }
    )
    return !result.includes(true);
}

// Validating the attributes for fields required for filing
// Company code : Entity
// Direction of trade : Should be either E or V
export const DEIntrastatFilingValidation = (data) => {
    var result = data.map(row => {
        row['Fiscal Period (dd/MM/yyyy)'] = new Date(Math.round((Number(row['Fiscal Period (dd/MM/yyyy)']) - 25569)*86400*1000)).toLocaleDateString();
            return  row['Company Code'] === undefined || 
                    !["E","V"].includes(row['Direction of trade'])   
        }
    )
    return !result.includes(true);
}
import React from 'react';
import {CollectionPreferences} from '@amzn/awsui-components-react/polaris';
import { DateTimeForm, formatDateTime } from '../phastos/table-date-filter-forms';
import {Icon} from "@amzn/awsui-components-react";
import {isNull} from "lodash";

export const columnDefinitions = [
  {
    id: 'orderId',
    header: 'Order Id',
    cell: item => item.orderId,
    minWidth: '40px'
  },
  {
    id: 'reversalId',
    cell: item => item.reversalId,
    header: 'Reversal Id',
    minWidth: '40px'
  },
  {
    id: 'shipmentId',
    header: 'Shipment Id',
    cell: item => item.shipmentId,
    minWidth: '40px'
  },
  {
    id: 'marketplaceId',
    header: 'MarketPlace Id',
    cell: item => item.marketplaceId,
    minWidth: '40px'
  },
  {
    id: 'merchantId',
    header: 'Merchant Id',
    cell: item => item.merchantId,
    minWidth: '40px'
  },
  {
    id: 'shipDate',
    header: 'Ship Date',
    cell: item => item.shipDate,
    minWidth: '50px'
  },
  {
    id: 'asin',
    header: 'ASIN',
    cell: item => item.asin,
    minWidth: '40px'
  },
  {
    id: 'shipFromCountry',
    header: 'Ship From Country',
    cell: item => item.shipFromCountry,
    minWidth: '40px'
  },
  {
    id: 'shipToCountry',
    header: 'Ship To Country',
    cell: item => item.shipToCountry,
    minWidth: '40px'
  },
  {
    id: 'tag',
    header: 'Tag',
    cell: item => item.tag,
    minWidth: '50px'
  }
];

export function getMatchesCountText(count) {
  return count === 1 ? `1 match` : `${count} matches`;
}

export const filteringProperties = [
  {
    key: 'shipDate',
    propertyLabel: 'Ship Deadline',
    groupValuesLabel: 'Created at value',
    defaultOperator: '>',
    operators: ['<', '<=', '>', '>='].map(operator => ({
      operator,
      form: DateTimeForm,
      format: formatDateTime,
      match: 'datetime',
    })),
  }
]

const VISIBLE_CONTENT_OPTIONS = [
  {
    label: 'Main transaction properties',
    options: [
      { id: 'orderId', label: 'Order Id' },
      { id: 'reversalId', label: 'Reversal Id' },
      { id: 'shipmentId', label: 'Shipment Id' },
      { id: 'marketplaceId', label: 'MarketPlace Id' },
      { id: 'merchantId', label: 'Merchant Id' },
      { id: 'shipDate', label: 'ShipDate' },
      { id: 'asin', label: 'ASIN' },
      { id: 'shipFromCountry', label: 'ShipFromCountry' },
      { id: 'shipToCountry', label: 'ShipToCountry' },
    ],
  },
];

export const PAGE_SIZE_OPTIONS = [
  { value: 10, label: '10 Transactions' },
  { value: 30, label: '30 Transactions' },
  { value: 50, label: '50 Transactions' },
];


export const Preferences = ({
  preferences,
  setPreferences,
  disabled,
  pageSizeOptions = PAGE_SIZE_OPTIONS,
  visibleContentOptions = VISIBLE_CONTENT_OPTIONS,
}) => (
  <CollectionPreferences
    title="Preferences"
    confirmLabel="Confirm"
    cancelLabel="Cancel"
    disabled={disabled}
    preferences={preferences}
    onConfirm={({ detail }) => setPreferences(detail)}
    pageSizePreference={{
      title: 'Page size',
      options: pageSizeOptions,
    }}
    wrapLinesPreference={{
      label: 'Wrap lines',
      description: 'Check to see all the text and wrap the lines',
    }}
    visibleContentPreference={{
      title: 'Select visible columns',
      options: visibleContentOptions,
    }}
  />
);

const getStatus = (status) => {
  if (status === 'Pending') {
    return (<div><Icon name='status-pending' /> Pending</div>);
  } else {
    return (<div style={{ color: 'green' }}><Icon name='status-positive' /> Completed</div>);
  }
};

export const exceptionSummaryColumnDefinition = [
  {
    id: 'step',
    header: 'Step',
    cell: item => item.step,
  },
  {
    id: 'status',
    header: 'Status',
    cell: item => getStatus(item.status),
  },
  {
    id: 'person',
    header: 'Person',
    cell: item => isNull(item.person) ? '-' : item.person,
  },
  {
    id: 'date',
    header: 'Date',
    cell: item => isNull(item.date) ? '-' : item.date,
  }
];
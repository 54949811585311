import React, { useEffect, useState } from 'react';
import {
    Container,
    SpaceBetween,
    Header,
    Button,
    Table,
    Toggle,
    Box,
    ExpandableSection, Popover,
} from '@amzn/awsui-components-react';
import "../../css/errorSummary.css";
import { getUserAlias } from "../../utils/utils";
import { modifyWorkflowStatus } from "../redux/exceptionAPIs";
import { TransactionsAdjustment } from './VATTransactionsAdjustment';
import {isNull, isUndefined} from 'lodash';
import { postMessage } from '../../../phastos/components/Slack/slackIntegrationAPIs';
import { ACKNOWLEDGEMENT_STRUCTURE_MAP_KEY, ASM_COMPLETE_STATUS, PHASTOS_OBLIGATION } from '../common/constants';
import { getExceptionTransactionDetails, showTransactionsHandler } from '../common/adjustment/constants/errorSummaryUtils';
import { getColumnDefinitions } from './constants/errorSummaryConstants';
import {TRANSACTION_CONTAINER_DOM_ID} from "./constants/boxLevelConstants";
import QuickSightFrame from "../common/QuickSightFrame";

export default function VATErrorSummary(props) {
    const { 
        exceptionData,
        isUnderReview,
        obligationStatus,
        stepsState,
        setStepsStateHandler,
        updateWorkflowStatusDispatcher,
        parentWorkflowId,
        slackChannel,
        transactionReviewEditMode,
        setTransactionReviewEditMode,
        transactionSummaryData,
        isTransactionReviewLoading,
        setIsTransactionReviewLoading,
        useCase,
        phastosUseCaseName,
        reportingPeriod,
    } = props;

    const isCompleteButtonDisabled = () => {
        return !(isUnderReview && !stepsState.transactionReviewStep.completed);
    };

    const [completeButtonState, setCompleteButtonState] = useState(false);

    const completeButtonClickHandler = async () => {
        setCompleteButtonState(true);
        const userAlias = await getUserAlias();
        const request = {
            parentWorkflowId: parentWorkflowId,
            acknowledgementStructureMap: {
                [ACKNOWLEDGEMENT_STRUCTURE_MAP_KEY.TransactionReview]: { status: ASM_COMPLETE_STATUS, user: userAlias }
            },
        };
        const onSuccess = () => {
            postMessage(slackChannel, `${userAlias}@ has completed the Transaction Review step.`);
            setStepsStateHandler("transactionReviewStep");
        };
        modifyWorkflowStatus(updateWorkflowStatusDispatcher, request, onSuccess);
        setCompleteButtonState(false);
    };

    const [items, setItems] = useState([]);

    const refreshSummaryData = (showTransactions) => {
        setIsTransactionReviewLoading(true);
        setShowTransactions(showTransactions);

        const request = {
            parentWorkflowId: parentWorkflowId,
            setItems: setItems,
            setIsLoading: setIsTransactionReviewLoading,
            useCase: useCase,
            phastosObligation: PHASTOS_OBLIGATION.VAT
        };
        getExceptionTransactionDetails(request);
    };

    useEffect(() => {
        if (isUndefined(transactionSummaryData) || isNull(transactionSummaryData)) {
            setIsTransactionReviewLoading(true);
        } else {
            setItems(transactionSummaryData);
            setIsTransactionReviewLoading(false);
        }
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (transactionSummaryData === undefined || transactionSummaryData.length < 1) { return }
        setItems(transactionSummaryData);
        setIsTransactionReviewLoading(false);
    }, [transactionSummaryData, setIsTransactionReviewLoading]);

    const handleShowTransactions = (params) => {
        setSummaryExpanded(false);
        showTransactionsHandler(params, setCurrentFilter, setTransactionFilterData, setShowTransactions, PHASTOS_OBLIGATION.VAT);
    };

    const columnDefinitions = getColumnDefinitions(handleShowTransactions, exceptionData?.currencySymbol);

    const [showTransactions, setShowTransactions] = useState(false);
    const [transactionFilterData, setTransactionFilterData] = useState({});

    const hideTransactionsHandler = () => {
        setShowTransactions(false);
    };

    const [currentFilter, setCurrentFilter] = useState('');
    const [isSummaryExpanded, setSummaryExpanded] = useState(true);

    useEffect(() => {
        if(isSummaryExpanded && !isTransactionReviewLoading) {
            refreshSummaryData(showTransactions);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isSummaryExpanded]);

    return (
        <SpaceBetween size='xl'>
            <Container header={
                <Header actions={
                    <SpaceBetween size='m' direction='horizontal'>
                        <Toggle
                            onChange={({ detail }) => {
                                setTransactionReviewEditMode(detail.checked);
                            }}
                            checked={transactionReviewEditMode}>
                            Edit Mode
                        </Toggle>
                        <Button disabled={isCompleteButtonDisabled() || completeButtonState}
                                onClick={completeButtonClickHandler}
                        >Complete</Button>
                    </SpaceBetween>
                }>Error Type Summary
                </Header>
            }>
            { transactionReviewEditMode &&
                <SpaceBetween size='xl'>
                    <ExpandableSection
                        variant="container"
                        expanded={isSummaryExpanded}
                        onChange={(e) => { setSummaryExpanded(e.detail.expanded) }}
                        header={
                            <Header actions={
                                <SpaceBetween size='m' direction='horizontal'>
                                    <div onClick={(e) => { e.stopPropagation() }}>
                                        <Button variant='normal'
                                                onClick={e => {
                                                    setSummaryExpanded(true);
                                                    refreshSummaryData(false);
                                                }}
                                                disabled={isTransactionReviewLoading}
                                        >Refresh</Button>
                                    </div>
                                </SpaceBetween>
                            }>Breakdown
                                <div style={{display: "inline"}} onClick={(e)=>{e.stopPropagation();}}>
                                <Popover
                                    position="top"
                                    size="small"
                                    triggerType="custom"
                                    onClick = {(e)=>{e.stopPropagation();}}
                                    content={<>Breakdown refreshes after every 2 minutes</>}>
                                    <Button iconName="status-info" variant="icon" />
                                </Popover>
                                </div>
                            </Header>
                        }
                    >
                        <Table
                            columnDefinitions={columnDefinitions}
                            items={items}
                            loading={isTransactionReviewLoading}
                            empty={
                                <Box
                                    margin={{ vertical: "xs" }}
                                    textAlign="center"
                                    color="inherit"
                                >
                                    <SpaceBetween size="m">
                                        <b>No transaction found</b>
                                    </SpaceBetween>
                                </Box>
                            }
                        />
                    </ExpandableSection>
                    <div id={TRANSACTION_CONTAINER_DOM_ID}>
                        { showTransactions && 
                            <TransactionsAdjustment
                                hideTransactionsHandler={hideTransactionsHandler}
                                stepsState={stepsState}
                                currentFilter={currentFilter}
                                obligationStatus={obligationStatus}
                                filterData={transactionFilterData}
                                setTransactionFilterData={setTransactionFilterData}
                                refreshSummaryData={refreshSummaryData}
                                setIsLoadingSummary={setIsTransactionReviewLoading}
                                parentWorkflowId={parentWorkflowId}
                                slackChannel={slackChannel}
                                useCase={useCase}
                                phastosUseCaseName={phastosUseCaseName}
                                reportingPeriod={reportingPeriod}
                            />
                        }  
                    </div>
                </SpaceBetween>
            }
            { !transactionReviewEditMode &&
                <QuickSightFrame linkKey={'EXCEPTION_REVIEW'}
                                 exceptionData={exceptionData}
                                 useCase={useCase}
                />
            }
            </Container>
        </SpaceBetween>
    );
}
import {getExcaliburAuditData} from "./excaliburAPIs";
import {ASC, DESC} from "../../../common/constants/constants";
import orderBy from 'lodash/orderBy';

export const INITIAL_STATE = {
    lastEvaluatedKey: null,
    cachedData:new Map(),
    pageCount:2,
    currentPageIndex:1,
    sortingColumn:{"sortingField": "startTimestamp"},
    sortingDescending:true,
    loading:true,
    loadingText:"Loading requests",
    initialize:true
};

export const tableReducerInit = initialState =>{
    return initialState;
}

export const tableReducer = (state, action) => {
    switch (action.type){
        case "API_RESPONSE":
            const currentOrder = state.sortingDescending ? DESC : ASC;
            state.cachedData.set(state.currentPageIndex,orderBy(action.payload.data,
                state.sortingColumn.sortingField,currentOrder));
            return {
                ...state,
                loading: false,
                initialize:false,
                lastEvaluatedKey: action.payload.lastEvaluatedKey,
            }
        case "PAGE_INDEX_CHANGED":
            if(state.cachedData.has(action.payload.pageIndex) === false){
                if(state.lastEvaluatedKey === null) {
                    // If the above condition is true,
                    // it means DDB has returned complete data and no data is left to show
                    state.cachedData.set(action.payload.pageIndex, []);
                }else {
                    state.pageCount = state.pageCount+1;
                    getExcaliburAuditData(action.payload.dispatcher, state.lastEvaluatedKey);
                }
            }
            return {
                ...state,
                currentPageIndex:action.payload.pageIndex,
            }
        case "SORTING_CHANGED":
            let newOrder = action.payload.isDescending ? DESC : ASC;
            state.loading= true
            let sortedPageItems = orderBy(state.cachedData.get(state.currentPageIndex),action.payload.sortingField,newOrder);
            state.cachedData.set(state.currentPageIndex, sortedPageItems);
            return {
                ...state,
                loading: false,
                sortingDescending:action.payload.isDescending,
                sortingColumn:{"sortingField":action.payload.sortingField}
            }
        case "REFRESH":
            state = INITIAL_STATE;
            // if(!table.cachedData.has(table.currentPageIndex)){
            getExcaliburAuditData(action.payload.dispatcher, state.lastEvaluatedKey);
            // }
            return {
                ...state
            }
        case "FAILURE_API_RESPONSE":
            return {
                ...state,
                loading:false,
                initialize:false,
            }
        default:
            break;
    }
}
import React, { useState } from "react";
import { Box, Button, Modal, SpaceBetween } from "@amzn/awsui-components-react";
import { isNull } from "lodash";
import { uploadAttachment } from "../../../../../common/fileUploader/putFileInS3Util";
import {DOCUMENT_TYPE} from "../../../../../common/constants/constants";

export default function AttachmentModal(props) {
    const {
        showAttachmentModal,
        setShowAttachmentModal,
        parentWorkflowId,
        loadingSubmit,
        setLoadingSubmit,
        onSuccessHandler,
        useCase,
        putFileInS3DetailsDispatcher,
        onFailureHandler
    } = props;

    const [fileState, setFileState] = useState(null);
    const hiddenFileInput = React.createRef();
    // eslint-disable-next-line no-unused-vars

    const setFile = file => {
        setFileState(file);
    };

    const onSubmitHandler = () => {
        setLoadingSubmit(true);
        uploadAttachment(putFileInS3DetailsDispatcher, {
            parentWorkflowId,
            fileName: fileState.name,
            documentType: DOCUMENT_TYPE.ATTACHMENTS,
            useCase: useCase, file: fileState
        }, onSuccessHandler, onFailureHandler);
    }

    const onDismissHandler = () => {
        setShowAttachmentModal(false);
    };

    return (
        <Modal
            visible={showAttachmentModal}
            onDismiss={onDismissHandler}
            header={'Attachments'}
            closeAriaLabel="Close dialog"
            size="small"
            footer={
                <Box float="right">
                    <SpaceBetween direction="horizontal" size="xs">
                        <Button variant="link" onClick={onDismissHandler}>Cancel</Button>
                        <Button variant="primary" onClick={onSubmitHandler} disabled={isNull(fileState)} loading={loadingSubmit}>Submit</Button>
                    </SpaceBetween>
                </Box>
            }
        >
            <input
                ref={hiddenFileInput}
                id="chooseJSONInput"
                type="file"
                hidden
                multiple={false}
                onChange={event => setFile(event.target.files[0])}
            />
            <Button
                id="chooseJSONButton"
                iconName="upload"
                formAction="none"
                onClick={() => {
                    hiddenFileInput.current.value = null;
                    hiddenFileInput.current.click();
                }}
            >
                Choose File
            </Button>
            {fileState ? fileState.name : ""}
        </Modal>
    )
}
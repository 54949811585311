import { isNull } from "lodash";
import { getUserProfiles, postMessage } from "../../../phastos/components/Slack/slackIntegrationAPIs";
import { getUserAlias } from "../../utils/utils";

export const toDateTimeFormat = (date) => {
    return date.toLocaleString('en-US', { month: 'short', day: 'numeric', hour: '2-digit', minute: '2-digit', hour12: false })
};

export const filterTextMessages = (messages) => {
    return messages.filter(message => message.subtype === undefined);
}

export const processUsers = (textMessages) => {
    const userIds = []
    for (let i=textMessages.length - 1; i>=0; i--) {
        if (userIds.includes(textMessages[i].user)) continue;
        userIds.push(textMessages[i].user);
    }
    return userIds;
};

const SLACK_APPLICATION_USER_ID = 'U0556UV6A9Y';

export const processTextMessages = async (userIds, textMessages, setCorrespondenceMessages) => {
    let correspondenceMessages = [];
    const userProfiles = await getUserProfiles(userIds);
    for (let i=textMessages.length - 1; i>=0; i--) {
        const textMessage = textMessages[i];

        let messageText;
        if (textMessage.user === SLACK_APPLICATION_USER_ID) {
            messageText =  textMessage.text;
        } else {
            const userProfile = userProfiles[textMessage.user];
            const userAlias = (isNull(userProfile) ? '?' : userProfile.profile.display_name) + '@';
            messageText =  userAlias + ' - ' + textMessage.text;
        }
        correspondenceMessages.push({
            text: messageText,
            time: toDateTimeFormat(new Date(textMessage.ts * 1000)),
        });
        setCorrespondenceMessages([...correspondenceMessages]);
    }
};

export const processNewMessage = async (slackChannel, newMessage, correspondenceMessages, setCorrespondenceMessages) => {
    const userAlias = await getUserAlias();
    const messageText = userAlias + '@ - ' + newMessage;
    correspondenceMessages.push({
        text: messageText,
        time: toDateTimeFormat(new Date()),
    });
    setCorrespondenceMessages([...correspondenceMessages]);
    postMessage(slackChannel, messageText);
};
import { 
    Box,
    Button,
    Modal,
    SpaceBetween,
    Textarea
} from "@amzn/awsui-components-react";
import { useState } from "react";

export function RejectReturnModal(props) {
    const {
        rejectConfirmModal,
        rejectConfirmHandler,
        setRejectConfirmModal,
    } = props;
    
    const [rejectReason, setRejectReason] = useState('');

    const resetRejectModal = () => {
        setRejectConfirmModal(false);
        setRejectReason('');
    };

    return (
        <Modal
            visible={rejectConfirmModal}
            onDismiss={resetRejectModal}
            header={'Reject return'}
            closeAriaLabel="Close"
            footer={
                <Box float="right">
                    <SpaceBetween direction="horizontal" size="xs">
                        <Button variant="primary" onClick={e => {
                            rejectConfirmHandler(rejectReason)
                            resetRejectModal();
                        }}>Confirm</Button>
                    </SpaceBetween>
                </Box>
            }
        >
            <Textarea
                onChange={({ detail }) => setRejectReason(detail.value)}
                value={rejectReason}
                placeholder={'Enter reason to reject'}
            />
        </Modal>
    )
}
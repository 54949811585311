export const getUKVATFilingPreviewJSON = (properties) => {
    const json = [
        {
            type : "ButtonDropdown",
            properties : {
                id : "ReportPeriodDropdown",
                items : properties.dropdownButton["ReportPeriodDropdown"].items,
                variant : "normal",
                display : "inline-block",
                value : properties.dropdownButton["ReportPeriodDropdown"].value,
                onItemClick : properties.dropdownButton["ReportPeriodDropdown"].onItemClick 
            }        
        },
        {
            type : "ValueWithLabel",
            properties : {
                id : "VAT Due Sales",
                label : "VAT Due Sales",
                value : properties.filingData['VAT Due Sales'].toLocaleString('en-US')
            }
        },
        {
            type : "ValueWithLabel",
            properties : {
                id : "VAT Due Acquisitions",
                label : "VAT Due Acquisitions",
                value : properties.filingData['VAT Due Acquisitions'].toLocaleString('en-US')
            }
        },
        {
            type : "ValueWithLabel",
            properties : {
                id : "Total Acquisitions Ex. VAT",
                label : "Total Acquisitions Ex. VAT",
                value : properties.filingData['Total Acquisitions Excluding VAT'].toLocaleString('en-US')
            }
        },
        {
            type : "ValueWithLabel",
            properties : {
                id : "VAT Registration Number",
                label : "VAT Registration Number",
                value : properties.filingData['VAT Registration Number']
            }
        },
        {
            type : "ValueWithLabel",
            properties : {
                id : "Total VAT Due",
                label : "Total VAT Due",
                value : properties.filingData['Total VAT Due'].toLocaleString('en-US')
            }
        },
        {
            type : "ValueWithLabel",
            properties : {
                id : "VAT Reclaimed CurrPeriod",
                label : "VAT Reclaimed CurrPeriod",
                value : properties.filingData['VAT Reclaimed Current Period'].toLocaleString('en-US')
            }
        },
        {
            type : "ValueWithLabel",
            properties : {
                id : "Net VAT Due",
                label : "Net VAT Due",
                value : properties.filingData['Net VAT Due'].toLocaleString('en-US')
            }
        },
        {
            type : "ValueWithLabel",
            properties : {
                id : "Total Value Sales Ex VAT",
                label : "Total Value Sales Ex VAT",
                value : properties.filingData['Total Value Sales Excluding VAT'].toLocaleString('en-US')
            }
        },
        {
            type : "ValueWithLabel",
            properties : {
                id : "Total Value Purchases Ex. VAT",
                label : "Total Value Purchases Ex. VAT",
                value : properties.filingData['Total Value Purchases Excluding VAT'].toLocaleString('en-US')
            }
        },
        {
            type : "ValueWithLabel",
            properties : {
                id : "Total Value Goods Supplied Ex. VAT",
                label : "Total Value Goods Supplied Ex. VAT",
                value : properties.filingData['Total Value Goods Supplied Excluding VAT'].toLocaleString('en-US')
            }
        }
    ];
    return json;
} 


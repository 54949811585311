import { Box, Button, Modal, SpaceBetween, Textarea } from "@amzn/awsui-components-react";
import { useState } from "react";
import { addUser } from "../../../phastos/components/Slack/slackIntegrationAPIs";

export function AddUserModal(props) {
    const { listChannelsSlackData, addUserSlackStatus, addUserSlackStatusDispatcher } = props;
    const [slackEmailAddress, setSlackEmailAddress] = useState('');

    const handleAddUserSlackClick = () => {
        const channelsInfo = listChannelsSlackData?.data?.channels.filter(channel => channel?.name === addUserSlackStatus.channelName);
        if (channelsInfo.length < 1) {
            addUserSlackStatusDispatcher({ type: "FAILURE", payload: {
                error: {},
                flashbarMessage: "Could not find the Slack channel, try creating it first.",
                dispatcher: addUserSlackStatusDispatcher,
            }});
        } else {
            addUser(channelsInfo[0].id, slackEmailAddress, addUserSlackStatusDispatcher);
        }
        addUserSlackStatusDispatcher({ type: "DISMISS_MODAL", payload: {} });
        setSlackEmailAddress('');
    };

    return (
        <Modal
            visible={addUserSlackStatus?.isModalVisible}
            onDismiss={() => addUserSlackStatusDispatcher({type: 'DISMISS_MODAL', payload: {}})}
            header={'Add user to Slack channel'}
            closeAriaLabel="Close"
            footer={
                <Box float="right">
                    <SpaceBetween direction="horizontal" size="xs">
                        <Button variant="primary" onClick={handleAddUserSlackClick}>Submit</Button>
                        {/* <Button variant="primary" onClick={submitAdhocRequest}>Initiate</Button> */}
                    </SpaceBetween>
                </Box>
            }
        >
            <Textarea  
                onChange={({ detail }) => setSlackEmailAddress(detail.value)}
                value={slackEmailAddress}
                placeholder={'Enter e-mail address'}
            />
        </Modal>
    );
}
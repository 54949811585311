import React from "react";
import "regenerator-runtime/runtime";

const NotFound = () => {
    return (
        <div className="NotFound">
            <header className="App-header">
                <h1>Error 404 .Not Found !</h1>
            </header>
        </div>
    )
}

export default NotFound;
import {
    CLIENT_NAME,
    FILING,
    UNDERSCORE
} from "../../../common/constants/constants";
import {submitFilingRequestAsync} from "../utils/filingAPIs";

export const handleFiling = (passedData, filingDispatcher, params, additionalParams = {}) => {
    const additionalDataMap = Object.assign({
        ExcaliburTransformationId: passedData.transactionId
    }, additionalParams);
    const filingObj = {
        "useCase": passedData.useCase,
        "clientName": CLIENT_NAME,
        "subUseCase": passedData.subUseCase + UNDERSCORE + FILING,
        "s3BucketName": passedData.inputFileBucket,
        "s3FolderPath": passedData.inputFileKey,
        "additionalDataMap": additionalDataMap
    }
    submitFilingRequestAsync(filingDispatcher, filingObj, () => {
    })
}
export const BOX_DESCRIPTION = {
    'G1':'Total sales and income and other supplies',
    'G2':'Exports',
    'G3':'Other GST free Supplies',
    'G4':'Input Taxed Sales',
    'G5':'Total Supplies with no GST (G5 = G2 + G3 + G4)',
    'G6':'Total Taxable Supplies (G6 = G1 - G5)',
    'G7':'Adjustments',
    'G8':'Taxable supplies after Adj\'s (G8 = G6 + G7)',
    'G9':'GST PAYABLE (G9 = G8 / 11)',
    '7A':'Deferred GST on imports (from ATO)',
    'G9.2':'TOTAL GST PAYABLE (G9.2 = G9 + 7A)',
    'G10':'Capital purchases',
    'G11':'Other non capital purchases',
    'G12':'Total acquisitions (G12 = G10 + G11)',
    'G13':'Acquisitions for making Input taxed supplies',
    'G14':'Acquisitions with no GST',
    'G15':'Non Deductible acquisitions/Private use',
    'G16':'Total non creditable acquisitions (G16 = G13 + G14 + G15)',
    'G17':'Total of creditable acquisitions (G17 = G12 - G16)',
    'G18':'Adjustments',
    'G19':'Total of creditable acquisitions after adjustments (G19 = G17 + G18)',
    'G20':'Total value of your input tax credit (G20 = G19 / 11)',
    'Net GST': 'Net GST (Net GST = G9 - G20)'
};

export const BOX_LIST = [
    'G1',
    'G2',
    'G3',
    'G4',
    'G5',
    'G6',
    'G7',
    'G8',
    'G9',
    '7A',
    'G9.2',
    'G10',
    'G11',
    'G12',
    'G13',
    'G14',
    'G15',
    'G16',
    'G17',
    'G18',
    'G19',
    'G20',
    'Net GST'
];

export const ADJUSTABLE_BOX_LIST = [
    'G1',
    'G2',
    'G3',
    'G4',
    'G7',
    '7A',
    'G10',
    'G11',
    'G13',
    'G14',
    'G15',
    'G18'
];

export const HIGHLIGHTED_BOX_MAP = {
    'G9.2': true,
    'G20': true,
    'Net GST': true,
};

export const DOWNLOAD_HEADERS_FOR_BAS_SUMMARY = {
    BOX_LABEL: "Box Label",
    LABEL_DESCRIPTION: "Label Description",
    TOTAL_GROSS_AMOUNT: "Calculated GST Inclusion (AUD)",
    ADJUSTMENT_VALUE:"Adjustment (AUD)",
    TOTAL_VALUE_AFTER_ADJUSTMENT:"Total For BAS (AUD)"
};

export const TRANSACTION_CONTAINER_DOM_ID = "transactions-container";
export const INPUT_ATTRIBUTE = {
    VALUE: "value",
    IS_INVALID: "isInvalid",
}
export const SELECT_BOX = "Select box";
export const SELECT_OPERATION = "Select operation";


export const getChannelNameFromObligation = (value) => {
    return value.parentWorkflowId.toLowerCase().replaceAll('_', '-').replaceAll('#','--').replaceAll(' ','-');
};

export const getChannelNameFromVATObligation = (value) => {
    return `${value.obligationName}-${value.country}-${value.reportingPeriod}`.toLowerCase().replaceAll('_', '-').replaceAll('#','--').replaceAll(' ','');
};

export function createFlashbarItem(type, flashbarMessage, id, dispatcher) {
    return {
        type: type,
        dismissible: true,
        dismissLabel: 'Dismiss message',
        onDismiss: () => {
            dispatcher({type: 'DISMISS_FLASHBAR', payload: {}})
        },
        content: (
            <>
            {flashbarMessage}
            </>
        ),
        id: id,
    }
}
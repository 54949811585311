import { createFlashbarItem } from "./slackUtils";

export const ADD_USER_SLACK_INITIAL_STATE = {
    initialize: true,
    loading: false,
    data: {},
    error: {},
    flashBarItem: [],
    isModalVisible: false,
    channelName: '',
};

export const addUserSlackStatusReducerInit = initialState => {
    return initialState;
};

export const addUserSlackStatusReducer = (state, action) => {
    switch (action.type){
        case "SUCCESS":
            return {
                ...state,
                loading: false,
                initialize: false,
                data: action.payload.data,
                flashBarItem: [createFlashbarItem('success', action.payload.flashbarMessage, 'add_user_success', action.payload?.dispatcher)],
            }
        case "FAILURE":
            return {
                ...state,
                loading: false,
                initialize: false,
                error: action.payload.error,
                flashBarItem: [createFlashbarItem('error', action.payload.flashbarMessage, 'add_user_failure', action.payload?.dispatcher)],
            }
        case "DISMISS_FLASHBAR":
            return {
                ...state,
                flashBarItem: []
            }
        case "SHOW_MODAL":
            return {
                ...state,
                isModalVisible: true,
                channelName: action.payload.channelName
            }
        case "DISMISS_MODAL":
            return {
                ...state,
                isModalVisible: false,
            }
        default:
            return state
    }
};
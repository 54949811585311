import React, {useCallback, useEffect, useState} from "react";
import Table from "./PhastosTable"
import {
    Header,
    SpaceBetween,
    Flashbar
} from '@amzn/awsui-components-react';
import Auth from '@aws-amplify/auth';
import {useReducer} from "react";
import {INITIAL_STATE, tableReducer, tableReducerInit} from "./components/phastosReducer";
import {fetchDropDownList, fetchWorkflows} from "./components/phastosAPIs";
import {getUserName} from '../../common/utils/apiUtils';
import AdhocModal from './components/AdhocModal';
import "./css/Phastos.css"
import {
    UPDATE_STATUS_INITIAL_STATE,
    updateStatusReducer,
    updateStatusReducerInit
} from "../exception/components/redux/updateStatusReducer";
import {
    CREATE_CHANNEL_SLACK_INITIAL_STATE,
    createChannelSlackStatusReducer,
    createChannelSlackStatusReducerInit
} from "./components/Slack/createChannelSlackReducer";
import {
    ADD_USER_SLACK_INITIAL_STATE,
    addUserSlackStatusReducer,
    addUserSlackStatusReducerInit
} from "./components/Slack/addUserSlackReducer";
import {listChannels} from "./components/Slack/slackIntegrationAPIs";
import {
    LIST_CHANNELS_SLACK_INITIAL_STATE,
    listChannelsSlackDataReducer,
    listChannelsSlackDataReducerInit
} from "./components/Slack/listChannelsSlackReducer";
import {OBLIGATION_STATUS} from "../exception/components/common/constants";
import {AddUserModal} from "../exception/components/common/AddUserModal";
import {PHASTOS_TAB} from "./Constants/Constants";
import AdhocReportModal from "./components/AdhocReportModal";

function Phastos(params) {
    const [user, setUser] = useState(null);

    //Table State Management Reducer
    const [table, tableDispatcher] = useReducer(tableReducer,
        INITIAL_STATE, tableReducerInit);

    const [showRefreshModal, setShowRefreshModal] = useState(false);
    const onRefreshDiscard = () => setShowRefreshModal(false);

    const [showAdhocReportRefreshModal, setShowAdhocReportRefreshModal] = useState(false);
    const onRefreshDiscardAdhocReportModal = () => setShowAdhocReportRefreshModal(false);

    const [statusFilterValue, setStatusFilterValue] = useState({
        dataSharingTab: null,
        vatTab: null,
        mogTab: null,
        digitalServicesTaxTab: null,
        adhocDataSharingTab: null
    });

    const [isVATFiledChecked, setIsVATFiledChecked] = useState(false);
    const [isDSFiledChecked, setIsDSFiledChecked] = useState(false);
    const [isMOGFiledChecked, setIsMOGFiledChecked] = useState(false);
    const [isAdhocDSFiledChecked, setIsAdhocDSFiledChecked] = useState(false);

    const [updateWorkflowStatus, updateWorkflowStatusDispatcher] = useReducer(updateStatusReducer,
        UPDATE_STATUS_INITIAL_STATE, updateStatusReducerInit);

    // Slack API Reducers
    const [createChannelSlackStatus, createChannelSlackStatusDispatcher] = useReducer(createChannelSlackStatusReducer,
        CREATE_CHANNEL_SLACK_INITIAL_STATE, createChannelSlackStatusReducerInit);
    const [addUserSlackStatus, addUserSlackStatusDispatcher] = useReducer(addUserSlackStatusReducer,
        ADD_USER_SLACK_INITIAL_STATE, addUserSlackStatusReducerInit);
    const [listChannelsSlackData, listChannelsSlackDataDispatcher] = useReducer(listChannelsSlackDataReducer,
        LIST_CHANNELS_SLACK_INITIAL_STATE, listChannelsSlackDataReducerInit);

    useEffect(() => {
        Auth.currentAuthenticatedUser().then((userInfo) => {
            const name = getUserName(userInfo);
            setUser(name);
        });
        fetchWorkflows(tableDispatcher);
        fetchDropDownList(tableDispatcher);
        listChannels(listChannelsSlackDataDispatcher);
    }, []);

    const [summaryDataTitle, setSummaryDataTitle] = useState("");

    useEffect(() => {
        if (params.activeTab === PHASTOS_TAB.DATA_SHARING) {
            setSummaryDataTitle("Data sharing");
        } else if (params.activeTab === PHASTOS_TAB.VAT_RETURNS) {
            setSummaryDataTitle("VAT/GST Filing");
        } else if (params.activeTab === PHASTOS_TAB.MOG) {
            setSummaryDataTitle("Intrastat");
        } else if (params.activeTab === PHASTOS_TAB.DIGITAL_SERVICES_TAX) {
            setSummaryDataTitle("Digital Services Tax");
        } else if (params.activeTab === PHASTOS_TAB.ADHOC_DATA_SHARING) {
            setSummaryDataTitle("Adhoc Data Sharing");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [params.activeTab]);

    const changeStatusFilterValue = useCallback((actualStatus) => {
        if (params.activeTab === PHASTOS_TAB.DATA_SHARING) {
            setStatusFilterValue(prevState => {
                return {
                    ...prevState,
                    dataSharingTab: actualStatus,
                }
            });
            if (actualStatus === OBLIGATION_STATUS.Filed) {
                setIsDSFiledChecked(true);
            }
        } else if (params.activeTab === PHASTOS_TAB.ADHOC_DATA_SHARING) {

            setStatusFilterValue(prevState => {
                return {
                    ...prevState,
                    adhocDataSharingTab: actualStatus,
                }
            });
            if (actualStatus === OBLIGATION_STATUS.Filed) {
                setIsAdhocDSFiledChecked(true);
            }
        } else if (params.activeTab === PHASTOS_TAB.VAT_RETURNS) {
            setStatusFilterValue(prevState => {
                return {
                    ...prevState,
                    vatTab: actualStatus,
                }
            });
            if (actualStatus === OBLIGATION_STATUS.Filed) {
                setIsVATFiledChecked(true);
            }
        } else if (params.activeTab === PHASTOS_TAB.MOG) {
            setStatusFilterValue(prevState => ({
                ...prevState,
                mogTab: actualStatus,
            }));
            if (actualStatus === OBLIGATION_STATUS.Filed) {
                setIsMOGFiledChecked(true);
            }
        } else if (params.activeTab === PHASTOS_TAB.DIGITAL_SERVICES_TAX) {
            setStatusFilterValue(prevState => ({
                ...prevState,
                digitalServicesTaxTab: actualStatus,
            }));
        }
    }, [params.activeTab]);

    const [viewSummaryMetricsProps, setViewSummaryMetricsProps] = useState({
        "summaryDataTitle": summaryDataTitle,
        "changeStatusFilterValue": changeStatusFilterValue,
        "tableData": table,
        "setShowRefreshModal": setShowRefreshModal,
        "setShowAdhocReportRefreshModal": setShowAdhocReportRefreshModal
    });

    useEffect(() => {
        setViewSummaryMetricsProps({
            "summaryDataTitle": summaryDataTitle,
            "changeStatusFilterValue": changeStatusFilterValue,
            "tableData": table,
            "setShowRefreshModal": setShowRefreshModal,
            "setShowAdhocReportRefreshModal": setShowAdhocReportRefreshModal
        });
    }, [summaryDataTitle, changeStatusFilterValue, table]);

    return (
        <>
            <div className="phastos">
                <Flashbar items={table?.flashBarItem}/>
                <Flashbar items={updateWorkflowStatus?.flashBarItem}/>
                <Flashbar items={createChannelSlackStatus?.flashBarItem}/>
                <Flashbar items={addUserSlackStatus?.flashBarItem}/>
                <Flashbar items={listChannelsSlackData?.flashBarItem}/>
                <Header variant="h1" className="header">{`Hello, ${user}`}</Header>
                <SpaceBetween size='xl'>
                    <Table
                        {...params}
                        user={user}
                        viewSummaryMetricsProps={viewSummaryMetricsProps}
                        dispatcher={tableDispatcher}
                        dataSharingData={table?.dataSharingData}
                        vatReturnData={table?.vatReturnData}
                        MOGData={table?.mogData}
                        adhocDSData={table?.adhocDSData}
                        digitalServicesTaxData={table?.digitalServicesTaxData}
                        activeTabId={params.activeTab}
                        setActiveTabId={params.setActiveTab}
                        statusFilterValue={statusFilterValue}
                        setStatusFilterValue={setStatusFilterValue}
                        isDSFiledChecked={isDSFiledChecked}
                        setIsDSFiledChecked={setIsDSFiledChecked}
                        isAdhocDSFiledChecked={isAdhocDSFiledChecked}
                        setIsAdhocDSFiledChecked={setIsAdhocDSFiledChecked}
                        isVATFiledChecked={isVATFiledChecked}
                        setIsVATFiledChecked={setIsVATFiledChecked}
                        isMOGFiledChecked={isMOGFiledChecked}
                        setIsMOGFiledChecked={setIsMOGFiledChecked}
                        listChannelsSlackData={listChannelsSlackData}
                        listChannelsSlackDataDispatcher={listChannelsSlackDataDispatcher}
                        updateWorkflowStatusDispatcher={updateWorkflowStatusDispatcher}
                        createChannelSlackStatusDispatcher={createChannelSlackStatusDispatcher}
                        addUserSlackStatusDispatcher={addUserSlackStatusDispatcher}
                    />
                </SpaceBetween>

                <AdhocModal table={table} tableDispatcher={tableDispatcher} showRefreshModal={showRefreshModal}
                            onDismiss={onRefreshDiscard} setShowRefreshModal={setShowRefreshModal}/>

                <AdhocReportModal table={table} tableDispatcher={tableDispatcher} showAdhocReportRefreshModal={showAdhocReportRefreshModal}
                                  onDismiss={onRefreshDiscardAdhocReportModal} setShowAdhocReportRefreshModal={setShowAdhocReportRefreshModal} domain={params.domain}/>
                <AddUserModal
                    listChannelsSlackData={listChannelsSlackData}
                    addUserSlackStatus={addUserSlackStatus}
                    addUserSlackStatusDispatcher={addUserSlackStatusDispatcher}
                />
            </div>
        </>
    );
}

export default Phastos;

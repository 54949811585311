import {Box, Button, Container, Header, SpaceBetween, StatusIndicator} from "@amzn/awsui-components-react";

import 'ag-grid-enterprise';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import React, { useEffect, useMemo, useRef, useState } from "react";
import { getAPIInputContext } from "../../../../common/utils/apiUtils";
import { API } from "aws-amplify";
import { PHASTOS_GET_TRANSACTION_LEVEL_ADJUSTMENT_AUDIT } from "../../../../ApiPaths";
import {isNull} from "lodash";
import TableDownloadButton, {DEFINITION_TYPE_AG_GRID} from "./TableDownloadButtonComponent";

export function TransactionsAudit(props) {
    const {parentWorkflowId, workflowIdentificationProps} = props;
    const gridRef = useRef();
    const [transactionsData, setTransactionsData] = useState([]);

    // eslint-disable-next-line
    const [columnDefs, setColumnDefs] = useState([
        {headerName: 'Transaction ID', field: 'transactionId', enableRowGroup: true, minWidth: 344, width: 344},
        {headerName: 'Updated By', field: 'updatedBy', minWidth: 160, width: 160},
        {headerName: 'Action Type', field: 'actionType', minWidth: 160, width: 160},
        {headerName: 'Updated On', field: 'timestamp', minWidth: 280, width: 280},
        {headerName: 'Updates', field: 'updates', minWidth: 600, width: 600},
        {headerName: 'Update Reason', field: 'updateReason', minWidth: 600, width: 600},
        {headerName: 'Action ID', field: 'actionId', enableRowGroup: true, hide: true},
        {headerName: 'Exception Key', field: 'exceptionKey', hide: true},
        {headerName: 'Phastos Workflow ID', field: 'phastosWorkflowId', hide: true},
    ]);

    const defaultColDef = useMemo(() => ({
        sortable: true,
        minWidth: 360,
        defaultMinWidth: 360,
        resizable: true,
        floatingFilter: true,
        enablePivot: true,
        editable: false,
        filter: true,
    }), []);

    const [isLoadingTransactions, setIsLoadingTransactions] = useState(false);

    useEffect(() => {
        setIsLoadingTransactions(true);
        getAPIInputContext().then(input => {
            input.body.parentWorkflowId = parentWorkflowId;
            console.log(input);
    
            API.post("TWIN_API", PHASTOS_GET_TRANSACTION_LEVEL_ADJUSTMENT_AUDIT, input).then(resp => {
                console.log(PHASTOS_GET_TRANSACTION_LEVEL_ADJUSTMENT_AUDIT + " API was called, response: " + resp);
                const data = JSON.parse(resp.body.data);
                if (resp.status === 200) {
                    setIsLoadingTransactions(false);
                    const transactionsAdjustmentAuditList = isNull(data.transactionsAdjustmentAuditList) ? [] : data.transactionsAdjustmentAuditList;
                    setTransactionsData(JSON.parse(JSON.stringify(transactionsAdjustmentAuditList)));
                } else {
                    console.log(PHASTOS_GET_TRANSACTION_LEVEL_ADJUSTMENT_AUDIT + " API Error Response: " + JSON.stringify(resp));
                    // Error handling
                }
            });
        });
        // eslint-disable-next-line
    }, []);

    return (
        <Container header={
            <Header actions={
                <SpaceBetween size="m" direction="horizontal">
                    <TableDownloadButton data={transactionsData}
                                         nameProps={workflowIdentificationProps}
                                         tableId={'Transactions Audit'}
                                         columnDefinition={columnDefs}
                                         tableType={DEFINITION_TYPE_AG_GRID}
                                         isHeaderButton={true}
                    />
                <Button variant='normal'
                        disabled={true}
                >Refresh</Button></SpaceBetween>
            }>Transactions level audit</Header>
        }>
            { !isLoadingTransactions && 
                (<div className="ag-theme-alpine" style={{ width: "100%", height: "600px"}}>
                    <AgGridReact ref={gridRef}
                                rowData={transactionsData}
                                columnDefs={columnDefs}
                                defaultColDef={defaultColDef}
                                animateRows={true}
                                rowSelection='multiple'
                                singleClickEdit={true}
                                suppressExcelExport={true}
                                enableCharts={true}
                                sideBar={true}
                                rowGroupPanelShow='always'
                                suppressRowClickSelection={true}
                    />
                </div>)
            }
            { isLoadingTransactions && 
                (<Box textAlign="center">
                    <StatusIndicator type="loading">
                        Loading...
                    </StatusIndicator>
                </Box>)
            }
        </Container>
    )
}
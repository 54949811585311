import {restructureJobDetailsData} from "../../../common/utils/commonUtils";

export const JOB_DETAILS_STATE = {
    initialize:true,
    scheduledJobs: [],
    adhocJobs: [],
    fetching: false,
};

export const jobDetailsTableReducerInit = initialState =>{
    return initialState;
}

export const tableReducer = (state, action) => {
    switch (action.type){
        case "API_BEGIN":
            return {
                ...state,
                scheduledJobs: [],
                adhocJobs: [],
                fetching: true,
            }
        case "API_RESPONSE":
            const result = restructureJobDetailsData(action.payload?.data);
            return {
                ...state,
                loading: false,
                initialize:false,
                scheduledJobs: result.scheduledJobs,
                adhocJobs: result.adhocJobs,
                fetching: false,
            }
        case "FAILURE_API_RESPONSE":
            return JOB_DETAILS_STATE;
        default:
            return state;
    }
}
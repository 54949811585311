export const DATA_SOURCE_TYPE = {
    VENDOR_ARRIVALS: "VENDOR_ARRIVALS",
    INTRA_FC: "INTRA_FC",
    MOOG: "MOOG",
    PURCHASE: "PURCHASE",
    RETURN: "RETURN",
    DONATIONS: "DONATIONS",
    COS: "COS",
    COS_TAR: "COS_TAR",
    UPS: "UPS",
    KERRY: "KERRY",
    AR: "AR",
    TAR: "TAR",
    AP: "AP",
    INTERCOMPANY: "Intercompany",
    AP_ACCRUAL: "APAccrual"
};

export const MOG_DATA_SOURCES_LIST = [
    DATA_SOURCE_TYPE.VENDOR_ARRIVALS,
    DATA_SOURCE_TYPE.INTRA_FC,
    DATA_SOURCE_TYPE.MOOG,
    DATA_SOURCE_TYPE.PURCHASE,
    DATA_SOURCE_TYPE.RETURN,
    DATA_SOURCE_TYPE.DONATIONS,
    DATA_SOURCE_TYPE.COS,
    DATA_SOURCE_TYPE.COS_TAR,
    DATA_SOURCE_TYPE.UPS,
    DATA_SOURCE_TYPE.KERRY
];

export const VAT_DATA_SOURCES_LIST = [
    DATA_SOURCE_TYPE.AR,
    DATA_SOURCE_TYPE.TAR,
    DATA_SOURCE_TYPE.AP,
    DATA_SOURCE_TYPE.INTERCOMPANY,
    DATA_SOURCE_TYPE.AP_ACCRUAL
];
export const SEARCH_DOCUMENTS_STATE = {
    initialize:true,
    listOfDocuments: [],
    fetching: false,
};

export const searchDocumentReducerInit = initialState =>{
    return initialState;
}

export const tableReducer = (state, action) => {
    switch (action.type){
        case "API_BEGIN":
            return {
                ...state,
                listOfDocuments: [],
                fetching: true,
            }

        case "API_RESPONSE": 
            return {
                ...state,
                initialize:false,
                listOfDocuments: action.payload?.data,
                fetching: false
            }
        case "FAILURE_API_RESPONSE":
            return SEARCH_DOCUMENTS_STATE;
        default:
            break;
    }
}
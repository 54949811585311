import {
    addExceptionAndFilterTableAttributes,
    AmountCellRenderer,
    LinkCellRenderer,
    searchDefinitions
} from "../../common/adjustment/constants/transactionLevelUtils";

export const columnDefinitions = [
    { headerName: '', field: 'checkBox', checkboxSelection: true, headerCheckboxSelection: true, headerCheckboxSelectionFilteredOnly: true, hide: false, minWidth: 54, filter: false, width: 54 },
    { headerName: 'Transaction ID', field: 'hashKey', enableRowGroup: true, hide: true },
    { headerName: 'Order ID', field: 'orderId', hide: false, cellRenderer: LinkCellRenderer },
    { headerName: 'Order Date', field: 'orderDate', hide: false, sortable: false, valueGetter: params => {
        if (params.data === undefined || params.data.orderDate === null || params.data.orderDate === undefined) return '';
        return (new Date(params.data.orderDate)).toLocaleDateString();
    } },
    { headerName: 'Source Type', field: 'sourceType', hide: false, enableRowGroup: true },
    { headerName: 'Net Sales Amount', field: 'netSalesAmount', hide: false, cellRenderer: AmountCellRenderer },
    { headerName: 'Net Tax Amount ', field: 'netTaxAmount', hide: false, cellRenderer: AmountCellRenderer },
    { headerName: 'Total Amount Base Currency', field: 'totalAmountBaseCurrency', hide: false, cellRenderer: AmountCellRenderer },
    { headerName: 'Base Currency Code', field: 'baseCurrencyCode' },
    { headerName: 'Currency Conversion Rate', field: 'currencyConversionRate', hide: false },
    { headerName: 'Transaction Category', field: 'transactionCategory', hide: false, enableRowGroup: true },
    { headerName: 'Transaction Type', field: 'transactionType', hide: false, enableRowGroup: true },
    { headerName: 'City', field: 'city', hide: false, editable: true },
    { headerName: 'LegalName', field: 'legalName', hide: false, editable: true },
    { headerName: 'LastName', field: 'lastName', hide: false, editable: true },
    { headerName: 'Address Line 1', field: 'adressLine1', hide: false, editable: true },
    { headerName: 'Address Line 2', field: 'adressLine2', hide: false, editable: true },
    { headerName: 'Date Of Birth', field: 'dateOfBirth', hide: false, editable: true },
    { headerName: 'TIN', field: 'taxRegistrationNumber', hide: false, editable: true },
    { headerName: 'City Of Birth', field: 'cityOfBirth', hide: false, editable: true },
    { headerName: 'Country Of Birth', field: 'countryOfBirth', hide: false, editable: true },
    { headerName: 'Ship From Country', field: 'shipFromCountry', hide: false, enableRowGroup: true },
    { headerName: 'Ship To Country', field: 'shipToCountry', hide: false, enableRowGroup: true },
    {headerName: 'Unresolved exceptions', field: 'unresolvedExceptions', hide: false},
    {headerName: 'Corrected exceptions', field: 'correctedExceptions', hide: false},
    {headerName: 'Confirmed exceptions', field: 'confirmedExceptions', hide: false},
    {headerName: 'Applied filters', field: 'appliedFilters', hide: false},
    {headerName: 'Entity Type', field: 'entityType', hide: false, editable: true},
    
    { headerName: 'Total Amount', field: 'totalAmount', cellRenderer: AmountCellRenderer },
    { headerName: 'Net Giftwrap Amount', field: 'netGiftwrapAmount', cellRenderer: AmountCellRenderer },
    { headerName: 'Net Shipping Amount', field: 'netShippingAmount', cellRenderer: AmountCellRenderer },
    { headerName: 'Total Discount Amount', field: 'totalDiscountAmount', cellRenderer: AmountCellRenderer },
    { headerName: 'ASIN', field: 'asin' },
    { headerName: 'Country Code', field: 'countryCode', enableRowGroup: true, editable: true },
    { headerName: 'Customer Shipment Item ID', field: 'customerShipmentItemId' },
    { headerName: 'Encrypted Merchant ID', field: 'encryptedMerchantId' },
    { headerName: 'Marketplace ID', field: 'marketplaceId' },
    { headerName: 'Merchant ID', field: 'merchantId' },
    { headerName: 'Order Item ID', field: 'orderItemId' },
    { headerName: 'Quantity', field: 'quantity' },
    { headerName: 'Reversal ID', field: 'reversalId' },
    { headerName: 'Ship Date', field: 'shipDate' },
    { headerName: 'Shipment ID', field: 'shipmentId' },
    { headerName: 'Exception Key', field: 'exceptionKey' },
    {
        headerName: 'Draft Changes (Y/N)',
        field: 'draftChanges',
        editable: false,
        valueGetter: params => {
            if (params.data === undefined) return 'N';
            return params.data.draftChanges === undefined ? 'N' : params.data.draftChanges;
        },
    }
];

export const defaultColumnDefinitions = {
    sortable: true,
    minWidth: 200,
    defaultMinWidth: 200,
    resizable: true,
    floatingFilter: true,
    type: 'editableColumn',
    enablePivot: true,
    editable: false,
    filter: true,
    hide: true,
};

export const searchDefinitionForDS = () => {
    const unsupportedSearchFields = ['checkBox', 'draftChanges', 'orderDate', 'netSalesAmount', 'encryptedMerchantId',
        'netTaxAmount', 'totalAmountBaseCurrency', 'totalAmount', 'netGiftwrapAmount', 'netShippingAmount',
       'quantity', 'currencyConversionRate'];
    const definitions = searchDefinitions(columnDefinitions, unsupportedSearchFields);
    addExceptionAndFilterTableAttributes(definitions);
    return definitions;
}
import {validateEightDigitInteger, validateInteger, validateNotNull} from "../utils/validationsUtil";

export const HIGHLIGHTED_BOX_MAP = {
    'G9.2': true,
    'G20': true,
    'Net GST': true,
};
export const TRANSACTION_CONTAINER_DOM_ID = "transactions-container";
export const INPUT_STATE = {
    VALUE: "value",
    IS_INVALID: "isInvalid",
    ERROR_MESSAGE: "errorMessage"
}
export const INPUT_FIELD = {
    ATTRIBUTE: "attribute",
    INPUT_VALUE: "inputValue",
    REASON: "reason"
};
export const SELECT_ATTRIBUTE = "Select attribute";
export const ADJUSTMENT_ATTRIBUTES = {
    COMMODITY_CODE: 'Commodity code',
    DESCRIPTION_OF_GOODS: 'Description of goods'
};
export const GET_VALIDATIONS_FOR_ATTRIBUTE = (attributeName) => {
    switch (attributeName) {
        case SELECT_ATTRIBUTE:
            return {
                [INPUT_FIELD.ATTRIBUTE]: [validateNotNull],
                [INPUT_FIELD.INPUT_VALUE]: [validateNotNull],
                [INPUT_FIELD.REASON]: [validateNotNull]
            };
        case ADJUSTMENT_ATTRIBUTES.COMMODITY_CODE:
            return {
                [INPUT_FIELD.ATTRIBUTE]: [validateNotNull],
                [INPUT_FIELD.INPUT_VALUE]: [validateNotNull, validateInteger, validateEightDigitInteger],
                [INPUT_FIELD.REASON]: [validateNotNull]
            };
        case ADJUSTMENT_ATTRIBUTES.DESCRIPTION_OF_GOODS:
            return {
                [INPUT_FIELD.ATTRIBUTE]: [validateNotNull],
                [INPUT_FIELD.INPUT_VALUE]: [validateNotNull],
                [INPUT_FIELD.REASON]: [validateNotNull]
            };
        default:
            return null;
    }
};

import { Button, Link } from "@amzn/awsui-components-react";
import {
    createSlackChannel,
    getApprovalDeadlineConfig,
    getReviewDeadlineConfig,
    handleAddUserSlackClick,
    handleObligationClick
} from "../../Constants/Constants";
import { formatStatus, statusColorCodes } from "../../../../common/utils/commonUtils";
import { formatDate } from "../../../../common/utils/apiUtils";
import { slackIconSvg } from "../../../../common/icons/icons8-slack";
import { addIconSvg } from "../../../../common/icons/icons8-add-24";

export const getColumnDefinitions = (navigate, createChannelSlackStatusDispatcher, addUserSlackStatusDispatcher, listChannelsSlackData, listChannelsSlackDataDispatcher) => {
    return [
        {
            id: 'obligation',
            header: 'Obligation',
            cell: item => {
                return (
                    <Link onFollow={() => handleObligationClick(item, navigate, listChannelsSlackData, true)}>{item.obligationName}</Link>
                )
            },
            minWidth: '160px'
        },
        {
            id: 'country',
            header: 'Country',
            cell: item => item.country,
            minWidth: '30px'
        },
        {
            id: 'entity',
            cell: item => item.entity,
            header: 'Entity',
            sortingField: "entity",
            minWidth: '80px'
        },
        {
            id: 'period',
            cell: item => item.reportingPeriod,
            header: 'Period',
            sortingField: "reportingPeriod",
            minWidth: '40px'
        },
        {
            id: 'status',
            header: 'Status',
            cell: item => {
                const statusValue = formatStatus(item.status)
                return (<div style={statusColorCodes(statusValue)}>{statusValue}</div>)
            },
            sortingField: "status",
            minWidth: '60px'
        },
        {
            id: 'reviewDeadline',
            header: 'Review Deadline',
            cell: item => {
                const config = getReviewDeadlineConfig(item);
                return (<div style={{color: config.color}}>{config.icon} {formatDate(item.reviewDeadline)}</div>)
            },
            sortingField: "reviewDeadLine",
            minWidth: '80px'
        },
        {
            id: 'approvalDeadline',
            header: 'Approval Deadline',
            cell: item => {
                const config = getApprovalDeadlineConfig(item);
                return (<div style={{color: config.color}}>{config.icon} {formatDate(item.approvalDeadline)}</div>)
            },
            sortingField: "reviewDeadLine",
            minWidth: '80px'
        },
        {
            id: 'correspondence',
            header: 'Correspondence',
            cell: item => {
                return (
                    <div className='correspondence-box'>
                        <Button iconSvg={slackIconSvg} variant="inline-icon"
                                onClick={() => createSlackChannel(item, createChannelSlackStatusDispatcher, listChannelsSlackDataDispatcher, true)}/>
                        <Button iconSvg={addIconSvg} variant="inline-icon"
                                onClick={()=> handleAddUserSlackClick(item, addUserSlackStatusDispatcher, true)}/>
                    </div>
                )
            },
            minWidth: '64px'
        }
    ];
};
import React from "react";
import { Container, Header, ColumnLayout } from "@amzn/awsui-components-react/polaris";

import { jsonToHtmlTransformer } from "../transformer/JsonToHtmlTranformer.js";

function ExcelPreviewBlock(params) {

    const { blockCaption, columnSize, config } = params;

    return (
        <>
            <Container header={<Header variant="h2">{blockCaption}</Header>}>
                <ColumnLayout columns={columnSize}>
                    {jsonToHtmlTransformer(config)}           
                </ColumnLayout>
            </Container>
        </>
    );
}

export default ExcelPreviewBlock;
import React, {useState} from "react";
import {Box, Button, Container, SpaceBetween, Header} from "@amzn/awsui-components-react";
import {formatDate} from "../../../../../common/utils/apiUtils";
import {convertToInternationalCurrencySystem} from "../../../../../common/utils/commonUtils";
import {getUserAlias} from "../utils/utils-v1";
import {modifyWorkflowStatus} from "../../redux/exceptionAPIs";

export default function Summary(props) {
    const {exceptionData, isUnderReview, stepsState, setStepsStateHandler, updateWorkflowStatusDispatcher, parentWorkflowId} = props;

    const isCompleteButtonDisabled = () => {
        return !(isUnderReview && !stepsState.summaryStep.completed);
    };

    const [completeButtonState, setCompleteButtonState] = useState(false);

    const completeButtonClickHandler = async () => {
        setCompleteButtonState(true);
        const userAlias = await getUserAlias();
        const request = {
            parentWorkflowId: parentWorkflowId,
            acknowledgementStructureMap: {
                "SUMMARY": { status:"COMPLETE", user : userAlias }
            },
        };
        const onSuccess = () => {
            setStepsStateHandler("summaryStep");
        };
        modifyWorkflowStatus(updateWorkflowStatusDispatcher, request, onSuccess);
        setCompleteButtonState(false);
    };

    return (
        <SpaceBetween size='xl'>
            <Container
                header={
                    <Header actions={
                        <Button variant='primary'
                                onClick={completeButtonClickHandler}
                                disabled={isCompleteButtonDisabled() || completeButtonState}
                        >Complete</Button>
                    }>
                        <Box className="box">
                            <h2>Summary</h2><p className="box-p">(as of {formatDate(exceptionData?.lastUpdateDate)})</p>
                        </Box>
                    </Header>
                }>
                <div>
                    <div className="container">
                        <div className="summary-box">
                            <h3>Total Transactions</h3>
                            <p>{convertToInternationalCurrencySystem(exceptionData?.totalTransactions, 0)}</p>
                        </div>
                        <div className="summary-box">
                            <h3>Error Transactions</h3>
                            <p>{convertToInternationalCurrencySystem(exceptionData?.errorTransactions, 0)}</p>
                        </div>
                        <div className="summary-box">
                            <h3>Reportable Transactions</h3>
                            <p>{convertToInternationalCurrencySystem(exceptionData?.reportableTransactions, 0)}</p>
                        </div>
                        <div className="summary-box">
                            <h3>Non-reportable Transactions</h3>
                            <p>{convertToInternationalCurrencySystem(exceptionData?.nonReportableTransactions, 0)}</p>
                        </div>
                        <div className="summary-box">
                            <h3>Unique Seller Count</h3>
                            <p>{convertToInternationalCurrencySystem(exceptionData?.reportableUniqueSellerCount, 0)}</p>
                        </div>
                    </div>
                    <div className="container">
                        <div className="summary-box">
                            <h3>Total Amount</h3>
                            <p>{exceptionData?.currencySymbol} {convertToInternationalCurrencySystem(exceptionData?.totalAmount)}</p>
                        </div>
                        <div className="summary-box">
                            <h3>Error Amount</h3>
                            <p>{exceptionData?.currencySymbol} {convertToInternationalCurrencySystem(exceptionData?.errorAmount)}</p>
                        </div>
                        <div className="summary-box">
                            <h3>Reportable Amount</h3>
                            <p>{exceptionData?.currencySymbol} {convertToInternationalCurrencySystem(exceptionData?.reportableAmount)}</p>
                        </div>
                        <div className="summary-box">
                            <h3>Non-reportable Amount</h3>
                            <p>{exceptionData?.currencySymbol} {convertToInternationalCurrencySystem(exceptionData?.nonReportableAmount)}</p>
                        </div>
                    </div>
                </div>
            </Container>
        </SpaceBetween>
    );
}
export const faqData = [{
    ques: "Who can use Phastos?", ans: `For organizations responsible for indirect tax filling compliance can use Phastos to make tax compliance\
easy. The software manages 3 different user roles \n(1) administrator – responsible for configuring the tool\
for type of reports to be pulled, (2) tax professional – responsible for preparing and reviewing the tax reports\
that needs to be filed, and (3) approvers or signatories – responsible for ensuring reports are filed correctly`},
{ ques: "How do I get started?", ans: `Tax professionals can visit and register at www.amazonphastos.com to get started` },
{
    ques: "What are the out-of-box reports supported by Phastos?", ans: `As of 01/01/2023, Phastos support 3 reports for Amazon (1) Intrastat reporting for all 27 EU states\
, (2) Shared Economy reports for all 11 EU states, and (3) VAT reporting for all 27 EU states. In 2023, Phastos is expected to report for DAC7 and DST regulations in EU`},
{
    ques: "What kind of data is pre-configured in Phastos?", ans: `Phastos handles following type of data 1.Sale of Goods and Services i.e., overall transactions of goods and services from one party to another\
where VAT is applied, as applicable 2.Logistics movement data i.e., any move of goods for retail customers, intra fulfilment center\
movements, inventory purchases for retail or corporate purchases.3.Personal data of the sellers using Amazon platform i.e., sellers legal name, permanent establishments,\
bank accounts, etc 4.Accounting data i.e., expenses booked in the general ledger to be used for reconciliation purpose`},
{
    ques: "For what all EU states Phastos has pre-defined connectors for filling?", ans: [
        {
            col1: "1.", col2: "Intrastat Reporting", col3: `Logistics movement of Goods(Arrivals and Dispatch)`, col4: `Austria, Belgium, Bulgaria, Croatia, Cyprus, Czech Republic,\
 Denmark, Estonia, Finland, France, Germany, Greece, Hungary, Ireland, Italy, Latvia, Lithuania, Luxembourg, Malta, Netherlands, Poland, Portugal, Romania, Slovakia, Slovenia, Spain, Sweden, UK`},
        {
            col1: "2.", col2: "ESS Reporting", col3: "Sale of Electronic Goods and Services", col4: `Austria, Belgium, Bulgaria, Croatia, Cyprus, Czech Republic,\
    Denmark, Estonia, Finland, France, Germany, Greece, Hungary, Ireland, Latvia, Lithuania, Luxembourg, Malta,\
    Netherlands, Poland, Portugal, Slovakia, Slovenia, Sweden, UK` },
        { col1: "3.", col2: "Data Sharing Reporting", col3: "Sale of Goods", col4: "France, Australia" },
        { col1: "4.", col2: "VAT Reporting", col3: "Sale of Goods and Services", col4: "Luxembourg" }], type: "table"
},
{
    ques: "What all file formats are supported by Phastos for filling", ans: `Currently Phastos supports filing of reports in XML and CSV format as required by most of the legislations\
in EU`},
{
    ques: "What is the process to onboard new report filing on Phastos platform?",
    ans: `New users need register themselves on the Phastos platform www.amazonphastos.com. Once registered,\
they will get access to Report Configuration Module where they will provide required information of the\
reporting like 1) Details of the data elements needed for filing. 2) Details of the reporting legislation. 3)\
Dates and Frequency of the filing. 4) Schema Definitions 5) Aggregation and other enrichment rules per the\
use case etc. A user-friendly interface will guide the users thorough different screens to collect this\
information for the reporting requirement`},
{
    ques: "Do I get to access the reports generated in the past via this platform?", ans: `Reports prepared, reviewed and filed through Phastos are available for download for auditability purpose\
through its UI.`}]
import {downloadFolder, getS3Bucket} from "../../../common/utils/s3Utils";
import { API } from "aws-amplify";
import { getAPIInputContext } from "../../../common/utils/apiUtils";
import {
    FILING_AUTHENTICATE_CODE,
    FILING_CREATE_FILE,
    FILING_TRANSMIT_FILE,
    FILING_TRANSMIT_FILE_ASYNC,
    FILING_FETCH_REPORTING_PERIOD,
    FILING_GET_TRANSMISSION_RECEIPT
} from "../../../ApiPaths";
import { CLIENT_NAME } from "../../../common/constants/constants";
import {getHMRCHeaders, getTaxIdentifier, setTaxIdentifier} from "../utils/headers";

/**
 * This function will validate auth code, necessary to make further api calls to tax authority
 * @param filingDispatcher
 */
export function authenticateEFilerClient(filingDispatcher, obj) {
    getAPIInputContext().then(async input => {
        input.body = {
            ...input.body,
            ...obj
        }
        setTaxIdentifier()
        input.body.additionalDataMap = await enrichDataMap(input.body.additionalDataMap)
        API.post("TWIN_API", FILING_AUTHENTICATE_CODE, input).then(resp => {
            console.log(FILING_AUTHENTICATE_CODE + " API was called, response : " + resp);
            if (resp.status === 200) {
                filingDispatcher({
                    type: "AUTH_UPDATE", payload: {
                        isUserAuthenticatedWithTaxAuthority: "SUCCESS"
                    }
                })
                const now = new Date();
                const item = {
                    value: true,
                    expiry: now.getTime() + 12600000, //Auth code is valid for 3.5hrs
                }
                localStorage.setItem("IS_AUTHENTICATED", JSON.stringify(item))
            } else {
                console.log(FILING_AUTHENTICATE_CODE + " API Error Response : " + JSON.stringify(resp));
                filingDispatcher({
                    type: "AUTH_UPDATE", payload: {
                        isUserAuthenticatedWithTaxAuthority: "FAILED"
                    }
                })
            }
        });
    });
}

/**
 * This function will fetch list of reporting period
 * @param filingDispatcher
 */
export function fetchReportingPeriod(filingDispatcher, obj) {

    getAPIInputContext().then(async input => {
        input.body = {
            ...input.body,
            ...obj
        }
        input.body.additionalDataMap = await enrichDataMap(input.body.additionalDataMap)

        API.post("TWIN_API", FILING_FETCH_REPORTING_PERIOD, input).then(resp => {
            console.log(FILING_FETCH_REPORTING_PERIOD + " API was called, response : " + JSON.stringify(resp));
            if (resp.status === 200) {
                if(resp.body.responseCode === "FAILURE"){
                    filingDispatcher({
                        type: "SET_FLASH_BAR", payload: {
                            msg: resp.body.reasonCode,
                            type: 'error',
                            dispatcher: filingDispatcher,
                            handleFlashBarAction : () => {
                                filingDispatcher({ type: "DISMISS_FLASHBAR", payload: {} })
                            }   
                        }
                    })
                }else{
                    filingDispatcher({
                        type: "SET_REPORTING_PERIOD", payload: resp.body
                    })
                }
            } else {
                console.log(FILING_FETCH_REPORTING_PERIOD + " API Error Response : " + JSON.stringify(resp));
                filingDispatcher({
                    type: "FAILURE_API_RESPONSE", payload: {}
                })
            }
        }, (err) => {
            console.log(FILING_FETCH_REPORTING_PERIOD + " API Error Response : " + err);
            filingDispatcher({
                type: "FAILURE_API_RESPONSE", payload: {
                }
            })
        });
    });
}

/**
 * This function will generate access restricted folder path for the client
 * @param filingDispatcher
 */
export function createFilePath(filingDispatcher, useCase, subUseCase) {

    getAPIInputContext().then(input => {
        input.body.useCase = useCase;
        input.body.subUseCase = subUseCase;
        input.body.clientName = CLIENT_NAME;


        API.post("TWIN_API", FILING_CREATE_FILE, input).then(resp => {
            console.log(FILING_CREATE_FILE + " API was called, response : " + resp);
            if (resp.status === 200) {
                filingDispatcher({
                    type: "CREATE_FILE_PATH_SUCCESS", payload: {
                        data: resp.body,
                    }
                })
            } else {
                console.log(FILING_CREATE_FILE + " API Error Response : " + JSON.stringify(resp));
                filingDispatcher({
                    type: "FAILURE_API_RESPONSE", payload: {}
                })
            }
        }, () => {
            filingDispatcher({
                type: "FAILURE_API_RESPONSE", payload: {}
            })
        });
    });
}

/**
 * This function will notify the server to submit the uploaded file on behalf of the client
 * @param filingDispatcher
 */
export function submitFilingRequest(filingDispatcher, obj, handleFlashBarAction) {
    getAPIInputContext().then(async input => {
        input.body = {
            ...input.body,
            ...obj,
            'requestor': input.body?.user.split("_")[1]
        }
        input.body.additionalDataMap = await enrichDataMap(input.body.additionalDataMap)
        API.post("TWIN_API", FILING_TRANSMIT_FILE, input).then(resp => {
            console.log(FILING_TRANSMIT_FILE + " API was called, response : " + resp);
            if (resp.status === 200) {
                const auditData = JSON.parse(resp.auditData)
                const msg = auditData?.status === "SUCCESS" ? `You have successfully submitted file to tax authority with Form Bundle Number - ${auditData?.additionalDataMap?.formBundleNumber}`
                    : `Request submission failed, please try after sometime`
                filingDispatcher({
                    type: "TRANSMIT_FILE_REQUEST_COMPLETED", payload: {
                        dispatcher: filingDispatcher,
                        fetchAudit: true
                    }
                })
                filingDispatcher({ type: "SET_FLASH_BAR", payload : {msg,  dispatcher: filingDispatcher, type: auditData?.status === "SUCCESS" ? "success" : "error", handleFlashBarAction}});
            } else {
                const msg = `Request submission failed, please try after sometime`;
                console.log(FILING_TRANSMIT_FILE + " API Error Response : " + JSON.stringify(resp));
                filingDispatcher({
                    type: "TRANSMIT_FILE_REQUEST_COMPLETED", payload: {
                        status: "FAILURE",
                        dispatcher: filingDispatcher
                    }
                })
                filingDispatcher({ type: "SET_FLASH_BAR", payload : {msg, dispatcher: filingDispatcher, type: "error", handleFlashBarAction}});
            }
        }, () => {
            const msg = `Request submission failed, please try after sometime`;
            filingDispatcher({
                type: "TRANSMIT_FILE_REQUEST_COMPLETED", payload: {
                    status: "FAILURE",
                    dispatcher: filingDispatcher
                }
            })
            filingDispatcher({ type: "SET_FLASH_BAR", payload : {msg,  dispatcher: filingDispatcher, type: "error", handleFlashBarAction}});
        });
    });
    handleFlashBarAction();
}

/**
 * This function will notify the server to submit the uploaded file on behalf of the client in Async mode
 * @param filingDispatcher
 */
export function submitFilingRequestAsync(filingDispatcher, obj, handleFlashBarAction) {
    getAPIInputContext().then(async input => {
        input.body = {
            ...input.body,
            ...obj,
            'requestor': input.body?.user.split("_")[1]
        }
        input.body.additionalDataMap = await enrichDataMap(input.body.additionalDataMap)
        API.post("TWIN_API", FILING_TRANSMIT_FILE_ASYNC, input).then(resp => {
            console.log(FILING_TRANSMIT_FILE_ASYNC + " API was called, response : " + resp);
            if (resp.status === 200) {
                const transactionId = resp.transactionId
                const msg = transactionId ? `Your request is under progress with Taskless TransactionId - ${transactionId}`
                    : `Request submission failed, please try after sometime`
                
                    filingDispatcher({
                        type: "TRANSMIT_FILE_REQUEST_COMPLETED", payload: {
                            dispatcher: filingDispatcher,
                            fetchAudit: true
                        }
                    })
                    filingDispatcher({ type: "SET_FLASH_BAR", payload : {msg,  dispatcher: filingDispatcher, type: transactionId ? "success" : "error", handleFlashBarAction}});
            } else {
                const msg = `Request submission failed, please try after sometime`;
                console.log(FILING_TRANSMIT_FILE + " API Error Response : " + JSON.stringify(resp));
                filingDispatcher({
                    type: "TRANSMIT_FILE_REQUEST_COMPLETED", payload: {
                        status: "FAILURE",
                        dispatcher: filingDispatcher
                    }
                })
                filingDispatcher({ type: "SET_FLASH_BAR", payload : {msg, dispatcher: filingDispatcher, type: "error", handleFlashBarAction}});
            }
        }, () => {
            const msg = `Request submission failed, please try after sometime`;
            filingDispatcher({
                type: "TRANSMIT_FILE_REQUEST_COMPLETED", payload: {
                    status: "FAILURE",
                    dispatcher: filingDispatcher
                }
            })
            filingDispatcher({ type: "SET_FLASH_BAR", payload : {msg,  dispatcher: filingDispatcher, type: "error", handleFlashBarAction}});
        });
    });

    handleFlashBarAction();
}


/**
 * This function will generate access restricted folder path for the client
 * @param filingDispatcher
 */
export function getFilingReceipt(filingDispatcher, useCase, subUseCase, transactionId, domain, setLoading) {

    setLoading(true)
    getAPIInputContext().then(async input => {
        input.body.useCase = useCase;
        input.body.subUseCase = subUseCase;
        input.body.transactionId = transactionId;
        input.body.clientName = CLIENT_NAME;
        input.body.additionalDataMap = await getHMRCHeaders();


        API.post("TWIN_API", FILING_GET_TRANSMISSION_RECEIPT, input).then(resp => {
            console.log(FILING_GET_TRANSMISSION_RECEIPT + " API was called, response : " + resp);
            if (resp.status === 200 && resp.body.responseCode === "SUCCESS") {
                downloadFolder(resp.body.folderPath, resp.body.s3Bucket, domain)
            } else {
                console.log(FILING_GET_TRANSMISSION_RECEIPT + " API Error Response : " + JSON.stringify(resp));
                filingDispatcher({
                    type: "FAILURE_API_RESPONSE", payload: {}
                })
            }
            setLoading(false)
        }, () => {
            filingDispatcher({
                type: "FAILURE_API_RESPONSE", payload: {}
            })
            setLoading(false)
        });
    });
}


const enrichDataMap = async (dataMap) => {
    const headers = await getHMRCHeaders();
    return {
        ...dataMap,
        ...headers,
        taxIdentifier: getTaxIdentifier(),
    }
}


export const uploadFile = (file, bucket, key, setUploading, domain, submitFilingRequest, dispatcher, obj, handleFlashBarAction) => {

    setUploading(true);
    //the upload folder depends on the use case so the key for the params:
    //for a use case FR, Key: FR/FR_fileName
    console.log("========uploading template=====");

    const params = {
        Bucket: bucket,
        Key: `${key}/${file.name}`,
        Body: file
    };

    const s3bucket = getS3Bucket(domain);
    s3bucket.upload(params, function (err, data) {
        if (err) {
            console.log("File could not be uploaded as" + err);
            throw err;
        }
        console.log(`File uploaded successfully at ${data.Location}`);
        submitFilingRequest(dispatcher, obj, handleFlashBarAction);
        setUploading(false);
    })
}


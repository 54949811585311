export const EXCEPTION_INITIAL_STATE = {
    initialize:true,
    summaryData: {},
    data: [],
    flashBarItem: [],
    requestId: ''
};

export const exceptionReducerInit = initialState =>{
    return initialState;
};

const getFlashBar = (type, msg, id, action) => {
    return [{
        type: type,
        dismissible: true,
        dismissLabel: "Dismiss message",
        onDismiss: () => {
            action.payload?.dispatcher({ type:"DISMISS_FLASHBAR", payload:{} })
        },
        content: (
            <>
                {msg}
            </>
        ),
        id: id,
    }]
};

export const exceptionReducer = (state, action) => {
    switch (action.type){
        case "SET_EXCEPTION_DATA":
                return {
                    ...state,
                    data: action.payload?.data
                }
        case "SET_REQUEST_ID":
                return {
                    ...state,
                    requestId: action.payload?.data?.requestId
                }
        case "GENERATE_PDF_SUCCESS":
                const pdfSuccessMessage = "PDF Generation in progress";
                return {
                    ...state,
                    requestId: action.payload?.data?.requestId,
                    flashBarItem: [getFlashBar("success", pdfSuccessMessage, action, "message_1")]
                }
        case "GENERATE_PDF_FAILURE":
                const pdfFailureMessage = "Something went wrong, please try again later";
                return {
                    ...state,
                    flashBarItem: [getFlashBar("error", pdfFailureMessage, action, "message_1")]
                }
        case "INITIATE_CUSTOMER_ENGAGEMENT_SUCCESS":
                const ceSuccessMessage = "Notification Triggered";
                return  {
                    ...state,
                    flashBarItem: [getFlashBar("success", ceSuccessMessage, action, "message_1")]
                }
        case "INITIATE_CUSTOMER_ENGAGEMENT_FAILURE":
                const ceFailureMessage = "Something went wrong, please try again later";
                return  {
                    ...state,
                    flashBarItem: [getFlashBar("error", ceFailureMessage, action, "message_1")]
                } 
        case "DISMISS_FLASHBAR":
                return {
                    ...state,
                    flashBarItem: []
                }               
        default:
            break;
    }
};
const AWS = require('aws-sdk');

export const downloadFile = (s3Key, s3BucketName, domain, callback) => {
    const s3bucket = getS3Bucket(domain);
    const params = {
        Bucket: s3BucketName,
        Key: s3Key
    }
    console.log("Params for s3 File to be fetched :", params);
    s3bucket.getObject(params, (err, data) => {
        if (data != null) {
            let file;
            file = new Blob([data.Body]);

            const downloadLink = document.createElement("a");

            downloadLink.download = params.Key;
            downloadLink.href = URL.createObjectURL(file);
            downloadLink.style.display = "none";

            document.body.appendChild(downloadLink);
            downloadLink.click();
            if (callback) {
                callback()
            }
        } else if (err)
            console.log("Error occurred downloading the file from s3" + err)
            if (callback) {
                callback()
            }
    });
}

export const downloadFileFromPresignedURL = (presignedURL) => {
    console.log("Downloading file from persigned URL: ", presignedURL);
    const downloadLink = document.createElement("a");

    downloadLink.href = presignedURL;
    downloadLink.style.display = "none";

    document.body.appendChild(downloadLink);
    downloadLink.click();

    document.body.removeChild(downloadLink);
}

export const downloadFolder = async (s3Key, s3BucketName, domain) => {
    const s3bucket = getS3Bucket(domain);
    var listObjectsRequest = {
        Bucket: s3BucketName,
        Prefix: s3Key
    }
    const listObjectResponse = await s3bucket.listObjects(listObjectsRequest).promise()
    var fileNames = listObjectResponse.Contents
    const downloadElements = []
    for(const obj of fileNames) {
        const fileName = obj.Key
        if(fileName.endsWith("/")) { // ignore folders
            continue
        }
        const params = {
            Bucket: s3BucketName,
            Key: fileName
        }
        console.log("Params for s3 File to be fetched :", params);
        const getObjectResponse = await s3bucket.getObject(params).promise()
        if (getObjectResponse != null) {
            let file = new Blob([getObjectResponse.Body]);
            const downloadLink = document.createElement("a");
            downloadLink.download = params.Key;
            downloadLink.href = URL.createObjectURL(file);
            downloadLink.style.display = "none";
            downloadLink.download = fileName.substring(fileName.lastIndexOf('/') + 1); // Set file Name
            document.body.appendChild(downloadLink);
            downloadElements.push(downloadLink)
        } else
            console.log("Error occurred downloading the file from s3")
    }
    for(let element of downloadElements) {
        element.click();
    }
}

export const uploadFiles = async (files, bucket, key, domain) => {

    //the upload folder depends on the use case so the key for the params:
    //for a use case FR, Key: FR/FR_fileName
    const s3bucket = getS3Bucket(domain);
    for(let file of files) {
        const params = {
            Bucket: bucket,
            Key: `${key}/${file.name}`,
            Body: file
        };
        console.log("Uploading files: ", params)
        try {
            const data = await s3bucket.upload(params).promise();
            console.log(`File uploaded successfully at ${data.Location}`);
        } catch (err) {
            console.log("File could not be uploaded as" + err);
        }
    }
}

export function getS3Bucket() {
    return new AWS.S3({apiVersion: "2006-03-01"});
}

export const getPresignedURL = async (s3Key, s3BucketName, domain, expiryTime) => {
    const s3bucket = getS3Bucket(domain);
    const params = {
        Bucket: s3BucketName,
        Key: s3Key,
        Expires: expiryTime
    };
    console.log("Params for s3 File to fetch the presignedURL for:", params);
    const presignedURL = await s3bucket.getSignedUrlPromise('getObject', params);
    return presignedURL;
};
export let fetchCountry = (useCase) => {
    const countryMap = {
        FRANCE: "FRANCE",
        FRANCE_WITHHOLDING: "FRANCE_WITHHOLDING",
        NOR: "NORWAY",
        CZ: "CZECH_REPUBLIC",
        LUX: "LUXEMBOURG",
        CZ_VAT: "CZ_VAT",
        CZ_ESL: "CZ_ESL",
        CZ_VLS: "CZ_VLS",
        LUX_IOSS: "LUX_IOSS",
        LUX_UOSS: "LUX_UOSS",
        LUX_NUOSS: "LUX_NUOSS",
        DE: "GERMANY",
        DE_VAT: "DE_VAT",
        DE_VAT_TEST: "DE_VAT_TEST",
        PL: "POLAND",
        PL_CIT: "PL_CIT",
        PL_INTRASTAT: "PL_INTRASTAT",
        IT: "ITALY"
    }
    if (useCase in countryMap) {
        return countryMap[useCase]
    } else {
        return "Compliance"
    }
}

import { Auth } from "aws-amplify";

export async function getAccessTokenAndFederateUser() {
    const authenticatedUser = await Auth.currentAuthenticatedUser();
    return [authenticatedUser.signInUserSession.idToken.jwtToken, authenticatedUser.username];
}

export function getUserName(userInfo) {
    let userName = userInfo?.signInUserSession?.idToken?.payload?.name?.split(',')[1];
    let name = userName?.charAt(1).toUpperCase() + userName?.slice(2).toLowerCase();
    return name.trim();
}

export async function getAPIInputContext() {
    const [token, username] = await getAccessTokenAndFederateUser();
    return {
        headers: {
            "Authorization": token,
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            Accept: 'application/json',
        },
        body: {
            'user': username,
        }
    };
}

export function getIntermediateDeadline(filingDeadline) {
    return filingDeadline - 1296000000;//15 days prior
}


export function formatDate(value) {

    let date = new Date(Math.round(Number(value)));
    let day = ("0" + date.getDate()).slice(-2);
    let month = ("0" + (date.getMonth() + 1)).slice(-2);
    let formatDate = date.getFullYear() + "-" + (month) + "-" + (day);

    return formatDate;
}

export function formatDateWithTime(value) {
 
    let date = new Date(Math.round(Number(value)));
    let day = ("0" + date.getDate()).slice(-2);
    let month = ("0" + (date.getMonth() + 1)).slice(-2);
    let hours = date.getHours();
    let minutes = date.getMinutes();
    let seconds = date.getSeconds();
    let formatDate = date.getFullYear() + "-" + (month) + "-" + (day) + " " + hours + ":" + minutes + ":" + seconds;
 
    return formatDate;
}

export function numberWithCommas(x, y = 2) {
    if (x)
        return Number.parseFloat(x).toFixed(y).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
}
import React, { useState } from "react";
import {
    Container,
    ButtonDropdown,
    Header,
    SpaceBetween,
    Button
} from "@amzn/awsui-components-react";
import "../../css/errorSummary.css"
import { getUserAlias } from "../../utils/utils";
import { modifyWorkflowStatus } from "../redux/exceptionAPIs";
import { postMessage } from "../../../phastos/components/Slack/slackIntegrationAPIs";
import { ACKNOWLEDGEMENT_STRUCTURE_MAP_KEY, ASM_COMPLETE_STATUS } from "../common/constants";
import QuickSightFrame from "../common/QuickSightFrame";

export default function ViewDashboards(props) {
    const {
        exceptionData,
        setStepsStateHandler,
        updateWorkflowStatusDispatcher,
        isUnderReview,
        stepsState,
        parentWorkflowId,
        useCase,
        slackChannel
    } = props;

    const DASHBOARD = {
        SOURCE_DATA_SUMMARY: { identifier: "SOURCE_DATA_SUMMARY", name: "Source Data Summary" },
        AP_GL_RECON: { identifier: "AP_GL_RECON", name: "AP GL Recon" },
        AR_GL_RECON: { identifier: "AR_GL_RECON", name: "AR GL Recon" },
    };

    const [selectedDashboard, setSelectedDashboard] = useState(DASHBOARD.SOURCE_DATA_SUMMARY.identifier);


    const [completeButtonState, setCompleteButtonState] = useState(false);

    const completeButtonClickHandler = async () => {
        setCompleteButtonState(true);
        const userAlias = await getUserAlias();
        const request = {
            parentWorkflowId: parentWorkflowId,
            acknowledgementStructureMap: {
                [ACKNOWLEDGEMENT_STRUCTURE_MAP_KEY.Insight]: { status: ASM_COMPLETE_STATUS, user: userAlias }
            },
        };
        const onSuccess = () => {
            postMessage(slackChannel, `${userAlias}@ has acknowledged the Insights step.`);
            setStepsStateHandler("insightStep");
        };
        modifyWorkflowStatus(updateWorkflowStatusDispatcher, request, onSuccess);
        setCompleteButtonState(false);
    };

    const isCompleteButtonDisabled = () => {
        return !(isUnderReview && !stepsState.insightStep.completed);
    };

    return (
        <Container
            header={<Header actions={
                <SpaceBetween size="s" direction="horizontal">
                <ButtonDropdown
                    items={[
                        { text: DASHBOARD.SOURCE_DATA_SUMMARY.name, id: DASHBOARD.SOURCE_DATA_SUMMARY.identifier },
                        { text: DASHBOARD.AP_GL_RECON.name, id: DASHBOARD.AP_GL_RECON.identifier },
                        { text: DASHBOARD.AR_GL_RECON.name, id: DASHBOARD.AR_GL_RECON.identifier },
                    ]}
                    onItemClick={(event) => { setSelectedDashboard(event.detail.id); }}
                >
                    {DASHBOARD[selectedDashboard].name}
                </ButtonDropdown>
                    <Button onClick={completeButtonClickHandler}
                            disabled={isCompleteButtonDisabled() || completeButtonState}>Acknowledge</Button>
                </SpaceBetween>
            }></Header>}>
            <QuickSightFrame linkKey={selectedDashboard}
                             exceptionData={exceptionData}
                             useCase={useCase}/>
        </Container>
    );
}
export const PHASTOS_FETCH_OBLIGATIONS = "/phastos/fetch_obligations";
export const PHASTOS_FETCH_ALL_DROPDOWN_FIELDS = "/phastos/fetch_all_dropdown_fields";
export const PHASTOS_FETCH_ALL_BRAAVOS_REPORT_IDS = "/phastos/get_braavos_reportids";
export const PHASTOS_ERROR_TRANSACTION = "/phastos/fetch_error_transactions";
export const PHASTOS_CREATE_ADHOC_REQUEST = "/phastos/create_adhoc_request";
export const PHASTOS_CREATE_CONFIG_FILE_RCM = "/phastos/create_config_file_rcm";
export const PHASTOS_GENERATE_PDF_REPORT = "/phastos/generate_pdf_report";
export const PHASTOS_INITIATE_CUSTOMER_ENGAGEMENT = "/phastos/initiate_customer_engagement";
export const EXCALIBUR_FETCH_AUDIT = "/excalibur/fetch_audit";
export const EXCALIBUR_DOWNLOAD_DOCUMENT = "/excalibur/download_document";
export const EXCALIBUR_GENERATE_REPORT = "/excalibur/generate_report";
export const FILING_AUTHENTICATE_CODE = "/filing/authenticate_efiler_client";
export const FILING_FETCH_REPORTING_PERIOD = "/filing/fetch_reporting_period";
export const FILING_CREATE_FILE = "/filing/create_file_path";
export const FILING_GET_TRANSMISSION_RECEIPT = "/filing/get_transmission_receipt";
export const FILING_TRANSMIT_FILE = "/filing/submit_file_sync";
export const FILING_TRANSMIT_FILE_ASYNC = "/filing/submit_file_async";
export const IS_PAGE_AUTHORIZED = "/auth/is_page_authorized";
export const GET_PERSONA_LIST_FOR_GIVEN_ROLE = "/auth/get_persona_list_for_given_role";
export const GET_QUICKSIGHT_EMBED_URL = "/get_quicksight_embed_url";
export const PHASTOS_MODIFY_WORKFLOW_STATUS = "/phastos/modify_workflow_status_internal";
export const PHASTOS_GET_WORKFLOW_DETAILS  = "/phastos/get_workflow_details";
export const PHASTOS_GET_JOB_DETAILS = "/phastos/get_job_details";
export const PHASTOS_SEARCH_DOCUMENT_BY_METADATA = "/phastos/search_documents_with_metadata";
export const PHASTOS_GET_S3_PRESIGNED_URL = "/phastos/get_phastos_document_presigned_url";
export const PHASTOS_GET_UPLOADED_DOCUMENT_DETAILS = "/phastos/get_phastos_uploaded_document_details";
export const PHASTOS_GET_REPORTING_AGGREGATED_DATA = "/phastos/get_reporting_aggregated_data_from_db";
export const PHASTOS_GET_BOX_LEVEL_ADJUSTMENT_AUDIT = "/phastos/get_box_level_adjustment_audit_by_parent_workflow_id";
export const PHASTOS_PUT_BOX_LEVEL_ADJUSTMENT = "/phastos/put_box_level_adjustment";
export const PHASTOS_GET_TRANSACTION_DETAILS = "/phastos/get_transaction_details";
export const PHASTOS_GET_TRANSACTION_LEVEL_ADJUSTMENT_AUDIT = "/phastos/get_transaction_level_adjustment_audit_by_parent_workflow_id";
export const PHASTOS_ADJUST_EXCEPTION_TRANSACTIONS = "/phastos/adjust_exception_transactions";
export const PHASTOS_GET_AUDIT_PACK_DOWNLOAD_S3_URL = "/phastos/get_audit_pack_download_s3_url";
export const REGISTER_PHASTOS_UPLOADED_DOCUMENT = "/phastos/register_phastos_uploaded_document";
export const PHASTOS_GET_TRANSACTION_SUMMARY_DETAILS = "/phastos/get_transaction_summary_details";
export const PHASTOS_EXPORT_AGGREGATED_REPORT = "/phastos/export_aggregated_report";
export const PHASTOS_GET_DOWNLOAD_FILE_URL = "/phastos/get_download_file_url";
export const TAKE_ACTION_ON_WORKFLOW = "/phastos/take_action_on_workflow";

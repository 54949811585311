import {Container, Grid, Header} from "@amzn/awsui-components-react";
import React from "react";

export default function DSTInformation(props) {

    return (<Grid gridDefinition={[{colspan: 12}, {colspan: 6}, {colspan: 6}]}>
        <Container header={<Header>Commonly Used Terms</Header>}>
            <ol>
                <li><b>Legal entity</b> - A legal entity means a company, irrespective of its corporate form. Eg-
                    <ul>
                        <li><b>Amazon.com, Inc.</b> - a US entity</li>
                        <li><b>Amazon EU Sarl</b> - a Luxembourg limited liability company</li>
                        <li><b>Amazon Deutschland Services GmbH </b> - a German limited liability company</li>
                        <li><b>Amazon EU Sarl, UK branch </b> - a branch of a Luxembourg limited liability company</li>
                    </ul>
                </li>
                <li>
                   <b>Marketplace</b> - A Marketplace is a place, physical or otherwise, where merchants offer goods for sale, and customers accept offers, place orders and make purchases. It is the logical container for all data and attributes associated with an eCommerce site.
                    <a href ="https://w.amazon.com/bin/view/MarketplaceID/"> See More</a>
                </li>
                <li>
                    <b>MarketPlaceId</b> - The MarketplaceId is the globally unique identifier of a marketplace.
                    <a href = "https://w.amazon.com/bin/view/MarketplaceID/"> See More</a>
                </li>
                <li>
                    <b>Sub Business</b> - It is the sub category of a Business.
                </li>
                <li>
                    <b>Nexus Ratio</b> - The total number of transactions within a specific country for a particular business divided by the total number of transactions within that business
                </li>
                <li>
                    <b>Criteria for Nexus Ratio</b> - French eligible transactions are identified by using following criteria:
                    <ul>
                        <li>for customer (end-consumers)</li>
                        <li>Bill-to address – Country. Country identification based on billing address entered by the user either at account creation or later when modifying user information. (entered by user at account creation or later)</li>
                        <li>Ship-to-address – Country. Country identification based on the information where the individual parcel based on a specific order has been shipped to the customer.</li>
                        <li>Selling partners - Registered place of establishment of the Seller as displayed also on invoices to the Seller</li>
                    </ul>
                </li>
            </ol>
        </Container>

        <Container header={<Header>DST Rate Calculation</Header>}>
            As of 1 January 2019, a 3% digital tax applies to companies providing certain digital services in France with global annual revenue in excess of EUR 750 million and annual revenue in France in excess of EUR 25 million.
        </Container>

        <Container header={<Header>DST Eligible Criteria</Header>}>
            <p>Criteria for the Digital Services Tax (DST) in France (as of January 2022) included:</p>
            <ul>
                <li><b>Revenue Threshold</b>: Applied to companies with global revenues exceeding specific thresholds(EUR 750 million ), considering French digital revenues(annual revenue in France in excess of EUR 25 million).</li>
                <li><b>Digital Activities</b>: Targeted online advertising, digital marketplaces, and sale of user data.</li>
                <li><b>User Base</b>: Considered the number of users or customers in France.</li>
            </ul>
        </Container>
    </Grid>)
}
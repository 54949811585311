import React, { useState } from "react";
import { Container } from "@amzn/awsui-components-react/polaris";
import { getUserAlias } from "../../utils/utils";
import { Button, Header, SpaceBetween } from "@amzn/awsui-components-react";
import { modifyWorkflowStatus } from "../redux/exceptionAPIs";
import { ACKNOWLEDGEMENT_STRUCTURE_MAP_KEY, ASM_COMPLETE_STATUS } from "./constants";
import QuickSightFrame from "./QuickSightFrame";

export default function ExceptionTrends(props) {
    const { 
        exceptionData,
        isUnderReview,
        stepsState,
        setStepsStateHandler,
        updateWorkflowStatusDispatcher,
        parentWorkflowId,
        useCase,
        isVATReturn 
    } = props;

    const isCompleteButtonDisabled = () => {
        if (isVATReturn) { return true; }
        return !(
            isUnderReview 
            && stepsState.summaryStep.completed
            && stepsState.exceptionReviewStep.completed 
            && !stepsState.trendsStep.completed
        );
    };

    const [completeButtonState, setCompleteButtonState] = useState(false);

    const completeButtonClickHandler = async () => {
        setCompleteButtonState(true);
        const userAlias = await getUserAlias();
        const request = {
            parentWorkflowId: parentWorkflowId,
            acknowledgementStructureMap: {
                [ACKNOWLEDGEMENT_STRUCTURE_MAP_KEY.Trends]: { status: ASM_COMPLETE_STATUS, user: userAlias }
            },
        };
        const onSuccess = () => {
            setStepsStateHandler("trendsStep");
        };
        modifyWorkflowStatus(updateWorkflowStatusDispatcher, request, onSuccess);
        setCompleteButtonState(false);
    };

    return (
        <SpaceBetween>
            <Container header={
                <Header actions={
                    ( !isVATReturn &&
                        <Button variant='primary'
                                onClick={completeButtonClickHandler}
                                disabled={isCompleteButtonDisabled() || completeButtonState}
                        >Complete
                        </Button>
                    )
                }></Header>
            }>
                <QuickSightFrame linkKey={'Trends'}
                                 exceptionData={exceptionData}
                                 useCase={useCase}/>
            </Container>
        </SpaceBetween>
    )
}
import React from "react";
import { Box, Container, Grid, Header, SpaceBetween } from "@amzn/awsui-components-react";
import { get, map } from "lodash";
import { VAT_SUMMARY_METRICS } from "../../Constants/Constants";
import Link from "@amzn/awsui-components-react/polaris/link";

const ViewSummaryMetricsForVAT = (props) => {
    const {
        summaryDataTitle,
        changeStatusFilterValue,
        tableData
    } = props;

    return (
        <Container
            header={
                <Header
                    variant="h2"
                >
                    Summary - {summaryDataTitle}
                </Header>
            }>
            <Grid
                gridDefinition={[
                    {colspan: 2},
                    {colspan: 2},
                    {colspan: 2},
                    {colspan: 2},
                    {colspan: 2},
                ]}
            >
                { map(VAT_SUMMARY_METRICS, ({label, key, isOverdueShow, actualStatus, overdueKey}) => {
                    return (
                        <SpaceBetween key={key} size='xxxs'>
                            <Box color="text-label" fontWeight="bold">
                                {label}
                            </Box>
                            <div style={{display: "grid" }}>
                                <Link 
                                    style={{display: "inline-block"}}
                                    fontSize="display-l"
                                    onFollow={() => {changeStatusFilterValue(actualStatus)}}
                                >
                                    { get(tableData, `vatSummaryData.${key}`, 0) }
                                </Link>
                                { isOverdueShow ? 
                                    <div style={{display: "inline-block"}}>
                                        Overdue: {get(tableData, `vatSummaryData.${overdueKey}`)}
                                    </div> : ''
                                }
                            </div>
                        </SpaceBetween>
                    );
                })}
            </Grid>
        </Container>
    )
}

export default ViewSummaryMetricsForVAT;
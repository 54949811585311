export const DOWNLOAD_INITIAL_STATE = {
    initialize:true,
    flashBarItem: [],
    fetching: false,
};

export const downloadReducerInit = initialState =>{
    return initialState;
}

const getFlashBar = (type, msg, action) => {
    return [{
        type: type,
        dismissible: true,
        dismissLabel: "Dismiss message",
        onDismiss: () => {
            action.payload?.dispatcher({type:"DISMISS_FLASHBAR", payload:{}})
        },
        content: (
            <>
                {msg}
            </>
        ),
        id: "message_1"
    }]
};

export const downloadReducer = (state, action) => {
    switch (action.type){
        case "DOWNLOAD_DOCUMENT_SUCCESS":
                return {
                    ...state,
                    flashBarItem: getFlashBar("success", "Document downloaded successfully", action),
                }
        case "DOWNLOAD_DOCUMENT_FAILURE": 
                return {
                    ...state,
                    flashBarItem: getFlashBar("error", "Something went wrong, please try again later", action),
                }
        case "DISMISS_FLASHBAR":
                return {
                    ...state,
                    flashBarItem: []
                }               
        default:
            break;
    }
}
import React, { useState, useReducer, useEffect, useRef } from "react";
import "./css/Filing.css";
import Table from "../filing/components/FilingTable";
import { ButtonDropdown, Container, Header, Flashbar } from "@amzn/awsui-components-react/polaris";
import { fetchCountry } from "../filing/utils/Utils";
import { getDropdownItems, getFilingInstruction } from "../filing/utils/Utils"
import UKVATFiling from "../filing/useCases/UKVATFiling";
import { INITIAL_STATE, filingReducer, filingReducerInit } from "./utils/filingReducer";
import WebBasedFiling from "./useCases/WebBasedFiling";
import { useLocation } from 'react-router-dom';
import {handleFiling} from "./useCases/ExcaliburFiling";
import {FILING, UNDERSCORE} from "../../common/constants/constants";
import AppConfig from "../../AppConfig";
function Filing(params) {

    const [filingData, filingDispatcher] = useReducer(filingReducer,
        INITIAL_STATE, filingReducerInit);

    const [useCase, setUseCase] = useState({ useCaseValue: null , isSelected: false });

    const location = useLocation();
    const initialize = useRef(false);

    useEffect(() => {
        const useCase = sessionStorage.getItem("UseCase");
        if (useCase) {
            setUseCase({ useCaseValue: useCase, isSelected: true })
        }
        if(!initialize.current && location?.state?.data) {
            initialize.current = true;
            const passStateData = location?.state?.data;
            // TODO : will use react-router functionality to replace history after dive deep
            window.history.replaceState({},document.title);
            handleFilingFromExcalibur(passStateData);
        }
        // TODO will fix this after dive deep
        // eslint-disable-next-line
    }, [])

    const handleFilingFromExcalibur = (passedData) => {
        const usecase = passedData.useCase + UNDERSCORE + passedData.subUseCase + UNDERSCORE + FILING;
        switch (usecase) {
            case 'DE_VAT_FILING' :
                handleFiling(passedData, filingDispatcher, params, {
                    "FilingType": "BULK",
                    "CERTIFICATE_BUCKET_NAME": AppConfig[params.domain].CERTIFICATE_BUCKET_NAME,
                });
                break;
            case 'DE_VAT_TEST_FILING' :
                handleFiling(passedData, filingDispatcher, params, {
                    "FilingType": "BULK",
                    "CERTIFICATE_BUCKET_NAME": AppConfig[params.domain].CERTIFICATE_BUCKET_NAME,
                });
                break;
            case 'IT_E_INVOICE_FILING' :
                handleFiling(passedData, filingDispatcher, params, {
                    "FilingType": "BULK",
                })
                break
            default :
                return <></>
        }
    }
    const getUseCaseComponent = (usecase) => {
        switch(usecase){
            case 'UK_VAT_FILING' : return <UKVATFiling {...params} filingData={filingData} filingDispatcher={filingDispatcher} useCase={useCase}/>
            case 'IR_MOSS_FILING' : 
            case 'IS_VAT_FILING' :
            case 'DE_VAT_FILING' :
            case 'TR_DST_FILING' :
            case 'TR_VAT_FILING' :
            case 'SA_VAT_FILING' :
            case 'DE_INTRASTAT_FILING' : return <WebBasedFiling {...params} filingData={filingData} filingDispatcher={filingDispatcher} useCase={useCase}/>
            default : return <></>
        }
    }

    return (
        <>
            <div className="filing">
                <Flashbar items={filingData?.flashBarItem} />
                <Header variant="h1" className="header">E-File</Header>
                <Container header={<h2>Start E-Filing</h2>}>
                    <div id="buttonGroup">
                        <ButtonDropdown classname="filing"
                            items={getDropdownItems()}
                            variant="normal"
                            onItemClick={(e) => {
                                setUseCase({ useCaseValue: e.detail.id, isSelected: true });
                                sessionStorage.setItem("UseCase", e.detail.id)
                                filingDispatcher({
                                    type: "RESET_STATE", payload: {
                                        periodList: [],
                                        filePath: {},
                                        flashBarItem: [],
                                        fileSummaryData: null,
                                        submitted: false  
                                    }
                                })
                            }}
                        >
                            {fetchCountry(useCase.useCaseValue)}
                        </ButtonDropdown>
                    </div>
                    <div>
                        {getFilingInstruction(useCase.useCaseValue)}
                    </div>
                    <div>
                        {getUseCaseComponent(useCase.useCaseValue)}
                    </div>
                </Container>
            </div>
            <br />
            <div className="filing">
                <div className="table">
                    <Table {...params} table={filingData} tableDispatcher={filingDispatcher} />
                </div>
            </div>
        </>
    );
}

export default Filing;
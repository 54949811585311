import React, {useEffect, useReducer, useState} from "react";
import {Box, Button, Container, SpaceBetween, Header, Flashbar} from "@amzn/awsui-components-react";
import {Table} from "@amzn/awsui-components-react/polaris";
import {INITIAL_STATE, DSFilingInit, DSFilingReducer} from "../redux/DSFilingReducer";
import {fetchJobDetailsForWorkflow, takeActionForWorkflow} from "../../../phastos/components/phastosAPIs";
import {getUserAlias} from "../DS-V1/utils/utils-v1";
import {
    JOB_DETAILS_STATE,
    jobDetailsTableReducerInit,
    tableReducer
} from "../../../phastos/components/jobDetailsReducer";
import {isObligationFiled} from "../../utils/utils";

export default function FilingTab(props) {
    const {itemData} = props;
    const [filingButtonDisabled, setFilingButtonDisabled] = useState(true);
    const [filingData, setFilingData] = useState([]);

    const [table, tableDispatcher] = useReducer(DSFilingReducer,
        INITIAL_STATE, DSFilingInit);

    const [jobDetails, jobDetailsTableDispatcher] = useReducer(tableReducer,
        JOB_DETAILS_STATE, jobDetailsTableReducerInit);

    //fetches job details for a parent workflow prefix
    const fetchJobDetails = () => {
        const lastIndex = itemData.parentWorkflowId.lastIndexOf("#");
        const parentWorkflowIdPrefix = itemData.parentWorkflowId.substr(0, lastIndex);
        jobDetailsTableDispatcher({
            type: "API_BEGIN", payload: {}
        })
        fetchJobDetailsForWorkflow(jobDetailsTableDispatcher, parentWorkflowIdPrefix);

    }

    useEffect(() => {
        fetchJobDetails()
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        const adhocJobs = jobDetails.adhocJobs || [];
        let filingJobs = adhocJobs.filter(job => job.jobType === 'DATA_FILING');
        setFilingData(filingJobs);

        const allJobsFailedOrNone = jobDetails.adhocJobs.length === 0 ||
            jobDetails.adhocJobs.every(job => job.status === 'FAILED');

        setFilingButtonDisabled(jobDetails.fetching || !allJobsFailedOrNone)
    }, [jobDetails]);

    //handler for initiate filing button, calls take action on wflow API
    const filingButtonClickHandler = async() => {
        const userAlias = await getUserAlias();
        const request = {
            workflowId: itemData.parentWorkflowId,
            actionType: "INITIATE_FILING",
            requestor: userAlias
        };
        const onSuccess = () => {
            setFilingButtonDisabled(true)
        };
        takeActionForWorkflow(tableDispatcher, request, onSuccess);
        fetchJobDetails();
    };

    return (
        <>
            <SpaceBetween size='xl'>
                <Flashbar items={table?.flashBarItem} />
                <Container header={<Header variant="h2">Start Filing</Header>}>
                    <p>Please click on "Initiate Filing" to send the report to tax authorities</p>
                    <Box float="right">
                        <SpaceBetween direction="horizontal" size="xxl">
                            <Button disabled={isObligationFiled(itemData.status) || filingButtonDisabled} variant = "primary" onClick = {filingButtonClickHandler}>Initiate Filing </Button>
                        </SpaceBetween>
                    </Box>
                </Container>
                <div>
                    <Table
                        header={<Header
                            variant="h2"
                            actions={
                                <SpaceBetween direction="horizontal" size="xs">
                                </SpaceBetween>
                            }
                        >Submitted Requests
                        </Header>}
                        columnDefinitions={[
                            {
                                id: "requestType",
                                header: "Request Type",
                                cell: e => e.jobType
                            },
                            {
                                id: "requestedTime",
                                header: "Requested Time",
                                cell: e => e.scheduledExecutionTime
                            },
                            {
                                id: "submittedBy",
                                header: "Submitted By",
                                cell: e => e.requester
                            },
                            {
                                id: "country",
                                header: "Country",
                                cell: e => e.country
                            },
                            {
                                id: "status",
                                header: "Status",
                                cell: e => e.status
                            }
                        ]}
                        visibleColumns={[
                            "requestType",
                            "requestedTime",
                            "submittedBy",
                            "country",
                            "status"
                        ]}
                        loading={jobDetails.fetching}
                        empty={
                            <Box textAlign="center" color="inherit">
                                <b>No files</b>
                                <Box
                                    padding={{ bottom: "s" }}
                                    variant="p"
                                    color="inherit">
                                    No files to display.
                                </Box>

                            </Box>
                        }
                        items={filingData? filingData: []}
                    />
                </div>
            </SpaceBetween>
        </>
    );
}
import { Link } from "@amzn/awsui-components-react";
import { EmptyState } from "../../../../../phastos/table-config";

export const COLUMN_IDS = {
    BASBox: 'basBox',
    AdjustmentDescription: 'adjustmentDescription',
    AdjustmentAmount: 'adjustmentAmount',
    VariancePercent: 'variancePercent',
    UpdatedBy: 'updatedBy',
    UpdatedOn: 'updatedOn',
};

export const columnDefinitions = [
    {
        id: COLUMN_IDS.BASBox,
        header: 'BAS Box',
        cell: item => item.groupingKey,
    },
    {
        id: COLUMN_IDS.AdjustmentDescription,
        cell: item => item.adjustment?.description,
        header: 'Adjustment Description',
    },
    {
        id: COLUMN_IDS.AdjustmentAmount,
        cell: item => <div style={{float: "right"}}>{ item.adjustment?.operation === "SUBTRACT" ? "-" : "" }{ parseFloat(item.adjustment?.value).toLocaleString("en-US") }</div>,
        header: 'Adjustment Amount',
    },
    {
        id: COLUMN_IDS.VariancePercent,
        cell: item => {
            if(item.varianceExceeded === "true") {
                return <div style={{color: "red", float: "right"}}>{item.variancePercent}%</div>;
            } else {
                return <div style={{float: "right"}}>{item.variancePercent}%</div>;
            }
        },
        header: 'Variance',
    },
    {
        id: COLUMN_IDS.UpdatedBy,
        header: 'Updated by',
        cell: item => <Link href={"https://phonetool.amazon.com/users/"+item.updatedBy} external={true}>{item.updatedBy}</Link>,
    },
    {
        id: COLUMN_IDS.UpdatedOn,
        header: 'Updated on',
        cell: item => item.timestamp,
    },
];

export const collectionOptions = (preferences) => {
    return {
        filtering: {
            empty: (
                <EmptyState
                    title="No summary"
                    subtitle="No summary to display."
                />
            ),
            noMatch: (
                <EmptyState
                    title="No matches"
                    subtitle="We can’t find a match."
                />
            ),
        },
        propertyFiltering: {
            filteringProperties: [{
                propertyLabel: 'Box Label',
                key: 'groupingKey',
                groupValuesLabel: 'Box Label',
                operators: [':', '!:', '=', '!='],
            }, {
                propertyLabel: 'Updated by',
                key: 'updatedBy',
                groupValuesLabel: 'Updated by',
                operators: [':', '!:', '=', '!='],
            }, {
                propertyLabel: 'Updated on',
                key: 'timestamp',
                groupValuesLabel: 'Updated on',
                operators: [':', '!:', '=', '!='],
            }],
            empty: <EmptyState title="No matches" />,
            noMatch: (
                <EmptyState
                    title="No matches"
                />
            ),
        },
        pagination: { pageSize: preferences.pageSize },
        selection: {}
    };
}

export const INPUT_TYPE = {
    BOX: "box",
    OPERATION: "operation",
    INPUT_VALUE: "inputValue",
    DESCRIPTION: "description",
    ADDITIONAL_DATA_MAP: "additionalDataMap"
};

import React, {useEffect} from "react";
import {
    Header,
    Pagination,
    PropertyFilter,
    SpaceBetween,
    Table,
} from "@amzn/awsui-components-react";
import {
    getMatchesCountText,
    paginationLabels, Preferences,
    PROPERTY_FILTERING_I18N_CONSTANTS
} from "../../table-config";
import {useNavigate} from "react-router-dom";
import {getColumnDefinitions} from "./table-config";
import ViewSummaryMetricsForDigitalServicesTax from "./ViewSummaryMetrics";
import {isNull} from "lodash";

export default function DigitalServicesTaxTab(props) {
    const navigate = useNavigate();
    const {
        viewSummaryMetricsProps,
        collectionProps,
        preferences,
        statusFilterValue,
        items,
        actions,
        paginationProps,
        propertyFilterProps,
        filteredItemsCount,
        setPreferences,
        createChannelSlackStatusDispatcher,
        addUserSlackStatusDispatcher,
        listChannelsSlackData,
        listChannelsSlackDataDispatcher,
    } = props;
    const columnDefinitions = getColumnDefinitions(
        navigate, createChannelSlackStatusDispatcher, addUserSlackStatusDispatcher, listChannelsSlackData, listChannelsSlackDataDispatcher
    );

    const {setPropertyFiltering} = actions;

    useEffect(() => {
        if (!isNull(statusFilterValue.digitalServicesTaxTab)) {
            setPropertyFiltering({
                ...propertyFilterProps.query,
                tokens: [{
                    propertyKey: 'status',
                    operator: '=',
                    value: statusFilterValue.digitalServicesTaxTab,
                }],
            })
        } else {
            setPropertyFiltering({
                ...propertyFilterProps.query,
                tokens: [],
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [statusFilterValue]);

    return (
        <SpaceBetween size="xl">
            <ViewSummaryMetricsForDigitalServicesTax
                summaryDataTitle={viewSummaryMetricsProps.summaryDataTitle}
                changeStatusFilterValue={viewSummaryMetricsProps.changeStatusFilterValue}
                tableData={viewSummaryMetricsProps.tableData}/>
            <Table
                {...collectionProps}
                header={
                    <div>
                        <Header
                        >
                            Obligations
                        </Header>
                    </div>
                }
                columnDefinitions={columnDefinitions}
                visibleColumns={preferences.visibleContent}
                items={items}
                pagination={<Pagination {...paginationProps} ariaLabels={paginationLabels}/>}
                filter={
                    <PropertyFilter
                        i18nStrings={PROPERTY_FILTERING_I18N_CONSTANTS}
                        filteringProperties={propertyFilterProps.filteringProperties}
                        filteringOptions={propertyFilterProps.filteringOptions}
                        onChange={propertyFilterProps.onChange}
                        query={propertyFilterProps.query}
                        countText={getMatchesCountText(filteredItemsCount)}
                    />
                }
                preferences={<Preferences preferences={preferences} setPreferences={setPreferences}/>}
            />
        </SpaceBetween>
    );
}
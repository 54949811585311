import React from "react";
import { Button } from "@amzn/awsui-components-react/polaris";
import {downloadTemplate} from '../../excalibur/components/downloadTemplate';
import { fetchCountry } from "../utils/Utils";
import {getParentAndSubUseCase} from "../../excalibur/components/fetchAvailableUseCases";
import { createFilePath } from '../utils/filingAPIs';
import { formatDate } from '../../../common/utils/apiUtils';
import {FILING_CONFIG} from '../utils/Constants'
import {getReadableFileSizeString} from '../utils/Utils'


function UploadDownloadBlock(params) {

    const { isDisabled, useCase, filingDispatcher, file, setFile, handleFlashBarAction }= params;

    const fileValidation = (keyValues) => {
        if (!params.fileValidation(keyValues)) {
            filingDispatcher({
                type: "SET_FLASH_BAR", payload: {
                    msg: 'Invalid template',
                    type: 'error',
                    dispatcher: filingDispatcher,
                    handleFlashBarAction:handleFlashBarAction
                }
            })
            return false;
        }
        return true;
    }

    const formatProperties = (oldData) => {
        const formatProperty = FILING_CONFIG[useCase.useCaseValue].propertyFormat
        const newData = []
        oldData.forEach(oldObj => {
            const newObj = {}
            for (const key in oldObj) {
                const k = key, v = oldObj[key]
                if (k in formatProperty) {
                    newObj[k] = formatProperty[k](v)
                } else {
                    newObj[k] = v;
                }
            }
            newData.push(newObj)
        })
        return newData
    }

    const readFile = async (file) => {
        const reader = new FileReader();

        reader.onload = async (event) => {
            const data = event.target.result
            const worker = new Worker(new URL("./ExcelWorker.js", import.meta.url));

            worker.onmessage = ({data}) => {
                worker.terminate();

                /* validate file */
                let fileSummaryData = fileValidation(data) ? data : null;
                fileSummaryData = FILING_CONFIG[useCase.useCaseValue].propertyFormat ? formatProperties(fileSummaryData): fileSummaryData
                filingDispatcher({
                    type: "SET_SUMMARY_DATA", payload: {
                        fileSummaryData: fileSummaryData
                    }
                })
            };

            worker.postMessage({
                fileData: data,
                sheetName: FILING_CONFIG[useCase.useCaseValue].sheetName,
                excelRowIndex: FILING_CONFIG[useCase.useCaseValue].excelRowIndex,
            });
        };
        if (file !== "")
            reader.readAsArrayBuffer(file);
    }

    const validateFileType = (data) => {
        let idxDot = data.name.lastIndexOf(".") + 1;
        var extFile = data.name.substr(idxDot, data.name.length).toLowerCase();
        if (!FILING_CONFIG[useCase.useCaseValue].allowedFileType.includes(extFile)) {
            filingDispatcher({
                type: "SET_FLASH_BAR", payload: {
                    msg: 'Uploaded file is of invalid format',
                    type: 'error',
                    dispatcher: filingDispatcher,
                    handleFlashBarAction:handleFlashBarAction
                }
            })
            return false
        }
        const isFileSizeValidate = validateFileSize(data);
        if(!isFileSizeValidate){
            return false;
        }
        setFile(data)
        readFile(data)
        const arr = getParentAndSubUseCase(useCase.useCaseValue);
        createFilePath(filingDispatcher, arr[0], arr[1])
        
    }

    const validateFileSize = (data) => {

        if(data.size > FILING_CONFIG[useCase.useCaseValue].allowedFileSize){
            filingDispatcher({
                type: "SET_FLASH_BAR", payload: {
                    msg: 'Uploaded file size is greater than ' + getReadableFileSizeString(FILING_CONFIG[useCase.useCaseValue].allowedFileSize),
                    type: 'error',
                    dispatcher: filingDispatcher,
                    handleFlashBarAction:handleFlashBarAction
                }
            })
            return false  
        }
        return true;
    }

    return (
        <>
            <div className="uploadBox">
                <Button className="download-btn" disabled={isDisabled} onClick={async (e) => {
                    await downloadTemplate(useCase.useCaseValue, params.domain, 'FILING_BUCKET')}}>
                        Download template {useCase.useCaseValue !== "Use Case" && "of " + fetchCountry(useCase.useCaseValue)}
                </Button>  
                <div>
                    <Button iconName="upload" disabled={isDisabled}
                            variant="primary">
                            <input className="inputButton hidden" type={"file"} 
                            accept={FILING_CONFIG[useCase.useCaseValue].allowedFileType.toString()} 
                            onClick={event => {
                                handleFlashBarAction();
                                event.target.value = null;
                            }} 
                            onChange={async (event) => {
                                const config = event.target.files
                                validateFileType(config[0]);
                            }} 
                            name="file" />
                            Upload File {useCase.useCaseValue !== "Use Case" && "of " + fetchCountry(useCase.useCaseValue)}
                        </Button>
                        {file ?
                            <div>
                                <p className="file-d">{file.name}</p>
                                <p className="file-d">Last Modified on - {formatDate(file.lastModified)}</p>
                                <p className="file-d">Size in bytes - {file.size}</p>
                            </div>
                            : null}
                    </div>
            </div>
        </>
    );

}

export default UploadDownloadBlock;

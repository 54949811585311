import { Link } from "@amzn/awsui-components-react";
import { PHASTOS_GET_TRANSACTION_DETAILS } from "../../../../../../ApiPaths";
import { getAPIInputContext } from "../../../../../../common/utils/apiUtils";
import { API } from "aws-amplify";
import {
    TOM_URL_PREFIX
} from "../../../../../../common/constants/constants";
import {isNull} from "lodash";
import {ERROR_TYPE_EXCEPTION, ERROR_TYPE_EXCLUDED, TRANSACTION_TYPE} from "./errorSummaryUtils";

export const LinkCellRenderer = (props) => {
    if (props.data === undefined || props.data['orderId'] === undefined || props.data['orderId'] === null) { 
        return <span></span> 
    };

    const orderId = props.data['orderId'];
    if (props.data['sourceType'] === 'AP' || props.data['sourceType'] === 'APAccrual') {
        const hashKey = props.data['hashKey'];
        const url = `${TOM_URL_PREFIX}${hashKey.split('#')[0]}`;
        return (
            <Link href={url} external={true}>{orderId}</Link>
        );
    }
    return <span>{orderId}</span>;
};

export const AmountCellRenderer = (props) => {
    if (props.data === undefined) return <div/>;

    const amount = props.data[props.column.colId];
    if (amount === null || amount === undefined) {
        return <div style={ {float: "right"} }>-</div>
    }
    const count = parseFloat(amount);
    const parsedCount = count.toLocaleString("en-US", {
        minimumFractionDigits: 2, maximumFractionDigits: 2
    });
    return <div style={ {float: "right"} }>{parsedCount}</div>
};

export const getEditableCellStyle = (params, baseTransactionsData) => {
    if (params.data === undefined || params.column.colId === 'draftChanges' || params.column.colId === 'checkBox') {
        return {};
    }
    const filteredRows = baseTransactionsData.filter(e => e.hashKey === params.data.hashKey);
    if (filteredRows.length < 1) { return {}; }
    if (filteredRows[0][params.column.colId] === params.data[params.column.colId]) {
        return {};
    } else {
        return { backgroundColor: 'lightCyan' };
    }
};

export const searchDefinitions = (columnDefinitions, unsupportedSearchFields) => {
    const defintions = [];
    columnDefinitions.forEach((item) => {
        if(!unsupportedSearchFields.includes(item.field)) {
            defintions.push(
                {
                    propertyLabel: item.headerName,
                    key: item.field,
                    operators: [':', '!:', '=', '!='],
                }
            )
        }
    });
    return defintions;
}

export const getConditionEnumFromOperator = (operator) => {
    switch (operator) {
        case "=" : return "EQUALS";
        case "!=" : return "NOT_EQUALS";
        case ":" : return "CONTAINS";
        case "!:" : return "NOT_CONTAINS";
        default : return null;
    }
}

export const getOperatorFromConditionEnum = (enumValue) => {
    switch (enumValue) {
        case "EQUALS" : return "=";
        case "NOT_EQUALS" : return "!=";
        case "CONTAINS" : return ":";
        case "NOT_CONTAINS" : return "!:";
        default : return null;
    }
}

export const getTransactionDetails = (request) => {
    getAPIInputContext().then(input => {
        input.body.parentWorkflowId = request.parentWorkflowId;
        input.body.filterConditions = request.filterData.filterConditions;
        input.body.pageNumber = request.currentPageNumber.toString();
        input.body.pageSize = request.pageSize.toString();
        input.body.useCase = request.useCase;
        input.body.phastosUseCase = request.phastosUseCase;
        input.body.reportingPeriod = request.reportingPeriod;

        API.post("TWIN_API", PHASTOS_GET_TRANSACTION_DETAILS, input).then(resp => {
            const data = JSON.parse(resp.body.data);
            if (resp.status === 200) {
                request.setIsLoadingTransactions(false);
                const transactionData = isNull(data.transactionDataList) ? [] : data.transactionDataList.map(e => JSON.parse(e));
                const currentPageNumber = isNull(data.currentPageNumber) ? 1 : data.currentPageNumber;
                const totalPageNumbers = isNull(data.lastPageNumber) ? 1 : data.lastPageNumber;
                const totalRecords = isNull(data.totalRecords) ? 0 : data.totalRecords;
                request.setBaseTransactionsData(JSON.parse(JSON.stringify(transactionData)));
                request.setTransactionsData(JSON.parse(JSON.stringify(transactionData)));
                request.setCurrentPageNumber(currentPageNumber);
                request.setTotalPageNumber(totalPageNumbers);
                request.setTotalRecords(totalRecords);
            } else {
                console.log(PHASTOS_GET_TRANSACTION_DETAILS + " API Error Response : " + JSON.stringify(resp));
                request.setIsLoadingTransactions(false);
                request.setBaseTransactionsData({});
                request.setTransactionsData({});
            }
        });
    });
};

const convertAndSetFloat = (amount) => {
    return parseFloat(parseFloat(amount).toFixed(2));
};

export const cellValueChanged = (event, transactionsData, baseTransactionsData, setTransactionsData) => {
    if (event.valueChanged) {
        const selectedRows = event.api.getSelectedRows();
        selectedRows.push(event.data); // Covers an edge case where row being edited is not selected 

        const mutableTransactionsData = (JSON.parse(JSON.stringify(transactionsData)));
        selectedRows.forEach(selectedRow => {
            // Update Value
            const filteredRows = mutableTransactionsData.filter(e => e.hashKey === selectedRow.hashKey);
            filteredRows.forEach(e => {
                if (event.column.colId === 'taxRate') {
                    e[event.column.colId] = event.newValue === null || event.newValue?.trim() === '' ? null : parseFloat(event.newValue);
                } else {
                    e[event.column.colId] = event.newValue;
                }

                if (event.column.colId === 'taxRate') {
                    e['netTaxAmount'] = event.newValue === null || event.newValue?.trim() === '' ? null : convertAndSetFloat(e['netSalesAmount'] * event.newValue * 0.01);
                    e['totalAmountBaseCurrency'] = convertAndSetFloat(e['netSalesAmount'] + e['netTaxAmount']);
                }

               
            });

            // Remove draftChanges to compare & set
            const baseFilteredRows = baseTransactionsData.filter(e => e.hashKey === filteredRows[0].hashKey);
            delete filteredRows[0].draftChanges;
            filteredRows[0].draftChanges = JSON.stringify(baseFilteredRows[0]) === JSON.stringify(filteredRows[0]) ? 'N' : 'Y';
        });

        setTransactionsData(mutableTransactionsData);
        event.api.refreshCells();
    }
};

export const updateDraftFilter = (gridRef, isChecked) => {
    var draftChangesFilterComponent = gridRef?.current?.api?.getFilterInstance(
        'draftChanges'
    );
    if (isChecked) {
        draftChangesFilterComponent?.setModel({ values: ['Y'] });
    } else {
        draftChangesFilterComponent?.setModel({ values: ['Y', 'N'] });
    }
    gridRef?.current?.api?.onFilterChanged();
};

export const updateActionStatus = (selectedRows, baseTransactionsData, setApplyAdjustmentsDisabled, setAcceptTransactionsDisabled) => {
    let changes = false;
    let noChanges = false;
    selectedRows.forEach(selectedRow => {
        const filteredRows = baseTransactionsData.filter(e => e.hashKey === selectedRow.hashKey);
        if (JSON.stringify(selectedRow) === JSON.stringify(filteredRows[0])) {
            noChanges = true;
        } else {
            changes = true;
        }
    });

    if (changes && !noChanges) {
        setApplyAdjustmentsDisabled(false);
        setAcceptTransactionsDisabled(true);
    } else if (noChanges && !changes) {
        setApplyAdjustmentsDisabled(true);
        setAcceptTransactionsDisabled(false);
    } else {
        setApplyAdjustmentsDisabled(true);
        setAcceptTransactionsDisabled(true);
    }
};

export const getAdjustmentsList = (gridRef, baseTransactionsData, eligibleAttributes) => {
    const transactionsAdjustmentInfoList = [];
    gridRef?.current?.api?.getSelectedRows().forEach(selectedRow => {
        const updates = [];

        const baseFilteredRows = baseTransactionsData.filter(e => e.hashKey === selectedRow.hashKey);
        eligibleAttributes.forEach(colId => {
            if (selectedRow[colId] !== baseFilteredRows[0][colId]) {
                const attributeValue = baseFilteredRows[0][colId];
                const updatedAttributeValue = selectedRow[colId];
                updates.push({
                    attributeName: colId,
                    attributeValue: attributeValue === null || attributeValue === undefined ? null : attributeValue.toString(),
                    updatedAttributeValue: updatedAttributeValue === null || updatedAttributeValue === undefined ? "0.0" : updatedAttributeValue.toString(),
                })
            }
        });

        transactionsAdjustmentInfoList.push({
            transactionId: selectedRow.hashKey,
            exceptionKey: selectedRow.exceptionKey,
            updates: updates,
        });
    })
    return transactionsAdjustmentInfoList;
};

export const ATTRIBUTES_FOR_EXCEPTION_TABLE = ['exceptionTag', 'exceptionStatus', 'severity'];
export const ATTRIBUTES_FOR_FILTER_TABLE = ['filterTag'];

export const addExceptionAndFilterTableAttributes = (definitions) => {
    definitions.push(
        {
            propertyLabel: 'Type',
            key: TRANSACTION_TYPE,
            operators: ['='],
        },
        {
            propertyLabel: 'Exception Reason Code',
            key: 'exceptionTag',
            group: ERROR_TYPE_EXCEPTION,
            operators: [':', '!:', '=', '!='],
        },
        {
            propertyLabel: 'Excluded Reason Code',
            key: 'filterTag',
            group: ERROR_TYPE_EXCLUDED,
            operators: [':', '!:', '=', '!='],
        },
        {
            propertyLabel: 'Exception Status',
            key: 'exceptionStatus',
            group: ERROR_TYPE_EXCEPTION,
            operators: [':', '!:', '=', '!='],
        },
        {
            propertyLabel: 'Exception Severity',
            key: 'severity',
            group: ERROR_TYPE_EXCEPTION,
            operators: [':', '!:', '=', '!='],
        }
    );
}
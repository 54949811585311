export const getColumnDefinitions = (useCaseType) => {
    return [
        {
            headerName: '',
            field: 'checkBox',
            checkboxSelection: true,
            headerCheckboxSelection: true,
            headerCheckboxSelectionFilteredOnly: true,
            hide: false,
            minWidth: 54,
            filter: false,
            width: 54
        },
        {headerName: 'Transaction ID', field: 'hashKey', enableRowGroup: true, hide: false},
        {headerName: 'Use case', field: 'sourceType', hide: false},
        {headerName: 'Commodity code', field: 'commodityCode', hide: false, editable: true},
        {headerName: 'Country of origin (CoO)', field: 'countryOfOrigin', hide: false, editable: true},
        {headerName: 'Nature of transaction (NOTC)', field: 'natureOfTransaction', hide: false, editable: true},
        {headerName: 'Weight', field: 'weight', hide: false, editable: true},
        {headerName: 'Units', field: 'units', hide: false, editable: true},
        {headerName: 'Invoice value (local currency)', field: 'invoiceValue', hide: false, editable: true},
        {headerName: 'Statistical value (local currency)', field: 'statisticalValue', hide: false, editable: true},
        {headerName: 'Ship to', field: 'shipTo', hide: false, editable: (useCaseType==='DISPATCH')},
        {headerName: 'Delivery terms', field: 'deliveryTerms', hide: false, editable: true},
        {headerName: 'Goods description', field: 'goodsDescription', hide: false, editable: true},
        {headerName: 'Mode of transport', field: 'modeOfTransport', hide: false, editable: true},
        {headerName: 'Port-Airport', field: 'portOrAirport', hide: false, editable: true},
        {headerName: 'Recipient\'s VAT ID', field: 'recipientVAT', hide: false, editable: true},
        {headerName: 'Region', field: 'region', hide: false, editable: true},
        {headerName: 'Ship from', field: 'shipFrom', hide: false, editable: (useCaseType==='ARRIVALS')},
        {headerName: 'Supplementary unit type', field: 'supplierUnitType', hide: false, editable: true},
        {headerName: 'Unresolved exceptions', field: 'unresolvedExceptions', hide: false},
        {headerName: 'Corrected exceptions', field: 'correctedExceptions', hide: false},
        {headerName: 'Confirmed exceptions', field: 'confirmedExceptions', hide: false},
        {headerName: 'Applied filters', field: 'appliedFilters', hide: false},
        {headerName: 'Exception key', field: 'exceptionKey', hide: true},
        {headerName: 'Parent Workflow ID', field: 'parentWorkflowId', hide: true},
        {headerName: 'Child Workflow ID', field: 'childWorkflowId', hide: true},
        {headerName: 'Job Run ID', field: 'jobRunId', hide: true},
        {
            headerName: 'Draft Changes (Y/N)',
            field: 'draftChanges',
            editable: false,
            valueGetter: params => {
                if (params.data === undefined) return 'N';
                return params.data.draftChanges === undefined ? 'N' : params.data.draftChanges;
            },
        }
    ];
}

export const defaultColumnDefinitions = {
    sortable: true,
    minWidth: 200,
    defaultMinWidth: 200,
    resizable: true,
    floatingFilter: true,
    type: 'editableColumn',
    enablePivot: true,
    editable: false,
    filter: true,
    hide: true,
};
import React, {useEffect, useState} from "react";
import {
    Header,
    Tabs,
    Box,
    Button,
    Flashbar,
    SpaceBetween, Grid
} from '@amzn/awsui-components-react';
import {useLocation, useNavigate} from "react-router-dom";
import {useReducer} from "react";
import {EXCEPTION_INITIAL_STATE, exceptionReducer, exceptionReducerInit} from "../redux/exceptionTableReducer";
//import {generatePDFReport, initiateCustomerEngagement} from "./components/exceptionAPIs";
import "../../css/Exception.css"
import ErrorSummary from "./DSErrorSummary-V1";
import ExceptionSummary from "./components/ExceptionSummary-V1";
import Summary from "./components/Summary-V1";
import ExceptionTrends from "./components/ExceptionTrends-V1";
import ExceptionChecklists from "./components/ExceptionChecklists-V1";
import {getAcknowledgeData, getUserAlias, isObligationUnderReview} from "./utils/utils-v1";
import ObligationDetails from "./components/ObligationDetails-V1";
import {UPDATE_STATUS_INITIAL_STATE, updateStatusReducer, updateStatusReducerInit} from "../redux/updateStatusReducer";
import {modifyWorkflowStatus} from "../redux/exceptionAPIs";
import { ROUTE_PATH_PHASTOS_PAGE } from "../../../../common/constants/route_paths";
import FilingTab from "../DS/DSFiling";
import {isObligationFiled, isObligationUnderFiling} from "../../utils/utils";

function Exception() {
    const [exceptionSummaryData, exceptionSummaryDispatcher] = useReducer(exceptionReducer,
        EXCEPTION_INITIAL_STATE, exceptionReducerInit);

    const params = useLocation();
    const navigate = useNavigate();

    const [itemData, setItemData] = useState();
    const [exceptionData, setExceptionData] = useState();
    const [isUnderReview, setIsUnderReview] = useState();
    const [isUnderFiling, setIsUnderFiling] = useState();
    const [isFiled, setIsFiled] = useState();
    const [stepsState, setStepsState] = useState(getAcknowledgeData());
    const [approveButtonDisabled, setApproveButtonDisabled] = useState(false);
    const [obligationStatus, setObligationStatus] = useState();
    const [parentWorkflowId, setParentWorkflowId] = useState();
    const [activeTabId, setActiveTabId] = useState('third');

    const [updateWorkflowStatus, updateWorkflowStatusDispatcher] = useReducer(updateStatusReducer,
        UPDATE_STATUS_INITIAL_STATE, updateStatusReducerInit);

    useEffect(() => {
        const stateData = params.state?.data;
        if (stateData === undefined) {
            navigate(ROUTE_PATH_PHASTOS_PAGE);
            return;
        }
        setItemData(stateData);

        //ToDo Add actual Api Call over here
        let requestId = localStorage.getItem("requestId");
        exceptionSummaryDispatcher({
            type: "SET_REQUEST_ID", payload: {
                data: {
                    requestId,
                }
            }
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        setParentWorkflowId(itemData?.parentWorkflowId);
        setObligationStatus(itemData?.status);
        setStepsState(getAcknowledgeData(itemData?.acknowledgementMap));
        setExceptionData(itemData?.exceptionData);
        setIsUnderReview(isObligationUnderReview(itemData?.status));
        setIsUnderFiling(isObligationUnderFiling(itemData?.status));
        setIsFiled(isObligationFiled(itemData?.status));
    }, [itemData]);

    const isApproveButtonDisabled = () => {
        return !(isUnderReview && stepsState.summaryStep.completed && stepsState.exceptionReviewStep.completed
            && stepsState.checklistStep.completed);
    };


    const isFilingTabDisabled = () => {
        return !((isUnderFiling || isFiled) && itemData?.featureFlags?.is_filing_enabled);
    };

    const setStepStateHandler = async (step) => {
        const userAlias = await getUserAlias();
        setStepsState({
            ...stepsState,
            [step]: {completed: true, user: userAlias, updatedOn: new Date().toLocaleDateString()},
        });
    };

    const approveButtonClickHandler = () => {
        const request = {
            parentWorkflowId: parentWorkflowId,
            status: "UNDER_APPROVAL",
            acknowledgementStructureMap: {},
        };
        const onSuccess = () => {
            setApproveButtonDisabled(true);
            setObligationStatus("UNDER_APPROVAL")
        };
        modifyWorkflowStatus(updateWorkflowStatusDispatcher, request, onSuccess);
    }

    const filingButtonClickHandler = () => {
        setActiveTabId("second");
    };
    return (
        <>
            <div className="phastos">
                <Flashbar items={exceptionSummaryData?.flashBarItem}/>
                <Flashbar items={updateWorkflowStatus?.flashBarItem}/>
                <SpaceBetween size='l'>
                    <Header variant="h1" className="header" actions={
                        <SpaceBetween direction="horizontal" size="xs">
                            <Button disabled={isApproveButtonDisabled() || approveButtonDisabled} onClick={approveButtonClickHandler}>Send for approval</Button>
                            <Button disabled={isFilingTabDisabled()} onClick={filingButtonClickHandler}>Start filing</Button>
                        </SpaceBetween>
                    }>
                    </Header>
                    <Grid gridDefinition={[{colspan: 6}, {colspan: 6}]}>
                        <ObligationDetails data={itemData} obligationStatus={obligationStatus} />
                        <ExceptionSummary stepsState={stepsState}/>
                    </Grid>
                    <div>
                        <Tabs
                            tabs={[
                                {
                                    label: 'Summary',
                                    id: 'third',
                                    content: <Summary
                                        exceptionData={exceptionData}
                                        isUnderReview={isUnderReview}
                                        stepsState={stepsState}
                                        setStepsStateHandler={setStepStateHandler}
                                        updateWorkflowStatusDispatcher={updateWorkflowStatusDispatcher}
                                        parentWorkflowId={parentWorkflowId}
                                    />,
                                },
                                {
                                    label: 'Exception review',
                                    id: 'first',
                                    content:
                                        <Box>
                                            <ErrorSummary
                                                exceptionData={exceptionData}
                                                isUnderReview={isUnderReview}
                                                stepsState={stepsState}
                                                setStepsStateHandler={setStepStateHandler}
                                                updateWorkflowStatusDispatcher={updateWorkflowStatusDispatcher}
                                                parentWorkflowId={parentWorkflowId}
                                            />
                                            <SpaceBetween/>
                                            {/* //To be enabled when we have the capability to show error transactions data in BE
                                <ExceptionTable  allItems={exceptionSummaryData?.data}/> */}
                                        </Box>
                                },
                                {
                                    label: 'Adjustments',
                                    id: 'sixth',
                                    content: <></>,
                                    disabled: true,
                                },
                                {
                                    label: 'Trends',
                                    id: 'fourth',
                                    disabled: true,
                                    content: <ExceptionTrends
                                        exceptionData={exceptionData}
                                        isUnderReview={isUnderReview}
                                        stepsState={stepsState}
                                        setStepsStateHandler={setStepStateHandler}
                                        updateWorkflowStatusDispatcher={updateWorkflowStatusDispatcher}
                                        parentWorkflowId={parentWorkflowId}
                                    />
                                },
                                {
                                    label: 'Checklists',
                                    id: 'fifth',
                                    content: <ExceptionChecklists
                                        exceptionData={exceptionData}
                                        isUnderReview={isUnderReview}
                                        stepsState={stepsState}
                                        setStepsStateHandler={setStepStateHandler}
                                        updateWorkflowStatusDispatcher={updateWorkflowStatusDispatcher}
                                        parentWorkflowId={parentWorkflowId}
                                    />
                                },
                                {
                                    label: 'Filing',
                                    id: 'second',
                                    content: <FilingTab
                                        itemData={itemData}
                                    />,
                                    disabled: isFilingTabDisabled()
                                }
                            ]}
                            activeTabId={activeTabId}
                            onChange={(event) => {setActiveTabId(event.detail.activeTabId)}}
                        />
                    </div>
                </SpaceBetween>
            </div>
        </>
    );
}

export default Exception;
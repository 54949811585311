import React from "react";
import { Box, Button, Container, Grid, Header, SpaceBetween } from "@amzn/awsui-components-react";
import { get, map } from "lodash";
import { SUMMARY_METRICS } from "../../Constants/Constants";
import Link from "@amzn/awsui-components-react/polaris/link";

const ViewSummaryMetricsForDataSharing = (props) => {
    const {
        summaryDataTitle,
        changeStatusFilterValue,
        tableData,
        setShowRefreshModal,
    } = props;

    return (
        <Container
            header={
                <Header
                    variant="h2"
                    actions={
                        <SpaceBetween
                            direction="horizontal"
                            size="xs"
                        >
                            <Button variant="secondary" onClick={() => setShowRefreshModal(true)}>
                                Initiate on-demand report
                            </Button>
                        </SpaceBetween>
                    }
                >
                    Summary - {summaryDataTitle}
                </Header>
            }>
            <Grid
                gridDefinition={[
                    {colspan: 2},
                    {colspan: 2},
                    {colspan: 2},
                    {colspan: 2},
                    {colspan: 2},
                    {colspan: 2},
                ]}
            >
                { map(SUMMARY_METRICS, ({label, key, isOverdueShow, actualStatus, overdueKey}) => {
                    return (
                        <SpaceBetween key={key} size='xxxs'>
                            <Box color="text-label" fontWeight="bold">
                                {label}
                            </Box>
                            <div style={{display: "grid" }}>
                                <Link 
                                    style={{display: "inline-block"}}
                                    fontSize="display-l"
                                    onFollow={() => { changeStatusFilterValue(actualStatus) }}
                                >
                                    {get(tableData, `dsSummaryData.${key}`, 0)}
                                </Link>
                                { isOverdueShow ? 
                                    <div style={{display: "inline-block"}}>
                                        Overdue: {get(tableData, `dsSummaryData.${overdueKey}`)}
                                    </div> : ''
                                }
                            </div>
                        </SpaceBetween>
                    );
                })}
            </Grid>
        </Container>
    )
}

export default ViewSummaryMetricsForDataSharing;
import { Button } from "@amzn/awsui-components-react/polaris";
import { getExcaliburAuditData } from '../../excalibur/components/excaliburAPIs';
import orderBy from 'lodash/orderBy';
import {populateReportingPeriod} from "../utils/Utils"

export const INITIAL_STATE = {
    data: [],
    reportingPeriod: null,
    periodList: [],
    lastEvaluatedKey: null,
    auditData: new Map(),
    pageCount: 2,
    currentPageIndex: 1,
    sortingColumn: { "sortingField": "startTimestamp" },
    sortingDescending: true,
    loading: true,
    loadingText: "Loading requests",
    initialize: true,
    filePath: {},
    flashBarItem: [],
    submitted: false,
    fileSummaryData : null
};

export const filingReducerInit = initialState => {
    return initialState;
}


export const filingReducer = (state, action) => {
    switch (action.type) {
        case "API_RESPONSE": 
            const currentOrder = state.sortingDescending ? 'desc' : 'asc';
            state.auditData.set(state.currentPageIndex, orderBy(action.payload.data,
                state.sortingColumn.sortingField, currentOrder));
            return {
                ...state,
                loading: false,
                initialize: false,
                lastEvaluatedKey: action.payload.lastEvaluatedKey,
            }
        case "CREATE_FILE_PATH_SUCCESS":
            return {
                ...state,
                filePath: action.payload?.data
            }
        case "TRANSMIT_FILE_REQUEST_COMPLETED":
            if(action.payload.fetchAudit){
                getExcaliburAuditData(action.payload.dispatcher, null, "RemoteFiling");
            }   
            return {
                ...state,
                submitted: true
            }

        case "SET_FLASH_BAR":
            let buttonCTA = action.payload.type === 'success' ? 'Continue Filing' : 'Retry';
            window.scrollTo(0,0);
            return {
                ...state,
                flashBarItem: [{
                    type: action.payload?.type,
                    action: action.payload?.handleFlashBarAction ? <Button onClick={action.payload?.handleFlashBarAction}>{buttonCTA}</Button> : null,
                    dismissible: true,
                    dismissLabel: "Dismiss message",
                    onDismiss: action.payload?.handleFlashBarAction? () => {
                        action.payload?.dispatcher({ type: "DISMISS_FLASHBAR", payload: {} })
                    } : null ,
                    content: (
                        <>
                            {action.payload?.msg}
                        </>
                    ),
                    id: "message_1"
                }]
            }
        case "FAILURE_API_RESPONSE":
            return {
                ...state,
                initialize: false,
            }
        case "AUTH_UPDATE":
            console.log(action.payload)
            return {
                ...state,
                isUserAuthenticatedWithTaxAuthority : action.payload.isUserAuthenticatedWithTaxAuthority
            }
        case "SET_AUTH_CODE":
            return {
                ...state,
                authCode: action.payload?.data
            }
        case "SET_REPORTING_PERIOD":
            const data = populateReportingPeriod(action.payload?.periodList);
            return {
                ...state,
                reportingPeriod: data,
                periodList: action.payload?.periodList
            }
        case "DISMISS_FLASHBAR":
            return {
                ...state,
                flashBarItem: []
            }
        
        case "PAGE_INDEX_CHANGED":
            if (state.auditData.has(action.payload.pageIndex) === false) {
                if (state.lastEvaluatedKey === null) {
                    // If the above condition is true,
                    // it means DDB has returned complete data and no data is left to show
                    state.auditData.set(action.payload.pageIndex, []);
                } else {
                    state.pageCount = state.pageCount + 1;
                    getExcaliburAuditData(action.payload.dispatcher, state.lastEvaluatedKey, "RemoteFiling");
                }
            }
            return {
                ...state,
                currentPageIndex: action.payload.pageIndex,
            }
        case "RESET_STATE": 
            return {
                ...state,
                periodList : action.payload.periodList,
                filePath : action.payload.filePath,
                fileSummaryData : action.payload.fileSummaryData,
                flashBarItem : action.payload.flashBarItem,
                submitted : action.payload.submitted,
                reportingPeriod : action.payload.reportingPeriod
            } 
        case "SET_SUMMARY_DATA": 
            return {
                ...state,
                fileSummaryData : action.payload.fileSummaryData
            }  
        case "AUDIT_SORTING_CHANGED":
            let newOrder = action.payload.isDescending ? 'desc' : 'asc';
            state.loading= true
            let sortedPageItems = orderBy(state.auditData.get(state.currentPageIndex),action.payload.sortingField,newOrder);
            state.auditData.set(state.currentPageIndex, sortedPageItems);
            return {
                ...state,
                loading: false,
                sortingDescending:action.payload.isDescending,
                sortingColumn:{"sortingField":action.payload.sortingField}
            }
        case "REFRESH_AUDIT":
            getExcaliburAuditData(action.payload.dispatcher, state.lastEvaluatedKey, "RemoteFiling");
            return INITIAL_STATE

        default:
            return state;
    }
}
import {Box, Button, Modal, SpaceBetween, Textarea} from "@amzn/awsui-components-react";
import React from "react";

export const TransactionAdjustmentModal = ({
                                               showTransactionAdjustmentModal,
                                               resetTransactionAdjustmentModal,
                                               headerText,
                                               confirmModalHandler,
                                               updateReason,
                                               setUpdateReason,
                                               placeholderText
                                           }) => (
    <Modal
        visible={showTransactionAdjustmentModal}
        onDismiss={resetTransactionAdjustmentModal}
        header={headerText}
        closeAriaLabel="Close"
        footer={
            <Box float="right">
                <SpaceBetween direction="horizontal" size="xs">
                    <Button variant="primary" onClick={confirmModalHandler}>Confirm</Button>
                </SpaceBetween>
            </Box>
        }
    >
        <Textarea
            onChange={({detail}) => {
                setUpdateReason(detail.value)
            }}
            value={updateReason}
            placeholder={placeholderText}
        />
    </Modal>
);
import { Button } from "@amzn/awsui-components-react";
import { createSlackChannel, handleAddUserSlackClick } from "../../Constants/Constants";
import { convertToInternationalCurrencySystem, formatStatus, statusColorCodes } from "../../../../common/utils/commonUtils";
import {formatDate, getIntermediateDeadline} from "../../../../common/utils/apiUtils";
import { slackIconSvg } from "../../../../common/icons/icons8-slack";
import { addIconSvg } from "../../../../common/icons/icons8-add-24";
import {phastosExportAggregatedReport} from "../phastosAPIs";

export const getColumnDefinitions = (navigate, createChannelSlackStatusDispatcher, addUserSlackStatusDispatcher, listChannelsSlackData, listChannelsSlackDataDispatcher, alias, tableDispatcher) => {    return [
        {
            id: 'obligation',
            header: 'Obligation',
            cell: item => item.obligationName,
            minWidth: '160px'
        },
        {
            id: 'correspondence',
            header: 'Correspondence',
            cell: item => {
                return (
                    <div className='correspondence-box'>
                        <Button iconSvg={slackIconSvg} variant="inline-icon"
                                onClick={() => createSlackChannel(item, createChannelSlackStatusDispatcher, listChannelsSlackDataDispatcher, false)}/>
                        <Button iconSvg={addIconSvg} variant="inline-icon"
                                onClick={()=> handleAddUserSlackClick(item, addUserSlackStatusDispatcher, false)}/>
                    </div>
                )
            },
            minWidth: '64px'
        },
        {
            id: 'phastosUseCaseName',
            header: 'UseCaseName',
            cell: item => item.phastosUseCaseName,
            minWidth: '30px'
        },
        {
            id: 'country',
            header: 'Country',
            cell: item => item.country,
            minWidth: '30px'
        },
        {
            id: 'entity',
            cell: item => item.entity,
            header: 'Entity',
            sortingField: "entity",
            minWidth: '80px'
        },
        {
            id: 'period',
            cell: item => item.reportingPeriod,
            header: 'Period',
            sortingField: "reportingPeriod",
            minWidth: '40px'
        },
        {
            id: 'type',
            header: 'Type',
            cell: item => item.type,
            minWidth: '50px'
        },
        {
            id: 'status',
            header: 'Status',
            cell: item => {
                const statusValue = formatStatus(item.status)
                return (<div style={statusColorCodes(statusValue)}>{statusValue}</div>)
            },
            sortingField: "status",
            minWidth: '60px'
        },
        {
            id: 'intermediateDeadline',
            header: 'Intermediate Deadline',
            cell: item => formatDate(getIntermediateDeadline(item.filingDeadline)),
            sortingField: "filingDeadline",
            minWidth: '80px'
        },
        {
            id: 'filingDeadline',
            header: 'Filing Deadline',
            cell: item => formatDate(item.filingDeadline),
            sortingField: "filingDeadline",
            minWidth: '100px'
        },
        {
            id: 'grossMerchantSaleAmount',
            header: 'Gross Merchant Sale Amount',
            cell: item => {
                if(item.exceptionData?.reportableAmount) {
                    return item.exceptionData?.currencySymbol + convertToInternationalCurrencySystem((item.exceptionData?.reportableAmount))
                }
                return "-"
            },
            minWidth: '100px',
            sortingField: "grossMerchantSaleAmount",
        },
        {
            id: 'totalUniqueMerchants',
            header: 'Total Unique Merchants',
            cell: item => {
                if(item.exceptionData?.reportableUniqueSellerCount) {
                    return convertToInternationalCurrencySystem(item.exceptionData?.reportableUniqueSellerCount);
                }
                return "-"
            },
            minWidth: '100px',
            sortingField: "totalUniqueMerchants"
        },
        {
            id: 'errorTransactions',
            header: 'Total Error count',
            cell: item => {
                if(item.exceptionData?.errorTransactions) {
                    return convertToInternationalCurrencySystem(item?.exceptionData?.errorTransactions)
                }
                return "-"
            },
            minWidth: '100px'
        },
        {
            id: 'action',
            header: 'Actions',
            cell: item => {
                return (
                    <div className='action-box'>
                        <Button iconName="download" variant="inline-icon"
                                onClick={()=> phastosExportAggregatedReport(tableDispatcher, item, alias)}/>                    </div>
                )
            },
            minWidth: '200px'
        }
    ];
};
import React, {useState} from 'react';
import {
    Box,
    Container,
    SpaceBetween,
    Header, Grid, Button,
} from '@amzn/awsui-components-react';
import "../../css/errorSummary.css";
import {numberWithCommas} from "../../../../common/utils/apiUtils";
import {getQuickSightLinkMap, getUserAlias} from "./utils/utils-v1";
import {modifyWorkflowStatus} from "../redux/exceptionAPIs";

export default function ErrorSummary(props) {
    const {exceptionData, isUnderReview, stepsState, setStepsStateHandler, updateWorkflowStatusDispatcher, parentWorkflowId} = props;
    const quickSightLinkMap = getQuickSightLinkMap(exceptionData);

    const isCompleteButtonDisabled = () => {
        return !(isUnderReview && stepsState.summaryStep.completed && !stepsState.exceptionReviewStep.completed);
    };

    const [completeButtonState, setCompleteButtonState] = useState(false);

    const completeButtonClickHandler = async () => {
        setCompleteButtonState(true);
        const userAlias = await getUserAlias();
        const request = {
            parentWorkflowId: parentWorkflowId,
            acknowledgementStructureMap: {
                "EXCEPTIONS": { status:"COMPLETE", user : userAlias }
            },
        };
        const onSuccess = () => {
            setStepsStateHandler("exceptionReviewStep");
        };
        modifyWorkflowStatus(updateWorkflowStatusDispatcher, request, onSuccess);
        setCompleteButtonState(false);
    };

    return (
        <SpaceBetween size='xl'>
            <Container header={
                <Header actions={
                    <Button variant='primary'
                            onClick={completeButtonClickHandler}
                            disabled={isCompleteButtonDisabled() || completeButtonState}
                    >Complete</Button>
                }>
                    <Box className="box">
                        <h2>Error Type Summary</h2>
                    </Box>
                </Header>
            }>
                <Grid gridDefinition={[{colspan: 4}, {colspan: 4}, {colspan: 4}]}>
                    <SpaceBetween size='xs'>
                        <Box variant='h3'>
                            Missing Tax Registration Number
                        </Box>
                        <Box color="text-label">
                            {numberWithCommas(exceptionData?.missingSellerData?.reportable_tax_registration_number_missing_sellers, 0)}
                        </Box>
                    </SpaceBetween>
                    <SpaceBetween size='xs'>
                        <Box variant='h3'>
                            Missing Address
                        </Box>
                        <Box color="text-label">
                            {numberWithCommas(exceptionData?.missingSellerData?.reportable_address_missing_sellers, 0)}
                        </Box>
                    </SpaceBetween>
                    <SpaceBetween size='xs'>
                        <Box variant='h3'>
                            Missing Legal Name
                        </Box>
                        <Box color="text-label">
                            {exceptionData?.missingSellerData?.reportable_legal_name_missing_sellers}
                        </Box>
                    </SpaceBetween>
                </Grid>
                <br/>
                {quickSightLinkMap?.Summary ?
                    <iframe
                        title='QuickSight'
                        className="dashboard"
                        src={quickSightLinkMap?.Summary}
                    ></iframe> : null}
            </Container>
        </SpaceBetween>
    );
}
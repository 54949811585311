import {useCollection} from "@amzn/awsui-collection-hooks";
import React, {useEffect, useState} from "react";
import {
    ATTRIBUTES_FOR_EXCEPTION_TABLE,
    ATTRIBUTES_FOR_FILTER_TABLE
} from "./adjustment/constants/transactionLevelUtils";
import {Button, Container, Flashbar, Header, PropertyFilter, SpaceBetween} from "@amzn/awsui-components-react";
import {PROPERTY_FILTERING_I18N_CONSTANTS} from "../../../phastos/table-config";
import {ERROR_TYPE_EXCEPTION, ERROR_TYPE_EXCLUDED} from "./adjustment/constants/errorSummaryUtils";
import {
    filterConditionsToTokens,
    handleTransactionType,
    tokensToFilterData,
    updateFilterData,
    validateConditions
} from "./AdjustmentTransactionSearchBarUtils";

export function AdjustmentSearchBar(props) {
    const {
        filterData,
        setTransactionFilterData,
        searchDefinition
    } = props;
    const {actions, propertyFilterProps} = useCollection(
        [],
        {
            filtering: {},
            propertyFiltering: {filteringProperties: searchDefinition},
            selection: {}
        }
    );

    const {setPropertyFiltering} = actions;
    useEffect(() => {
        const selectedFilters = filterConditionsToTokens(filterData.filterConditions);
        setPropertyFiltering({tokens: selectedFilters, operation: 'and'});
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filterData]);

    const [invalidInputFlashBarItemContent, setInvalidInputFlashBarItemContent] = useState({});
    const [flashBarVisibility, setFlashBarVisibility] = useState(false);

    return (
        <Container footer={<Header actions={<Button variant="normal"
                                                    onClick={() => {
                                                        updateFilterData(filterData,
                                                            propertyFilterProps.query,
                                                            setTransactionFilterData)
                                                    }}>Search</Button>}/>}>
            <SpaceBetween size='xs' direction='vertical'>
                {flashBarVisibility &&
                    <Flashbar items={[{
                        ...invalidInputFlashBarItemContent,
                        type: 'error',
                        onDismiss: () => {
                            setFlashBarVisibility(false);
                        },
                        dismissible: true,
                        id: 'invalidFilterInputFlashBar'
                    }]}/>}
                <SpaceBetween size='xxs' direction='horizontal'>
                    <PropertyFilter
                        i18nStrings={PROPERTY_FILTERING_I18N_CONSTANTS}
                        filteringProperties={propertyFilterProps.filteringProperties}
                        filteringOptions={propertyFilterProps.filteringOptions}
                        onChange={(event) => {
                            const conditions = tokensToFilterData(event.detail.tokens);
                            if (!validateConditions(conditions, setInvalidInputFlashBarItemContent, setFlashBarVisibility)) {
                                //in-case of invalid conditions make no change.
                                return;
                            }
                            handleTransactionType(conditions, ATTRIBUTES_FOR_EXCEPTION_TABLE, ERROR_TYPE_EXCEPTION);
                            handleTransactionType(conditions, ATTRIBUTES_FOR_FILTER_TABLE, ERROR_TYPE_EXCLUDED);
                            filterData.filterConditions = conditions;
                            setPropertyFiltering({
                                tokens: filterConditionsToTokens(filterData.filterConditions),
                                operation: 'and'
                            });
                        }}
                        query={propertyFilterProps.query}
                        hideOperations
                        customGroupsText={[
                            {
                                properties: ERROR_TYPE_EXCEPTION,
                                values: 'Exception values',
                                group: ERROR_TYPE_EXCEPTION
                            },
                            {
                                properties: ERROR_TYPE_EXCLUDED,
                                values: 'Excluded values',
                                group: ERROR_TYPE_EXCLUDED
                            }]}
                    />
                </SpaceBetween>
            </SpaceBetween>
        </Container>
    )
}
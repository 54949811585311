import React, {useState, useReducer} from "react";
import Button from "@amzn/awsui-components-react/polaris/button";
import ButtonDropdown from "@amzn/awsui-components-react/polaris/button-dropdown";
import Table from "./ExcaliburTable"
import Container from "@amzn/awsui-components-react/polaris/container";
import Header from "@amzn/awsui-components-react/polaris/header";
import Spinner from "@amzn/awsui-components-react/polaris/spinner";
import StatusIndicator from "@amzn/awsui-components-react/polaris/status-indicator";
import {FORMATS} from "../../common/constants/constants";
import {downloadTemplate} from "./components/downloadTemplate";
import {configUpload} from "./components/configUpload";
import "./css/Excalibur.css"
import {caseChange, changeSubUseCase} from "./caseChange";
import {fetchCountry} from "./components/fetchCountry";
import {getDropdownItems, subUseCaseConfig, getSubUseCaseSelectionLimits} from "./components/fetchAvailableUseCases";
import {Flashbar, Grid} from "@amzn/awsui-components-react";
import {DOWNLOAD_INITIAL_STATE, downloadReducerInit, downloadReducer} from "../excalibur/components/downloadDocumentReducer";
import Multiselect from "@amzn/awsui-components-react/polaris/multiselect";

function Excalibur(params) {

    const [success, setSuccess] = useState({isVisible:false});
    const [uploading, setUploading] = useState(false);
    const [useCase, setUseCase] = useState({useCaseValue : "", isSelected:false});
    const [selectedSubUseCases, setSelectedSubUseCases] = useState([]);
    const [downloadDocument, downloadDocumentDispatcher] = useReducer(downloadReducer,
        DOWNLOAD_INITIAL_STATE, downloadReducerInit);
    const [isRequiredSelectionComplete, setIsRequiredSelectionComplete] = useState(false);

    const getFirstSelectedUseCase = () => {
        if(getSubUseCaseSelectionLimits(useCase.useCaseValue) > 0) {
            return selectedSubUseCases.length ? selectedSubUseCases[0]?.value : "";
        } else {
            return useCase.useCaseValue;
        }
    }

    const getAllSelectedUseCase = () => {
        if(getSubUseCaseSelectionLimits(useCase.useCaseValue) > 0) {
            const selectedCases = []
            for(const usecase of selectedSubUseCases) {
                selectedCases.push(usecase.value)
            }
            return selectedCases;
        } else {
            return [useCase.useCaseValue];
        }
    }

    return (
        <>
            <div className="excalibur">
                <Flashbar items={downloadDocument?.flashBarItem}/>
                <Header variant="h1" className="header" >Excalibur</Header>
                <Container
                    header={
                        <h2>Submit New Request</h2>
                    }>
                        <Container>
                            1. Make sure your transaction file(s) are created using our upload file template.<br/>
                            2. Only .csv, .xlsx, .xls and .txt format files are allowed. File size is limited to 300MB.
                        </Container>
                    <Grid gridDefinition={{ colspan: 2 }} id={"optionsGroup"}>
                        <ButtonDropdown classname="excalibur"
                                        items={getDropdownItems()}
                                        variant="normal"
                                        onItemClick={(e) => {
                                            caseChange(e, setUseCase, setSuccess, setIsRequiredSelectionComplete)
                                            setSelectedSubUseCases([])
                                        }}

                        >{fetchCountry(useCase.useCaseValue)}
                        </ButtonDropdown>
                        { subUseCaseConfig[useCase.useCaseValue] &&
                            <Multiselect
                                selectedOptions={selectedSubUseCases}
                                onChange={({detail}) => changeSubUseCase(detail.selectedOptions,
                                    useCase, setSelectedSubUseCases, setIsRequiredSelectionComplete)}
                                options={subUseCaseConfig[useCase.useCaseValue]}
                                placeholder="Sub-Use cases"
                            />
                        }
                        <Button disabled={!isRequiredSelectionComplete} onClick={(e) => {
                            downloadTemplate(getFirstSelectedUseCase(), params.domain, 'EXCALIBUR_BUCKET')
                        }}>
                            Download template {getFirstSelectedUseCase() !== "" && "of " + fetchCountry(getFirstSelectedUseCase())}
                        </Button>
                        <Button disabled={!isRequiredSelectionComplete} iconName="upload" className="polarisInputButton"
                                variant="primary">
                            <input className="inputButton hidden" type={"file"} accept={FORMATS} onChange={(event) => {
                                getFirstSelectedUseCase() !== "" && configUpload(event, getAllSelectedUseCase(), params.domain,
                                    setSuccess, setUploading)}} name="file"/>
                            Upload File {getFirstSelectedUseCase() !== "" && "of " + fetchCountry(getFirstSelectedUseCase())}
                        </Button>

                        {uploading && <Spinner className="uploadSpinner"/>}
                        {success.isVisible &&
                            <StatusIndicator type={success.type}>
                                {success.message}
                            </StatusIndicator>
                        }
                    </Grid>
                </Container>
                <br/>
                <div>
                    <Table {...params} downloadDocumentDispatcher={downloadDocumentDispatcher}/>
                </div>
            </div>
        </>
    );
}

export default Excalibur;

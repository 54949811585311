import React, {useEffect, useState, useReducer} from "react";
import "./css/DocumentManager.css";
import {
    Button,
    ButtonDropdown,
    Container,
    Header,
    SpaceBetween,
    Flashbar
} from "@amzn/awsui-components-react";
import Autosuggest from "@amzn/awsui-components-react/polaris/autosuggest";
import Table from "../documentmanager/MetaDataSearchTable";
import Input from "@amzn/awsui-components-react/polaris/input";
import {countryCodeConfig, documentTypeConfig, getDropdownItemsForEntity} from
"./components/fetchAvailableModule";
import {getUserAlias} from "../exception/utils/utils";
import {useCaseConfig, useCaseEnum} from "../../common/utils/commonUtils";
import Popover from "@amzn/awsui-components-react/polaris/popover";
import {CLIENT_NAME, PHASTOS} from "../../common/constants/constants";
import StatusIndicator from "@amzn/awsui-components-react/polaris/status-indicator";
import {SEARCH_DOCUMENTS_STATE, tableReducer, searchDocumentReducerInit} from "./components/searchDocumentReducer";
import {fetchDocumentListThroughMetadataSearch} from "../../app/phastos/components/phastosAPIs";
import {DOWNLOAD_INITIAL_STATE, downloadReducerInit, downloadReducer} from "../excalibur/components/downloadDocumentReducer";
import { isEqual } from 'lodash';


function DocumentManager(params) {

    const [userAlias, setUserAlias] = useState("");
    const initialSearchStateMap =  {requester: "", merchantId: "", reportingYear: "", countryCode: "", 
            documentType: "", useCase: "", entity: "", subUseCase: ""};
    const [useCaseLabel, setUseCaseLabel] = useState("");
    const [searchStateMap, setSearchStateMap] = useState(initialSearchStateMap);
    const [searchDocumentTable, searchDocumentsTableDispatcher] = useReducer(tableReducer,
            SEARCH_DOCUMENTS_STATE, searchDocumentReducerInit);
    const [downloadDocument, downloadDocumentDispatcher] = useReducer(downloadReducer,
            DOWNLOAD_INITIAL_STATE, downloadReducerInit);
          
    const [
        selectedItems,
        setSelectedItems
    ] = useState([]);
    useEffect(() => {
        async function fetchData() {
            let alias = await getUserAlias();
            setUserAlias(alias);
        }
        fetchData();
    }, []);

    const handleSearchClick = () => {
        searchDocumentsTableDispatcher({
            type: "API_BEGIN", payload: {}
        })
        setSelectedItems([]);
        fetchDocumentListThroughMetadataSearch(searchDocumentsTableDispatcher, searchStateMap, params.domain)
        setSearchStateMap(initialSearchStateMap);
    };
    const isDisabled = isEqual(searchStateMap, initialSearchStateMap);

    const generateReportingYearOptions = () => {
        const currentYear = new Date().getFullYear();
        const options = [];
        for (let i = 0; i < 4; i++) {
            options.push({ value: (currentYear - i - 1).toString() });
        }
        return options;
    }

    return (
        <>
            <div className="searchField">
            <Flashbar items={downloadDocument?.flashBarItem}/>
                <Header variant="h1" className="header">MetaData Search
                    <Popover
                        dismissButton={false}
                        position="top"
                        size="small"
                        triggerType="custom"
                        content={
                            <StatusIndicator type="info">
                                Search stored files with custom metadata fields
                            </StatusIndicator>
                        }
                    >
                        <Button iconName="status-info" variant="icon" />
                    </Popover>
                </Header>
                <Container
                    header={
                        <h2>Search Document through Metadata fields</h2>

                    }>
                    <Container>
                        Documents generated through Phastos or excalibur could be retrieved and downloaded <br />
                        based on pre-defined metadata attributes.
                    </Container>
                    <SpaceBetween direction="vertical" size="xl">
                        <div id="buttonGroup">
                            <ButtonDropdown classname="searchField"
                                            items={countryCodeConfig}
                                            variant="normal"
                                            onItemClick={(e) => {
                                                setSearchStateMap({
                                                    ...searchStateMap,
                                                    countryCode: e.detail.id
                                                });
                                            }}

                            >{searchStateMap.countryCode === "" ? "Select Country Code" : searchStateMap.countryCode}
                            </ButtonDropdown>
                            <ButtonDropdown classname="searchField"
                                            items={documentTypeConfig}
                                            variant="normal"
                                            onItemClick={(e) => {
                                                setSearchStateMap({
                                                    ...searchStateMap,
                                                    documentType: e.detail.id
                                                });
                                            }}

                            >{searchStateMap.documentType === "" ? "Select Document Type" : searchStateMap.documentType}
                            </ButtonDropdown>
                            <ButtonDropdown classname="searchField"
                                            items={useCaseConfig}
                                            variant="normal"
                                            onItemClick={(e) => {
                                                const useCaseMap = useCaseEnum[e.detail.id];
                                                setSearchStateMap({
                                                    ...searchStateMap,
                                                    useCase: useCaseMap.useCase,
                                                    subUseCase : useCaseMap.subUseCase
                                                });
                                                setUseCaseLabel(e.detail.id);
                                            }}

                            >{useCaseLabel === "" ? "Select Use case" : useCaseLabel}
                            </ButtonDropdown>
                            <ButtonDropdown classname="searchField"
                                            items={getDropdownItemsForEntity()}
                                            variant="normal"
                                            onItemClick={(e) => {
                                                setSearchStateMap({
                                                    ...searchStateMap,
                                                    entity: e.detail.id
                                                });
                                            }}

                            >{searchStateMap.entity === "" ? "Select Entity" : searchStateMap.entity}
                            </ButtonDropdown>
                        </div>
                        <div id="searchButtonGroup">
                            <Input
                                className="inputButton"
                                onChange={({detail}) =>
                                    setSearchStateMap({
                                        ...searchStateMap,
                                        merchantId: detail.value
                                    })
                                }
                                value={searchStateMap.merchantId}
                                placeholder="Enter a MerchantId"
                            />
                            <Autosuggest
                                         onChange={({ detail }) =>
                                             setSearchStateMap({
                                                 ...searchStateMap,
                                                 requester: detail.value
                                             })
                                         }
                                         value={searchStateMap.requester}
                                         options={[
                                             { value: userAlias},
                                             { value: CLIENT_NAME },
                                             { value: PHASTOS },
                                         ]}
                                         enteredTextLabel={value => `Use: "${value}"`}
                                         placeholder="Enter Requestor Alias or ClientName"
                                         empty="No matches found"
                            />
                            <Autosuggest
                                onChange={({ detail }) =>
                                    setSearchStateMap({
                                        ...searchStateMap,
                                        reportingYear: detail.value
                                    })
                                }
                                value={searchStateMap.reportingYear}
                                options={generateReportingYearOptions()}
                                enteredTextLabel={value => `Use: "${value}"`}
                                placeholder="Enter Reporting Year"
                                empty="No matches found"
                            />
                        </div>
                        <SpaceBetween className="search-button" size = "xl" direction= "horizontal">
                        <Button 
                                onClick={() => setSearchStateMap(initialSearchStateMap)}>
                                Reset
                        </Button>
                        <Button variant="primary"
                                onClick={handleSearchClick} disabled={isDisabled}>
                                Search
                        </Button>
                        </SpaceBetween>
                        
                    </SpaceBetween>
                </Container>
                <br />
                <div className="table">
                    <Table {...params} searchDocumentTable={searchDocumentTable} selectedItems={selectedItems}
                    setSelectedItems={setSelectedItems} downloadDocumentDispatcher={downloadDocumentDispatcher}/>
                </div>
            </div>
        </>
    );
}

export default DocumentManager;
import {API} from "aws-amplify";
import {getAPIInputContext} from "../utils/apiUtils";
import {IS_PAGE_AUTHORIZED} from "../../ApiPaths";

/**
 * @param page
 * @param setContent
 */
export async function getAuthorizationData(page, authorizationDataDispatcher) {

    getAPIInputContext().then(input => {
        input.body.resourceName = page;

        API.post("TWIN_API", IS_PAGE_AUTHORIZED, input).then(resp => {
            console.log(IS_PAGE_AUTHORIZED + " API was called, response : " + JSON.stringify(resp));
            authorizationDataDispatcher({
                type: "SUCCESS", payload: {
                    pageName: page,
                    response: resp,
                }
            });
        });
    });

}
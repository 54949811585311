import React, {useEffect, useState} from "react";
import {Button, Icon, StatusIndicator} from "@amzn/awsui-components-react/polaris";
import {useSearchParams} from 'react-router-dom';
import * as XLSX from "xlsx";
import AppConfig from '../../../AppConfig';
import {getParentAndSubUseCase} from "../../excalibur/components/fetchAvailableUseCases";
import {CLIENT_NAME, HMRC_VAT_RETURN_FILE_NAME, HMRC_VAT_RETURN_SHEET_NAME} from "../../../common/constants/constants";
import {authenticateEFilerClient, fetchReportingPeriod, submitFilingRequest, uploadFile} from '../utils/filingAPIs';
import UploadDownloadBlock from "../components/UploadDownloadBlock";
import ExcelPreviewBlock from "../components/ExcelPreviewBlock";
import SubmitFiling from "../components/SubmitFiling";
import {getStartEndDateOfFiscalYear} from "../utils/Utils";
import {getUKVATFilingPreviewJSON} from "../utils/usecaseExcelPreviewUtils";


function UKVATFiling(params) {

    const {filingData, filingDispatcher, useCase} = params;

    const [searchParams] = useSearchParams();
    const code = searchParams.get('code');
    const errorHMRC = searchParams.get('error');

    const [file, setFile] = useState('');
    const [uploading, setUploading] = useState(false);
    const [reportingPeriod, setReportingPeriod] = useState({ id: 0, text: "Select Reporting Period" });
    const [previewBlock, setPreviewBlock] = useState()


    const fileValidation = (data) => {
        var result = data.map(row => {
                return  row['VAT Registration Number'] === undefined 
            }
        )
        return !result.includes(true);
    }

    const handleAuthenticate = () => {
        const href = window.location.origin;
        const encoded = encodeURIComponent(`${href}/filinghmrc`)
        window.location.href = `${AppConfig[params.domain].UK_FILING_REDIRECT_URI}${encoded}`;
    }

    const handleReAuthenticate = () => {
        localStorage.removeItem("IS_AUTHENTICATED");
        filingDispatcher({
            type: "AUTH_UPDATE", payload: {
                isUserAuthenticatedWithTaxAuthority: ""
            }
        })  
        handleAuthenticate();
    }

    const handleContinueFiling = () => {
        setReportingPeriod({ id: 0, text: "Select Reporting Period" })
        setFile('');
        filingDispatcher({
            type: "RESET_STATE", payload: {
                periodList: [],
                filePath: {},
                flashBarItem: [],
                submitted: false,
                reportingPeriod: null
            }
        })
    }

    const buildJsonToExcel = (jsonData) => {
        try {
            const workSheet = XLSX.utils.json_to_sheet(jsonData);
            const workBook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(workBook, workSheet, HMRC_VAT_RETURN_SHEET_NAME);
            const buff = XLSX.write(workBook, {type: "buffer", bookType: "xlsx"})
            const blob = new Blob([buff], { type: 'application/octet-stream' });
            return new File([blob], HMRC_VAT_RETURN_FILE_NAME)
        } catch (e) {
            console.log("Exception occurred: ", e);
        }
    }

    const handleSubmitClick = () => {
        const arr = getParentAndSubUseCase(useCase.useCaseValue);
        const filingObj = {
            "useCase": arr[0],
            "clientName": CLIENT_NAME,
            "subUseCase": arr[1],
            "s3BucketName": AppConfig[params.domain].FILING_BUCKET,
            "s3FolderPath": filingData.filePath?.s3BucketUrl
        }
        const obj = {
            domain: params.domain,
            onUpload: setUploading
        }
        filingData.fileSummaryData[0] = {
            ...filingData.fileSummaryData[0],
            "Period Key": reportingPeriod.periodKey,
            "Reporting Period": reportingPeriod.text
        }
        const excelFile = buildJsonToExcel(filingData.fileSummaryData)
        uploadFile(excelFile, AppConfig[params.domain].FILING_BUCKET, filingData.filePath?.s3BucketUrl, obj.onUpload, obj.domain, submitFilingRequest, filingDispatcher, filingObj, handleContinueFiling);
    }

    useEffect(() => {
        const getReportingPeriod = (vatNo) => {
            const arr = getParentAndSubUseCase(useCase.useCaseValue);
            const { startDate, endDate } = getStartEndDateOfFiscalYear();
            let obj = {
                useCase: arr[0],
                subUseCase: arr[1],
                clientName: CLIENT_NAME,
                additionalDataMap: {
                    clientVrn: vatNo,
                    startDate: startDate,
                    endDate: endDate
                }
            }
            fetchReportingPeriod(filingDispatcher, obj);
        }
        if(filingData.fileSummaryData){
            getReportingPeriod(filingData.fileSummaryData[0]['VAT Registration Number'])
        }
    },[filingData.fileSummaryData, useCase.useCaseValue, filingDispatcher])

    useEffect(() => {
        let itemStr = localStorage.getItem("IS_AUTHENTICATED");
        if (itemStr) {
            const item = JSON.parse(itemStr)
            const now = new Date()
            if (now.getTime() > item.expiry) {
                localStorage.removeItem("IS_AUTHENTICATED");
                filingDispatcher({
                    type: "AUTH_UPDATE", payload: {
                        isUserAuthenticatedWithTaxAuthority: ""
                    }
                })        
            } else {
                filingDispatcher({
                    type: "AUTH_UPDATE", payload: {
                        isUserAuthenticatedWithTaxAuthority: "SUCCESS"
                    }
                })
            }
        } else {
            if (code && filingData.isUserAuthenticatedWithTaxAuthority !== "SUCCESS") {
                const arr = getParentAndSubUseCase(useCase.useCaseValue);
                const redirectUri = `${window.location.origin}/filinghmrc`
                let obj = {
                    useCase: arr[0],
                    subUseCase: arr[1],
                    clientName: CLIENT_NAME,
                    additionalDataMap: {
                        authCode: code,
                        redirectUri: redirectUri
                    }
                }
                authenticateEFilerClient(filingDispatcher, obj);
                filingDispatcher({
                    type: "SET_AUTH_CODE", payload: {
                        data: code,
                    }
                })
            }
        }
    }, [code, filingData.isUserAuthenticatedWithTaxAuthority, useCase.useCaseValue, filingDispatcher]);

    useEffect(() => {
        if (errorHMRC) {
            filingDispatcher({
                type: "AUTH_UPDATE", payload: {
                    isUserAuthenticatedWithTaxAuthority: "FAILED"
                }
            })
        }
    }, [errorHMRC, filingDispatcher])

    useEffect(() => {
        const reportingPeriodDropdownHandler = (e) => {
            let selectedReportingPeriod = filingData?.reportingPeriod[e.detail.id - 1];
            setReportingPeriod(selectedReportingPeriod);
        }

        if(filingData.fileSummaryData && filingData.reportingPeriod){
            const previewBlock = filingData.fileSummaryData.map((summary, idx) => {
                const properties = {
                    filingData : summary,
                    dropdownButton : {
                        ReportPeriodDropdown : {
                            value : reportingPeriod,
                            items : filingData?.reportingPeriod,
                            onItemClick : reportingPeriodDropdownHandler
                        }
                    }
                }
                const previewJson = getUKVATFilingPreviewJSON(properties);
                return <ExcelPreviewBlock id={idx} blockCaption={"Tax Summary"} config={previewJson} columnSize={4}/>
            })
            setPreviewBlock(previewBlock);
        }   
    },[filingData.reportingPeriod, reportingPeriod, filingData.fileSummaryData])

    return (
        <>
            <div>
                <div id="buttonGroup">
                    <div className="authBox">
                        <Button className="auth-btn" disabled={filingData.isUserAuthenticatedWithTaxAuthority === "SUCCESS"} onClick={handleAuthenticate}>{filingData.isUserAuthenticatedWithTaxAuthority === "SUCCESS" ? <StatusIndicator type={"success"}>
                            You are now authenticated
                        </StatusIndicator> : filingData.isUserAuthenticatedWithTaxAuthority === "FAILED" ? <StatusIndicator type={"error"}>Authenticated failed <Icon name="refresh" /></StatusIndicator> : `AUTHENTICATE`}</Button>
                        {filingData.isUserAuthenticatedWithTaxAuthority === "SUCCESS" ? <Button className="reauthenticate-btn" variant="primary" onClick={handleReAuthenticate}>Authenticate with another user</Button> : null}
                    </div>
                </div>
                <UploadDownloadBlock 
                    {...params} 
                    file={file}
                    setFile={setFile}
                    isDisabled={!useCase.isSelected || filingData.isUserAuthenticatedWithTaxAuthority !== "SUCCESS"}
                    fileValidation={fileValidation}
                    handleFlashBarAction={handleContinueFiling}
                    workSheetIndex={1}/>

                <br/>
            </div>
            {filingData.fileSummaryData && filingData.reportingPeriod ? 
            <>
                {previewBlock}
                <br/>
                <SubmitFiling disabled={file === "" || reportingPeriod?.id === 0} uploading={uploading} handleSubmitClick={handleSubmitClick} useCase={useCase}/>

            </> : null }
        </>
    );
}

export default UKVATFiling;
import {ISVATFilingValidation, DEIntrastatFilingValidation} from "../utils/WebBasedFilingValidation"
import {convertToXDecimalPlaces} from "./Utils";


const ENTITY_NAME =  "Entity Name"
const VAT_REGISTRATION_NUMBER =  "VAT Registration Number"
const VAT_DUE_SALES =  "VAT Due Sales"
const VAT_DUE_ACQUISITIONS = "VAT Due Acquisitions"
const TOTAL_VAT_DUE = "Total VAT Due"
const VAT_RECLAIMED_CURRENT_PERIOD = "VAT Reclaimed Current Period"
const NET_VAT_DUE = "Net VAT Due"
const TOTAL_VALUE_SALES_EX_VAT = "Total Value Sales Excluding VAT"
const TOTAL_VALUE_PURCHASE_EX_VAT = "Total Value Purchases Excluding VAT"
const TOTAL_VALUE_GOODS_SUPPLIED_EX_VAT = "Total Value Goods Supplied Excluding VAT"
const TOTAL_ACQUISITIONS_EX_VAT = "Total Acquisitions Excluding VAT"

export const FILING_CONFIG = {
    UK_VAT_FILING : {
        instructions : [
            "To perform the filing using Excalibur, please authenticate it to do the filing on your behalf.",
            "Please fill out the provided template duly and upload it for submission.",
            "After uploading, please review the information and click Submit",
            "For any queries, reach out to taskless-rangers@amazon.com"
        ],
        concentMessage : "When you submit this VAT information you are making a legal declaration that the information is true and complete. A false declaration can result in prosecution.",
        allowedFileType : ["csv", "xlsx", "xls", "xlsb"],
        allowedFileSize : 200*1024*1024, //200MB
        sheetName: 'Excalibur',
        headers : [],
        propertyFormat: {
            [ENTITY_NAME]: (a) => a.toString(),
            [VAT_REGISTRATION_NUMBER]: (a) => a.toString(),
            [VAT_DUE_SALES]: (a) => convertToXDecimalPlaces(a, 2),
            [VAT_DUE_ACQUISITIONS]: (a) => convertToXDecimalPlaces(a, 2),
            [TOTAL_VAT_DUE]: (a) => convertToXDecimalPlaces(a, 2),
            [VAT_RECLAIMED_CURRENT_PERIOD]: (a) => convertToXDecimalPlaces(a, 2),
            [NET_VAT_DUE]: (a) => convertToXDecimalPlaces(a, 2),
            [TOTAL_VALUE_SALES_EX_VAT]: (a) => convertToXDecimalPlaces(a, 0),
            [TOTAL_VALUE_PURCHASE_EX_VAT]: (a) => convertToXDecimalPlaces(a, 0),
            [TOTAL_VALUE_GOODS_SUPPLIED_EX_VAT]: (a) => convertToXDecimalPlaces(a, 0),
            [TOTAL_ACQUISITIONS_EX_VAT]: (a) => convertToXDecimalPlaces(a, 0)
        }
    },
    IS_VAT_FILING : {
        instructions : [
            "Please upload the .xlsx file in the given template format with sheet name as IS Summary",  
            "After uploading, please review the information and click Submit",
            "Once the submission is successful, proofs can be downloaded from the table below ",
            "For any queries, reach out to taskless-rangers@amazon.com"
        ],
        allowedFileType : ["csv", "xlsx", "xls", "xlsb"],
        allowedFileSize : 200*1024*1024, //200MB
        sheetName: 'IS Summary',
        headers : ["Code", "Company", "Reduced Net Rate 11%", "Reduced VAT rate 11%", "Standard NET rate 24%", "Standard VAT rate 24%"],
        excelRowIndex : [2,19],
        fileValidator : (data) => { return ISVATFilingValidation(data) }
    },
    DE_INTRASTAT_FILING : {
        instructions : [
            "Please upload the .xlsx file in the given template format with sheet name as DE_INTRASTAT_SUMMARY",  
            "After uploading, please review the information and click Submit",
            "Once the submission is successful, proofs can be downloaded from the table below ",
            "For any queries, reach out to taskless-rangers@amazon.com"
        ],
        allowedFileType : ["csv", "xlsx", "xls", "xlsb"],
        allowedFileSize : 200*1024*1024, //200MB
        sheetName: 'DE_INTRASTAT_SUMMARY',
        headers : [ "Company Code", "Direction of trade", "Fiscal Period (dd/MM/yyyy)", "Member State of Consignment", "Member State of Destination", "Region of Destination",
                    "Region of Origin", "Country of Origin", "Commodity number", "Description of the goods", "Net mass in full kg", "Quantity in supplementary units", 
                    "Invoiced amount in full euros", "Statistical amount in full euros", "DE number (identification number) currently voluntary"
        ],
        fileValidator : (data) => { return DEIntrastatFilingValidation(data) }
    },
    TR_VAT_FILING : {
        instructions : [
            "Please upload the .xlsx file in the given template format",
            "After uploading, please review the information and click Submit",
            "Once the submission is successful, proofs can be downloaded from the table below",
            "For any queries, reach out to taskless-rangers@amazon.com"
        ],
        allowedFileType : ["xlsx", "xls", "xlsb"],
        allowedFileSize : 5*1024*1024, //5MB
        sheetName: 'TR_VAT_SUMMARY',
        headers : [ "Company", "TaxRegistrationNumber", "Year", "Period", "Branch", "NationalTradeRegNumber", "Email", "TelephoneCode", "TelephoneNumber", "WebsiteAddress", "Tax Base", "VAT Rate"
        ],
        fileValidator : (data) => { return () => {} }
    },
    SA_VAT_FILING: {
        instructions: [
            "Please upload the .xlsx file in the given template format",
            "After uploading, please review the information and click Submit",
            "Once the submission is successful, proofs can be downloaded from the table below",
            "For any queries, reach out to taskless-rangers@amazon.com"
        ],
        allowedFileType: ["xlsx", "xls", "xlsb"],
        allowedFileSize: 5 * 1024 * 1024, //5MB
        sheetName: 'SA_VAT_SUMMARY',
        headers: ["Entity", "TaxPayer", "Year", "Month", "FirstName", "Surname", "Capacity", "BusTelNo", "Email"],
        fileValidator: (data) => {
            return () => {
            }
        }
    },
    DE_VAT_FILING : {
        instructions : [
            "Please upload the .xml file in the given template format with sheet name as DE_VAT_SUMMARY",
            "After uploading, please review the information and click Submit",
            "Once the submission is successful, proofs can be downloaded from the table below ",
            "For any queries, reach out to taskless-rangers@amazon.com"
        ],
        allowedFileType : ["csv", "xlsx", "xls"],
        allowedFileSize : 5*1024*1024, //5MB
        headers : [],
        fileValidator : (data) => { return () => {} }
    },
    IR_MOSS_FILING : {
        instructions : [
            "Please upload the .xlsx file in the given template format",  
            "After uploading, please review the information and click Submit",
            "Once the submission is successful, proofs can be downloaded from the table below ",
            "For any queries, reach out to taskless-rangers@amazon.com"
        ],
        allowedFileType : ["csv", "xlsx", "xls", "xlsb"],
        allowedFileSize : 200*1024*1024, //200MB
        sheetName: 'Summary',
        headers : [ "VATIdentificationNumberIssuedBy", "VATIdentificationNumber", "TaxableYear", "TaxableQuarter", "EntityName"],
        // add validation logic in future if any
        fileValidator : (data) => { return () => {} }
    },
    TR_DST_FILING : {
        instructions : [
            "Please upload the .xlsx file in the given template format",
            "After uploading, please review the information and click Submit",
            "Once the submission is successful, proofs can be downloaded from the table below ",
            "For any queries, reach out to fintech-sentinels@amazon.com"
        ],
        allowedFileType : ["xlsx"],
        allowedFileSize : 5*1024*1024, //5MB
        sheetName: 'Summary',
        headers : [ "Filing Period", "Company Code", "Company Name", "Local TIN", "1/1-A Advertising",
                    "1/1-B Digital product sales", "1/1-C Online marketplace", "Website of the Company"],
        // add validation logic in future if any
        fileValidator : (data) => { return () => {} }
    }

}

export const USE_CASE_CONFIG = [
    {
        id: "FR",
        text: "FRANCE"
    },
    {
        id: "AU",
        text: "AUSTRIA",
    },
    {
        id: "UK",
        text: "UK",
        items: [
            {id: "UK_VAT_FILING", text: "VAT"},
        ]
    },
    {
        id: "IS",
        text: "IS",
        items: [
            {id: "IS_VAT_FILING", text: "VAT"},
        ]
    },
    {
        id: "DE",
        text: "DE",
        items: [
            {id: "DE_INTRASTAT_FILING", text: "INTRASTAT"},
            {id: "DE_VAT_FILING", text: "VAT"},
        ]
    },
    {
        id: "IR",
        text: "IR",
        items: [
            {id: "IR_MOSS_FILING", text: "MOSS_FILING"},
        ]
    },
    {
        id: "TR",
        text: "TR",
        items: [
            {id: "TR_DST_FILING", text: "DST_FILING"},
            {id: "TR_VAT_FILING", text: "VAT_FILING"},
        ]
    },
    {
        id: "SA",
        text: "SA",
        items: [
            {id: "SA_VAT_FILING", text: "VAT_FILING"}
        ]
    }
];

import React from "react";
import {Header, Table} from "@amzn/awsui-components-react";
import {exceptionSummaryColumnDefinition} from "../../table-config";

export default function ExceptionSummary(props) {
    const { exceptionSummaryTableProp } = props;

    return (
        <Table
            header={
                <div>
                    <Header>Execution summary</Header>
                </div>
            }
            columnDefinitions={exceptionSummaryColumnDefinition}
            items={ exceptionSummaryTableProp }
        />
    );
}
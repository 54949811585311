import React, {useState} from "react";
import {Container} from "@amzn/awsui-components-react/polaris";
import {getQuickSightLinkMap, getUserAlias} from "../utils/utils-v1";
import {Button, Header, SpaceBetween} from "@amzn/awsui-components-react";
import {modifyWorkflowStatus} from "../../redux/exceptionAPIs";

export default function ExceptionTrends(props) {
    const {exceptionData, isUnderReview, stepsState, setStepsStateHandler, updateWorkflowStatusDispatcher, parentWorkflowId} = props;
    const quickSightLinkMap = getQuickSightLinkMap(exceptionData);

    const isCompleteButtonDisabled = () => {
        return !(isUnderReview && stepsState.summaryStep.completed
            && stepsState.exceptionReviewStep.completed && !stepsState.trendsStep.completed);
    };

    const [completeButtonState, setCompleteButtonState] = useState(false);

    const completeButtonClickHandler = async () => {
        setCompleteButtonState(true);
        const userAlias = await getUserAlias();
        const request = {
            parentWorkflowId: parentWorkflowId,
            acknowledgementStructureMap: {
                "TRENDS": { status:"COMPLETE", user : userAlias }
            },
        };
        const onSuccess = () => {
            setStepsStateHandler("trendsStep");
        };
        modifyWorkflowStatus(updateWorkflowStatusDispatcher, request, onSuccess);
        setCompleteButtonState(false);
    };

    return (
        <SpaceBetween>
            {quickSightLinkMap?.Trends ?
                <Container header={
                    <Header actions={
                        <Button variant='primary'
                                onClick={completeButtonClickHandler}
                                disabled={isCompleteButtonDisabled() || completeButtonState}
                        >Complete</Button>
                    }></Header>
                }>
                    <iframe
                        title='QuickSight'
                        className="dashboard"
                        src={quickSightLinkMap?.Trends}
                    ></iframe>
                </Container>
                : null
            }
        </SpaceBetween>
    )
}
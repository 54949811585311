import {API} from "aws-amplify";
import {getAPIInputContext} from "../../../common/utils/apiUtils";
import {WEBSITE_CLIENT_NAME} from "../../../common/constants/constants";
import {getURLlink} from "../../documentmanager/constants/Constants";
import {EXCALIBUR_FETCH_AUDIT, EXCALIBUR_GENERATE_REPORT, EXCALIBUR_DOWNLOAD_DOCUMENT} from "../../../ApiPaths";

/**
 * This function will fetch paginated Audit for Excalibur
 * @param tableDispatcher
 * @param lastEvaluatedKey Variable used as next startKey for fetching next page of data
 */
export function getExcaliburAuditData(tableDispatcher, lastEvaluatedKey, transactionType) {
    
    getAPIInputContext().then(input => {
        input.body.startKey = lastEvaluatedKey;
        input.body.transactionType = transactionType;

        API.post("TWIN_API", EXCALIBUR_FETCH_AUDIT, input).then(resp => {
            console.log(EXCALIBUR_FETCH_AUDIT + " API was called, response : " + resp);
            if (resp.status === 200) {
                tableDispatcher({
                    type: "API_RESPONSE", payload: {
                        data: JSON.parse(resp.body.auditData),
                        lastEvaluatedKey: resp.body.lastEvaluatedKey
                    }
                })
            } else {
                console.log(EXCALIBUR_FETCH_AUDIT + " API Error Response : " + JSON.stringify(resp));
                tableDispatcher({
                    type: "FAILURE_API_RESPONSE", payload: {}
                })
            }
        });
    });

}

/**
 * This function will fetch Transaction Audit Data
 * @param lastEvaluatedKey Variable used as next startKey for fetching next page of data
 * @param transactionType Variable used to pass transactionType to excalibur fetch audit api
 * @param transactionId Variable used to pass transactionId to excalibur fetch audit api
 * @param clientId Variable used to pass clientId to excalibur fetch audit api
 */
export function getTransactionAuditData(lastEvaluatedKey, transactionType, transactionId, clientId) {
    return new Promise((resolve, reject) => {
        getAPIInputContext().then(input => {
            input.body.startKey = lastEvaluatedKey;
            input.body.transactionType = transactionType;
            input.body.transactionId = transactionId;
            input.body.clientId = clientId;

            API.post("TWIN_API", EXCALIBUR_FETCH_AUDIT, input).then(resp => {
                if (resp.status === 200) {
                    resolve(resp.body);
                } else {
                    reject(new Error("API Error"));
                }
            });
        });
    });
}

/**
 * This function will put request to generate Excalibur Report
 * @param setData Hook used to update current Audit Data
 * @param setLastEvaluatedKey Hook used to update lastEvaluatedKey
 * @param lastEvaluatedKey Variable used as next startKey for fetching next page of data
 */
export function generateExcaliburReport(s3Key, s3VersionId, useCaseArray,
                                        filename, setSuccess) {

    getAPIInputContext().then(input => {
        input.body.uploadedFileS3Path = s3Key;
        input.body.uploadedFileS3VersionId = s3VersionId;
        input.body.useCase = useCaseArray[0];
        input.body.useCaseType = useCaseArray[1];
        input.body.entityName = filename;
        console.log(input);

        API.post("TWIN_API", EXCALIBUR_GENERATE_REPORT, input).then(resp => {
            console.log(EXCALIBUR_GENERATE_REPORT + " API was called, response : " + resp);
            if (resp.status === 200) {
                setSuccess(() => {
                    return {
                        isVisible: true,
                        type: "success",
                        message: "File Uploaded Successfully. Report Generation in progress"
                    }
                });
            } else if (resp.status === 401) {
                setSuccess(() => {
                    return {
                        isVisible: true,
                        type: "error",
                        message: "User is not authorized to perform this operation"
                    }
                });
            } else {
                setSuccess(() => {
                    return {
                        isVisible: true,
                        type: "error",
                        message: "Some internal error occurred. Please try again in some time."
                    }
                });
            }
        });
    });

}

/**
 * This function will download file URL for Excalibur
 * @param fileName Variable used to download document URL 
 */
export function downloadDocumentBys3PreSignedURL(fileName, useCase, subUseCase, downloadDocumentDispatcher) {
    getAPIInputContext().then(input => {
        input.body.documentName = fileName;
        input.body.clientName = WEBSITE_CLIENT_NAME;
        input.body.requestor = getRequestor(useCase, subUseCase);
        API.post("TWIN_API", EXCALIBUR_DOWNLOAD_DOCUMENT, input).then(resp => {
        console.log(EXCALIBUR_DOWNLOAD_DOCUMENT + " API was called, response : " + resp);
            if (resp.status === 200) {
                getURLlink(resp.documentUrl, fileName);
                downloadDocumentDispatcher({
                    type: "DOWNLOAD_DOCUMENT_SUCCESS", payload: {dispatcher: downloadDocumentDispatcher}
                })
            } else {
                downloadDocumentDispatcher({
                    type: "DOWNLOAD_DOCUMENT_FAILURE", payload: {dispatcher: downloadDocumentDispatcher}
                })
            }
        });
    });
}

function getRequestor(useCase, subUseCase) {
    if (useCase === "NA" && subUseCase === "NA") {
        return "Phastos";
    } else if (subUseCase === "NA") {
        return useCase;
    } else {
        return useCase + "_" + subUseCase;
    }
}




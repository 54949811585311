export const addIconSvg = (
    <span>
        <svg 
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 50 50"
            width="18px"
            height="18px"
        >
            <path d="M25,2C12.317,2,2,12.317,2,25s10.317,23,23,23s23-10.317,23-23S37.683,2,25,2z M37,26H26v11h-2V26H13v-2h11V13h2v11h11V26z"/>
        </svg>
    </span>
);
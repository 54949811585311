import React, {useEffect, useReducer, useState} from "react";
import {
    Button,
    Header,
    Pagination,
    PropertyFilter,
    SpaceBetween,
    Table,
    Checkbox
} from "@amzn/awsui-components-react";
import {
    getMatchesCountText,
    paginationLabels, Preferences,
    PROPERTY_FILTERING_I18N_CONSTANTS
} from "../../table-config";
import { useNavigate } from "react-router-dom";
import { isNull } from "lodash";
import { getAcknowledgeData } from "../../../exception/utils/utils";
import { getColumnDefinitions } from "./table-config";
import { OBLIGATION_STATUS } from "../../../exception/components/common/constants";
import ViewSummaryMetricsForAdhocDataSharing from "./ViewSummaryMetrics";
import {INITIAL_STATE, tableReducer, tableReducerInit} from "../phastosReducer";

export default function AdhocDataSharingTab(props) {
    const navigate = useNavigate();
    // eslint-disable-next-line
    const [table, tableDispatcher] = useReducer(tableReducer,
        INITIAL_STATE, tableReducerInit);
    const {
        alias,
        viewSummaryMetricsProps,
        collectionProps,
        onSelectionChange,
        selectedItems,
        allItems,
        preferences,
        items,
        paginationProps,
        propertyFilterProps,
        filteredItemsCount,
        setPreferences,
        statusFilterValue,
        actions,
        isAdhocDSFiledChecked,
        setIsAdhocDSFiledChecked,
        createChannelSlackStatusDispatcher,
        addUserSlackStatusDispatcher,
        listChannelsSlackData,
        listChannelsSlackDataDispatcher,
    } = props;

    const columnDefinitions = getColumnDefinitions(
        navigate, createChannelSlackStatusDispatcher, addUserSlackStatusDispatcher, listChannelsSlackData, listChannelsSlackDataDispatcher, alias, tableDispatcher
    );
    const actionsDisabledInitialState = {approveButton: true, startFilingButton: true};
    const [actionsDisabled, setActionDisabled] = useState(actionsDisabledInitialState);
    const { setPropertyFiltering } = actions;

    useEffect(() => {
        if (!isNull(statusFilterValue.adhocDataSharingTab)) {
            setPropertyFiltering({
                ...propertyFilterProps.query,
                tokens: [{
                    propertyKey: 'status',
                    operator: '=',
                    value: statusFilterValue.adhocDataSharingTab,
                }],
            })
        } else {
            setPropertyFiltering({
                ...propertyFilterProps.query,
                tokens: [],
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [statusFilterValue]);

    useEffect(() => {
        if (selectedItems?.length === 1) {
            const acknowledgeData = getAcknowledgeData(selectedItems[0]?.acknowledgementMap);
            if (selectedItems[0].status === OBLIGATION_STATUS.UnderReview
                && acknowledgeData.summaryStep.completed
                && acknowledgeData.exceptionReviewStep.completed
                && acknowledgeData.trendsStep.completed
                && acknowledgeData.checklistStep.completed) {
                setActionDisabled(prevState => ({
                    ...prevState,
                    approveButton: false,
                }));
            }
        } else {
            setActionDisabled(actionsDisabledInitialState);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedItems]);

    const getObligationTableActions = () => {
        return (
            <SpaceBetween size='l' direction='horizontal'>
                <Checkbox
                    checked={isAdhocDSFiledChecked}
                    onChange={event => {
                        setIsAdhocDSFiledChecked(event.detail.checked);
                    }}
                >
                    Include filed obligations
                </Checkbox>
                <Button variant='primary' disabled={actionsDisabled.startFilingButton}>Start filing</Button>
            </SpaceBetween>
        );
    };

    return (
        <SpaceBetween size="xl">
            <ViewSummaryMetricsForAdhocDataSharing
                summaryDataTitle={viewSummaryMetricsProps.summaryDataTitle}
                changeStatusFilterValue={viewSummaryMetricsProps.changeStatusFilterValue}
                tableData={viewSummaryMetricsProps.tableData}
                setShowAdhocReportRefreshModal={viewSummaryMetricsProps.setShowAdhocReportRefreshModal} />
            <Table
                {...collectionProps}
                selectionType="multi"
                onSelectionChange={onSelectionChange}
                header={
                    <div>
                        <Header
                            counter={selectedItems.length ? `(${selectedItems.length}/${allItems.length})` : `(${allItems.length})`}
                            actions={getObligationTableActions()}
                        >
                            Obligations
                        </Header>
                    </div>
                }
                columnDefinitions={columnDefinitions}
                visibleColumns={preferences.visibleContent}
                selectedItems={selectedItems}
                items={items}
                pagination={<Pagination {...paginationProps} ariaLabels={paginationLabels}/>}
                filter={
                    <PropertyFilter
                        i18nStrings={PROPERTY_FILTERING_I18N_CONSTANTS}
                        filteringProperties={propertyFilterProps.filteringProperties}
                        filteringOptions={propertyFilterProps.filteringOptions}
                        onChange={propertyFilterProps.onChange}
                        query={propertyFilterProps.query}
                        countText={getMatchesCountText(filteredItemsCount)}
                    />
                }
                preferences={<Preferences preferences={preferences} setPreferences={setPreferences}/>}
            />
        </SpaceBetween>
    );

}
import {isNil} from "lodash";
import {getQuickSightEmbedUrl} from "../redux/exceptionAPIs.js";

const triggerGetQuickSightEmbedUrlStart = (dashboardId, dispatcher) => {
    dispatcher({type: "BEGIN", payload: {}});
};

export const triggerGetQuickSightEmbedUrl = (quicksightFrameProps, useCase, dispatcher) => {
    if (isNil(quicksightFrameProps?.dashboardId) || isNil(quicksightFrameProps?.serviceName)) {
        return;
    }
    triggerGetQuickSightEmbedUrlStart(quicksightFrameProps?.dashboardId, dispatcher);
    getQuickSightEmbedUrl(dispatcher,
        {
            dashboardId: quicksightFrameProps?.dashboardId,
            serviceName: quicksightFrameProps?.serviceName,
            useCase: useCase
        });
};
import React, { useEffect, useReducer, useState } from 'react';
import {Button, Cards, Flashbar, Link, SpaceBetween} from "@amzn/awsui-components-react";
import { downloadAttachmentBys3PreSignedURL, getAttachmentDetails } from "../../redux/exceptionAPIs";
import {
    GET_UPLOADED_DOCUMENT_DETAILS_INITIAL_STATE,
    getUploadedDocumentDetailsReducer,
    getUploadedDocumentDetailsReducerInit
} from "../../redux/getUploadedDocumentDetailsReducer";
import { Header } from "@amzn/awsui-components-react/polaris";
import AttachmentModal from "./AttachmentModal";
import {
    DOWNLOAD_INITIAL_STATE,
    downloadReducer,
    downloadReducerInit
} from "../../../../excalibur/components/downloadDocumentReducer";
import { EmptyState } from "../../../../phastos/table-config";
import { postMessage } from '../../../../phastos/components/Slack/slackIntegrationAPIs';
import { getUserAlias } from '../../../utils/utils';
import {
    PUT_FILE_IN_S3_INITIAL_STATE,
    putFileInS3Init,
    putFileInS3Reducer
} from "../../../../../common/fileUploader/putFileInS3Reducer";
import {DOCUMENT_TYPE, GET_PRESIGNED_URL_OPERATION_NAME} from "../../../../../common/constants/constants";

export default function ViewAttachments(props) {
    const { parentWorkflowId, slackChannel, useCase } = props;
    const [attachmentDetails, attachmentDetailsDispatcher] = useReducer(getUploadedDocumentDetailsReducer,
        GET_UPLOADED_DOCUMENT_DETAILS_INITIAL_STATE, getUploadedDocumentDetailsReducerInit);

    // eslint-disable-next-line no-unused-vars
    const [downloadDocument, downloadDocumentDispatcher] = useReducer(downloadReducer,
        DOWNLOAD_INITIAL_STATE, downloadReducerInit);

    const [putFileInS3Details, putFileInS3DetailsDispatcher] = useReducer(putFileInS3Reducer,
        PUT_FILE_IN_S3_INITIAL_STATE, putFileInS3Init);

    const triggerGetAttachmentDetailStart = () => {
        attachmentDetailsDispatcher({type: "BEGIN", payload: {}});
    };

    const triggerGetAttachmentDetails = () => {
        triggerGetAttachmentDetailStart();
        getAttachmentDetails(attachmentDetailsDispatcher,
            { parentWorkflowId, useCase: useCase, documentType: DOCUMENT_TYPE.ATTACHMENTS });
    };

    useEffect(() => {
        triggerGetAttachmentDetails();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [parentWorkflowId, useCase]);

    const [loadingSubmit, setLoadingSubmit] = useState(false);

    const onSuccessHandler = async () => {
        const userAlias = await getUserAlias();
        postMessage(slackChannel, `${userAlias}@ has attached a file.`);
        setShowAttachmentModal(false);
        setLoadingSubmit(false);
        triggerGetAttachmentDetailStart();
        setTimeout(() => {
            triggerGetAttachmentDetails();
        }, 5000);
    }

    const onFailureHandler = async () => {
        setShowAttachmentModal(false);
        setLoadingSubmit(false);
    };

    const [showAttachmentModal, setShowAttachmentModal] = useState(false);

    return (
        <SpaceBetween size="l" direction="vertical">
        <Flashbar items={putFileInS3Details?.flashBarItem}/>
        <Cards items={attachmentDetails.documents} loadingText={"Loading"} cardDefinition={{
            header: item => (
                <Link fontSize="heading-s" onFollow={() => {
                    const request = {
                        parentWorkflowId: parentWorkflowId,
                        useCase: useCase,
                        documentType: DOCUMENT_TYPE.ATTACHMENTS,
                        fileName: item.documentId,
                        operationName: GET_PRESIGNED_URL_OPERATION_NAME.DOWNLOAD,
                        downloadFileName: item.fileName
                    };
                    downloadAttachmentBys3PreSignedURL(downloadDocumentDispatcher, request);
                }}
                >
                    {item.fileName}
                </Link>
            ),
            sections: [
                {
                    id: "uploadedOn",
                    header: "Uploaded on",
                    content: item => item.uploadedDate.split('.')[0]
                },
                {
                    id: "uploadedBy",
                    header: "Uploaded by",
                    content: item => item.uploadedBy
                }
            ]
        }} cardsPerRow={[{cards: 3}]} loading={attachmentDetails.fetching}
        header={<Header actions={<Button onClick={() => {setShowAttachmentModal(true)}}>Add attachments</Button>}>Attachments</Header>}
               empty={<EmptyState
                   title="No attachment"
                   subtitle="No attachment to display."
               />}
        />

        <AttachmentModal showAttachmentModal={showAttachmentModal} setShowAttachmentModal={setShowAttachmentModal} parentWorkflowId={parentWorkflowId}
                         loadingSubmit={loadingSubmit} setLoadingSubmit={setLoadingSubmit} onSuccessHandler={onSuccessHandler} useCase={useCase}
                         putFileInS3DetailsDispatcher={putFileInS3DetailsDispatcher} onFailureHandler={onFailureHandler}/>
        </SpaceBetween>
    )
}
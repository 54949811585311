import {get, isNull, isUndefined} from "lodash";
import Auth from "@aws-amplify/auth";

export const getQuickSightLinkMap = (data) => {
    const quickSightLinkMap = get(data, 'quickSightLinkMap', []);
    const updatedQuickSightLinksMap = {};
    if (isNull(quickSightLinkMap)) {
        return null;
    }
    quickSightLinkMap.forEach(quickSightLink => {
        updatedQuickSightLinksMap[quickSightLink.text] = quickSightLink.id;
    });
    return updatedQuickSightLinksMap;
};

export const getUserAlias = async () => {
    let name = ""
    await Auth.currentAuthenticatedUser().then((userInfo) => {
        const username = userInfo.username?.split('_');
        name = username.length > 1 ? username[1] : username;
    });
    return name;
}

export const isObligationUnderReview = (status) => {
    return status === 'UNDER_REVIEW';
}

export const isAcknowledgeCompleted = (status) => {
    return status === "COMPLETE";
}

export const getFormattedDate = (timestamp) => {
    if (isUndefined(timestamp)) return '-';
    return new Date(Number.parseInt(timestamp)).toLocaleDateString();
}

export const getFormatedUserName = (user) => {
    if (isUndefined(user)) return '-';
    return user;
}

export const getAcknowledgeData = (acknowledgeMap) => {
    return {
        summaryStep: {
            completed: isAcknowledgeCompleted(acknowledgeMap?.SUMMARY?.status),
            user: getFormatedUserName(acknowledgeMap?.SUMMARY?.user),
            updatedOn: getFormattedDate(acknowledgeMap?.SUMMARY?.lastUpdate)
        },
        exceptionReviewStep: {
            completed: isAcknowledgeCompleted(acknowledgeMap?.EXCEPTIONS?.status),
            user: getFormatedUserName(acknowledgeMap?.EXCEPTIONS?.user),
            updatedOn: getFormattedDate(acknowledgeMap?.EXCEPTIONS?.lastUpdate)
        },
        trendsStep: {
            completed: isAcknowledgeCompleted(acknowledgeMap?.TRENDS?.status),
            user: getFormatedUserName(acknowledgeMap?.TRENDS?.user),
            updatedOn: getFormattedDate(acknowledgeMap?.TRENDS?.lastUpdate)
        },
        checklistStep: {
            completed: isAcknowledgeCompleted(acknowledgeMap?.CHECKLIST?.status),
            user: getFormatedUserName(acknowledgeMap?.CHECKLIST?.user),
            updatedOn: getFormattedDate(acknowledgeMap?.CHECKLIST?.lastUpdate)
        },
    };
}
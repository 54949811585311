import { getAPIInputContext } from "../../../common/utils/apiUtils";
import { API } from "aws-amplify";
import {PHASTOS_GET_TRANSACTION_SUMMARY_DETAILS} from "../../../ApiPaths";
import {set, isUndefined, isNull} from "lodash";
import {SHIELD_METRIC_TYPES} from "../../exception/components/VAT/constants/errorSummaryConstants"
import { requireDerivedTaxCodeGrouping } from "../utils/utils";

export function fetchSourceSummaryDetails(request) {
    getAPIInputContext().then(input => {
        input.body.parentWorkflowId = request.parentWorkflowId;
        input.body.useCase = request.useCase;
        input.body.metricType = SHIELD_METRIC_TYPES.TRANSACTION_SUMMARY;
        input.body.groupingAttributeNameList = ["sourceType"];

        API.post("TWIN_API", PHASTOS_GET_TRANSACTION_SUMMARY_DETAILS, input).then(resp => {
            if (resp.status === 200) {
                let response = JSON.parse(resp.body.data);
                const transactionSummaryList = response.transactionSummaryList;
                if (!isUndefined(transactionSummaryList) && !isNull(transactionSummaryList)) {
                    const deserializedTransactionSummaryList = transactionSummaryList.map(e => JSON.parse(e));
                    set(response, 'transactionSummaryList', deserializedTransactionSummaryList);
                }
                request.tableDispatcher({
                    type: "API_RESPONSE_FOR_SOURCE_SUMMARY", payload: {
                        data: {
                            ...response,
                            lastUpdatedOn: new Date().toTimeString(),
                            phastosObligation: request.phastosObligation 
                        },
                    }
                })
            } else {
                request.tableDispatcher({
                    type: "FAILURE_API_RESPONSE", payload: {}
                })
            }
        });
    });
};

export function fetchDataForSourceType(tableDispatcher, request) {
    for (const selectedItem of request.selectedItems) {
        getAPIInputContext().then(input => {
            input.body = {
                ...input.body,
                parentWorkflowId: request.parentWorkflowId,
                useCase: request.useCase,
                metricType: SHIELD_METRIC_TYPES.TRANSACTION_SUMMARY
            };
            input.body.groupingAttributeNameList = ["transactionStatus"];
            input.body.equalAttributeMap = {"sourceType": selectedItem.sourceType};
            API.post("TWIN_API", PHASTOS_GET_TRANSACTION_SUMMARY_DETAILS, input).then(resp => {
                if (resp.status === 200) {
                    tableDispatcher({
                        type: "API_RESPONSE_FOR_TXN", payload: {
                            data: JSON.parse(resp.body.data)?.transactionSummaryList.map(e => {
                                const parsedData = JSON.parse(e);
                                set(parsedData, 'sourceType', selectedItem.sourceType);
                                return parsedData;
                            }),
                            sourceType: selectedItem.sourceType,
                        }
                    })
                } else {
                    tableDispatcher({
                        type: "FAILURE_API_RESPONSE", payload: {}
                    })
                }
            });
        });

        if (requireDerivedTaxCodeGrouping(request.phastosObligation)) {
            getAPIInputContext().then(input => {
                input.body = {
                    ...input.body,
                    parentWorkflowId: request.parentWorkflowId,
                    useCase: request.useCase,
                    metricType: SHIELD_METRIC_TYPES.TRANSACTION_SUMMARY
                };
                input.body.groupingAttributeNameList = ["derivedTaxCode"];
                input.body.equalAttributeMap = {"sourceType": selectedItem.sourceType};
                API.post("TWIN_API", PHASTOS_GET_TRANSACTION_SUMMARY_DETAILS, input).then(resp => {
                    if (resp.status === 200) {
                        tableDispatcher({
                            type: "API_RESPONSE_FOR_TAX_CODE", payload: {
                                data: JSON.parse(resp.body.data)?.transactionSummaryList.map(e => {
                                    const parsedData = JSON.parse(e);
                                    set(parsedData, 'sourceType', selectedItem.sourceType);
                                    return parsedData;
                                }),
                                sourceType: selectedItem.sourceType
                            }
                        })
                    } else {
                        tableDispatcher({
                            type: "FAILURE_API_RESPONSE", payload: {}
                        })
                    }
                });
            });
        }
    }
};
import React, {useState} from "react";
import {
    Button,
    ButtonDropdown,
    SpaceBetween,
    Modal,
    Box,
    DatePicker,
    FormField
  } from '@amzn/awsui-components-react';
import { createAdhocRequest } from "./phastosAPIs";

export default function AdhocModal(props) {
    const {showRefreshModal, onDismiss, setShowRefreshModal, table, tableDispatcher} = props;
    const [isError, setIsError] = React.useState(false);
    const [errorMsg, setErrorMsg] = React.useState("");
    const [startDate, setStartDate] =  React.useState("");
    const [endDate, setEndDate] = React.useState("");
    const [useCase, setUseCase] = useState({id: 0,text: "Select Value"});
    const [countryCode, setCountryCode] = useState({id: 0,text: "Select Value"});

    const submitAdhocRequest = () => {
        const start = new Date(startDate);
        const end = new Date(endDate);
        if(!startDate || !endDate || startDate>=endDate || (end - start > 31536000000)) {
          setIsError(true)
          setErrorMsg("Please enter appropriate start and end date")
          return;
        }

        if((countryCode.text || useCase.text) === 'Select Value') {
            setIsError(true)
            setErrorMsg("Please enter all values")
            return;
        }
  
        let obj = {
          'startDate': start.getTime(),
          'endDate': end.getTime(),
          'countryCode': countryCode.text,
          'useCaseName': useCase.text
        }
        createAdhocRequest(tableDispatcher, obj)
        setShowRefreshModal(false)
      } 

    return (
        <Modal
            visible={showRefreshModal}
            onDismiss={onDismiss}
            header={'Initiate on-demand report'}
            closeAriaLabel="Close dialog"
            footer={
                <Box float="right">
                    <SpaceBetween direction="horizontal" size="xs">
                        <Button variant="link" onClick={() => setShowRefreshModal(false)}>Cancel</Button>
                        <Button variant="primary" onClick={submitAdhocRequest}>Initiate</Button>
                    </SpaceBetween>
                </Box>
            }
        >
            <div>
                {isError ? <Box variant="p" color="text-status-error">
                    {errorMsg}
                </Box> : null}
                <FormField
                    label="Use Case*"
                    constraintText=""
                >
                    <ButtonDropdown classname="select"
                        items={table?.useCaseArr}
                        variant="normal"
                        onItemClick={(e) => {
                            let selectedUseCase = table?.useCaseArr[e.detail.id - 1];
                            setUseCase(selectedUseCase);
                            tableDispatcher({
                                type: "POPULATE_COUNTRY_CODE_VALUES", payload: {
                                    data: selectedUseCase
                                }
                            })
                        }}
                    >
                        {useCase?.text}
                    </ButtonDropdown>
                </FormField>
                <FormField
                    label="Country Code*"
                    constraintText=""
                >
                    <ButtonDropdown classname="select"
                        items={table?.countryCodeArr}
                        variant="normal"
                        onItemClick={(e) => {
                            setCountryCode(table?.countryCodeArr[e.detail.id - 1])
                        }}
                    >
                        {countryCode?.text}
                    </ButtonDropdown>
                </FormField>
                <FormField
                    label="Start Date*"
                    constraintText="Select the start date for the report"
                >
                    <DatePicker
                        onChange={({ detail }) => {
                            setIsError(false);
                            setStartDate(detail.value)
                        }}
                        value={startDate}
                        openCalendarAriaLabel={selectedDate =>
                            "Choose start date" +
                            (selectedDate
                                ? `, selected date is ${selectedDate}`
                                : "")
                        }
                        nextMonthAriaLabel="Next month"
                        placeholder="YYYY/MM/DD"
                        previousMonthAriaLabel="Previous month"
                        todayAriaLabel="Today"
                    />
                </FormField>
                <FormField
                    label="End Date*"
                    constraintText="End date should not be more than 1yr ahead of start date"
                >
                    <DatePicker
                        onChange={({ detail }) => {
                            setIsError(false);
                            setEndDate(detail.value)
                        }}
                        value={endDate}
                        openCalendarAriaLabel={selectedDate =>
                            "Choose end date" +
                            (selectedDate
                                ? `, selected date is ${selectedDate}`
                                : "")
                        }
                        nextMonthAriaLabel="Next month"
                        placeholder="YYYY/MM/DD"
                        previousMonthAriaLabel="Previous month"
                        todayAriaLabel="Today"
                    />
                </FormField>
            </div>
        </Modal>
    )
}


import {get} from "lodash";
import {INPUT_STATE} from "../constants/boxLevelConstants";
import {Icon} from "@amzn/awsui-components-react";
import React from "react";
import { PHASTOS_OBLIGATION } from "../../common/constants";
import {MOG_EXCEPTION_SEVERITY_MAPPING, MOG_EXCEPTION_STATUS_MAPPING} from "../constants/errorSummaryConstants";

export const getErrorTag = (data, inputType) => {
    if(get(data, [inputType, INPUT_STATE.IS_INVALID])){
        return <div className="errorTag"><Icon name="status-warning" variant="error" /> {get(data, [inputType, INPUT_STATE.ERROR_MESSAGE])}</div>
    }
    return <div></div>
}

export const getExceptionStatusMapping = (phastosObligation) => {
    switch (phastosObligation) {
        case PHASTOS_OBLIGATION.MOG:
            return MOG_EXCEPTION_STATUS_MAPPING;
        case PHASTOS_OBLIGATION.VAT:
        default:
            return null;
    }
};

export const getExceptionSeverityMapping = (phastosObligation) => {
    switch (phastosObligation) {
        case PHASTOS_OBLIGATION.MOG:
            return MOG_EXCEPTION_SEVERITY_MAPPING;
        case PHASTOS_OBLIGATION.VAT:
        default:
            return null;
    }
}
import {Button} from "@amzn/awsui-components-react";
import * as React from "react";
import {exportToExcel} from "react-json-to-excel";

export const DEFINITION_TYPE_POLARIS = 'POLARIS';
export const DEFINITION_TYPE_AG_GRID = 'AG-GRID';

const JSON_SPACE = 4;


/**
 * This function will rename headers for array of maps and only include headers that are present in headerToKeyMap
 * @param data
 * @param columnDef
 * @param definitionType
 * @returns {*}
 */
const renameData = (data, columnDef, definitionType) => {
    const handler = getKeyValueHandler(definitionType);
    return data.map(item => {
        return createMap(columnDef, item, handler);
    });
}

/**
 * Function to create the map of header and display value of an item.
 * @param definitions
 * @param item
 * @param handler
 * @returns {{}}
 */
const createMap = (definitions, item, handler) => {
    const resultMap = {};
    definitions.forEach(definition => {
        const {cellKey, cellValue} = handler(definition, item);
        resultMap[cellKey] = cellValue === '-' ? '' : cellValue;
    });
    return resultMap;
}

const getKeyValueHandler = (definitionType) => {
    switch (definitionType) {
        case DEFINITION_TYPE_POLARIS:
            return getKeyValueFromPolarisDefinition;
        case DEFINITION_TYPE_AG_GRID:
            return getKeyValueFromAGGridDefinition;
        default :
            return null;
    }
}

const getKeyValueFromPolarisDefinition = (definition, item) => {
    const cellValue = getTextFromJSX(definition.cell(item));
    const cellKey = definition.header;
    return {cellKey, cellValue};
}

const getKeyValueFromAGGridDefinition = (definition, item) => {
    let cellValue = '';
    if (definition.valueGetter) {
        cellValue = getTextFromJSX(definition.valueGetter({data: item}));
    } else {
        cellValue = item[definition.field];
    }
    const cellKey = definition.headerName;
    return {cellKey, cellValue};
}


/**
 * This function will create the download file name
 * @param tableId
 * @param props
 * @returns {*}
 */
const getDownloadFileName = (tableId, props) => {
    const sortedKeys = Object.keys(props).sort();
    const separator = ' - ';
    const concatenatedString = sortedKeys.map(key => props[key])
        .filter(value => value !== null && value !== undefined)
        .join(separator);

    return tableId + (concatenatedString ? separator + concatenatedString : '');
}

/**
 * Utility function to get text from a JSX element
 * @param element
 * @returns {string|*|string|string}
 */
const getTextFromJSX = (element) => {
    // This is a simplified implementation. You may need a more sophisticated method depending on your JSX structure.
    if (typeof element === 'string') {
        return element;
    }
    if (typeof element === 'number') {
        return element.toString();
    }
    if (Array.isArray(element)) {
        return element.map(getTextFromJSX).join('');
    }
    if (element && element.props && element.props.children) {
        return getTextFromJSX(element.props.children);
    }
    return '';
}

/**
 * Button Component to download table data
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export default function TableDownloadButton(props) {
    const {
        data,
        tableId,
        nameProps,
        columnDefinition,
        tableType,
        additionalButtonDisableFlag,
        isHeaderButton,
    } = props;

    const showButtonDisabled = (items) => {
        return (items === null || items === undefined || items.length === 0 || additionalButtonDisableFlag);
    }

    const downloadTableOnClickHandler = (data, tableId, nameProps, columnDefinition, definitionType) => {
        if (!data) {
            return;
        }
        const formattedJSON = JSON.parse(JSON.stringify(renameData(data, columnDefinition, definitionType),
            null, JSON_SPACE));
        exportToExcel(formattedJSON, getDownloadFileName(tableId, nameProps));
    }


    return (<Button iconName="download"
                    ariaLabel="download"
                    variant={isHeaderButton ? "normal" : "icon"}
                    onClick={() => {
                        downloadTableOnClickHandler(
                            data,
                            tableId,
                            nameProps,
                            columnDefinition,
                            tableType
                        )
                    }}
                    disabled={showButtonDisabled(data)}
    />);
}



import React, { useState } from 'react';
import { 
    Grid,
    Container,
    SpaceBetween,
    Toggle,
    Header,
    Box,
    Button,
    Link
} from "@amzn/awsui-components-react";
import { Icon } from "@amzn/awsui-components-react/polaris";
import { getUserAlias } from "../../utils/utils";
import { modifyWorkflowStatus } from "../redux/exceptionAPIs";
import { postMessage } from '../../../phastos/components/Slack/slackIntegrationAPIs';
import { ACKNOWLEDGEMENT_STRUCTURE_MAP_KEY, ASM_COMPLETE_STATUS, OBLIGATION_STATUS } from '../common/constants';
import {APPROVER_ROLE, REVIEWER_ROLE} from "../../../../common/constants/constants";

export default function VATChecklists(props) {
    const {
        setStepsStateHandler,
        updateWorkflowStatusDispatcher,
        isUnderReview,
        stepsState,
        parentWorkflowId,
        setActiveTabId,
        checklistState,
        setChecklistState,
        obligationStatus,
        userRole,
        slackChannel
    } = props;
    const [completeButtonState, setCompleteButtonState] = useState(false);

    const completeButtonClickHandler = async () => {
        setCompleteButtonState(true);
        const userAlias = await getUserAlias();
        const request = {
            parentWorkflowId: parentWorkflowId,
            acknowledgementStructureMap: {
                [ACKNOWLEDGEMENT_STRUCTURE_MAP_KEY.Checklist]: { status: ASM_COMPLETE_STATUS, user: userAlias }
            },
        };
        const onSuccess = () => {
            postMessage(slackChannel, `${userAlias}@ has completed the Checklists step.`);
            setStepsStateHandler("checklistStep");
        };
        modifyWorkflowStatus(updateWorkflowStatusDispatcher, request, onSuccess);
        setCompleteButtonState(false);
    };

    const isCompleteButtonDisabled = () => {
        return !(
            isUnderReview && !stepsState.checklistStep.completed 
            && checklistState.preparer.option1 && checklistState.preparer.option2 && checklistState.preparer.option3 
            && checklistState.preparer.option4 && checklistState.preparer.option5 && checklistState.preparer.option6
        );
    };

    const SYSTEM_CHECKLIST_OPTIONS = [
        "Validated that all relevant data sources have been ingested completely and processed.",
        "Conducted data integrity checks between sources and GL to ensure accuracy and completeness.",
        "100% of transactions ingested have been evaluated for reporting i.e. there is no loss of data from ingestions stage to final calculation stage.",
        "100% of transactions have been assigned Tax Codes and Exception Codes.",
        "Stored complete audit trail of transactions along with intermediary and output files.",
        "Logged all user activities and data changes.",
        "Validated security control to ensure sensitive data protection.",
        "Validated all mandatory attributes are present in data.",
        "Transactions accounted as AP accrual in last reporting period already excluded from AP in current period."
    ];

    const getChecklistTextDiv = (text) => {
        return (
            <div>
                <Icon variant="success" name="status-positive"/>
                {text}
            </div>
        )
    }

    return (
        <Grid gridDefinition={[{colspan: 6}, {colspan: 6}, {colspan: 6}]}>
            <Container header={<Header>System checklist</Header>}>
                <SpaceBetween size="s" direction="vertical">
                    {SYSTEM_CHECKLIST_OPTIONS.map((option) => {return getChecklistTextDiv(option)})}
                </SpaceBetween>
            </Container>
            <Container 
                header={
                    <Header actions={
                        <Button onClick={completeButtonClickHandler} disabled={isCompleteButtonDisabled() || completeButtonState}>
                            Complete
                        </Button>
                    }>
                        Manual Checklist
                    </Header>
                }>
                <SpaceBetween size="s" direction="vertical">
                    <Box variant="h2">Preparer</Box>
                    <Toggle 
                        checked={checklistState.preparer.option1}
                        disabled={(obligationStatus === OBLIGATION_STATUS.UnderApproval || stepsState.checklistStep.completed) || userRole===APPROVER_ROLE}
                        onChange={({detail}) => setChecklistState(prevState => {
                            return {
                                ...prevState,
                                preparer: {
                                    ...prevState.preparer,
                                    option1: detail.checked,
                                }
                            }
                        })}
                    >
                        {<div>{"I’ve reviewed the"} <Link onFollow={()=>{setActiveTabId("first")}}>aggregated summary</Link> {"for this obligation."}</div>}
                    </Toggle>
                    <Toggle 
                        checked={checklistState.preparer.option2}
                        disabled={(obligationStatus === OBLIGATION_STATUS.UnderApproval || stepsState.checklistStep.completed) || userRole===APPROVER_ROLE}
                        onChange={({detail}) => setChecklistState(prevState => {
                            return {
                                ...prevState,
                                preparer: {
                                    ...prevState.preparer,
                                    option2: detail.checked,
                                }
                            }
                        })}
                    >
                        {<div>{"I've reviewed the aggregated amounts against the"} <Link onFollow={()=>{setActiveTabId("second")}}>tax code breakdown summary</Link>{"."}</div>}
                    </Toggle>
                    <Toggle 
                        checked={checklistState.preparer.option3}
                        disabled={(obligationStatus === OBLIGATION_STATUS.UnderApproval || stepsState.checklistStep.completed) || userRole===APPROVER_ROLE}
                        onChange={({detail}) => setChecklistState(prevState => {
                            return {
                                ...prevState,
                                preparer: {
                                    ...prevState.preparer,
                                    option3: detail.checked,
                                }
                            }
                        })}
                    >
                        {<div>{"I’ve reviewed, corrected and considered the impact of accepted"} <Link onFollow={()=>{setActiveTabId("fifth")}}>exceptions</Link>{", highlighted for this obligation."}</div>}
                    </Toggle>
                    <Toggle 
                        checked={checklistState.preparer.option4}
                        disabled={(obligationStatus === OBLIGATION_STATUS.UnderApproval || stepsState.checklistStep.completed) || userRole===APPROVER_ROLE}
                        onChange={({detail}) => setChecklistState(prevState => {
                            return {
                                ...prevState,
                                preparer: {
                                    ...prevState.preparer,
                                    option4: detail.checked,
                                }
                            }
                        })}
                    >
                        {<div>{"I've reviewed all"} <Link onFollow={()=>{setActiveTabId("sixth")}}>adjustments</Link> {"made against misclassified transactions or incorrect data."}</div>}
                    </Toggle>
                    <Toggle 
                        checked={checklistState.preparer.option5}
                        disabled={(obligationStatus === OBLIGATION_STATUS.UnderApproval || stepsState.checklistStep.completed) || userRole===APPROVER_ROLE}
                        onChange={({detail}) => setChecklistState(prevState => {
                            return {
                                ...prevState,
                                preparer: {
                                    ...prevState.preparer,
                                    option5: detail.checked,
                                }
                            }
                        })}
                    >
                        {<div>{"I've provided detailed explanation and uploaded"} <Link onFollow={()=>{setActiveTabId("seventh")}}>attachments</Link> {"for applicable adjustments."}</div>}
                    </Toggle>
                    <Toggle 
                        checked={checklistState.preparer.option6}
                        disabled={(obligationStatus === OBLIGATION_STATUS.UnderApproval || stepsState.checklistStep.completed) || userRole===APPROVER_ROLE}
                        onChange={({detail}) => setChecklistState(prevState => {
                            return {
                                ...prevState,
                                preparer: {
                                    ...prevState.preparer,
                                    option6: detail.checked,
                                }
                            }
                        })}
                    >
                        {<div>{"I’ve reviewed the historic"} <Link onFollow={()=>{setActiveTabId("eight")}}>trends</Link> {"and completed internal control checks to review this obligation."}</div>}
                    </Toggle>
                </SpaceBetween>
                <br />
                <SpaceBetween size="s" direction="vertical">
                    <Box variant="h2">Approver</Box>
                    <Toggle 
                        checked={checklistState.approver.option1}
                        disabled={(obligationStatus === OBLIGATION_STATUS.UnderFiling || obligationStatus === OBLIGATION_STATUS.Filed) || userRole === REVIEWER_ROLE}
                        onChange={({detail}) => setChecklistState(prevState => {
                            return {
                                ...prevState,
                                approver: {
                                    ...prevState.approver,
                                    option1: detail.checked,
                                }
                            }
                        })}
                    >
                        I've reviewed the reviewers checklist.
                    </Toggle>
                    <Toggle
                        checked={checklistState.approver.option2}
                        disabled={obligationStatus === OBLIGATION_STATUS.UnderFiling || obligationStatus === OBLIGATION_STATUS.Filed || userRole === REVIEWER_ROLE}
                        onChange={({detail}) => setChecklistState(prevState => {
                            return {
                                ...prevState,
                                approver: {
                                    ...prevState.approver,
                                    option2: detail.checked,
                                }
                            }
                        })}
                    >
                        {<div>{"I've reviewed that the"} <Link onFollow={()=>{setActiveTabId("sixth")}}>adjustments</Link> {"are logical, reasonable and supported by relevant evidence."}</div>}
                    </Toggle>
                    <Toggle 
                        checked={checklistState.approver.option3}
                        disabled={obligationStatus === OBLIGATION_STATUS.UnderFiling || obligationStatus === OBLIGATION_STATUS.Filed || userRole === REVIEWER_ROLE}
                        onChange={({detail}) => setChecklistState(prevState => {
                            return {
                                ...prevState,
                                approver: {
                                    ...prevState.approver,
                                    option3: detail.checked,
                                }
                            }
                        })}
                    >
                        {<div>{"I've reviewed the attached supporting"} <Link onFollow={()=>{setActiveTabId("seventh")}}>documents.</Link></div>}
                    </Toggle>
                    <Toggle 
                        checked={checklistState.approver.option4}
                        disabled={obligationStatus === OBLIGATION_STATUS.UnderFiling || obligationStatus === OBLIGATION_STATUS.Filed || userRole === REVIEWER_ROLE}
                        onChange={({detail}) => setChecklistState(prevState => {
                            return {
                                ...prevState,
                                approver: {
                                    ...prevState.approver,
                                    option4: detail.checked,
                                }
                            }
                        })}
                    >
                        I've reviewed the systematic checks and controls in the system
                    </Toggle>
                </SpaceBetween>
            </Container>
        </Grid>
    )
}
import React from "react";
import {Box, SpaceBetween, Toggle} from "@amzn/awsui-components-react";

export default function ChecklistToggle(props) {
    const {checklistOption, checkedValue, setCheckListOptionsState, checklistText} = props;

    return (
        <SpaceBetween size='l' direction='horizontal'>
            <Toggle checked={checkedValue}
                    onChange={({detail}) => setCheckListOptionsState(prevState => {
                        return {
                            ...prevState,
                            [checklistOption]: detail.checked,
                        }
                    })}/>
            <Box>{checklistText}</Box>
        </SpaceBetween>
    );
}
import React, { useEffect, useState } from "react";
import Table from "@amzn/awsui-components-react/polaris/table";
import Box from "@amzn/awsui-components-react/polaris/box";
import Header from "@amzn/awsui-components-react/polaris/header";
import { getColumnDefinitions } from "./table-config";
import { get, isEmpty, set, isNull } from 'lodash';
import { getTransactionStatusMapping } from "./utils/utils";
import TableDownloadButton, {DEFINITION_TYPE_POLARIS} from "../exception/components/common/TableDownloadButtonComponent";

export default function TransactionCategoryTable(props) {
    const {
        sourceSummaryData,
        selectedItems,
        phastosObligation,
        workflowData
    } = props;

    const [data, setData] = useState([]);

    useEffect(() => {
        const result = [];
        for (const selectedItem of selectedItems) {
            if (sourceSummaryData.transactionDataForSource[selectedItem.sourceType]) {
                const transactionStatusMapping = getTransactionStatusMapping(phastosObligation);
                for (const sourceSummaryItem of sourceSummaryData.transactionDataForSource[selectedItem.sourceType]) {
                    if (!isNull(transactionStatusMapping) && !isEmpty(get(sourceSummaryItem, 'transactionStatus', ''))) {
                        set(sourceSummaryItem, 'transactionStatus', get(transactionStatusMapping, get(sourceSummaryItem, 'transactionStatus', ''), ''));
                    }
                    result.push(sourceSummaryItem);
                }
            }
        }
        setData(result);
    }, [selectedItems, sourceSummaryData, phastosObligation]);

    return (
        <Table
            items={data}
            loading={sourceSummaryData.fetchingDataForSource}
            columnDefinitions={getColumnDefinitions(phastosObligation)}
            loadingText="Loading resources"
            sortingDisabled
            empty={
                <Box textAlign="center" color="inherit">
                    <b>No data</b>
                    <Box
                        padding={{ bottom: "s" }}
                        variant="p"
                        color="inherit"
                    >
                        Select a source to view transaction data
                    </Box>
                </Box>
            }
            header={<Header actions={
                <TableDownloadButton data={data}
                                     nameProps={{
                                         obligationName: workflowData?.obligationName,
                                         reportingPeriod: workflowData?.reportingPeriod,
                                         country: workflowData?.country
                                     }}
                                     tableId={'Summary of Transaction Status for Source'}
                                     columnDefinition={getColumnDefinitions(phastosObligation)}
                                     tableType={DEFINITION_TYPE_POLARIS}
                                     additionalButtonDisableFlag={(sourceSummaryData.sourceSummary.length !== selectedItems.length)}
                                     isHeaderButton={true}
                />
            }>Summary of Transaction Status for Source</Header>}
        />
    );
}
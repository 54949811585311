import { v4 as uuid4 } from 'uuid';
import {getAPIInputContext} from "../../../common/utils/apiUtils";
let CryptoJS = require("crypto-js");

export const getHMRCHeaders = async () => {
    const data = await getAPIInputContext()
    return {
        "Gov-Client-Connection-Method": "WEB_APP_VIA_SERVER",
        "Gov-Client-Browser-Do-Not-Track": Boolean(navigator.doNotTrack).toString(),
        "Gov-Client-Browser-JS-User-Agent": navigator.userAgent,
        "Gov-Client-Device-ID": getDeviceId(),
        "Gov-Client-Screens": `width=${window.screen.width}&height=${window.screen.height}&scaling-factor=1&colour-depth=${window.screen.colorDepth}`,
        "Gov-Client-Timezone": getClientTimezone(),
        "Gov-Client-User-IDs": `e-filer=${encryptText(data.body.user)}`,
        "Gov-Client-Window-Size": `width=${window.innerWidth}&height=${window.innerHeight}`,
        "Gov-Vendor-Version": "TwinWebsite=1.0"
    }
}

export function encryptText(text) {
    const encrypted = CryptoJS.AES.encrypt(text, process.env.REACT_APP_SECRET_KEY);
    return encrypted.toString();
}

export const getDeviceId = () => {
    if(localStorage.getItem("device_id") != null) {
        return localStorage.getItem("device_id")
    } else {
        let uniqueId = uuid4();
        localStorage.setItem("device_id", uniqueId)
    }
}

export const getTaxIdentifier = () => {
    if(!localStorage.getItem("tax_identifier")) {
        setTaxIdentifier()
    }
    return localStorage.getItem("tax_identifier")
}

export const setTaxIdentifier = () => {
    let uniqueId = uuid4();
    localStorage.setItem("tax_identifier", uniqueId);
}

// Method to extract the timezone expressed as: UTC±<hh>:<mm>
const getClientTimezone = () => {
    let offset = new Date().getTimezoneOffset(), o = Math.abs(offset);
    return "UTC" + (offset < 0 ? "+" : "-") + ("00" + Math.floor(o / 60)).slice(-2) + ":" + ("00" + (o % 60)).slice(-2);
}
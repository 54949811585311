import React from "react";
import { Container, Button, Checkbox } from "@amzn/awsui-components-react/polaris";
import {FILING_CONFIG} from '../utils/Constants' 

function SubmitFiling(params) {

    const { uploading, handleSubmitClick, disabled, useCase } = params;
    const [checked, setChecked] = React.useState(false);

    const onSubmitClick = () => {
        handleSubmitClick();
        setChecked(false);
    }

    return (
        <>
            <Container>
                <Checkbox
                    onChange={({ detail }) =>
                        setChecked(detail.checked)
                    }
                    checked={checked}
                >
                { FILING_CONFIG[useCase.useCaseValue]?.concentMessage ? FILING_CONFIG[useCase.useCaseValue].concentMessage : 
                "I have reviewed the information provided and ready to submit them to tax authority" }
                </Checkbox>
            </Container>
            <div className="submitBox">
                <Button variant="primary" loading={uploading} disabled={!checked || disabled } onClick={onSubmitClick}>Submit</Button>
            </div>
        </>
    );
}

export default SubmitFiling;

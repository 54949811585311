import React, {useState} from "react";
import { Container, Header, SpaceBetween } from "@amzn/awsui-components-react";
import AdjustmentsModal from "../common/adjustment/AdjustmentModal";
import QuickSightFrame from "../common/QuickSightFrame";

export default function ViewSummaryBreakdown(props) {
    const { exceptionData, useCase} = props;

    const [showAdjustmentModal, setShowAdjustmentModal] = useState(false);

    const adjustmentModalDismissHandler = () => {
        setShowAdjustmentModal(false);
    };
    /*
    Adjustment Modal component is not getting used, in case this component is needed in the future ,
    please add dispatcher and other important attributes
    */

    return (
        <SpaceBetween>
            <Container header={<Header>Intrastat Summary</Header>}>
                <QuickSightFrame
                    exceptionData={exceptionData}
                    useCase={useCase}
                    linkKey = 'SUMMARY_BREAKDOWN'/>
            </Container>
            <AdjustmentsModal
                showAdjustmentModal={showAdjustmentModal}
                onDismiss={adjustmentModalDismissHandler}
            />
        </SpaceBetween>
    );
}
import React from "react";
import { Table, Container, Header } from "@amzn/awsui-components-react/polaris";
import { faqData } from "./listOfFaq";

function Faq(params) {
    return (
        <Container>
            <Header variant="h1" className="header">FAQ's</Header>
            {faqData.map((item, index) => {
                return (
                    <Container
                        key={index}
                        header={
                            <Header
                                variant="h2"
                                description=""
                            >
                                {item.ques}
                            </Header>
                        }
                    >
                        {item.type === 'table' ? 
                            <Table visibleColumns={[
                                "col1",
                                "col2",
                                "col3",
                                "col4",
                            ]} columnDefinitions={[
                                {
                                  id: "col1",
                                  header: "#",
                                  cell: e => e.col1,
                                },
                                {
                                  id: "col2",
                                  header: "Tax Report",
                                  cell: e => e.col2,
                                },
                                { id: "col3", 
                                  header: "Report Type", 
                                  cell: e => e.col3 
                                },
                                {
                                  id: "col4",
                                  header: "Jurisdiction",
                                  cell: e => e.col4
                                }
                              ]} items={item.ans} 
                             />
                        : item.ans}
                        
                    </Container>
                )
            })}
        </Container>
    );
}

export default Faq;

import React, { useState, useEffect } from "react";
import { Header, SpaceBetween, Table, Button } from "@amzn/awsui-components-react";
import AdjustmentsModal from "./AdjustmentModal";
import { useCollection } from "@amzn/awsui-collection-hooks";
import {
    getMatchesCountText,
    PROPERTY_FILTERING_I18N_CONSTANTS,
} from "../../../../phastos/table-config";
import { Icon, Pagination, PropertyFilter } from "@amzn/awsui-components-react/polaris";
import { Preferences } from "../../../table-config";
import { getUserAlias } from "../../../utils/utils";
import { modifyWorkflowStatus } from "../../redux/exceptionAPIs";
import { TransactionsAudit } from "../TransactionsAudit";
import { postMessage } from "../../../../phastos/components/Slack/slackIntegrationAPIs";
import { ACKNOWLEDGEMENT_STRUCTURE_MAP_KEY, ASM_COMPLETE_STATUS } from "../constants";
import { COLUMN_IDS, collectionOptions, columnDefinitions } from "./constants/boxLevelConstants";
import {getBoxLevelAdjustmentAudit} from "../../../../phastos/components/phastosAPIs";
import TableDownloadButton, {DEFINITION_TYPE_POLARIS} from "../TableDownloadButtonComponent";

function Adjustments(props) {
    const { 
        setStepsStateHandler,
        updateWorkflowStatusDispatcher,
        isUnderReview,
        stepsState,
        parentWorkflowId,
        slackChannel,
        isVAT,
        workflowIdentificationProps
    } = props;
    const [showAdjustmentModal, setShowAdjustmentModal] = useState(false);

    const adjustmentModalDismissHandler = () => {
        setShowAdjustmentModal(false);
    };

    const [completeButtonState, setCompleteButtonState] = useState(false);

    const completeButtonClickHandler = async () => {
        setCompleteButtonState(true);
        const userAlias = await getUserAlias();
        const request = {
            parentWorkflowId: parentWorkflowId,
            acknowledgementStructureMap: {
                [ACKNOWLEDGEMENT_STRUCTURE_MAP_KEY.Audit]: { status: ASM_COMPLETE_STATUS, user: userAlias }
            },
        };
        const onSuccess = () => {
            postMessage(slackChannel, `${userAlias}@ has acknowledged the Audit step.`);
            setStepsStateHandler("auditStep");
        };
        modifyWorkflowStatus(updateWorkflowStatusDispatcher, request, onSuccess);
        setCompleteButtonState(false);
    };

    const isCompleteButtonDisabled = () => {
        return !(isUnderReview && !stepsState.auditStep.completed);
    };

    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true);
        getBoxLevelAdjustmentAudit(parentWorkflowId)
            .then(data => {
                setData(data);
            });
        setLoading(false);
        // eslint-disable-next-line
    }, []);

    const [preferences, setPreferences] = useState({ 
        pageSize: 10,
        visibleContent: [
            COLUMN_IDS.AdjustmentDescription,
            COLUMN_IDS.AdjustmentAmount,
            COLUMN_IDS.BASBox,
            COLUMN_IDS.VariancePercent,
            COLUMN_IDS.UpdatedBy,
            COLUMN_IDS.UpdatedOn,
        ],
    });

    const { items, filteredItemsCount, collectionProps, propertyFilterProps, paginationProps } = useCollection(data, collectionOptions(preferences));

    const [selectedBoxItems, setSelectedBoxItems] = useState([]);
    // TODO: - Remove if not required
    // eslint-disable-next-line no-unused-vars
    const [selectedTxnItems, setSelectedTxnItems] = useState([]);
    const onBoxSelectionChange = (item) => {
        setSelectedBoxItems(item.detail.selectedItems)
    };
    // eslint-disable-next-line no-unused-vars
    const onTxnSelectionChange = (item) => {
        setSelectedTxnItems(item.detail.selectedItems)
    };

    return (
        <SpaceBetween size="xl">
            {isVAT ? <Table
                {...collectionProps}
                onSelectionChange={onBoxSelectionChange}
                header={
                    <div>
                        <Header actions={<Button onClick={completeButtonClickHandler}
                                                 disabled={isCompleteButtonDisabled() || completeButtonState}>Acknowledge</Button>}
                                description={
                                    <div>
                                        <Icon name="status-info" size="small"></Icon>  
                                        <span style={{color: "red"}}>Highlighted</span> text indicates adjustment amount exceeds defined variance of 2%
                                    </div>
                                }>
                            Box level audit
                        </Header>
                    </div>
                }
                columnDefinitions={columnDefinitions}
                visibleColumns={preferences.visibleContent}
                selectedItems={selectedBoxItems}
                items={items}
                trackBy="basBox"
                pagination={<Pagination {...paginationProps} />}
                loading={loading}
                preferences={<SpaceBetween size="m" direction="horizontal">
                    <TableDownloadButton data={items}
                                         nameProps={workflowIdentificationProps}
                                         tableId={'Box Level Audit'}
                                         columnDefinition={columnDefinitions}
                                         tableType={DEFINITION_TYPE_POLARIS}
                    />
                    <Preferences preferences={preferences} setPreferences={setPreferences} visibleContentOptions={[
                    {
                        label: 'Box label  properties',
                        options: [
                            { id: COLUMN_IDS.BASBox, label: 'BAS box' },
                            { id: COLUMN_IDS.AdjustmentDescription, label: 'Adjustment description' },
                            { id: COLUMN_IDS.AdjustmentAmount, label: 'Adjustment amount' },
                            { id: COLUMN_IDS.VariancePercent, label: 'Variance percent' },
                            { id: COLUMN_IDS.UpdatedBy, label: 'Updated by' },
                            { id: COLUMN_IDS.UpdatedOn, label: 'Updated on' },
                        ],
                    },
                ]}/>
                </SpaceBetween>}
                filter={
                    <PropertyFilter
                        i18nStrings={PROPERTY_FILTERING_I18N_CONSTANTS}
                        filteringProperties={propertyFilterProps.filteringProperties}
                        filteringOptions={propertyFilterProps.filteringOptions}
                        onChange={propertyFilterProps.onChange}
                        query={propertyFilterProps.query}
                        countText={getMatchesCountText(filteredItemsCount)}
                    />
                }
            />: null}
            <TransactionsAudit parentWorkflowId={parentWorkflowId} workflowIdentificationProps = {workflowIdentificationProps}/>
            <AdjustmentsModal
                showAdjustmentModal={showAdjustmentModal}
                onDismiss={adjustmentModalDismissHandler}
            />
        </SpaceBetween>
    );
}

export default Adjustments;
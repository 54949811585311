export const AUTHORIZATION_INITIAL_STATE = {
    data: undefined,
};
 
export const authorizationReducerInit = initialState =>{
    return initialState;
}
 
export const authorizationReducer = (state, action) => {
    switch (action.type){
        case "SUCCESS":
            const { pageName, response } = action?.payload;
            return {
                ...state,
                data: {
                    ...state.data,
                    [pageName]: {
                        isAuthorized: response?.status === 200 && response?.isAuthorized === true,
                        isInternalError: response?.status !== 200,
                    }
                }
            };
        default:
            return state;
    }
}
import React, { useEffect } from "react";
import Table from "@amzn/awsui-components-react/polaris/table";
import Header from "@amzn/awsui-components-react/polaris/header";
import {Pagination, PropertyFilter, SpaceBetween} from "@amzn/awsui-components-react";
import Box from "@amzn/awsui-components-react/polaris/box";
import CollectionPreferences from "@amzn/awsui-components-react/polaris/collection-preferences";
import { fetchDataForSourceType } from "./redux/sourceSummaryAPI";
import { useCollection } from "@amzn/awsui-collection-hooks";
import { EmptyState, getMatchesCountText, PROPERTY_FILTERING_I18N_CONSTANTS } from "../phastos/table-config";
import {getSourceSummaryColumnDef, getSourceSummaryFilteringProperties, getSourceSummaryVisibleContentDef} from "./table-config";
import TableDownloadButton, {DEFINITION_TYPE_POLARIS} from "../exception/components/common/TableDownloadButtonComponent";

const Actiontable = (params) => {
    const {
        selectedItems,
        setSelectedItems,
        workflowData,
        sourceSummaryData,
        sourceSummaryTableDispatcher,
        refreshSourceSummaryData,
        phastosObligation,
    } = params;

    const onSelectionChange = (item) => {
        setSelectedItems(item.detail.selectedItems)
    };

    useEffect(() => {
        if (selectedItems.length === 0) {
            sourceSummaryTableDispatcher({type: "RESET_DATA_FOR_SOURCE_DATA", payload: {}});
        } else {
            sourceSummaryTableDispatcher({
                type: "API_BEGIN_FOR_SOURCE_DATA", payload: {}
            });
            fetchDataForSourceType(sourceSummaryTableDispatcher, {
                parentWorkflowId: workflowData.parentWorkflowId,
                selectedItems,
                useCase: workflowData?.obligationName, 
                reportingPeriod: workflowData?.reportingPeriod,
                phastosObligation: phastosObligation
            });
        }
        // eslint-disable-next-line
    }, [selectedItems]);

    // eslint-disable-next-line no-unused-vars
    const { items, filteredItemsCount, collectionProps, propertyFilterProps, paginationProps } = useCollection(
        sourceSummaryData.sourceSummary,
        {
            filtering: {
                empty: (
                    <EmptyState
                        title="No summary"
                        subtitle="No summary to display."
                    />
                ),
                noMatch: (
                    <EmptyState
                        title="No matches"
                        subtitle="We can’t find a match."
                    />
                ),
            },
            propertyFiltering: {
                filteringProperties: getSourceSummaryFilteringProperties(phastosObligation),
                empty: <EmptyState title="No matches" />,
                noMatch: (
                    <EmptyState
                        title="No matches"
                    />
                ),
            },
            sorting: {
                defaultState: {
                    sortingColumn: {
                        sortingField: "progress",
                    },
                    isDescending: true
                }},
            selection: {}
        }
    );

    useEffect(() => {
        refreshSourceSummaryData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // TODO: - Use constants
    return (
        <Table
            items={items}
            loading={sourceSummaryData.fetchingSourceSummary}
            columnDefinitions={getSourceSummaryColumnDef(phastosObligation)}
            loadingText="Loading"
            selectionType="multi"
            trackBy="sourceType"
            visibleColumns={[
                "sourceType",
                "status",
                "progress",
                "transactionCount",
                "exceptionCount",
                "invoiceValue",
                "statisticalValue",
                "reportSource"
            ]}
            empty={
                <Box textAlign="center" color="inherit">
                    <b>No source data</b>
                    <Box
                        padding={{ bottom: "s" }}
                        variant="p"
                        color="inherit"
                    >
                        No source data to display.
                    </Box>
                </Box>
            }
            header={
                <Header
                    counter={
                        selectedItems.length
                            ? "(" + selectedItems.length + "/" + sourceSummaryData.sourceSummary.length + ")"
                            : "(" + sourceSummaryData.sourceSummary.length + ")"
                    }
                    description={`Last updated: ${sourceSummaryData.lastUpdatedMap.sourceSummary}`}
                >
                    Source Data Summary
                </Header>
            }
            pagination={
                <Pagination
                    currentPageIndex={1}
                    pagesCount={1}
                    ariaLabels={{
                        nextPageLabel: "Next page",
                        previousPageLabel: "Previous page",
                        pageLabel: pageNumber =>
                            `Page ${pageNumber} of all pages`
                    }}
                />
            }
            preferences={<SpaceBetween size="m" direction="horizontal">
                <TableDownloadButton data={items}
                                     nameProps={{
                                         obligationName: workflowData?.obligationName,
                                         reportingPeriod: workflowData?.reportingPeriod,
                                         country: workflowData?.country
                                     }}
                                     tableId={'Source Data Summary'}
                                     columnDefinition={getSourceSummaryColumnDef(phastosObligation)}
                                     tableType={DEFINITION_TYPE_POLARIS}/>
                <CollectionPreferences
                    title="Preferences"
                    confirmLabel="Confirm"
                    cancelLabel="Cancel"
                    preferences={{
                        pageSize: 10,
                        visibleContent: [
                            "sourceType",
                            "status",
                            "progress",
                            "transactionCount",
                            "exceptionCount",
                        ]
                    }}
                    pageSizePreference={{
                        title: "Page size",
                        options: [
                            { value: 10, label: "10 data" },
                            { value: 20, label: "20 data" }
                        ]
                    }}
                    wrapLinesPreference={{
                        label: "Wrap lines",
                        description:
                            "Select to see all the text and wrap the lines"
                    }}
                    stripedRowsPreference={{
                        label: "Striped rows",
                        description:
                            "Select to add alternating shaded rows"
                    }}
                    contentDensityPreference={{
                        label: "Compact mode",
                        description:
                            "Select to display content in a denser, more compact mode"
                    }}
                    visibleContentPreference={getSourceSummaryVisibleContentDef(phastosObligation)}
                /></SpaceBetween>
            }
            {...collectionProps}
            filter={
                <PropertyFilter
                    i18nStrings={PROPERTY_FILTERING_I18N_CONSTANTS}
                    filteringProperties={propertyFilterProps.filteringProperties}
                    filteringOptions={propertyFilterProps.filteringOptions}
                    onChange={propertyFilterProps.onChange}
                    query={propertyFilterProps.query}
                    countText={getMatchesCountText(filteredItemsCount)}
                />
            }
            onSelectionChange={onSelectionChange}
            selectedItems={selectedItems}
        />
    );
}

export default Actiontable;
import {API} from "aws-amplify";
import {getAPIInputContext, formatDate} from "../../../common/utils/apiUtils";
import {
    PHASTOS_FETCH_OBLIGATIONS,
    PHASTOS_CREATE_ADHOC_REQUEST,
    PHASTOS_FETCH_ALL_DROPDOWN_FIELDS,
    PHASTOS_GET_WORKFLOW_DETAILS,
    PHASTOS_GET_JOB_DETAILS,
    PHASTOS_SEARCH_DOCUMENT_BY_METADATA,
    PHASTOS_GET_BOX_LEVEL_ADJUSTMENT_AUDIT,
    PHASTOS_PUT_BOX_LEVEL_ADJUSTMENT,
    PHASTOS_CREATE_CONFIG_FILE_RCM,
    PHASTOS_EXPORT_AGGREGATED_REPORT,
    PHASTOS_FETCH_ALL_BRAAVOS_REPORT_IDS,
    PHASTOS_GET_DOWNLOAD_FILE_URL,
    TAKE_ACTION_ON_WORKFLOW
} from "../../../ApiPaths";
import AppConfig from "../../../AppConfig";
import {extractSearchStateMap} from "../../../app/documentmanager/constants/Constants"
import {downloadFileFromPresignedURL} from "../../../common/utils/s3Utils";


/**
 * This function will fetch obligations for Phastos
 * @param tableDispatcher
 */
export function fetchObligations(tableDispatcher) {

    getAPIInputContext().then(input => {

        API.post("TWIN_API", PHASTOS_FETCH_OBLIGATIONS, input).then(resp => {
            if (resp.status === 200) {
                tableDispatcher({
                    type: "API_RESPONSE", payload: {
                        data: JSON.parse(resp?.body?.obligationData),
                    }
                })
            } else {
                tableDispatcher({
                    type: "FAILURE_API_RESPONSE", payload: {}
                })
            }
        });
    });

}

export function fetchDropDownList(tableDispatcher) {
    getAPIInputContext().then(input => {

        API.post("TWIN_API", PHASTOS_FETCH_ALL_DROPDOWN_FIELDS, input).then(resp => {
            if (resp.status === 200) {
                tableDispatcher({
                    type: "CUSTOM_FIELDS_SUCCESS", payload: {
                        data: resp?.body,
                    }
                })
            } else {
                tableDispatcher({
                    type: "CUSTOM_FIELDS_FAILURE", payload: {}
                })
            }
        }, (err) => {
            console.log(PHASTOS_FETCH_ALL_DROPDOWN_FIELDS + " API failed, error : " + err);
            tableDispatcher({
                type: "CUSTOM_FIELDS_FAILURE", payload: {}
            })
        });
    });
}

export function fetchBraavosReportIds(braavosGetReportRequest, tableDispatcher) {
    getAPIInputContext().then(input => {
        input.body = braavosGetReportRequest;
        API.post("TWIN_API", PHASTOS_FETCH_ALL_BRAAVOS_REPORT_IDS, input).then(resp => {
            if (resp.status === 200) {
                tableDispatcher({
                    type: "BRAAVOS_GET_REPORTID_SUCCESS", payload: {
                        data: JSON.parse(resp?.body?.data),
                    }
                })
            } else {
                tableDispatcher({
                    type: "BRAAVOS_GET_REPORTID_FAILURE", payload: {}
                })
            }
        }, (err) => {
            console.log(PHASTOS_FETCH_ALL_BRAAVOS_REPORT_IDS + " API failed, error : " + err);
            tableDispatcher({
                type: "BRAAVOS_GET_REPORTID_FAILURE", payload: {errMsg: "Exception occurred while fetching report details from Braavos, please try again later", dispatcher: tableDispatcher}
            })
        });
    });
}

export function createAdhocRequest(tableDispatcher, obj) {
    getAPIInputContext().then(input => {
        input.body = obj;
        API.post("TWIN_API", PHASTOS_CREATE_ADHOC_REQUEST, input).then(resp => {
            console.log(PHASTOS_CREATE_ADHOC_REQUEST + " API was called, response : " + resp);
            if (resp.status === 200) {
                tableDispatcher({
                    type: "ADHOC_REQUEST_SUCCESS", payload: {
                        adhocRequestData: {
                            startDate: obj.startDate,
                            endDate: obj.endDate,
                            countryCode: obj.countryCode,
                            useCase: obj.useCaseName
                        },
                        msg: `An adhoc report for ${obj.useCaseName} from ${formatDate(obj.startDate)} to ${formatDate(obj.endDate)} has been initiated`,
                        dispatcher: tableDispatcher
                    }
                })
            } else {
                tableDispatcher({
                    type: "ADHOC_REQUEST_FAILURE",
                    payload: {errMsg: "Something went wrong, please try again later", dispatcher: tableDispatcher}
                })
            }
        }, (err) => {
            console.log(PHASTOS_CREATE_ADHOC_REQUEST + " API failed, error : " + err);
            tableDispatcher({
                type: "ADHOC_REQUEST_FAILURE",
                payload: {errMsg: "Something went wrong, please try again later", dispatcher: tableDispatcher}
            })
        });
    });

}

/**
 * API to create specs config file in RCM
 * @param tableDispatcher
 * @param obj
 */
export function createAdhocReportRequest(tableDispatcher, adhocReportRequest) {
    getAPIInputContext().then(input => {
        input.body = adhocReportRequest;
        API.post("TWIN_API", PHASTOS_CREATE_CONFIG_FILE_RCM, input).then(resp => {
            console.log(PHASTOS_CREATE_CONFIG_FILE_RCM + " API was called, response : " + resp);
            if (resp.status === 200) {
                tableDispatcher({
                    type: "ADHOC_REPORT_REQUEST_SUCCESS", payload: {
                        msg: `An adhoc report request for ${adhocReportRequest.braavosReportIds} with ${adhocReportRequest.phastosUseCaseName} has been created in RCM`,
                        dispatcher: tableDispatcher
                    }
                })
            } else {
                tableDispatcher({
                    type: "ADHOC_REPORT_REQUEST_FAILURE",
                    payload: {errMsg: "Something went wrong, please try again later", dispatcher: tableDispatcher}
                })
            }
        }, (err) => {
            console.log(PHASTOS_CREATE_CONFIG_FILE_RCM + " API failed, error : " + err);
            tableDispatcher({
                type: "ADHOC_REPORT_REQUEST_FAILURE",
                payload: {errMsg: "Something went wrong, please try again later", dispatcher: tableDispatcher}
            })
        });
    });

}

/**
 * API to fetch the aggregated data report of Trode
 * @param tableDispatcher
 * @param itemData
 * @param alias
 */
export function phastosExportAggregatedReport(tableDispatcher, itemData, alias) {
    getAPIInputContext().then(async input => {
        input.body.parentWorkflowId = itemData?.parentWorkflowId;
        input.body.phastosUseCaseName = itemData?.phastosUseCaseName;
        input.body.alias = alias;
        input.body.reportingPeriod = itemData?.reportingPeriod;

        API.post("TWIN_API", PHASTOS_EXPORT_AGGREGATED_REPORT, input).then(resp => {
            console.log(PHASTOS_EXPORT_AGGREGATED_REPORT + " API was called, response : " + resp);
            if (resp.status === 200) {
                console.log("PHASTOS_EXPORT_AGGREGATED_REPORT executed successfully");
                tableDispatcher({
                    type: "EXPORT_AGGREGATED_REPORT_SUCCESS", payload: {
                        msg: `Exported Aggregated report for ${itemData?.parentWorkflowId} successfully`,
                        dispatcher: tableDispatcher
                    }
                })
            } else {
                tableDispatcher({
                    type: "EXPORT_AGGREGATED_REPORT_FAILURE", payload: { errMsg: "Something went wrong, please try again later", dispatcher: tableDispatcher}
                })
            }
        }, (err) => {
            console.log(PHASTOS_EXPORT_AGGREGATED_REPORT + " API failed, error : " + err);
            tableDispatcher({
                type: "EXPORT_AGGREGATED_REPORT_FAILURE", payload: { errMsg: "Something went wrong, please try again later", dispatcher: tableDispatcher}
            })
        });
    });

}

export function fetchWorkflows(tableDispatcher) {

    getAPIInputContext().then(input => {

        API.post("TWIN_API", PHASTOS_GET_WORKFLOW_DETAILS, input).then(resp => {
            if (resp.status === 200) {
                tableDispatcher({
                    type: "API_RESPONSE", payload: {
                        data: JSON.parse(resp?.body?.obligationData),
                    }
                })
            } else {
                tableDispatcher({
                    type: "FAILURE_API_RESPONSE", payload: {}
                })
            }
        });
    });

}

export function fetchJobDetailsForWorkflow(tableDispatcher, parentWorkflowIdPrefix) {
    getAPIInputContext().then(input => {
        input.body.parentWorkflowIdPrefix = parentWorkflowIdPrefix;
        API.post("TWIN_API", PHASTOS_GET_JOB_DETAILS, input).then(resp => {
            if (resp.status === 200) {
                tableDispatcher({
                    type: "API_RESPONSE", payload: {
                        data: JSON.parse(resp?.body?.jobData),
                    }
                })
            } else {
                tableDispatcher({
                    type: "FAILURE_API_RESPONSE", payload: {}
                })
            }
        });
    });
}

export function fetchDocumentListThroughMetadataSearch(tableDispatcher, searchStateMap, domain) {
    getAPIInputContext().then(input => {
        input.body.documentAdditionalDataMap = extractSearchStateMap(searchStateMap);
        input.body.clientID = AppConfig[domain].EXCALIBUR_CLIENT_ID;
        API.post("TWIN_API", PHASTOS_SEARCH_DOCUMENT_BY_METADATA, input).then(resp => {
            if (resp.status === 200) {
                tableDispatcher({
                    type: "API_RESPONSE", payload: {
                        data: resp?.body?.DocumentRegistrationDataList,
                    }
                })
            } else {
                tableDispatcher({
                    type: "FAILURE_API_RESPONSE", payload: {}
                })
            }
        });
    });
}

export function getBoxLevelAdjustmentAudit(parentWorkflowId)  {
    return getAPIInputContext().then(input => {
        input.body.parentWorkflowId = parentWorkflowId;
        return API.post("TWIN_API", PHASTOS_GET_BOX_LEVEL_ADJUSTMENT_AUDIT, input).then(resp => {
            if (resp.status === 200) {
                return JSON.parse(resp.body.data).businessObjects;
            } else return [];
        });
    });
}

export const boxLevelAdjustment = (input, dispatcher) => {
    API.post("TWIN_API", PHASTOS_PUT_BOX_LEVEL_ADJUSTMENT, input).then(resp => {
        if (resp.status === 200) {
            dispatcher({
                type: "API_REQUEST_SUCCESS", payload: {
                    msg: "Adjustment applied successfully.",
                    dispatcher: dispatcher
                }
            });
        } else {
            dispatcher({
                type: "API_REQUEST_FAILURE", payload: {
                    errMsg: "Adjustment Failed",
                    dispatcher: dispatcher
                }
            });
        }
    });
}

        export const downloadObligationDetails = (request, downloadDocumentDispatcher, phastosUseCaseName) => {
    getAPIInputContext().then(input => {
        //TODO: Pass useCase as well for authorisation
        input.body.parentWorkflowId = request.parentWorkflowId;
        input.body.jobId = request.jobId;
        input.body.source = request.jobType;
        input.body.phastosUseCaseName = phastosUseCaseName;

        API.post("TWIN_API", PHASTOS_GET_DOWNLOAD_FILE_URL, input).then(resp => {
            if (resp.status === 200 && resp.downloadFileUrl !== null) {
                    downloadFileFromPresignedURL(resp.downloadFileUrl);
                    downloadDocumentDispatcher({
                        type: "DOWNLOAD_DOCUMENT_SUCCESS", payload: {dispatcher: downloadDocumentDispatcher}
                    })
            } else {
                console.log(PHASTOS_GET_DOWNLOAD_FILE_URL + " API Error Response : " + JSON.stringify(resp));
                downloadDocumentDispatcher({
                    type: "DOWNLOAD_DOCUMENT_FAILURE", payload: {dispatcher: downloadDocumentDispatcher}
                })
            }
        });
    });
}

export function takeActionForWorkflow(tableDispatcher, request, onSuccess) {
    getAPIInputContext().then(input => {
        input.body.workflowId = request.workflowId;
        input.body.actionType = request.actionType;
        input.body.requestor = request.requestor
        API.post("TWIN_API", TAKE_ACTION_ON_WORKFLOW, input).then(resp => {
            if (resp.status === 200) {
                tableDispatcher({
                    type: "API_REQUEST_SUCCESS", payload: {
                        msg: 'Action initiated successfully.',
                        dispatcher: tableDispatcher,
                        requestId: resp.requestId,
                    }
                });
                if (onSuccess) {
                    onSuccess();
                }
            } else {
                tableDispatcher({
                    type: "API_REQUEST_FAILURE", payload: {
                        errMsg: 'Failed to initiate action.',
                        dispatcher: tableDispatcher,
                    }
                })
            }
        });
    });

}


import { Box, CollectionPreferences } from '@amzn/awsui-components-react/polaris';
import { DateTimeForm, formatDateTime } from './table-date-filter-forms';
import Button from "@amzn/awsui-components-react/polaris/button";
import { formatDate, getIntermediateDeadline, numberWithCommas } from '../../common/utils/apiUtils';
import {convertToInternationalCurrencySystem} from "../../common/utils/commonUtils";
import {downloadObligationDetails} from "./components/phastosAPIs";
import {
  DATA_SHARING, EXPORT_SUPPORTED_TASK_TYPES,
  SUCCESS
} from "./Constants/Constants";

export const defaultSortingAppliedOn = {
  id: 'intermediateDeadline',
  header: 'Intermediate Deadline',
  cell: item => formatDate(getIntermediateDeadline(item.filingDeadline)),
  sortingField: "filingDeadline",
  minWidth: '80px'
};

export const isActionButtonEnabled = (value, isExportEnabled) => {
  if (value.obligationName === DATA_SHARING && isExportEnabled && EXPORT_SUPPORTED_TASK_TYPES.includes(value.jobType)) return value.status === SUCCESS;
  return false;
}

export const scheduledColumnDefinitions = (downloadDocumentDispatcher, isExportEnabled, phastosUseCaseName) => [
  {
    id: 'obligation',
    header: 'Obligation',
    cell: item => (
        item.obligationName
    ),
    minWidth: '180px'
  },
  {
    id: 'entity',
    cell: item => item.entity,
    header: 'Entity',
    minWidth: '80px'
  },
  {
    id: 'period',
    cell: item => item.reportingPeriod,
    header: 'Period',
    minWidth: '40px'
  },
  {
    id: 'country',
    header: 'Country',
    cell: item => item.country,
    minWidth: '30px'
  },
  {
    id: 'type',
    header: 'Job Type',
    cell: item => item.jobType,
    minWidth: '50px'
  },
  {
    id: 'status',
    header: 'Status',
    cell: item => item.status,
    minWidth: '60px'
  },
  {
    id: 'scheduledExecutionTime',
    header: 'Scheduled Time',
    cell: item => formatDate(item.scheduledExecutionTime),
    minWidth: '60px'
  },
  {
    id: 'grossMerchantSaleAmount',
    header: 'Gross Merchant Sale Amount',
    cell: item => {
      if(item.exceptionData?.reportableAmount) {
        return item.exceptionData?.currencySymbol + convertToInternationalCurrencySystem((item.exceptionData?.reportableAmount));
      }
      return "-";
    },
    minWidth: '100px'
  },
  {
    id: 'totalUniqueMerchants',
    header: 'Total Unique Merchants',
    cell: item => item.exceptionData?.reportableUniqueSellerCount,
    minWidth: '100px'
  },
  {
    id: 'action',
    header: 'Actions',
    cell: item => {
      return (
          <div className='action-box'>
            <Button iconName="download" variant="inline-icon"
                    disabled={!isActionButtonEnabled(item, isExportEnabled)}
                    onClick={() => downloadObligationDetails(item, downloadDocumentDispatcher, phastosUseCaseName)}/>
          </div>
      )
    },
    minWidth: '200px'
  }
];

export const adhocColumnDefinitions = (navigate) => {
  return [
    {
      id: 'obligation',
      header: 'Obligation',
      cell: item => (item.obligationName),
      minWidth: '180px'
    },
    {
      id: 'entity',
      cell: item => item.entity,
      header: 'Entity',
      minWidth: '80px'
    },
    {
      id: 'period',
      cell: item => item.reportingPeriod,
      header: 'Period',
      minWidth: '40px'
    },
    {
      id: 'country',
      header: 'Country',
      cell: item => item.country,
      minWidth: '30px'
    },
    {
      id: 'type',
      header: 'Type',
      cell: item => item.type,
      minWidth: '50px'
    },
    {
      id: 'status',
      header: 'Status',
      cell: item => item.status,
      minWidth: '60px'
    },
    {
      id: 'createdAt',
      header: 'Requested Date',
      cell: item => formatDate(item.adhocReportRequestDate),
      minWidth: '60px'
    },
    {
      id: 'grossMerchantSaleAmount',
      header: 'Gross Merchant Sale Amount',
      cell: item => {
        if(item.exceptionData?.reportableAmount) {
          return item.exceptionData?.currencySymbol + convertToInternationalCurrencySystem((item.exceptionData?.reportableAmount));
        }
        return "-";
      },
      minWidth: '100px'
    },
    {
      id: 'totalUniqueMerchants',
      header: 'Total Unique Merchants',
      cell: item => numberWithCommas(item.exceptionData?.reportableUniqueSellerCount),
      minWidth: '100px'
    },
    {
      id: 'action',
      header: 'Actions',
      cell: item => {
        return (
            <div className='action-box'>
              <Button iconName="download" variant="inline-icon"
                      onClick={()=> console.log('clicked')}/>
            </div>
        )
      },
      minWidth: '200px'
    }
  ];
}

export function getMatchesCountText(count) {
  return count === 1 ? `1 match` : `${count} matches`;
}

export function EmptyState({ title, subtitle, action }) {
  return (
    <Box textAlign="center" color="inherit">
      <Box variant="strong" textAlign="center" color="inherit">
        {title}
      </Box>
      <Box variant="p" padding={{ bottom: 's' }} color="inherit">
        {subtitle}
      </Box>
      {action}
    </Box>
  );
}

export const paginationLabels = {
  nextPageLabel: 'Next page',
  pageLabel: (pageNumber) => `Go to page ${pageNumber}`,
  previousPageLabel: 'Previous page'
};

export const filteringProperties = [
  {
    propertyLabel: 'Obligation',
    key: 'obligationName',
    groupValuesLabel: 'Obligation Name',
    operators: [':', '!:', '=', '!='],
  },
  {
    propertyLabel: 'Reporting Period',
    key: 'reportingPeriod',
    groupValuesLabel: 'Reporting Period',
    operators: [':', '!:', '=', '!='],
  },
  {
    propertyLabel: 'Country',
    key: 'country',
    groupValuesLabel: 'Country',
    operators: [':', '!:', '=', '!='],
  },
  {
    propertyLabel: 'Type',
    key: 'type',
    groupValuesLabel: 'Type',
    operators: [':', '!:', '=', '!='],
  },
  {
    propertyLabel: 'Status',
    key: 'status',
    groupValuesLabel: 'Status values',
    operators: [':', '!:', '=', '!='],
  },
  {
    key: 'filingDeadline',
    propertyLabel: 'Filing Deadline',
    groupValuesLabel: 'Created at value',
    defaultOperator: '>',
    operators: ['<', '<=', '>', '>='].map(operator => ({
      operator,
      form: DateTimeForm,
      format: formatDateTime,
      match: 'datetime',
    })),
  },
  {
    key: 'intermediateDeadline',
    propertyLabel: 'Intermediate Deadline',
    groupValuesLabel: 'Created at value',
    defaultOperator: '>',
    operators: ['<', '<=', '>', '>='].map(operator => ({
      operator,
      form: DateTimeForm,
      format: formatDateTime,
      match: 'datetime',
    })),
  },
]

export const vatFilteringProperties = [
  {
    propertyLabel: 'Obligation',
    key: 'obligationName',
    groupValuesLabel: 'Obligation Name',
    operators: [':', '!:', '=', '!='],
  },
  {
    propertyLabel: 'Reporting Period',
    key: 'reportingPeriod',
    groupValuesLabel: 'Reporting Period',
    operators: [':', '!:', '=', '!='],
  },
  {
    propertyLabel: 'Country',
    key: 'country',
    groupValuesLabel: 'Country',
    operators: [':', '!:', '=', '!='],
  },
  {
    propertyLabel: 'Type',
    key: 'type',
    groupValuesLabel: 'Type',
    operators: [':', '!:', '=', '!='],
  },
  {
    propertyLabel: 'Status',
    key: 'status',
    groupValuesLabel: 'Status values',
    operators: [':', '!:', '=', '!='],
  }
]

export const PROPERTY_FILTERING_I18N_CONSTANTS = {
  filteringAriaLabel: 'your choice',
  dismissAriaLabel: 'Dismiss',

  filteringPlaceholder: 'Apply filters',
  groupValuesText: 'Values',
  groupPropertiesText: 'Properties',
  operatorsText: 'Operators',

  operationAndText: 'and',
  operationOrText: 'or',

  operatorLessText: 'Less than',
  operatorLessOrEqualText: 'Less than or equal',
  operatorGreaterText: 'Greater than',
  operatorGreaterOrEqualText: 'Greater than or equal',
  operatorContainsText: 'Contains',
  operatorDoesNotContainText: 'Does not contain',
  operatorEqualsText: 'Equals',
  operatorDoesNotEqualText: 'Does not equal',

  editTokenHeader: 'Edit filter',
  propertyText: 'Property',
  operatorText: 'Operator',
  valueText: 'Value',
  cancelActionText: 'Cancel',
  applyActionText: 'Apply',
  allPropertiesLabel: 'All properties',

  tokenLimitShowMore: 'Show more',
  tokenLimitShowFewer: 'Show fewer',
  clearFiltersText: 'Clear filters',
  removeTokenButtonAriaLabel: token => `Remove token ${token.propertyKey} ${token.operator} ${token.value}`,
  enteredTextLabel: text => `Use: "${text}"`,
};

const VISIBLE_CONTENT_OPTIONS = [
  {
    label: 'Main tasks properties',
    options: [
      { id: 'obligation', label: 'Obligation' },
      { id: 'period', label: 'Period' },
      { id: 'country', label: 'Country' },
      { id: 'type', label: 'Type' },
      { id: 'status', label: 'Status' },
      { id: 'entity', label: 'Entity' },
      { id: 'filingDeadline', label: 'Filing Deadline' },
      { id: 'intermediateDeadline', label: 'Intermediate Deadline' },
      { id: 'totalErrorCount', label: 'Total Error Count' },
      { id: 'grossMerchantSaleAmount', label: 'Gross Merchant Sale Amount' },
      { id: 'totalUniqueMerchants', label: 'Total Unique Merchants' },
      { id: 'action', label: 'Action' },
      { id: 'correspondence', label: 'Correspondence' },
    ],
  },
];

export const PAGE_SIZE_OPTIONS = [
  { value: 10, label: '10 Tasks' },
  { value: 30, label: '30 Tasks' },
  { value: 50, label: '50 Tasks' },
];


export const Preferences = ({
  preferences,
  setPreferences,
  disabled,
  pageSizeOptions = PAGE_SIZE_OPTIONS,
  visibleContentOptions = VISIBLE_CONTENT_OPTIONS,
}) => (
  <CollectionPreferences
    title="Preferences"
    confirmLabel="Confirm"
    cancelLabel="Cancel"
    disabled={disabled}
    preferences={preferences}
    onConfirm={({ detail }) => setPreferences(detail)}
    pageSizePreference={{
      title: 'Page size',
      options: pageSizeOptions,
    }}
    wrapLinesPreference={{
      label: 'Wrap lines',
      description: 'Check to see all the text and wrap the lines',
    }}
    visibleContentPreference={{
      title: 'Select visible columns',
      options: visibleContentOptions,
    }}
  />
);
import {API} from "aws-amplify";
import {getAPIInputContext} from "../../../../common/utils/apiUtils";
import {
    GET_QUICKSIGHT_EMBED_URL,
    PHASTOS_ERROR_TRANSACTION,
    PHASTOS_GENERATE_PDF_REPORT, PHASTOS_GET_AUDIT_PACK_DOWNLOAD_S3_URL,
    PHASTOS_GET_REPORTING_AGGREGATED_DATA, PHASTOS_GET_S3_PRESIGNED_URL,
    PHASTOS_GET_UPLOADED_DOCUMENT_DETAILS,
    PHASTOS_INITIATE_CUSTOMER_ENGAGEMENT,
    PHASTOS_MODIFY_WORKFLOW_STATUS,
} from "../../../../ApiPaths";
import {getURLlink} from "../../../documentmanager/constants/Constants";
import {BOX_DESCRIPTION, BOX_LIST} from "../VAT/constants/boxLevelConstants";
import {get, isNull, isUndefined, set} from "lodash";

/**
 * This function will fetch error transactions for Phastos
 * @param tableDispatcher
 */
export function fetchErrorTransactions(tableDispatcher) {
    getAPIInputContext().then(input => {
        API.post("TWIN_API", PHASTOS_ERROR_TRANSACTION, input).then(resp => {
            if (resp.status === 200) {
                tableDispatcher({
                    type: "API_RESPONSE", payload: {
                        data: JSON.parse(resp.body.data),
                    }
                })
            } else {
                tableDispatcher({
                    type: "FAILURE_API_RESPONSE", payload: {}
                })
            }
        });
    });
}

export function generatePDFReport(tableDispatcher) {
    getAPIInputContext().then(input => {
        API.post("TWIN_API", PHASTOS_GENERATE_PDF_REPORT, input).then(resp => {
            if (resp.status === 200) {
                tableDispatcher({
                    type: "GENERATE_PDF_SUCCESS", payload: {
                        data: {
                            requestId: resp.requestId,
                            dispatcher: tableDispatcher
                        }
                    }
                })
                localStorage.setItem('requestId', resp?.requestId);
            } else {
                tableDispatcher({
                    type: "GENERATE_PDF_FAILURE", payload: {
                        data: {
                            dispatcher: tableDispatcher
                        }
                    }
                })
            }
        });
    });
}

export function initiateCustomerEngagement(tableDispatcher, obj) {
    getAPIInputContext().then(input => {
        input.body.requester = input.body?.user?.split("_")[1];
        input.body.useCase = obj.useCase;
        input.body.parentWorkflowId = obj.parentWorkflowId;
        input.body.requestId = obj.requestId;

        API.post("TWIN_API", PHASTOS_INITIATE_CUSTOMER_ENGAGEMENT, input).then(resp => {
            if (resp.status === 200) {
                tableDispatcher({
                    type: "INITIATE_CUSTOMER_ENGAGEMENT_SUCCESS", payload: {
                        data: {
                            dispatcher: tableDispatcher
                        }
                    }
                })
            } else {
                tableDispatcher({
                    type: "INITIATE_CUSTOMER_ENGAGEMENT_FAILURE", payload: {
                        data: {
                            dispatcher: tableDispatcher
                        }
                    }
                })
            }
        });
    });
}

export function modifyWorkflowStatus(tableDispatcher, request, onSuccess) {
    getAPIInputContext().then(input => {
        input.body.parentWorkflowId = request.parentWorkflowId;
        input.body.status = request.status;
        input.body.acknowledgementStructureMap = request.acknowledgementStructureMap;

        API.post("TWIN_API", PHASTOS_MODIFY_WORKFLOW_STATUS, input).then(resp => {
            if (resp.status === 200) {
                tableDispatcher({
                    type: "SUCCESS", payload: {
                        msg: 'Status updated successfully.',
                        dispatcher: tableDispatcher,
                        requestId: resp.requestId,
                    }
                });
                if (onSuccess) {
                    onSuccess();
                }
            } else {
                tableDispatcher({
                    type: "FAILURE", payload: {
                        errMsg: 'Failed to update the status.',
                        dispatcher: tableDispatcher,
                    }
                })
            }
        });
    });
}

export function getAttachmentDetails(tableDispatcher, request) {
    getAPIInputContext().then(input => {
        input.body.parentWorkflowId = request.parentWorkflowId;
        input.body.useCase = request.useCase;
        input.body.documentType = request.documentType;

        API.post("TWIN_API", PHASTOS_GET_UPLOADED_DOCUMENT_DETAILS, input).then(resp => {
            if (resp.status === 200) {
                tableDispatcher({
                    type: "SUCCESS", payload: {
                        documents: resp.body.documents,
                    }
                });
            } else {
                tableDispatcher({
                    type: "FAILURE", payload: {}
                })
            }
        });
    });
}

/**
 * sample request to TwinService :
 * {
 *     "dashboardId" : "6b1f23ad-9d1c-43c8-811c-16ec6c92a638",
 *     "serviceName" : "SHIELD",
 *     "useCase" : "BAS#AU",
 *     "user" : "amazon-federate_tny"
 * }
 * @param dispatcher
 * @param request
 */
export function getQuickSightEmbedUrl(dispatcher, request) {
    getAPIInputContext().then(input => {
        input.body.serviceName = request.serviceName;
        input.body.dashboardId = request.dashboardId;
        input.body.useCase = request.useCase;

        API.post("TWIN_API", GET_QUICKSIGHT_EMBED_URL, input).then(resp => {
            if (resp.status === 200) {
                dispatcher({
                    type: "SUCCESS", payload: {
                        id : request.dashboardId,
                        embedUrl: resp.embedUrl
                    }
                });
            } else {
                dispatcher({
                    type: "FAILURE", payload: {}
                });
            }
        });
    });
}

export function downloadAttachmentBys3PreSignedURL(downloadDocumentDispatcher, request) {
    getAPIInputContext().then(input => {
        input.body = {
            ...input.body,
            ...request,
        };
        API.post("TWIN_API", PHASTOS_GET_S3_PRESIGNED_URL, input).then(resp => {
            if (resp.status === 200) {
                getURLlink(resp.body.presignedUrl, request.downloadFileName);
                downloadDocumentDispatcher({
                    type: "DOWNLOAD_DOCUMENT_SUCCESS", payload: {dispatcher: downloadDocumentDispatcher}
                })
            } else {
                downloadDocumentDispatcher({
                    type: "DOWNLOAD_DOCUMENT_FAILURE", payload: {dispatcher: downloadDocumentDispatcher}
                })
            }
        });
    });
}

export function downloadAuditPackFileBys3PreSignedURL(downloadDocumentDispatcher, request) {
    getAPIInputContext().then(input => {
        input.body = {
            ...input.body,
            ...request,
        };
        API.post("TWIN_API", PHASTOS_GET_AUDIT_PACK_DOWNLOAD_S3_URL, input).then(resp => {
            if (resp.status === 200) {
                getURLlink(JSON.parse(resp.body.data).presignedUrl, null);
                downloadDocumentDispatcher({
                    type: "DOWNLOAD_DOCUMENT_SUCCESS", payload: {dispatcher: downloadDocumentDispatcher}
                })
            } else {
                downloadDocumentDispatcher({
                    type: "DOWNLOAD_DOCUMENT_FAILURE", payload: {dispatcher: downloadDocumentDispatcher}
                })
            }
        });
    });
}


export function getReportingAggregatedData(request) {
    getAPIInputContext().then(input => {
        request.setLoading(true);
        input.body.parentWorkflowId = request.parentWorkflowId;
        input.body.useCase = request.useCase;
        input.lastEvaluatedKey = request.lastEvaluatedKey;

        API.post("TWIN_API", PHASTOS_GET_REPORTING_AGGREGATED_DATA, input).then(resp => {
            if (resp.status === 200) {
                const data = JSON.parse(resp.body.data);
                const result = [];
                const allBoxMap = {};
                for (const box of BOX_LIST) {
                    for (const item of data.reportingAggregatedDataInfoList) {
                        const parsedItem = JSON.parse(item);
                        const boxLabel = get(parsedItem, 'groupingAttribute', null);
                        if (box === boxLabel) {
                            set(parsedItem, 'labelDescription', BOX_DESCRIPTION[boxLabel]);
                            allBoxMap[boxLabel] = parsedItem;
                        }
                    }
                }
                for (const box of BOX_LIST) {
                    const item = get(allBoxMap, box, null);
                    if (!isNull(item)) {
                        result.push(item);
                    } else {
                        result.push({
                            groupingAttribute: box,
                            labelDescription: BOX_DESCRIPTION[box],
                            totalGrossAmount: null,
                            totalTaxAmount: null,
                            adjustmentValue: null,
                            totalValueAfterAdjustment: null,
                        })
                    }
                }

                request.setData(result);
                request.setLastEvaluatedKey(data.lastEvaluatedKey);
                request.setLastUpdatedOn(new Date().toTimeString());
                request.setLoading(false);
            } else {
                request.setLastUpdatedOn('-');
                request.setLoading(false);
            }
        });
    });
}

export function getReportingAggregatedDataForDST(request) {
    getAPIInputContext().then(input => {
        request.setLoading(true);
        input.body.parentWorkflowId = request.parentWorkflowId;
        input.body.useCase = request.useCase;
        input.body.lastEvaluatedKey = request.lastEvaluatedKey;
        API.post("TWIN_API", PHASTOS_GET_REPORTING_AGGREGATED_DATA, input).then(resp => {
            if (resp.status === 200) {
                const response = JSON.parse(resp.body.data);
                const result = [];
                const reportingAggregatedDataInfoList = response.reportingAggregatedDataInfoList;
                for (let index in reportingAggregatedDataInfoList) {
                    const parsedData = JSON.parse(reportingAggregatedDataInfoList[index]);
                    result.push(parsedData);
                }
                if (isNull(response.lastEvaluatedKey) || isUndefined(response.lastEvaluatedKey)) {
                    request.setOpenEnd(false);
                }

                request.setData({
                    ...request.data,
                    [request.currentPageNumber]: result,
                });
                request.setLastEvaluatedKey(response.lastEvaluatedKey);
                request.setLastUpdatedOn(new Date().toTimeString());
            } else {
                request.setLoading(false);
                request.setLastUpdatedOn('-');
            }
        });
    });
}

export function getReportingAggregatedDataForMOG(request) {
    getAPIInputContext().then(input => {
        input.body.parentWorkflowId = request.parentWorkflowId;
        input.body.useCase = request.useCase;
        input.body.lastEvaluatedKey = request.lastEvaluatedKey;

        API.post("TWIN_API", PHASTOS_GET_REPORTING_AGGREGATED_DATA, input).then(resp => {
            if (resp.status === 200) {
                const response = JSON.parse(resp.body.data);
                const result = [];
                const reportingAggregatedDataInfoList = response.reportingAggregatedDataInfoList;
                for (let index in reportingAggregatedDataInfoList) {
                    const parsedData = JSON.parse(reportingAggregatedDataInfoList[index]);
                    result.push(parsedData);
                }
                if (isNull(response.lastEvaluatedKey) || isUndefined(response.lastEvaluatedKey)) {
                    request.setOpenEnd(false);
                }

                request.setData({
                    ...request.data,
                    [request.currentPageNumber]: result,
                });
                request.setLastEvaluatedKey(response.lastEvaluatedKey);
                request.setLastUpdatedOn(new Date().toTimeString());
            } else {
                request.setLoading(false);
                request.setLastUpdatedOn('-');
            }
        });
    });
}

export const GET_QUICKSIGHT_EMBED_URL_INITIAL_STATE = {
    fetching: false,
    embedUrl: '',
};

export const getQuicksightEmbedUrlInitialState = initialState =>{
    return initialState;
};

export const getQuicksightEmbedUrlReducer = (state, action) => {
    switch (action.type){
        case "BEGIN":
            return {
                ...state,
                fetching: true

            }
        case "SUCCESS":
            return {
                ...state,
                embedUrl : action.payload?.embedUrl,
                fetching: false
            }
        case "FAILURE":
            return {
                ...state,
                fetching: false

            }
        default:
            return state
    }
};



const useCaseConfig = [
    {
        id: "FRANCE",
        text: "FRANCE"
    },
    {
        id: "NOR",
        text: "NORWAY",
    },
    {
        id: "CZ",
        text: "CZECH_REPUBLIC",
    },
    {
        id: "LUX",
        text: "LUXEMBOURG",
    },
    {
        id: "DE",
        text: "GERMANY",
    },
    {
        id: "PL",
        text: "POLAND",
    },
    {
        id: "IT",
        text: "ITALY",
    }
];

export const subUseCaseConfig = {
    IT: [
        {value: "IT_E_INVOICE", label: "E_INVOICE"},
    ],
    CZ: [
        {value: "CZ_VAT", label: "VAT"},
        {value: "CZ_ESL", label: "ESL"},
        {value: "CZ_VLS", label: "VLS"}
    ],
    LUX: [
        {value: "LUX_IOSS", label: "IOSS"},
        {value: "LUX_UOSS", label: "UOSS"},
        {value: "LUX_NUOSS", label: "NUOSS"}
    ],
    DE: [
        {value: "DE_VAT", label: "VAT"},
        {value: "DE_VAT_TEST", label: "VAT_TEST"}
    ],
    PL: [
        {value: "PL_CIT", label: "CIT"},
        {value: "PL_INTRASTAT", label: "INTRASTAT"}
    ],
    FRANCE: [
        {value: "FRANCE_WITHHOLDING", label: "WITHHOLDING"}
    ]
}

export const getSubUseCaseSelectionLimits = (useCase) => {
    switch(useCase) {
        case "CZ":
            return subUseCaseConfig.CZ.length;
        case "LUX":
            return 1;
        case "DE":
            return 1;
        case "PL":
            return 1;
        case "FRANCE":
            return 1;
        case "IT":
            return 1
        default:
            return 0;
    }
}

//TODO : create API to fetch available use-cases
export function getDropdownItems() {
    return useCaseConfig;
}

/**
 * This will split id by first occurrence of "_".
 * That is it will return array of 2 elements first being parent use-case
 * Second element would be sub-use-case
 * @param useCaseId
 */
export function getParentAndSubUseCase(useCaseId) {
    const arr = useCaseId.split(/_(.*)/s);
    console.log(arr);
    if (arr.length === 1) {
        return arr;
    }
    return arr.slice(0, -1);
}
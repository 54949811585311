import React, { useEffect, useState } from "react";
import { Button, Header, PropertyFilter, SpaceBetween, Table } from "@amzn/awsui-components-react";
import { useCollection } from "@amzn/awsui-collection-hooks";
import { EmptyState, getMatchesCountText, PROPERTY_FILTERING_I18N_CONSTANTS } from "../../../phastos/table-config";
import { Preferences } from "../../table-config";
import AdjustmentsModal from "../common/adjustment/AdjustmentModal";
import { postMessage } from "../../../phastos/components/Slack/slackIntegrationAPIs";
import { getUserAlias } from "../../utils/utils";
import { getReportingAggregatedData, modifyWorkflowStatus } from "../redux/exceptionAPIs";
import { isNull } from "lodash";
import {DOWNLOAD_HEADERS_FOR_BAS_SUMMARY, HIGHLIGHTED_BOX_MAP} from "./constants/boxLevelConstants";
import { ACKNOWLEDGEMENT_STRUCTURE_MAP_KEY, ASM_COMPLETE_STATUS, OBLIGATION_STATUS } from "../common/constants";
import { getCurrencyAmountForSummary } from "../common/adjustment/constants/errorSummaryUtils";
import TableDownloadButton, {DEFINITION_TYPE_POLARIS} from "../common/TableDownloadButtonComponent";

function VATSummary(props) {
    const {
        slackChannel,
        obligationStatus,
        parentWorkflowId,
        useCase,
        lastEvaluatedKey,
        setLastEvaluatedKey,
        lastUpdatedOn,
        setLastUpdatedOn,
        onViewBasSummary,
        setStepsStateHandler,
        updateWorkflowStatusDispatcher,
        isUnderReview,
        stepsState,
        dispatcher,
        workflowIdentificationProps
    } = props;

    const COLUMN_IDENTIFIER = {
        GroupingAttribute: 'groupingAttribute',
        LabelDescription: 'labelDescription',
        CalculatedGSTIncl: 'calculatedGSTIncl',
        VAT: 'vat',
        Adjustment: 'adjustment',
        TotalForBAS: 'totalForBAS',
    };

    const columnDefinitions = [
        {
            id: COLUMN_IDENTIFIER.GroupingAttribute,
            cell: item => {
                if (HIGHLIGHTED_BOX_MAP[item.groupingAttribute]) {
                    return <div style={{color: "red"}}>{item.groupingAttribute}</div>
                } else {
                    return item.groupingAttribute;
                }
            },
            header: DOWNLOAD_HEADERS_FOR_BAS_SUMMARY.BOX_LABEL,
        },
        {
            id: COLUMN_IDENTIFIER.LabelDescription,
            cell: item => {
                if (HIGHLIGHTED_BOX_MAP[item.groupingAttribute]) {
                    return <div style={{color: "red"}}>{item.labelDescription}</div>
                } else {
                    return item.labelDescription;
                }
            },
            header: DOWNLOAD_HEADERS_FOR_BAS_SUMMARY.LABEL_DESCRIPTION,
        },
        {
            id: COLUMN_IDENTIFIER.CalculatedGSTIncl,
            header: DOWNLOAD_HEADERS_FOR_BAS_SUMMARY.TOTAL_GROSS_AMOUNT,
            cell: item => {
                if (!isNull(item.totalGrossAmount)) {
                    return getCurrencyAmountForSummary(item.totalGrossAmount);
                } else {
                    return <div style={{float: "right"}}>{"-"}</div>;
                }
            },
            sortingField: 'totalGrossAmount'
        },
        {
            id: COLUMN_IDENTIFIER.Adjustment,
            header: DOWNLOAD_HEADERS_FOR_BAS_SUMMARY.ADJUSTMENT_VALUE,
            cell: item => {
                if (!isNull(item.adjustmentValue)) {
                    return getCurrencyAmountForSummary(item.adjustmentValue);
                } else {
                    return <div style={{float: "right"}}>{"-"}</div>;
                }
            },
            sortingField: 'adjustmentValue'
        },
        {
            id: COLUMN_IDENTIFIER.TotalForBAS,
            header: DOWNLOAD_HEADERS_FOR_BAS_SUMMARY.TOTAL_VALUE_AFTER_ADJUSTMENT,
            cell: item => {
                if (!isNull(item.totalValueAfterAdjustment)) {
                    return getCurrencyAmountForSummary(item.totalValueAfterAdjustment);
                } else {
                    return <div style={{float: "right"}}>{"-"}</div>;
                }
            },
            sortingField: 'totalValueAfterAdjustment'
        },
    ];

    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);

    const refreshData = () => {
        const request = {
            setLoading: setLoading,
            setData: setData,
            setLastUpdatedOn: setLastUpdatedOn,
            setLastEvaluatedKey: setLastEvaluatedKey,
            parentWorkflowId: parentWorkflowId,
            useCase: useCase,
            lastEvaluatedKey: lastEvaluatedKey
        };
        getReportingAggregatedData(request);
    }

    useEffect(() => {
        refreshData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onAdjustmentSuccessHandler = async () => {
        const userAlias = await getUserAlias();
        postMessage(slackChannel, `${userAlias}@ has applied box-level adjustments.`);
        refreshData();
    }

    const [completeButtonState, setCompleteButtonState] = useState(false);

    const completeButtonClickHandler = async () => {
        setCompleteButtonState(true);
        const userAlias = await getUserAlias();
        const request = {
            parentWorkflowId: parentWorkflowId,
            acknowledgementStructureMap: {
                [ACKNOWLEDGEMENT_STRUCTURE_MAP_KEY.Summary]: { status: ASM_COMPLETE_STATUS, user: userAlias }
            },
        };
        const onSuccess = () => {
            postMessage(slackChannel, `${userAlias}@ has completed the Summary step.`);
            setStepsStateHandler("summaryStep");
        };
        modifyWorkflowStatus(updateWorkflowStatusDispatcher, request, onSuccess);
        setCompleteButtonState(false);
    };

    const [preferences, setPreferences] = useState({
        pageSize: 10,
        visibleContent: [
            COLUMN_IDENTIFIER.GroupingAttribute,
            COLUMN_IDENTIFIER.LabelDescription,
            COLUMN_IDENTIFIER.CalculatedGSTIncl,
            COLUMN_IDENTIFIER.VAT,
            COLUMN_IDENTIFIER.Adjustment,
            COLUMN_IDENTIFIER.TotalForBAS,
        ] 
    });

    // eslint-disable-next-line no-unused-vars
    const { items, filteredItemsCount, collectionProps, propertyFilterProps, paginationProps } = useCollection(
        data,
        {
            filtering: {
                empty: (
                    <EmptyState
                        title="No summary"
                        subtitle="No summary to display."
                    />
                ),
                noMatch: (
                    <EmptyState
                        title="No matches"
                        subtitle="We can’t find a match."
                    />
                ),
            },
            propertyFiltering: {
                filteringProperties: [
                    {
                        propertyLabel: 'Box Label',
                        key: 'groupingAttribute',
                        groupValuesLabel: 'Box Label',
                        operators: [':', '!:', '=', '!='],
                    },
                    {
                        propertyLabel: 'Label Description',
                        key: 'labelDescription',
                        groupValuesLabel: 'Label Description',
                        operators: [':', '!:', '=', '!='],
                    }],
                empty: <EmptyState title="No matches" />,
                noMatch: (
                    <EmptyState
                        title="No matches"
                    />
                ),
            },
            selection: {}
        }
    );

    const [showAdjustmentModal, setShowAdjustmentModal] = useState(false);

    const adjustmentModalDismissHandler = () => {
        setShowAdjustmentModal(false);
    };

    const onApplyAdjustmentHandler = async () => {
        setShowAdjustmentModal(true);
    }

    const [selectedItems, setSelectedItems] = useState([]);
    const onSelectionChange = (item) => {
        setSelectedItems(item.detail.selectedItems)
    };

    const isCompleteButtonDisabled = () => {
        return !(isUnderReview && !stepsState.summaryStep.completed);
    };

    const isApplyAdjustmentDisabled = () => {
        return (obligationStatus === (OBLIGATION_STATUS.UnderApproval || OBLIGATION_STATUS.UnderFiling || OBLIGATION_STATUS.Filed) || stepsState.summaryStep.completed)
    }

    return (
        <SpaceBetween size="xl">
            <Table
                {...collectionProps}
                onSelectionChange={onSelectionChange}
                header={
                    <div>
                        <Header
                            actions={<SpaceBetween size='xs' direction='horizontal'>
                                        <Button onClick={(refreshData)}>Refresh</Button>
                                        <Button onClick={onApplyAdjustmentHandler} disabled={isApplyAdjustmentDisabled()}>Apply adjustments</Button>
                                        <Button onClick={()=> {onViewBasSummary("second")}}>View BAS breakdown</Button>
                                        <Button onClick={completeButtonClickHandler}
                                                disabled={isCompleteButtonDisabled() || completeButtonState}>Complete</Button>
                                    </SpaceBetween>}
                            description={`Last updated: ${lastUpdatedOn}`}
                        >
                            BAS Summary
                        </Header>
                    </div>
                }
                columnDefinitions={columnDefinitions}
                visibleColumns={preferences.visibleContent}
                selectedItems={selectedItems}
                items={items}
                loading={loading}
                trackBy={COLUMN_IDENTIFIER.GroupingAttribute}
                preferences={<SpaceBetween size="m" direction="horizontal">
                    <TableDownloadButton data={items}
                                         nameProps={workflowIdentificationProps}
                                         tableId={'BAS Summary'}
                                         columnDefinition={columnDefinitions}
                                         tableType={DEFINITION_TYPE_POLARIS}/>
                    <Preferences preferences={preferences} setPreferences={setPreferences} visibleContentOptions={[
                    {
                        label: 'Summary properties',
                        options: [
                            { id: COLUMN_IDENTIFIER.GroupingAttribute, label: 'BAS label' },
                            { id: COLUMN_IDENTIFIER.LabelDescription, label: 'Label description' },
                            { id: COLUMN_IDENTIFIER.CalculatedGSTIncl, label: 'Calculated GST Inclusion' },
                            { id: COLUMN_IDENTIFIER.VAT, label: 'VAT' },
                            { id: COLUMN_IDENTIFIER.Adjustment, label: 'Adjustment' },
                            { id: COLUMN_IDENTIFIER.TotalForBAS, label: 'Total for BAS' },
                        ],
                    },
                    ]}/></SpaceBetween>}
                filter={
                    <PropertyFilter
                        i18nStrings={PROPERTY_FILTERING_I18N_CONSTANTS}
                        filteringProperties={propertyFilterProps.filteringProperties}
                        filteringOptions={propertyFilterProps.filteringOptions}
                        onChange={propertyFilterProps.onChange}
                        query={propertyFilterProps.query}
                        countText={getMatchesCountText(filteredItemsCount)}
                    />
                }
            />
            <AdjustmentsModal
                showAdjustmentModal={showAdjustmentModal}
                onDismiss={adjustmentModalDismissHandler}
                onSuccess={onAdjustmentSuccessHandler}
                summaryData={data}
                parentWorkflowId={parentWorkflowId}
                dispatcher={dispatcher}
            />
        </SpaceBetween>
    );
}

export default VATSummary;
import { createFlashbarItem } from "./slackUtils";

export const GET_MESSAGES_SLACK_INITIAL_STATE = {
    initialize: true,
    loading: false,
    data: {},
    error: {},
    flashBarItem: [],
};

export const getMessagesSlackDataReducerInit = initialState => {
    return initialState;
};

export const getMessagesSlackDataReducer = (state, action) => {
    switch (action.type){
        case "SUCCESS":
            return {
                ...state,
                loading: false,
                initialize:false,
                data: action.payload.data,
            }
        case "FAILURE":
            return {
                ...state,
                loading: false,
                initialize:false,
                error: action.payload.error,
                flashBarItem: [createFlashbarItem('error', action.payload.flashbarMessage, 'list_channels_failure', action.payload?.dispatcher)],
            }
        case "DISMISS_FLASHBAR":
            return {
                ...state,
                flashBarItem: []
            }
        default:
            return state
    }
};
export const countryCodeConfig = [
    {
        id: "FR",
        text: "FRANCE (FR)"
    },
    {
        id: "LU",
        text: "LUXEMBOURG (LU)",
    },
    {
        id: "NO",
        text: "NORWAY (NO)",
    },
    {
        id: "AT",
        text: "AUSTRIA (AT)",
    },
    {
        id: "AU",
        text: "AUSTRALIA (AU)",
    },
    {
        id: "DE",
        text: "GERMANY (DE)",
    },
    {
        id: "PT",
        text: "PORTUGAL (PT)",
    },
    {
        id: "UK",
        text: "UNITED KINGDOM (UK)",
    }
];

export const documentTypeConfig = [
    {
        id: "PHASTOS_GENERATED_DOCUMENTS",
        text: "PHASTOS GENERATED DOCUMENTS"
    },
    {
        id: "FILING_ACKNOWLEDGEMENT",
        text: "FILING ACKNOWLEDGEMENT",
    },
    {
        id: "CUSTOMER_ENGAGEMENT_DOCUMENT",
        text: "CUSTOMER ENGAGEMENT DOCUMENT",
    }
];

const entityConfig = [
    "ASI",
    "ASII",
    "ASE",
    "AMZNMX",
    "AMZNJP",
    "STE AMAZON COMMERCIAL SERVICES PTY LTD",
    "AMAZON TURKEY PERAKENDE HIZMETLERI LIMITED SIRKETI",
    "STE AMAZON ASIA-PACIFIC HOLDINGS PRIVATE LIMITED",
    "STE AFAQ Q TECH GENERAL TRADING LLC",
    "STE SOUQ.COM FZ-LLC",
    "AWS"
];

//TODO : create API to fetch available entities
export function getDropdownItemsForEntity() {
    return entityConfig.map(entity => ({ id: entity, text: entity }));
}
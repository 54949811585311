import React, {useEffect, useState} from "react"
import {Pagination, Table} from "@amzn/awsui-components-react/polaris";
import {Box, Header, PropertyFilter, SpaceBetween} from "@amzn/awsui-components-react";
import {collectionOptions} from "../common/adjustment/constants/boxLevelConstants";
import {useCollection} from "@amzn/awsui-collection-hooks";
import {getMatchesCountText, PROPERTY_FILTERING_I18N_CONSTANTS} from "../../../phastos/table-config";
import {Preferences} from "../../table-config";
import {DST_COLUMN_IDENTIFIER, dstAuditTableColumnDefinitions} from "./Constants";
import {getBoxLevelAdjustmentAudit} from "../../../phastos/components/phastosAPIs";

export default function DSTAdjustmentAudit(props) {

    const {parentWorkflowId} = props;
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true);
        getBoxLevelAdjustmentAudit(parentWorkflowId)
            .then(data => {
                setData(data);
            });
        setLoading(false);
        // eslint-disable-next-line
    }, []);

    const [preferences, setPreferences] = useState({
        pageSize: 10,
        visibleContent: [
            DST_COLUMN_IDENTIFIER.AdjustmentDescription,
            DST_COLUMN_IDENTIFIER.LegalEntity,
            DST_COLUMN_IDENTIFIER.MarketplaceId,
            DST_COLUMN_IDENTIFIER.UpdatedBy,
            DST_COLUMN_IDENTIFIER.UpdatedOn,
            DST_COLUMN_IDENTIFIER.TotalDSTScopeTransaction,
            DST_COLUMN_IDENTIFIER.TotalRevenue,
            DST_COLUMN_IDENTIFIER.TotalTransaction
        ],
    });

    const { items, filteredItemsCount, collectionProps, propertyFilterProps, paginationProps } = useCollection(data, collectionOptions(preferences));

    return (
        <Table
            {...collectionProps}
            columnDefinitions={dstAuditTableColumnDefinitions}
            visibleColumns={preferences.visibleContent}
            items={items}
            loading={loading}
            loadingText="Loading resources"
            preferences={<Preferences preferences={preferences} setPreferences={setPreferences} visibleContentOptions={[
                {
                    label: 'Audit properties',
                    options: [
                        { id: DST_COLUMN_IDENTIFIER.MarketplaceId, label: 'Marketplace id' },
                        { id: DST_COLUMN_IDENTIFIER.LegalEntity, label: 'Legal entity' },
                        { id: DST_COLUMN_IDENTIFIER.AdjustmentDescription, label: 'Adjustment description' },
                        { id: DST_COLUMN_IDENTIFIER.TotalRevenue, label: 'Total revenue' },
                        { id: DST_COLUMN_IDENTIFIER.TotalTransaction, label: 'Total transactions' },
                        { id: DST_COLUMN_IDENTIFIER.TotalDSTScopeTransaction, label: 'Total transactions in DST scope' },
                        { id: DST_COLUMN_IDENTIFIER.UpdatedBy, label: 'Updated by' },
                        { id: DST_COLUMN_IDENTIFIER.UpdatedOn, label: 'Updated on' },
                    ],
                },
            ]}/>}
            resizableColumns={true}
            empty={
                <Box
                    margin={{ vertical: "xs" }}
                    textAlign="center"
                    color="inherit"
                >
                    <SpaceBetween size="m">
                        <b>No adjustment audit found</b>
                    </SpaceBetween>
                </Box>
            }
            filter={
                <PropertyFilter
                    i18nStrings={PROPERTY_FILTERING_I18N_CONSTANTS}
                    filteringProperties={propertyFilterProps.filteringProperties}
                    filteringOptions={propertyFilterProps.filteringOptions}
                    onChange={propertyFilterProps.onChange}
                    query={propertyFilterProps.query}
                    countText={getMatchesCountText(filteredItemsCount)}
                />
            }
            header={
                <Header>Audits</Header>
            }
            pagination={<Pagination {...paginationProps} />}
        />
    );
}
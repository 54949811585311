import { Button, Link } from "@amzn/awsui-components-react";
import {
  getApprovalDeadlineConfig,
  getReviewDeadlineConfig,
  getFilingDeadlineConfig,
  handleObligationClick,
  createSlackChannel,
  handleAddUserSlackClick
} from "../../Constants/Constants";
import { formatDate } from "../../../../common/utils/apiUtils";
import { slackIconSvg } from "../../../../common/icons/icons8-slack";
import { addIconSvg } from "../../../../common/icons/icons8-add-24";

export function formatStatus(value){
  return statusMap[value];
}

const statusMap = {
  "FILED":"Completed",
  "UNDER_APPROVAL":"Approval",
  "UNDER_PREPARATION":"Preparation",
  "NOT_STARTED":"Upcoming",
  "UNDER_REVIEW":"Review",
  "UNDER_FILING":"Filing"
}

export const defaultSortingForMOGAppliedOn = {
  id: 'country',
  header: 'Country',
  cell: item => item.country,
  sortingField: "country",
  minWidth: '30px'
}

export const getColumnDefinitions = (navigate, createChannelSlackStatusDispatcher, addUserSlackStatusDispatcher, listChannelsSlackData, listChannelsSlackDataDispatcher) => {
    return [
      {
        id: 'obligation',
        header: 'Obligation',
        cell: item => {
          return (
              <Link onFollow={() => handleObligationClick(item, navigate, listChannelsSlackData, false)}>{`${item.obligationName} ${item.identifierMap.useCaseType}`}</Link>
          )
        },
        minWidth: '160px',
        sortingField: "obligation"
      },
      {
        id: 'country',
        header: 'Country',
        cell: item => item.country,
        sortingField: "country",
        minWidth: '30px'
      },
      {
        id: 'entity',
        cell: item => item.entity,
        header: 'Entity name',
        sortingField: "entity",
        minWidth: '80px'
      },
      {
        id: 'entityCode',
        cell: item => item.identifierMap.entityCode,
        header: 'Entity code',
        sortingField: "entityCode",
        sortingComparator: (s1, s2) => s1.identifierMap.entityCode.localeCompare(s2.identifierMap.entityCode),
        minWidth: '80px'
      },
      {
        id: 'period',
        cell: item => item.reportingPeriod,
        header: 'Period',
        sortingField: "reportingPeriod",
        minWidth: '40px'
      },
      {
        id: 'status',
        header: 'Status',
        cell: item => formatStatus(item.status),
        sortingField: "status",
        minWidth: '60px'
      },
      {
        id: 'reviewDeadline',
        header: 'Review Deadline',
        cell: item => {
          const config = getReviewDeadlineConfig(item);
          return (<div style={{color: config.color}}>{config.icon} {formatDate(item.reviewDeadline)}</div>)
        },
        sortingField: "filingDeadline",
        minWidth: '80px'
      },
      {
        id: 'approvalDeadline',
        header: 'Approval Deadline',
        cell: item => {
          const config = getApprovalDeadlineConfig(item);
          return (<div style={{color: config.color}}>{config.icon} {formatDate(item.approvalDeadline)}</div>)
        },
        sortingField: "filingDeadline",
        minWidth: '80px'
      },
      {
        id: 'filingDeadline',
        header: 'Filing Deadline',
        cell: item => {
          const config = getFilingDeadlineConfig(item);
          return (<div style={{color: config.color}}>{config.icon} {formatDate(item.filingDeadline)}</div>)
        },
        sortingField: "filingDeadline",
        minWidth: '100px'
      },
      {
        id: 'correspondence',
        header: 'Correspondence',
        cell: item => {
          return (
              <div className='correspondence-box'>
                <Button iconSvg={slackIconSvg} variant="inline-icon"
                        onClick={() => createSlackChannel(item, createChannelSlackStatusDispatcher, listChannelsSlackDataDispatcher, false)}/>
                <Button iconSvg={addIconSvg} variant="inline-icon"
                        onClick={()=> handleAddUserSlackClick(item, addUserSlackStatusDispatcher, false)}/>
              </div>
          )
        },
        minWidth: '64px'
      }
    ];
  };
import {getAPIInputContext} from "../utils/apiUtils";
import {API} from "aws-amplify";
import {REGISTER_PHASTOS_UPLOADED_DOCUMENT} from "../../ApiPaths";
import {getUserAlias} from "../../app/exception/utils/utils";

export function registerUploadedDocument(tableDispatcher, onSuccess, onFailure, request, documentId) {
    getAPIInputContext().then(async input => {
        input.body.parentWorkflowId = request.parentWorkflowId;
        input.body.fileName = request.fileName;
        input.body.documentType = request.documentType;
        input.body.useCase = request.useCase;
        input.body.documentId = documentId;
        input.body.user = await getUserAlias()
        API.post("TWIN_API", REGISTER_PHASTOS_UPLOADED_DOCUMENT, input).then(resp => {
            if (resp.status === 200) {
                tableDispatcher({
                    type: "SUCCESS", payload: {
                        msg: 'Attachment uploaded successfully.',
                        dispatcher: tableDispatcher,
                    }
                });

                if (onSuccess) {
                    onSuccess();
                }
            } else {
                onFailure();
                tableDispatcher({
                    type: "FAILURE", payload: {
                        errMsg: 'Failed to upload attachment.',
                        dispatcher: tableDispatcher,
                    }
                });
            }
        });
    });
}
import * as React from "react";
import TopNavigation from "@amzn/awsui-components-react/polaris/top-navigation";
import Auth from '@aws-amplify/auth';
import "./Navbar.css";
import { useNavigate } from "react-router-dom";
import {
  ROUTE_DOCUMENT_MANAGER,
  ROUTE_EXCALIBUR,
  ROUTE_PATH_FILING_PAGE,
  ROUTE_PATH_HOME_PAGE,
  ROUTE_PATH_PHASTOS_PAGE
} from "../constants/route_paths";

const Navbar = ({ user }) => {

  const handleUserMenuItemClick = async (event) => {
    event.preventDefault();
    if (event.detail.id === 'signout') {
      Auth.signOut();
    }
  };

  const navigate = useNavigate();
  const customNavigate = (event) => {
    event.preventDefault();
    navigate(event.detail.href === undefined ? ROUTE_PATH_HOME_PAGE : event.detail.href);
  };

  return (
    <TopNavigation className="nav-bar"
      identity={{
        href: ROUTE_PATH_HOME_PAGE,
        title: "Home",
        onFollow: customNavigate,
      }}
      utilities={[
        {
          type: "menu-dropdown",
          text: user ? user : "Login",
          description: user ? user + "@amazon.com" : "Login details",
          iconName: "user-profile",
          onItemClick: handleUserMenuItemClick,
          items: [
            {
              id: "signout",
              text: "Sign out",
            }
          ],
        },
        {
          type: "menu-dropdown",
          iconName: "menu",
          ariaLabel: "Menu",
          items: [
            {
              type: "button",
              text: "Phastos",
              href: ROUTE_PATH_PHASTOS_PAGE,
            },
            {
              type: "button",
              text: "Excalibur",
              href: ROUTE_EXCALIBUR,
            },
            {
              type: "button",
              text: "Filing",
              href: ROUTE_PATH_FILING_PAGE,
            },
            {
              type: "button",
              text: "Search Document",
              href: ROUTE_DOCUMENT_MANAGER,
            },
          ],
          onItemClick: customNavigate,
        }
      ]}
      i18nStrings={{
        searchIconAriaLabel: "Search",
        searchDismissIconAriaLabel: "Close search",
        overflowMenuTriggerText: "More",
        overflowMenuTitleText: "All",
        overflowMenuBackIconAriaLabel: "Back",
        overflowMenuDismissIconAriaLabel: "Close menu"
      }}
    />
  );
}
export default Navbar;
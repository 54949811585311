import React, {useEffect, useState} from "react";
import {
    Button,
    Header,
    Link,
    Pagination,
    PropertyFilter,
    SpaceBetween
} from "@amzn/awsui-components-react";
import {Table} from "@amzn/awsui-components-react/polaris";
import {useCollection} from "@amzn/awsui-collection-hooks";
import {EmptyState, getMatchesCountText, PROPERTY_FILTERING_I18N_CONSTANTS} from "../../../phastos/table-config";
import {
    getReportingAggregatedDataForDST,
} from "../redux/exceptionAPIs";
import {isNull, isUndefined, max} from "lodash";
import {getCurrencyAmountForSummary} from "../common/adjustment/constants/errorSummaryUtils";
import {convertStringToPercentage} from "../../utils/utils";

function DSTSummary(props) {
    const {
        parentWorkflowId,
        useCase,
        lastUpdatedOn,
        setLastUpdatedOn,
        setEntityFilter,
        setActiveTabId,
        entityBreakDownTabId
    } = props;

    const COLUMN_IDENTIFIER = {
        LegalEntity: 'legalEntityName',
        MarketplaceId: 'marketplaceId',
        TotalTransactionDSTScope: 'totalTransactionDSTScope',
        TotalTransaction: 'totalTransaction',
        NexusRatio: "nexusRatio",
        DSTRate: "dstRate",
        TotalRevenue: "totalRevenue",
        DSTBaseAmount: "dstBaseAmount",
        DSTLiability: "dstLiability",
        Category : "category",
        TotalRevenueAmountDstReportingCurrency: "entityMarketplaceTotalRevenueAmountDstReportingCurrency",
        DstBaseAmountDstReportingCurrency: "entityMarketplaceDstBaseAmountDstReportingCurrency",
        DstLiabilityDstReportingCurrency: "entityMarketplaceDstLiabilityDstReportingCurrency"
    };

    const INITIAL_PAGE_NUMBER = 1;
    const [lastEvaluatedKey, setLastEvaluatedKey] = useState(null);
    const [totalPageNumber, setTotalPageNumber] = useState(INITIAL_PAGE_NUMBER);
    const [currentPageNumber, setCurrentPageNumber] = useState(INITIAL_PAGE_NUMBER);
    const [currentPageData, setCurrentPageData] = useState([]);
    const [openEnd, setOpenEnd] = useState(true);

    const onPaginationChange = (event) => {
        const pageNumber = event.detail.currentPageIndex;
        setCurrentPageNumber(pageNumber);
        setTotalPageNumber(max([totalPageNumber, pageNumber]));

        if (isNull(data[pageNumber]) || isUndefined(data[pageNumber])) {
            refreshData(pageNumber);
        } else {
            setCurrentPageData(getEntityInformation(data));
            setLoading(false);
        }
    }

    const onEntityClick = (entityName) => {
        setEntityFilter(entityName);
        setActiveTabId(entityBreakDownTabId);
    }

    const columnDefinitions = [
        {
            id: COLUMN_IDENTIFIER.Category,
            cell: item => {
                return item.businessCategory;
            },
            header: 'Category',
        },
        {
            id: COLUMN_IDENTIFIER.LegalEntity,
            cell: item => {
                return (
                    <Link onFollow={() => onEntityClick(item.legalEntity)}>{item.legalEntity}</Link>
                )
            },

            header: 'Legal Entity',
        },
        {
            id: COLUMN_IDENTIFIER.TotalTransactionDSTScope,
            cell: item => {
                return getCurrencyAmountForSummary(item.entityMarketplaceTotalDSTScopeTransaction);
            },
            header: 'Total Transaction DST Scope',
        },
        {
            id: COLUMN_IDENTIFIER.TotalTransaction,
            cell: item => {
                return getCurrencyAmountForSummary(item.entityMarketplaceTotalTransaction);
            },
            header: 'Total Transaction',
        },
        {
            id: COLUMN_IDENTIFIER.NexusRatio,
            cell: item => {
                return convertStringToPercentage(item.entityMarketplaceNexusRatio);
            },
            header: 'Nexus Ratio',
        },
        {
            id: COLUMN_IDENTIFIER.DSTRate,
            cell: item => {
                return convertStringToPercentage(item.dstRate);
            },
            header: 'DST Rate',
        },
        {
            id: COLUMN_IDENTIFIER.TotalRevenue,
            cell: item => {
                return getCurrencyAmountForSummary(item.entityMarketplaceTotalRevenue, item.localCurrency)
            },
            header: 'Total Revenue (Local Currency)',
        },
        {
            id: COLUMN_IDENTIFIER.DSTBaseAmount,
            cell: item => {
                return getCurrencyAmountForSummary(item.entityMarketplaceDstBaseAmount, item.localCurrency)
            },
            header: 'DST Base Revenue (Local Currency)',
        },
        {
            id: COLUMN_IDENTIFIER.DSTLiability,
            cell: item => {
                return getCurrencyAmountForSummary(item.entityMarketplaceDstLiability, item.localCurrency);
            },
            header: 'DST Liability (Local Currency)',
        },
        {
            id: COLUMN_IDENTIFIER.TotalRevenueAmountDstReportingCurrency,
            cell: item => {
                return getCurrencyAmountForSummary(item.entityMarketplaceTotalRevenueAmountDstReportingCurrency, item.dstReportingCurrency);
            },
            header: 'Total Revenue (DST Reporting Currency)',
        },
        {
            id: COLUMN_IDENTIFIER.DstBaseAmountDstReportingCurrency,
            cell: item => {
                return getCurrencyAmountForSummary(item.entityMarketplaceDstBaseAmountDstReportingCurrency, item.dstReportingCurrency);
            },
            header: 'DST Base Revenue (DST Reporting Currency)',
        },
        {
            id: COLUMN_IDENTIFIER.DstLiabilityDstReportingCurrency,
            cell: item => {
                return getCurrencyAmountForSummary(item.entityMarketplaceDstLiabilityDstReportingCurrency, item.dstReportingCurrency);
            },
            header: 'DST Liability (DST Reporting Currency)',
        },

    ];

    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);

    const refreshData = (pageNumber) => {
        setLoading(true);
        const request = {
            data: data,
            setData: setData,
            setLastUpdatedOn: setLastUpdatedOn,
            lastEvaluatedKey: lastEvaluatedKey,
            setLastEvaluatedKey: setLastEvaluatedKey,
            parentWorkflowId: parentWorkflowId,
            useCase: useCase,
            currentPageNumber: pageNumber,
            setOpenEnd: setOpenEnd,
            setLoading: setLoading
        };
        getReportingAggregatedDataForDST(request);
    }

    useEffect(() => {
        refreshData(INITIAL_PAGE_NUMBER);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (!isNull(data[currentPageNumber]) && !isUndefined(data[currentPageNumber])) {
            setCurrentPageData(getEntityInformation(data));
            setLoading(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    function getEntityInformation(data) {
        const dataList = Object.values(data).flatMap(page => Object.values(page));
        const entitySet = new Set();
        let result = [];
        for (let index in dataList) {
            if (!entitySet.has(dataList[index].legalEntity)) {
                entitySet.add(dataList[index].legalEntity);
                result.push(dataList[index]);
            }
        }

        return result;
    }

    const {items, filteredItemsCount, collectionProps, propertyFilterProps} = useCollection(
        currentPageData,
        {
            filtering: {
                empty: (
                    <EmptyState
                        title="No summary"
                        subtitle="No summary to display."
                    />
                ),
                noMatch: (
                    <EmptyState
                        title="No matches"
                        subtitle="We can’t find a match."
                    />
                ),
            },
            propertyFiltering: {
                filteringProperties: [
                    {
                        propertyLabel: 'Legal Entity',
                        key: 'legalEntity',
                        groupValuesLabel: 'Legal Entity',
                        operators: ['=', '!='],
                    },
                    {
                        propertyLabel: 'Category',
                        key: 'category',
                        groupValuesLabel: 'Category',
                        operators: ['=', '!='],
                    },
                ],
                empty: <EmptyState title="No matches"/>,
                noMatch: (
                    <EmptyState
                        title="No matches"
                    />
                ),
            },
            selection: {}
        }
    );

    return (
        <SpaceBetween size="xl">
            <Table
                {...collectionProps}
                loading={loading}
                header={
                    <div>
                        <Header
                            actions={<SpaceBetween size='xs' direction='horizontal'>
                                <Button onClick={() => {
                                    refreshData(currentPageNumber)
                                }}>Refresh</Button>
                            </SpaceBetween>}
                            description={`Last updated: ${lastUpdatedOn}`}
                        >
                            DST Summary
                        </Header>
                    </div>
                }
                columnDefinitions={columnDefinitions}
                resizableColumns={false}
                items={items}
                filter={
                    <PropertyFilter
                        i18nStrings={PROPERTY_FILTERING_I18N_CONSTANTS}
                        filteringProperties={propertyFilterProps.filteringProperties}
                        filteringOptions={propertyFilterProps.filteringOptions}
                        onChange={propertyFilterProps.onChange}
                        query={propertyFilterProps.query}
                        countText={getMatchesCountText(filteredItemsCount)}
                    />
                }
                pagination={<Pagination
                    currentPageIndex={currentPageNumber}
                    onChange={onPaginationChange}
                    pagesCount={totalPageNumber}
                    openEnd={openEnd}
                />}
            />
        </SpaceBetween>
    )
}

export default DSTSummary;
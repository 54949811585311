import React, { useEffect } from "react";
import { Header, Pagination } from "@amzn/awsui-components-react/polaris";

function ExcelPreviewTable(params) {

    const { tableTitle, header, data} = params;

    const [currentPageIndex, setCurrentPageIndex] = React.useState(1);
    const [totalPageCount, setTotalPageCount] = React.useState(1);
    const [previewData, setPreviewData] = React.useState([]);

    useEffect(()=>{
        setTotalPageCount(Math.ceil((data.length)/10))
        setPreviewData(data.slice(0, 10))
    },[data])

    const getPreviewDataPerPage = (pageIndex) => {
        const startIdx = pageIndex*10 ;
        setPreviewData(data?.slice(startIdx, startIdx + 10))
    }


    return (
        <>
            <Header variant="h2">{tableTitle}</Header>
            <div style={{float:"right"}}>
                <Pagination
                    currentPageIndex={currentPageIndex}
                    onChange={({ detail }) => {
                        setCurrentPageIndex(detail.currentPageIndex)
                        getPreviewDataPerPage(detail.currentPageIndex-1)
                    }
                        
                    }
                    pagesCount={totalPageCount}
                />
            </div>
            <table id="excelPreviewTable">
                <thead>
                    <tr id={0}>
                    {Object.entries(header).map((entry) => {
                            const [index, columnHeader] = entry;
                            return  <th id={index}>{columnHeader}</th>
                    })}
                    </tr>
                </thead>
                <tbody>
                    {previewData.map((res,rowIdx) => {
                        return <>
                            <tr id={rowIdx}>
                                {Object.entries(header).map((entry) => {
                                    const [columnIdx, columnHeader] = entry;
                                    return  <td id={rowIdx+'-'+columnIdx}>{res[columnHeader]}</td>
                                })}
                            </tr>
                        </>
                    })}
                </tbody>
            </table>
        </>
    );
}

export default ExcelPreviewTable;

import { Link } from "@amzn/awsui-components-react";
import { getCurrencyAmountForSummary } from "../../common/adjustment/constants/errorSummaryUtils";

export const getColumnDefinitions = (handleShowTransactions, currencySymbol) => {
    return [
        {
          id: 'transactionType',
          header: 'Type',
          cell: item => {
            return (
                <Link onFollow={() => handleShowTransactions({
                    type: item.transactionType,
                })}>{item.transactionType}</Link>
            )
          },
          minWidth: '144px'
        }, 
        {
            id: 'transactionCategory',
            header: 'Reason Code',
            cell: item => {
              return (
                  <Link onFollow={() => handleShowTransactions({
                    type: item.transactionType,
                    category: item.transactionCategory,
                })}>{item.transactionCategory}</Link>
              )
            },
            minWidth: '184px'
        },
        {
            id: 'severity',
            header: 'Severity',
            cell: item => item.severity,
            minWidth: '128px'
        },
        {
            id: 'exceptionStatus',
            header: 'Status',
            cell: item => {
              return (
                  <Link onFollow={() => handleShowTransactions({
                    type: item.transactionType,
                    category: item.transactionCategory,
                    status: item.exceptionStatus,
                })}>{item.exceptionStatus}</Link>
              )
            },
            minWidth: '128px'
        },
        {
            id: 'transactionCount',
            header: 'Count',
            cell: item => {
                const count  = parseInt(item.transactionCount);
                const parsedCount = count.toLocaleString("en-US");
                return <div style={{float: "right"}}>{parsedCount}</div>;
            },
            minWidth: '108px'
        },
        {
            id: 'netAmount',
            header: 'Net Amount (AUD)',
            cell: item => { return getCurrencyAmountForSummary(item.netAmount, currencySymbol); },
            minWidth: '160px'
        }, 
        {
            id: 'netTax',
            header: 'Net Tax (AUD)',
            cell: item => { return getCurrencyAmountForSummary(item.netTax, currencySymbol); },
            minWidth: '160px'
        },
        {
            id: 'netGrossAmount',
            header: 'Net Gross Amount (AUD)',
            cell: item => { return getCurrencyAmountForSummary(item.netGrossAmount, currencySymbol); },
            minWidth: '176px'
        }
    ];
};

export const SHIELD_METRIC_TYPES = {
    TRANSACTION_REVIEW_SUMMARY: "TRANSACTION_REVIEW_SUMMARY",
    TRANSACTION_SUMMARY: "TRANSACTION_SUMMARY"
};
import { createFlashbarItem } from "./slackUtils";

export const CREATE_CHANNEL_SLACK_INITIAL_STATE = {
    initialize: true,
    loading: false,
    data: {},
    error: {},
    flashBarItem: [],
};

export const createChannelSlackStatusReducerInit = initialState => {
    return initialState;
};

export const createChannelSlackStatusReducer = (state, action) => {
    switch (action.type){
        case "SUCCESS": 
            return {
                ...state,
                loading: false,
                initialize:false,
                data: action.payload.data,
                flashBarItem: [createFlashbarItem('success', action.payload.flashbarMessage, 'slack_API_success', action.payload?.dispatcher)],
            }
        case "FAILURE":
            return {
                ...state,
                loading: false,
                initialize:false,
                error: action.payload.error,
                flashBarItem: [createFlashbarItem('error', action.payload.flashbarMessage, 'slack_API_failure', action.payload?.dispatcher)],
            }
        case "DISMISS_FLASHBAR":
                return {
                    ...state,
                    flashBarItem: []
                }     
        default:
            return state
    }
};
import {getAPIInputContext} from "../../../../common/utils/apiUtils";
import {API} from "aws-amplify";
import {GET_PERSONA_LIST_FOR_GIVEN_ROLE, IS_PAGE_AUTHORIZED} from "../../../../ApiPaths";

/**
 * Updates User Role based on getPersonaListForGivenRole Api of TwinService
 * Updates UserRole based on isPageAuthorized Api in case of failure as backward compatible option
 * @param useCase
 * @param parentWorkflowId
 * @param resourceNameList - List of possible roles with indexing in order of priority for eg : [REVIEWER_ROLE, APPROVER_ROLE, ADMINISTRATOR_ROLE]
 */
export function updateUserRole(useCase, parentWorkflowId, resourceNameList, setUserRole) {
    try {
        getAPIInputContext().then(input => {
            input.body.useCase = useCase;
            input.body.parentWorkflowId = parentWorkflowId;

            API.post("TWIN_API", GET_PERSONA_LIST_FOR_GIVEN_ROLE, input).then(resp => {
                if (resp.status === 200) {
                    for (let resourceNameListIdx = 0; resourceNameListIdx < resourceNameList.length; resourceNameListIdx++) {
                        const role = resourceNameList[resourceNameListIdx];
                        if (resp.body.personaList.includes(role)) {
                            setUserRole(role);
                        }
                    }
                }
                else{
                    updateUserRoleBasedOnIsPageAuthorized(resourceNameList, setUserRole)
                }
            });
        });
    }
    catch (e) {
        console.log(e);
        updateUserRoleBasedOnIsPageAuthorized(resourceNameList, setUserRole);
    }
}

/**
 * Updates UserRole based on IsPageAuthorized API
 * @param resourceNameList - - List of possible roles with indexing in order of priority for eg : [REVIEWER_ROLE, APPROVER_ROLE, ADMINISTRATOR_ROLE]
 * @param setUserRole - to update userRole state
 */
export function updateUserRoleBasedOnIsPageAuthorized(resourceNameList, setUserRole){
    for (let resourceNameListIdx = 0; resourceNameListIdx < resourceNameList.length; resourceNameListIdx++) {
        getAPIInputContext().then(input => {
            input.body.resourceName = resourceNameList[resourceNameListIdx];
            API.post("TWIN_API", IS_PAGE_AUTHORIZED, input).then(resp => {
                if (resp.status === 200) {
                    if (resp.isAuthorized) {
                        console.log(input.body.resourceName);
                        setUserRole(input.body.resourceName);
                    }
                }
            });

        });
    }
}
export const slackIconSvg = (
    <span>
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            width="20px"
            height="20px"
        >
            <path d="M9 7C8.359 7 4.639 7 4 7 2.895 7 2 7.895 2 9c0 1.105.895 2 2 2 .639 0 4.359 0 5 0 1.105 0 2-.895 2-2C11 7.895 10.105 7 9 7zM11 4.107V5.5C11 5.776 10.779 6 10.503 6 10.021 6 9.317 6 9 6 7.895 6 7 5.105 7 4c0-1.215 1.083-2.176 2.336-1.973C10.319 2.187 11 3.111 11 4.107zM7 14c0 .641 0 4.361 0 5 0 1.105.895 2 2 2 1.105 0 2-.895 2-2 0-.639 0-4.359 0-5 0-1.105-.895-2-2-2C7.895 12 7 12.895 7 14zM4.107 12H5.5C5.776 12 6 12.221 6 12.497 6 12.979 6 13.682 6 14c0 1.105-.895 2-2 2-1.215 0-2.176-1.083-1.973-2.336C2.187 12.68 3.11 12 4.107 12zM14 16c.641 0 4.361 0 5 0 1.105 0 2-.895 2-2 0-1.105-.895-2-2-2-.639 0-4.359 0-5 0-1.105 0-2 .895-2 2C12 15.104 12.895 16 14 16zM12 18.893v-1.393c0-.276.221-.5.497-.5.482 0 1.186 0 1.503 0 1.105 0 2 .895 2 2 0 1.215-1.083 2.176-2.336 1.973C12.68 20.812 12 19.889 12 18.893zM16 9c0-.641 0-4.361 0-5 0-1.105-.895-2-2-2-1.105 0-2 .895-2 2 0 .639 0 4.359 0 5 0 1.105.895 2 2 2C15.104 11 16 10.104 16 9zM18.893 11h-1.393c-.276 0-.5-.221-.5-.497 0-.482 0-1.186 0-1.503 0-1.105.895-2 2-2 1.215 0 2.176 1.083 1.973 2.336C20.812 10.319 19.889 11 18.893 11z"/>
        </svg>
    </span>
);
